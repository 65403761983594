import gql from 'graphql-tag';

export const updateRole = gql`
  mutation updateRoleMutation($id: ID!, $newPermission: String!) {
    updateRole(id: $id, newPermission: $newPermission) {
      user {
        company {
          employees {
            id
            username
            email
            jobtitle
            helpdeskId
            isVerified
            role {
              id
              name
            }
            groups {
              id
              name
            }
          }
          pendingEmployees {
            id
            email
            isVerified
            role {
              id
              name
            }
            invitationToken {
              isExpired
            }
            groups {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const updateRoles = gql`
  mutation updateRolesMutation($ids: [ID!], $newPermission: String!) {
    updateRoles(ids: $ids, newPermission: $newPermission) {
      employees {
        id
        username
        email
        jobtitle
        helpdeskId
        isVerified
        role {
          id
          name
        }
        groups {
          id
          name
        }
      }
      pendingEmployees {
        id
        email
        isVerified
        role {
          id
          name
        }
        invitationToken {
          isExpired
        }
        groups {
          id
          name
        }
      }
    }
  }
`;

export const createRole = gql`
  mutation createRoleMutation(
    $name: String!
    $customRolePermissionNames: [String]!
  ) {
    createRole: createCustomRole(
      name: $name
      customRolePermissionNames: $customRolePermissionNames
    ) {
      id
      name
      isCustom
      customRolePermissions {
        rolePermission
      }
    }
  }
`;

export const updateCustomRole = gql`
  mutation updateCustomRoleMutation(
    $id: ID!
    $name: String!
    $customRolePermissionNames: [String]!
  ) {
    updateCustomRole(
      id: $id
      name: $name
      customRolePermissionNames: $customRolePermissionNames
    ) {
      id
      name
      isCustom
      customRolePermissions {
        rolePermission
      }
    }
  }
`;

export const deleteCustomRole = gql`
  mutation deleteCustomRoleMutation($id: ID!) {
    deleteCustomRole(id: $id)
  }
`;

export const updateUsersRole = gql`
  mutation updateUsersRoleMutationn($ids: [ID!]!, $customRoleId: ID!) {
    updateUsersRole(ids: $ids, customRoleId: $customRoleId) {
      id
      isVerified
      role {
        id
        name
      }
    }
  }
`;
