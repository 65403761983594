import * as types from './mutation-types';
import initState from './state';

export default {
  [types.LOGOUT](state) {
    if (state.timeoutId) clearTimeout(state.timeoutId);

    Object.assign(state, initState());
  },
  [types.SET_IS_CONNECTED](state, isConnected) {
    state.isConnected = isConnected;
  },

  [types.SET_WEBSOCKET_DATA](state, { connectionUrl, connectionGroups }) {
    state.connectionUrl = connectionUrl;
    state.connectionGroups = connectionGroups;
  },

  [types.SET_TIMEOUT_ID](state, timeoutId) {
    if (state.timeoutId) clearTimeout(state.timeoutId);
    state.timeoutId = timeoutId;
  },
};
