import * as types from '../mutation-types';
import debounce from 'lodash.debounce';

// USAGE TABLE
export const getUsageStatCardsData = async function ({ getters, commit }) {
  const { queryParamsFromFilters: params } = getters;
  commit(types.SET_USAGE_STAT_CARDS_LOADING, true);
  const rawUsageStatCards =
    await this.$services.contentAnalytics.getUsageStatCards(params);
  commit(types.SET_USAGE_STAT_CARDS, rawUsageStatCards);
  commit(types.SET_USAGE_STAT_CARDS_LOADING, false);
};

export const getAdoptionRateData = async function ({ commit, getters }) {
  const { queryParamsFromFilters: params } = getters;
  commit(types.SET_USAGE_ADOPTION_RATE_CHART_DATA_LOADING, true);
  const rawUsageAdoptionRateChartData =
    await this.$services.contentAnalytics.getAdoptionRateData(params);
  commit(
    types.SET_USAGE_ADOPTION_RATE_CHART_DATA,
    rawUsageAdoptionRateChartData,
  );
  commit(types.SET_USAGE_ADOPTION_RATE_CHART_DATA_LOADING, false);
};

export const getUsageTableData = async function ({ commit, getters }) {
  const { queryParamsForUsageTable: params } = getters;
  commit(types.SET_USAGE_TABLE_LOADING, true);
  const rawUsageTable = await this.$services.contentAnalytics.getUsageTableData(
    params,
  );
  commit(types.SET_USAGE_TABLE, rawUsageTable);
  commit(types.SET_USAGE_TABLE_LOADING, false);
};

export const debouncedGetUsageTableData = debounce(getUsageTableData, 700);

export const initAdditionalUsageTableFilters = async function ({
  commit,
  getters,
}) {
  if (!getters.additionalUsageTableFiltersArePopulated) {
    commit(types.SET_USAGE_TABLE_ADDITIONAL_FILTERS, [
      { key: 'isWithViews', value: true },
      { key: 'search', value: '' },
      { key: 'page', value: 1 },
    ]);
  }
};

export const updateUsageTableIsWithViews = async function (
  { commit, state },
  value,
) {
  const idx = state.additionalUsageTableFilters.findIndex(
    ({ key }) => key === 'isWithViews',
  );
  if (idx !== -1) {
    const el = state.additionalUsageTableFilters[idx];
    commit(types.SET_USAGE_TABLE_ADDITIONAL_FILTERS_VALUE, {
      value: { ...el, value },
      idx,
    });
  }
};

export const updateUsageTableDataOptions = function (
  { commit, dispatch, getters },
  options,
) {
  const { currentPage, sort } = options;

  if (getters.usageTableSearchFilterChanged)
    commit(types.SET_USAGE_TABLE_SEARCH_FILTER_CHANGED, false);
  return Promise.all([
    dispatch('updateUsageTablePage', currentPage),
    dispatch('updateUsageTableSortOptions', sort),
  ]);
};

export const updateUsageTablePage = async function ({ commit, state }, value) {
  const idx = state.additionalUsageTableFilters.findIndex(
    ({ key }) => key === 'page',
  );
  if (idx !== -1) {
    const currentPage = state.additionalUsageTableFilters[idx];
    commit(types.SET_USAGE_TABLE_PAGE_CHANGED, currentPage.value !== value);
    // { key: String, value: any }
    commit(types.SET_USAGE_TABLE_ADDITIONAL_FILTERS_VALUE, {
      value: { key: 'page', value },
      idx,
    });
  }
};

export const updateUsageTableSortOptions = async function ({ commit }, sort) {
  commit(types.SET_USAGE_TABLE_SORT_OPTIONS, sort);
};

export const updateUsageTableSearch = async function (
  { commit, state },
  value,
) {
  const idx = state.additionalUsageTableFilters.findIndex(
    ({ key }) => key === 'search',
  );
  if (idx !== -1) {
    const currentFilter = state.additionalUsageTableFilters[idx];
    commit(
      types.SET_USAGE_TABLE_SEARCH_FILTER_CHANGED,
      currentFilter.value !== value,
    );
    // { key: String, value: any }
    commit(types.SET_USAGE_TABLE_ADDITIONAL_FILTERS_VALUE, {
      value: { ...currentFilter, value },
      idx,
    });
  }
};

// USAGE MODAL

export const hideUsageModal = async function ({ commit }) {
  commit(types.SET_USAGE_MODAL_SHOW, false);
  commit(types.RESET_USAGE_MODAL);
};

export const getUsageModalTableData = async function ({
  commit,
  state,
  getters,
}) {
  if (!getters.shouldGetUsageModalDetails) return;
  const { queryParamsForUsageModalTable: params } = getters;
  commit(types.SET_USAGE_MODAL_TABLE_IS_LOADING, true);
  commit(
    types.SET_USAGE_MODAL_TABLE,
    await this.$services.contentAnalytics.getUsageModalTableDataForUser(
      state.usageModalId,
      params,
    ),
  );
  commit(types.SET_USAGE_MODAL_TABLE_IS_LOADING, false);
};

export const updateUsageModalTableDataOptions = function (
  { dispatch },
  options,
) {
  const { currentPage, sort } = options;

  return Promise.all([
    dispatch('updateUsageModalTablePage', currentPage),
    dispatch('updateUsageModalTableSortOptions', sort),
  ]);
};

export const updateUsageModalTablePage = async function (
  { commit, state },
  value,
) {
  const idx = state.additionalUsageModalTableFilters.findIndex(
    ({ key }) => key === 'page',
  );
  if (idx !== -1) {
    const currentPage = state.additionalUsageModalTableFilters[idx];
    commit(
      types.SET_USAGE_MODAL_TABLE_PAGE_CHANGED,
      currentPage.value !== value,
    );
    // { key: String, value: any }
    commit(types.SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS_VALUE, {
      value: { key: 'page', value },
      idx,
    });
  }
};

export const updateUsageModalTableSortOptions = async function (
  { commit },
  sort,
) {
  commit(types.SET_USAGE_MODAL_TABLE_SORT_OPTIONS, sort);
};

export const prepareUsageModalDetails = async function (
  { commit, dispatch, state, getters },
  id = null,
) {
  if (id && state.usageModalId !== id) {
    commit(types.SET_USAGE_MODAL_ID, id);
  }

  commit(types.SET_USAGE_MODAL_SHOW, true);
  commit(types.SET_USAGE_MODAL_CHART_IS_LOADING, true);
  commit(types.SET_USAGE_MODAL_DETAILS_IS_LOADING, true);
  // MODAL STATS TITLES
  commit(
    types.SET_USAGE_MODAL_DETAILS,
    await this.$services.contentAnalytics.getUsageForUser(
      state.usageModalId,
      getters.queryParamsFromFilters,
    ),
  );
  commit(types.SET_USAGE_MODAL_DETAILS_IS_LOADING, false);
  // CHART
  commit(
    types.SET_USAGE_MODAL_CHART_DATA,
    await this.$services.contentAnalytics.getUsageLineChartForUser(
      state.usageModalId,
      getters.queryParamsFromFilters,
    ),
  );
  commit(types.SET_USAGE_MODAL_CHART_IS_LOADING, false);
  if (!getters.additionalUsageTableModalFiltersArePopulated)
    commit(types.SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS, [
      { key: 'page', value: 1 },
    ]);
  dispatch('getUsageModalTableData');
};

export const initUsageTableSortOptions = async function ({ commit, getters }) {
  if (!getters.usageTableSortOptionsArePopulated)
    commit(types.SET_USAGE_TABLE_SORT_OPTIONS, {});
};

export const initUsageModalTableSortOptions = async function ({
  commit,
  getters,
}) {
  if (!getters.usageModalTableSortOptionsArePopulated)
    commit(types.SET_USAGE_MODAL_TABLE_SORT_OPTIONS, {});
};
