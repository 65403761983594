import { urlCurator } from './event.utils';
import store from '../../../store';

export class ContentEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  static eventProcessing(content) {
    let path = content.path;
    let enrichedPath;

    // entity = 'Case'
    if (!content.type && content.path) {
      enrichedPath = content.path; // Keep list of objects
      path = content.path.filter(Boolean).map(({ id }) => id);
    }

    return {
      id: content.id,
      knowledgeId: content.knowledgeId,
      type: content.type,
      caseParameters: content.caseParameters,
      parameters: content.parameters,
      labels: content.labels ? content.labels.map((l) => l.id) : [],
      path,
      enrichedPath,
    };
  }

  static searchEventProcessing(search) {
    return {
      ...search,
      query: search.search,
    };
  }

  static searchEventHitsProcessing(payload) {
    return {
      displayedHits: payload.displayedHits,
      totalHits: payload.totalHits,
    };
  }

  async read(content, url, origin = 'WEB_PARAMETRIC') {
    const source = content.source || 'navigation';
    const data = {
      content: this.constructor.eventProcessing(content),
      deepLoading: [
        'search',
        'favorite',
        'recommendation',
        'notification-attached-content',
        'inline-content',
        'previous-inline-content',
      ].includes(source),
      source,
      lang: content.lang,
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_READ',
      event: data,
      url: urlCurator(url),
    });
  }

  async create(content, url, origin = 'ADMIN') {
    const event = { content: this.constructor.eventProcessing(content) };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_CREATE',
      event: event,
      url: urlCurator(url),
    });
  }

  async archive(content, url, origin = 'ADMIN') {
    const event = { content: this.constructor.eventProcessing(content) };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_ARCHIVE',
      event: event,
      url: urlCurator(url),
    });
  }

  async feedback(content, feedback, url, origin = 'WEB_PARAMETRIC') {
    const event = {
      content: this.constructor.eventProcessing(content),
      feedback,
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_FEEDBACK',
      event: event,
      url: urlCurator(url),
    });
  }

  async satisfaction(
    content,
    satisfaction,
    lang,
    url,
    origin = 'WEB_PARAMETRIC',
  ) {
    const event = {
      content: this.constructor.eventProcessing(content),
      satisfaction,
      lang,
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_SATISFACTION',
      event: event,
      url: urlCurator(url),
    });
  }

  async contribution(content, contribution, url, origin = 'WEB_PARAMETRIC') {
    const event = {
      ...this.constructor.eventProcessing(content),
      contribution,
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_CONTRIBUTION',
      event: event,
      url: urlCurator(url),
    });
  }

  async addFavorite(favorite, url, origin = 'MAYDAY_WEB') {
    const event = { favorite };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_FAVORITE_ADD',
      event: event,
      url: urlCurator(url),
    });
  }

  async removeFavorite(favorite, url, origin) {
    const event = { favorite };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_FAVORITE_REMOVE',
      event: event,
      url: urlCurator(url),
    });
  }

  async search(payload, url, origin = 'MAYDAY_WEB') {
    const event = {
      knowledgeId: payload.knowledgeId,
      search: this.constructor.searchEventProcessing(payload.search),
      ...this.constructor.searchEventHitsProcessing(payload),
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_SEARCH',
      event: event,
      url: urlCurator(url),
    });
  }

  async searchSuccess(payload, url, origin = 'MAYDAY_WEB') {
    const event = {
      knowledgeId: payload.knowledgeId,
      content: this.constructor.eventProcessing(payload.content),
      search: this.constructor.searchEventProcessing(payload.search),
      itemIndex: payload.itemIndex,
      ask: payload.ask,
      ...this.constructor.searchEventHitsProcessing(payload),
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_SEARCH_SUCCESS',
      event: event,
      url: urlCurator(url),
    });
  }

  async searchAbort(payload, url, origin = 'MAYDAY_WEB') {
    const event = {
      knowledgeId: payload.knowledgeId,
      search: this.constructor.searchEventProcessing(payload.search),
      ...this.constructor.searchEventHitsProcessing(payload),
    };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_SEARCH_ABORT',
      event: event,
      url: urlCurator(url),
    });
  }

  async verificationPolicyUpdate(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_VERIFICATION_POLICY_UPDATE',
      event,
      url: urlCurator(url),
    });
  }

  async verificationPolicyAdd(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_VERIFICATION_POLICY_ADD',
      event,
      url: urlCurator(url),
    });
  }

  async verificationPolicyRemove(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_VERIFICATION_POLICY_REMOVE',
      event,
      url: urlCurator(url),
    });
  }

  async contentIsOutdatedUpdate(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_IS_OUTDATED_UPDATE',
      event,
      url: urlCurator(url),
    });
  }

  async contentPublishedUpdate(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_PUBLISHED_UPDATE',
      event,
      url: urlCurator(url),
    });
  }

  /**
   * WORKFLOW_ACTIIONS
   */
  async createWorkflowAction(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_WORKFLOW_ACTION_CREATE',
      event,
      url: urlCurator(url),
    });
  }

  async updateWorkflowAction(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_WORKFLOW_ACTION_UPDATE',
      event,
      url: urlCurator(url),
    });
  }

  async deleteWorkflowAction(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_WORKFLOW_ACTION_DELETE',
      event,
      url: urlCurator(url),
    });
  }

  async orderWorkflowAction(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'CONTENT_WORKFLOW_ACTION_ORDER',
      event,
      url: urlCurator(url),
    });
  }

  /**
   * WORKFLOW_ACTIION_LIBRARY
   */
  async addWorkflowActionLibrary(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'WORKFLOW_ACTION_LIBRARY_CREATE',
      event,
      url: urlCurator(url),
    });
  }

  async updateWorkflowActionLibrary(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'WORKFLOW_ACTION_LIBRARY_UPDATE',
      event,
      url: urlCurator(url),
    });
  }
}
