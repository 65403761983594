export const workflowMetrics = (
  { workflowMetrics },
  { workflowMetricsIsLoading },
) => {
  if (workflowMetricsIsLoading) {
    return [];
  }
  return workflowMetrics;
};

export const workflowMetricsIsLoading = ({ workflowMetricsIsLoading }) => {
  return workflowMetricsIsLoading;
};

export const workflowFunctionInfos = ({ workflowResources }) => {
  const names = new Map();
  for (let resource of workflowResources) {
    for (let fun of resource.functions) {
      names.set(fun.id, {
        name: fun.title,
        url: `${fun.config.method.toUpperCase()} ${fun.config.url}`,
      });
    }
  }
  return names;
};
