<template>
  <div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('concepts.new-concept-modal.label-title') }}
      </h4>
      <div class="d-flex align-items-center gap-2 w-full">
        <el-input
          v-model="concept.label"
          :placeholder="$t('concepts.new-concept-modal.label-placeholder')"
          @change="onKeyupEnterLabel"
          @keyup.tab="onKeyupEnterLabel"
        />
        <el-select v-model="concept.defaultLanguage" class="select">
          <el-option
            v-for="lang in langOptions"
            :key="lang"
            :value="lang"
            :label="$t(`knowledge.languages.${lang}`)"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('concepts.new-concept-modal.body-title') }}
      </h4>
      <el-input
        ref="ConceptBodyEditor"
        v-model="concept.body"
        :placeholder="$t('concepts.new-concept-modal.body-placeholder')"
        type="textarea"
      />
    </div>
  </div>
</template>
<script>
import { langOptions } from '@/utils/langOptions.js';
export default {
  name: 'concept-edition',

  data() {
    return {
      concept: {
        label: '',
        body: '',
        defaultLanguage: null,
      },
      langOptions,
    };
  },
  methods: {
    onKeyupEnterLabel() {
      if (this.concept.label.length > 0) {
        this.$refs['ConceptBodyEditor'].focus();
      }
    },
  },
  watch: {
    concept: {
      handler() {
        this.$emit('update-concept', this.concept);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  text-align: left;
  margin-bottom: 1rem;
}
.section-title {
  color: $grey-7-mayday;
  text-transform: uppercase;
}

.notification__editor {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
}

.add-button {
  min-width: 57px;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue-mayday;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  &__disable {
    background-color: $blue-1-mayday;
    &:hover {
      cursor: not-allowed;
    }
  }
  &__enable:hover {
    cursor: pointer;
  }
}
</style>
