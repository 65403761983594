import * as types from './mutation-types';
import initState from './state';

export default {
  /**
   * GENERAL
   */
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  /**
   * COMPANY FAVORITE
   */
  [types.UPDATE_COMPANY_FAVORITE_LOADING](state, loading) {
    state.companyFavoriteLoading = loading;
  },
  /**
   * CURRENT PLUGIN
   */
  [types.UPDATE_PLUGIN](state, payload) {
    const { type } = payload[0];

    state.pluginEntities[type] = payload;
  },
};
