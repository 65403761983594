/* eslint-disable no-unused-vars */
export const cleanPluginEntities = (pluginEntities) => {
  if (!pluginEntities) {
    return [];
  }
  const cleanedPluginEntities = pluginEntities.map(
    ({ __typename: entityTypename, ...entity }) => {
      const { integrations, icon } = entity;
      const { __typename: iconTypename, ...cleanedIcon } = icon || {};
      let cleanedIntegrations = [];
      if (integrations) {
        cleanedIntegrations = integrations.map(
          ({ __typename: integrationTypename, ...integration }) => integration,
        );
      }
      return {
        ...entity,
        integrations: cleanedIntegrations,
        icon: cleanedIcon,
      };
    },
  );

  return cleanedPluginEntities;
};
