// GENERAL DASHBOARD
export const SET_FILTERS = 'SET_FILTERS';
export const SET_FILTERS_LOADING = 'SET_FILTERS_LOADING';
export const SET_FILTERS_VALUES = 'SET_FILTERS_VALUES';
export const SET_FILTERS_ADDITIONAL_VALUES = 'SET_FILTERS_ADDITIONAL_VALUES';
export const SET_FILTERS_DATE_RANGE_SHORTCUT_VALUES =
  'SET_FILTERS_DATE_RANGE_SHORTCUT_VALUES';
export const SET_FILTERS_VALUE = 'SET_FILTERS_VALUE';
export const SET_FILTERS_ADDITIONAL_VALUE = 'SET_FILTERS_ADDITIONAL_VALUE';
export const RESET_FILTERS_VALUE = 'RESET_FILTERS_VALUE';
export const SET_TABLE_FILE = 'SET_TABLE_FILE';
export const SET_TABLE_FILE_IS_LOADING = 'SET_TABLE_FILE_IS_LOADING';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const LOGOUT = 'LOGOUT';
export const RESET_CONTENTS = 'RESET_CONTENTS';
export const RESET_USAGES = 'RESET_USAGES';
export const SET_MAP_SOURCE_TO_LABEL = 'SET_MAP_SOURCE_TO_LABEL';

export const SET_VIEWS_GRAPH_ADDITIONAL_FILTERS =
  'SET_VIEWS_GRAPH_ADDITIONAL_FILTERS';
export const SET_VIEWS_GRAPH_ADDITIONAL_FILTER_VALUE =
  'SET_VIEWS_GRAPH_ADDITIONAL_FILTER_VALUE';
export const SET_CONTENT_TABLE_ADDITIONAL_FILTERS =
  'SET_CONTENT_TABLE_ADDITIONAL_FILTERS';
export const SET_CONTENT_TABLE_ADDITIONAL_FILTER_VALUE =
  'SET_CONTENT_TABLE_ADDITIONAL_FILTER_VALUE';
export const SET_CONTENT_TABLE_ADDITIONAL_SEARCH_FILTER_CHANGED =
  'SET_CONTENT_TABLE_ADDITIONAL_SEARCH_FILTER_CHANGED';
export const SET_CONTENT_TABLE_PAGE_CHANGED = 'SET_CONTENT_TABLE_PAGE_CHANGED';
export const SET_CONTENT_TABLE_ADDITIONAL_COLLECTION_FILTER_CHANGED =
  'SET_CONTENT_TABLE_ADDITIONAL_COLLECTION_FILTER_CHANGED';
export const SET_CONTENT_CHART_DATA_LOADING = 'SET_CONTENT_CHART_DATA_LOADING';
export const SET_CONTENT_LINE_CHART_BY_COLLECTION =
  'SET_CONTENT_LINE_CHART_BY_COLLECTION';
export const SET_CONTENT_LINE_CHART_BY_SOURCE =
  'SET_CONTENT_LINE_CHART_BY_SOURCE';
export const SET_CONTENT_STAT_CARDS = 'SET_CONTENT_STAT_CARDS';
export const SET_CONTENT_STAT_CARDS_LOADING = 'SET_CONTENT_STAT_CARDS_LOADING';
export const SET_CONTENT_PUBLIC_STAT_CARDS = 'SET_CONTENT_PUBLIC_STAT_CARDS';
export const SET_CONTENT_PUBLIC_STAT_CARDS_LOADING =
  'SET_CONTENT_PUBLIC_STAT_CARDS_LOADING';
export const SET_CONTENT_TABLE = 'SET_CONTENT_TABLE';
export const SET_CONTENT_TABLE_LOADING = 'SET_CONTENT_TABLE_LOADING';
export const SET_CONTENT_SEARCH_STATISTICS = 'SET_CONTENT_SEARCH_STATISTICS';
export const SET_CONTENT_SEARCH_STATISTICS_LOADING =
  'SET_CONTENT_SEARCH_STATISTICS_LOADING';
export const SET_CONTENT_TABLE_SORT_OPTIONS = 'SET_CONTENT_TABLE_SORT_OPTIONS';

// DOWNLOAD ANALYTICS
export const SET_EXPORTS = 'SET_EXPORTS';
export const EXPORT_USER_LOADING = 'EXPORT_USER_LOADING';

// CONTENT MODAL
export const SET_CONTENT_MODAL_SHOW = 'SET_CONTENT_MODAL_SHOW';
export const RESET_CONTENT_MODAL = 'RESET_CONTENT_MODAL';
export const SET_CONTENT_MODAL_ID = 'SET_CONTENT_MODAL_ID';
export const SET_CONTENT_MODAL_IS_DIAGNOSTIC =
  'SET_CONTENT_MODAL_IS_DIAGNOSTIC';
export const SET_CONTENT_MODAL_LINE_CHART = 'SET_CONTENT_MODAL_LINE_CHART';
export const SET_CONTENT_MODAL_LINE_CHART_IS_LOADING =
  'SET_CONTENT_MODAL_LINE_CHART_IS_LOADING';
export const SET_CONTENT_MODAL_CHART_TYPE = 'SET_CONTENT_MODAL_CHART_TYPE';
export const SET_CONTENT_MODAL_DIAGNOSTIC_CHART =
  'SET_CONTENT_MODAL_DIAGNOSTIC_CHART';
export const SET_CONTENT_MODAL_DIAGNOSTIC_CHART_IS_LOADING =
  'SET_CONTENT_MODAL_DIAGNOSTIC_CHART_IS_LOADING';
export const SET_CONTENT_MODAL_CONTENT_DETAILS =
  'SET_CONTENT_MODAL_CONTENT_DETAILS';
export const SET_CONTENT_MODAL_CONTENT_DETAILS_IS_LOADING =
  'SET_CONTENT_MODAL_CONTENT_DETAILS_IS_LOADING';

// CONTENT SEARCH TABLES
export const SET_CONTENT_SEARCH_ADDITIONAL_FILTER_VALUE =
  'SET_CONTENT_SEARCH_ADDITIONAL_FILTER_VALUE';

// USAGE
export const SET_USAGE_STAT_CARDS = 'SET_USAGE_STAT_CARDS';
export const SET_USAGE_STAT_CARDS_LOADING = 'SET_USAGE_STAT_CARDS_LOADING';
export const SET_USAGE_ADOPTION_RATE_CHART_DATA =
  'SET_USAGE_ADOPTION_RATE_CHART_DATA';
export const SET_USAGE_ADOPTION_RATE_CHART_DATA_LOADING =
  'SET_USAGE_ADOPTION_RATE_CHART_DATA_LOADING';

// USAGE TABLE
export const SET_USAGE_TABLE_ADDITIONAL_FILTERS =
  'SET_USAGE_TABLE_ADDITIONAL_FILTERS';
export const SET_USAGE_TABLE_ADDITIONAL_FILTERS_VALUE =
  'SET_USAGE_TABLE_ADDITIONAL_FILTERS_VALUE';
export const SET_USAGE_TABLE = 'SET_USAGE_TABLE';
export const SET_USAGE_TABLE_LOADING = 'SET_USAGE_TABLE_LOADING';
export const SET_USAGE_TABLE_PAGE_CHANGED = 'SET_USAGE_TABLE_PAGE_CHANGED';
export const SET_USAGE_TABLE_SORT_OPTIONS = 'SET_USAGE_TABLE_SORT_OPTIONS';
export const SET_USAGE_TABLE_SEARCH_FILTER_CHANGED =
  'SET_USAGE_TABLE_SEARCH_FILTER_CHANGED';

// USAGE MODAL
export const SET_USAGE_MODAL_SHOW = 'SET_USAGE_MODAL_SHOW';
export const RESET_USAGE_MODAL = 'RESET_USAGE_MODAL';
export const SET_USAGE_MODAL_ID = 'SET_USAGE_MODAL_ID';
export const SET_USAGE_MODAL_DETAILS = 'SET_USAGE_MODAL_DETAILS';
export const SET_USAGE_MODAL_DETAILS_IS_LOADING =
  'SET_USAGE_MODAL_DETAILS_IS_LOADING';
export const SET_USAGE_MODAL_TABLE = 'SET_USAGE_MODAL_TABLE';
export const SET_USAGE_MODAL_TABLE_IS_LOADING =
  'SET_USAGE_MODAL_TABLE_IS_LOADING';
export const SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS =
  'SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS';
export const SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS_VALUE =
  'SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS_VALUE';
export const SET_USAGE_MODAL_TABLE_PAGE_CHANGED =
  'SET_USAGE_MODAL_TABLE_PAGE_CHANGED';
export const SET_USAGE_MODAL_TABLE_SORT_OPTIONS =
  'SET_USAGE_MODAL_TABLE_SORT_OPTIONS';
export const SET_USAGE_MODAL_CHART_IS_LOADING =
  'SET_USAGE_MODAL_CHART_IS_LOADING';
export const SET_USAGE_MODAL_CHART_DATA = 'SET_USAGE_MODAL_CHART_DATA';
export const SET_USAGE_MODAL_FEEDBACKS = 'SET_USAGE_MODAL_FEEDBACKS';
export const SET_USAGE_MODAL_FEEDBACKS_IS_LOADING =
  'SET_USAGE_MODAL_FEEDBACKS_IS_LOADING';

// ASK METRICS
export const SET_ASK_METRICS_IS_LOADING = 'SET_ASK_METRICS_IS_LOADING';
export const SET_ASK_METRICS = 'SET_ASK_METRICS';
export const EXPORT_ASK_ANALYTICS_LOADING = 'EXPORT_ASK_ANALYTICS_LOADING';

// WORKFLOW METRICS
export const SET_WORKFLOW_METRICS_IS_LOADING = 'SET_WORKFLOW_METRICS_IS_LOADING';
export const SET_WORKFLOW_METRICS = 'SET_WORKFLOW_METRICS';
export const EXPORT_WORKFLOW_ANALYTICS_LOADING = 'EXPORT_WORKFLOW_ANALYTICS_LOADING';

export const SET_RESOURCES = 'SET_RESOURCES';