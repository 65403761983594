import gql from 'graphql-tag';

export default {
  getArchives: gql`
    query getArchivesQuery(
      $knowledge: String!
      $entities: [String!]
      $page: Int
      $count: Int
      $search: String
      $lang: String
    ) {
      getStructuredArchives(
        knowledge: $knowledge
        entities: $entities
        page: $page
        count: $count
        search: $search
        lang: $lang
      ) {
        archivedArticles {
          results {
            ... on ContentArchive {
              id
              content {
                id
                label
                type
              }
              isFromCase
              formerCaseParents {
                id
                label
              }
              deletedCaseArchiveFrom {
                id
                case {
                  id
                  label
                }
              }
              author {
                id
                username
                isDeleted
              }
              createdAt
            }
          }
          total
          page
          pages
        }
        archivedDiagnostics {
          results {
            ... on ContentArchive {
              id
              content {
                id
                label
                type
              }
              isFromCase
              formerCaseParents {
                id
                label
              }
              deletedCaseArchiveFrom {
                id
                case {
                  id
                  label
                }
              }
              author {
                id
                username
                isDeleted
              }
              createdAt
            }
          }
          total
          page
          pages
        }
        archivedCases {
          results {
            ... on CaseArchive {
              id
              case {
                id
                label
                type
              }
              isFromCase
              formerCaseParents {
                id
                label
              }
              deletedCaseArchiveFrom {
                id
                case {
                  id
                  label
                }
              }
              author {
                id
                username
                isDeleted
              }
              createdAt
            }
          }
          total
          page
          pages
        }
      }
    }
  `,
};
