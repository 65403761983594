import utils from './content.utils';

export class ContentService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  async getVersions(id) {
    return this.data.readContentVersions(id);
  }
  async getDescendents(id) {
    return this.data.getTreeNodeDescendents(id);
  }
  async getTreeNodesAncestors(ids) {
    return this.data.getTreeNodesAncestors(ids);
  }

  /**
   * CREATE METHODS
   */

  async createVerificationPolicy(
    contentId,
    userId,
    customRoleId,
    verificationRange,
  ) {
    return this.data.createVerificationPolicy(
      contentId,
      userId,
      customRoleId,
      verificationRange,
    );
  }

  /**
   * UPDATE METHODS
   */

  async update(newContent, processing = false) {
    let localContent = { ...newContent };
    if (processing) localContent = utils.parsingContentHTML(newContent);
    return this.data.updateNodeContent(localContent);
  }
  async setContentAsTrusted(contentId) {
    return this.data.setContentAsTrusted(contentId);
  }
  async setContentAsUntrusted(contentId) {
    return this.data.setContentAsUntrusted(contentId);
  }
  async updateVerificationPolicy(
    id,
    userId,
    customRoleId,
    verificationRange,
  ) {
    return this.data.updateVerificationPolicy(
      id,
      userId,
      customRoleId,
      verificationRange,
    );
  }
  async updatePublishStatus(id, published) {
    return this.data.updateContentPublishStatus(id, published);
  }
  async bulkUpdatePublishStatus(ids, published) {
    return this.data.bulkUpdateContentPublishStatus(ids, published);
  }
  async bulkUpdateOutdatedStatus(ids, isOutdated) {
    return this.data.bulkUpdateContentOutdatedStatus(ids, isOutdated);
  }

  /**
   * DELETE METHODS
   */

  async deleteVerificationPolicy(contentId) {
    return this.data.deleteVerificationPolicy(contentId);
  }
}
