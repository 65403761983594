import store from '@/store';

export class AiEditorAssistEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  async trigger(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'AI_EDITOR_ASSIST_TRIGGER',
      event,
      url,
    });
  }

  async useSuggestion(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'AI_EDITOR_ASSIST_USE_SUGGESTION',
      event,
      url,
    });
  }

  async cancelSuggestion(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'AI_EDITOR_ASSIST_CANCEL_SUGGESTION',
      event,
      url,
    });
  }
}
