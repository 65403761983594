import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import initState from './state';

export default {
  namespaced: true,
  state: initState(),
  getters,
  actions,
  mutations,
};
