import gql from 'graphql-tag';

export default {
  getAllCompanyPublicConfigs: gql`
    query getAllCompanyPublicConfigs {
      CompanyPublicConfigs: getAllCompanyPublicConfigs {
        id
        knowledgeId
        hostname
        customHostname
      }
    }
  `,

  getCompanyPublicConfigById: gql`
    query getCompanyPublicConfigById($id: ID!) {
      CompanyPublicConfig: getCompanyPublicConfigById(id: $id) {
        id
        buildConfig
        uiConfig
        hostname
        knowledgeId
        customHostname
        embeddedAccessKey
      }
    }
  `,

  getCompanyPublicConfigByKnowledgeId: gql`
    query getCompanyPublicConfigByKnowledgeId($id: ID!) {
      CompanyPublicConfig: getCompanyPublicConfigByKnowledgeId(id: $id) {
        id
        buildConfig
        uiConfig
        knowledgeId
        hostname
        customHostname
        embeddedAccessKey
      }
    }
  `,

  checkHostnameValidity: gql`
    query checkHostnameValidity($hostname: String) {
      hostnameValidity: checkHostnameValidity(hostname: $hostname)
    }
  `,
  checkCdnEndpointHttpsStatus: gql`
    query checkCdnEndpointHttpsStatus($hostname: String) {
      cdnEndpointHttpsStatus: checkCdnEndpointHttpsStatus(hostname: $hostname)
    }
  `,
  checkCdnEndpointValidity: gql`
    query checkCdnEndpointValidity(
      $customHostname: String
      $companyPublicConfigId: ID!
    ) {
      cdnEndpointValidity: checkCdnEndpointValidity(
        customHostname: $customHostname
        companyPublicConfigId: $companyPublicConfigId
      )
    }
  `,

  publishOperations: gql`
    mutation publishOperations($id: ID!, $operations: [OperationInput]) {
      changedRows: updateBuildAndUiConfig(id: $id, operations: $operations)
    }
  `,

  getPendingDeploymentStatus: gql`
    query getPendingDeploymentStatus($companyPublicConfigId: ID!) {
      deploymentStatus: getPendingDeploymentStatus(
        companyPublicConfigId: $companyPublicConfigId
      ) {
        companyPublicConfigId
        deploymentId
        deploymentStatus
        publicConfigVersion
        publicConfigBuild
        createdAt
      }
    }
  `,

  createCompanyPublicConfig: gql`
    mutation createCompanyPublicConfig(
      $subdomain: String!
      $lang: String!
      $operations: [OperationInput]
      $groups: [String]
    ) {
      CompanyPublicConfig: createCompanyPublicConfig(
        subdomain: $subdomain
        lang: $lang
        operations: $operations
        groups: $groups
      ) {
        id
        buildConfig
        uiConfig
        hostname
        knowledgeId
        customHostname
      }
    }
  `,

  uploadPublicFile: gql`
    mutation uploadPublicFile($fileName: String!, $fileData: String!) {
      uploadPublicFile(fileName: $fileName, fileData: $fileData)
    }
  `,
};
