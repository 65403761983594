import data from './knowledge.data.raw';

export class KnowledgeServiceData {
  constructor(client) {
    this.client = client;
  }

  async getOneById(id) {
    return this.client
      .query({
        query: data.readKnowledge,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.readKnowledge)
      .catch((error) => error);
  }

  async getDefault() {
    return this.client
      .query({
        query: data.defaultKnowledge,
      })
      .then((response) => response.data.defaultKnowledge)
      .catch((error) => error);
  }

  async getCollections(id) {
    return this.client
      .query({
        query: data.readKnowledgeCollections,
        variables: { id },
      })
      .then((response) => response.data.readCollections)
      .catch((error) => error);
  }

  async updateDefaultKnowledge(knowledgeId) {
    return this.client
      .query({
        query: data.updateDefaultKnowledgeMutation,
        variables: { knowledgeId },
      })
      .then((response) => response.data.updateDefaultKowledge)
      .catch((error) => error);
  }

  async deleteKnowledge(knowledgeId) {
    return this.client
      .mutation({
        query: data.deleteKnowledgeMutation,
        variables: { knowledgeId },
      })
      .then((response) => response.data.deleteKnowledgeMutation)
      .catch((error) => error);
  }
}
