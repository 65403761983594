import { handleCancelService } from '@/adapters/axiosClient';

export class WorkflowAnalyticServiceData {
  constructor(analyticsClient) {
    this.analyticsClient = analyticsClient;
    this.handleCancelService = handleCancelService;
    this.workflowMetricsCancelService = null;
  }

  getWorkflowMetrics(params) {
    this.workflowMetricsCancelService = this.handleCancelService(
      this.workflowMetricsCancelService,
    );
    return this.analyticsClient
      .get('/workflow/metrics', {
        params,
        cancelToken: this.workflowMetricsCancelService.token,
      })
      .then((res) => res.data);
  }

  getContextSessionLogsExport(sessionId) {
    const fileName = `context-session-${sessionId}.xlsx`;
    return this.analyticsClient
      .get(`/workflow/session/${sessionId}`, {
        responseType: 'blob',
        params: { sessionId },
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }
}
