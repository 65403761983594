import {
  rawDataToLineChart,
  rawDataToPieChart,
  rawDataToStackedLineChart,
  createFilterValueAccessor,
  isNotEmptyParam,
  mapTrendToBadgeType,
} from '../utils';
import { EXTERNAL_DOC_ANCHORS } from '../constants';

export const queryParamsForViewsGraph = (state, getters) => {
  return getters.queryParamsFromFilters
    .concat(
      state.additionalViewsGraphFilters.filter(
        ({ key }) => key !== 'graphType',
      ),
    )
    .filter(({ value }) => isNotEmptyParam(value));
};

export const queryParamsForContentTable = (state, getters) => {
  return getters.queryParamsFromFilters
    .concat(state.additionalContentTableFilters)
    .concat(getters.contentTableSortQueryParam)
    .filter(({ value }) => isNotEmptyParam(value));
};

export const queryParamsForContentSearchStatistics = (_state, getters) => {
  return getters.queryParamsFromFilters.concat({
    key: 'mergeTerms',
    value: getters.mergeTerms,
  });
};

export const viewsGraphDrawType = ({ additionalViewsGraphFilters }) => {
  return createFilterValueAccessor(additionalViewsGraphFilters, 'graphType');
};

export const viewsGraphFilterType = ({ additionalViewsGraphFilters }) => {
  return createFilterValueAccessor(additionalViewsGraphFilters, 'filterType');
};

export const contentTableViewsFilterType = ({
  additionalContentTableFilters,
}) => {
  return createFilterValueAccessor(
    additionalContentTableFilters,
    'isWithViews',
  );
};

export const contentTableSearchFilter = ({ additionalContentTableFilters }) => {
  return createFilterValueAccessor(additionalContentTableFilters, 'search');
};

export const contentTableCollectionsFilter = ({
  additionalContentTableFilters,
}) => {
  return createFilterValueAccessor(additionalContentTableFilters, 'parentIds');
};

export const viewsGraphIsBySource = (_, getters) => {
  return getters.viewsGraphFilterType === 'source';
};

export const viewsGraphIsPie = (_, getters) => {
  return getters.viewsGraphDrawType === 'doughnut';
};

export const contentTableIsWithViews = (_, getters) => {
  return getters.contentTableViewsFilterType;
};

export const rawContentLineChart = (state, getters) => {
  return getters.viewsGraphIsBySource
    ? state.rawContentLineChartBySource
    : state.rawContentLineChartByCollection;
};

export const contentLineChartBySource = (state, getters) => {
  const { analyticsDateRange: labels, mapSourceToLabel } = getters;

  return rawDataToLineChart(
    labels,
    state.rawContentLineChartBySource,
    'label',
    'interactionDate',
    'count',
    mapSourceToLabel,
  );
};

export const contentPieChartBySource = (state, getters) => {
  return rawDataToPieChart(
    state.rawContentLineChartBySource,
    'label',
    'count',
    getters.mapSourceToLabel,
  );
};

export const contentLineChartByCollection = (state, getters) => {
  const { analyticsDateRange: labels, mapSourceToLabel } = getters;

  return rawDataToLineChart(
    labels,
    state.rawContentLineChartByCollection,
    'label',
    'interactionDate',
    'count',
    mapSourceToLabel,
  );
};

export const contentPieChartByCollection = (state, getters) => {
  return rawDataToPieChart(
    state.rawContentLineChartByCollection,
    'label',
    'count',
    getters.mapSourceToLabel,
  );
};

export const contentChartData = (_, getters) => {
  const isBySource = getters.viewsGraphIsBySource;
  const isPie = getters.viewsGraphIsPie;

  if (isBySource) {
    return isPie
      ? getters.contentPieChartBySource
      : getters.contentLineChartBySource;
  } else {
    return isPie
      ? getters.contentPieChartByCollection
      : getters.contentLineChartByCollection;
  }
};

export const contentChartIsEmpty = (state, getters) => {
  const { contentChartDataIsLoading, contentChartData } = getters;
  if (contentChartDataIsLoading) {
    return false;
  }
  return (
    contentChartData != null &&
    contentChartData.hasOwnProperty('datasets') &&
    Array.isArray(contentChartData.datasets) &&
    !contentChartData.datasets.length
  );
};

export const additionalViewsGraphFiltersArePopulated = ({
  additionalViewsGraphFilters,
}) =>
  additionalViewsGraphFilters != null &&
  Array.isArray(additionalViewsGraphFilters) &&
  !!additionalViewsGraphFilters.length;

export const contentChartDataIsLoading = ({ contentChartDataIsLoading }) =>
  contentChartDataIsLoading;

export const additionalContentTableFiltersArePopulated = (
  { additionalContentTableFilters },
  { contentTableIsLoading },
) =>
  !contentTableIsLoading &&
  additionalContentTableFilters != null &&
  !!additionalContentTableFilters.length;

export const contentTableDataIsLoading = ({ contentTableDataIsLoading }) =>
  contentTableDataIsLoading;

export const contentTableSearchFilterChanged = ({
  contentTableSearchFilterChanged,
}) => contentTableSearchFilterChanged;

export const contentTableData = ({ contentTableData: { results = [] } = {} }) =>
  results;

export const contentTableDataIsReady = (
  _,
  { contentTableIsLoading, contentTableData },
) =>
  !contentTableIsLoading &&
  contentTableData != null &&
  !!contentTableData.length;

export const contentTableIsEmpty = (
  _,
  { contentTableIsLoading, contentTableData },
) => {
  return (
    !contentTableIsLoading &&
    contentTableData != null &&
    !contentTableData.length
  );
};

export const contentTableMetadata = ({ contentTableData = {} }) => {
  // eslint-disable-next-line no-unused-vars
  const { results, ...metadata } = contentTableData;

  return metadata;
};

export const contentTableTotalCount = (
  _,
  { contentTableMetadata, contentTableDataIsReady },
) => {
  if (!contentTableDataIsReady) return 0;
  return contentTableMetadata.total;
};

export const contentTableTotalPages = (
  _,
  { contentTableMetadata, contentTableDataIsReady },
) => {
  if (!contentTableDataIsReady) return 1;
  return contentTableMetadata.pages;
};

export const contentTableCurrentPage = (
  { additionalContentTableFilters },
  { contentTableDataIsReady },
) => {
  if (!contentTableDataIsReady) return 1;
  return createFilterValueAccessor(additionalContentTableFilters, 'page') || 1;
};

export const contentStatsLoading = ({ rawContentStatCardsLoading }) =>
  rawContentStatCardsLoading;

export const contentStatsAreReady = ({
  rawContentStatCards,
  rawContentStatCardsLoading,
}) =>
  !rawContentStatCardsLoading &&
  rawContentStatCards !== null &&
  rawContentStatCards.hasOwnProperty('articleCount') &&
  rawContentStatCards.hasOwnProperty('diagnosticCount') &&
  rawContentStatCards.hasOwnProperty('confidenceScore');

export const contentPublicStatCardsLoading = ({
  rawContentPublicStatCardsLoading,
}) => rawContentPublicStatCardsLoading;

export const contentPublicStatsAreReady = ({
  rawContentPublicStatCards,
  rawContentPublicStatCardsLoading,
}) =>
  !rawContentPublicStatCardsLoading &&
  rawContentPublicStatCards !== null &&
  rawContentPublicStatCards.hasOwnProperty('views') &&
  rawContentPublicStatCards.hasOwnProperty('sessions') &&
  rawContentPublicStatCards.hasOwnProperty('feedbacks');

export const contentKnowledgeStats = (
  { rawContentStatCards },
  { contentStatsAreReady },
) => {
  if (!contentStatsAreReady) {
    return [];
  }
  // eslint-disable-next-line no-unused-vars
  const { confidenceScore, ...knowledgeStats } = rawContentStatCards;

  return [
    {
      name: 'new-dashboard.stat-card.name.analytics-contents-stats.articles',
      footerSlot: 'draftCount',
      iconSlot: 'articleIcon',
      badgeType: 'success',
      stat: knowledgeStats.articleCount,
      footerStat: knowledgeStats.articleDraftCount,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentArticleCount,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-contents-stats.diagnostics',
      footerSlot: 'draftCount',
      iconSlot: 'diagnosticIcon',
      badgeType: 'success',
      stat: knowledgeStats.diagnosticCount,
      footerStat: knowledgeStats.diagnosticDraftCount,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentDiagnosticCount,
    },
  ];
};

export const contentConfidenceScoreStat = (
  { rawContentStatCards },
  { contentStatsAreReady },
) => {
  if (!contentStatsAreReady) {
    return [];
  }

  const { confidenceScore } = rawContentStatCards;

  return [
    {
      name: '',
      footerSlot: 'additional-text',
      icon: 'percentage',
      iconColor: 'blue',
      stat: confidenceScore,
      footerStat: confidenceScore < 1 ? 'todo' : 'ok',
      isEmpty: confidenceScore === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentConfidenceScore,
    },
  ];
};

export const contentConfidenceScoreStatIsEmpty = ({
  rawContentStatCardsLoading,
  rawContentStatCards,
}) =>
  !rawContentStatCardsLoading &&
  rawContentStatCards.hasOwnProperty('confidenceScore') &&
  !rawContentStatCards.confidenceScore;

export const contentPublicKnowledgeStats = (
  { rawContentPublicStatCards },
  { contentPublicStatsAreReady },
) => {
  if (!contentPublicStatsAreReady) return [];

  return [
    {
      name: 'new-dashboard.stat-card.name.analytics-contents-public-stats.views',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-contents-public-stats.views',
      icon: 'eye',
      footerSlot: 'trendText',
      stat: rawContentPublicStatCards.views.count,
      badgeType: mapTrendToBadgeType[rawContentPublicStatCards.views.trend],
      badgeSlot: 'trendBadge',
      additionalStat: rawContentPublicStatCards.views.trend,
      footerStat: rawContentPublicStatCards.views.trend,
      isEmpty: rawContentPublicStatCards.views.count === 0,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-contents-public-stats.sessions',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-contents-public-stats.sessions',
      icon: 'users',
      footerSlot: 'trendText',
      stat: rawContentPublicStatCards.sessions.count,
      badgeType: mapTrendToBadgeType[rawContentPublicStatCards.sessions.trend],
      badgeSlot: 'trendBadge',
      additionalStat: rawContentPublicStatCards.sessions.trend,
      footerStat: rawContentPublicStatCards.sessions.trend,
      isEmpty: rawContentPublicStatCards.sessions.count === 0,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-contents-public-stats.feedbacks',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-contents-public-stats.feedbacks',
      icon: 'megaphone',
      stat: rawContentPublicStatCards.feedbacks,
      isEmpty: rawContentPublicStatCards.feedbacks === 0,
    },
  ];
};

export const contentTableDataOptions = (
  { additionalContentTableFilters },
  {
    additionalContentTableFiltersArePopulated,
    contentTableSortOptionsArePopulated,
    contentTableCurrentPage,
    contentTableTotalPages,
    contentTableSearchFilter,
    contentTableSortOptions,
  },
) => {
  if (
    !additionalContentTableFiltersArePopulated ||
    !contentTableSortOptionsArePopulated
  ) {
    return {};
  }
  const collectionFilter = additionalContentTableFilters.find(
    ({ key }) => key === 'parentIds',
  );
  const collectionValues = collectionFilter ? collectionFilter.value : [];

  return {
    currentPage: contentTableCurrentPage,
    totalPageCount: contentTableTotalPages,
    filters: [
      {
        key: 'collection',
        values: collectionValues,
      },
    ],
    sort: contentTableSortOptions,
    search: contentTableSearchFilter,
  };
};

export const contentTableIsReady = (_, { contentTableDataOptions }) => {
  return (
    contentTableDataOptions.hasOwnProperty('currentPage') &&
    contentTableDataOptions.hasOwnProperty('totalPageCount') &&
    contentTableDataOptions.hasOwnProperty('filters') &&
    contentTableDataOptions.hasOwnProperty('sort') &&
    contentTableDataOptions.hasOwnProperty('search')
  );
};

export const contentTableSortOptionsArePopulated = ({
  contentTableSortOptions,
}) => contentTableSortOptions != null;

export const contentTableSortOptions = (
  { contentTableSortOptions },
  { contentTableSortOptionsArePopulated },
) => {
  return contentTableSortOptionsArePopulated ? contentTableSortOptions : {};
};

export const contentTableSortQueryParam = (
  _,
  { contentTableSortOptions, contentTableSortOptionsArePopulated },
) => {
  if (!contentTableSortOptionsArePopulated) {
    return [];
  }

  return [
    {
      key: 'sortDirection',
      value: contentTableSortOptions.ascending ? 'ASC' : 'DESC',
    },
    { key: 'sortOption', value: contentTableSortOptions.prop },
  ];
};

export const contentModalIsReady = (
  _,
  {
    contentModalDetails,
    contentModalDetailsIsLoading,
    shouldGetContentModalDetails,
  },
) =>
  !contentModalDetailsIsLoading &&
  shouldGetContentModalDetails &&
  contentModalDetails != null &&
  contentModalDetails.hasOwnProperty('content') &&
  contentModalDetails.hasOwnProperty('parents') &&
  contentModalDetails.hasOwnProperty('views');

export const contentModalMetadata = (
  _,
  { contentModalDetails, contentModalIsReady },
) => {
  if (!contentModalIsReady) {
    return {
      contentTitle: '',
      parentTitle: '',
    };
  }

  const { content, parents } = contentModalDetails;

  return {
    contentTitle: content.title,
    parentTitle: parents.map((parent) => parent.title).join('/'),
  };
};

export const contentModalOverviewStats = (
  _state,
  { contentModalDetails, contentModalIsReady },
  _rootState,
  { isParametric },
) => {
  if (!contentModalIsReady) {
    return [];
  }
  const { views, userReturns, feedbacks, satisfactions, languages } =
    contentModalDetails;

  return [
    {
      name: 'new-dashboard.stat-card.name.analytics-contents-stats.content-views',
      footerSlot: 'trendText',
      icon: 'eye',
      badgeSlot: 'trendBadge',
      badgeType: mapTrendToBadgeType[views.trend],
      stat: views.count,
      additionalStat: views.trend,
      tooltipStat: languages,
      footerStat: views.trend,
      isEmpty: views.count === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentModalViewCount,
    },
    {
      name: `new-dashboard.stat-card.name.analytics-contents-stats.${
        isParametric ? 'user-returns' : 'feedbacks'
      }`,
      icon: 'megaphone',
      footerSlot: 'userReturnsRepartition',
      footerStat: {
        feedbackCount: feedbacks || 0,
        satisfactionCount: satisfactions || 0,
      },
      stat: userReturns,
      isEmpty: userReturns === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.contentModalFeedbackCount,
    },
  ];
};

export const contentSearchStatisticsAreReady = (
  _,
  { contentSearchStatistics },
) => !!Object.keys(contentSearchStatistics).length;

export const contentSearchStatistics = ({ contentSearchStatistics }) =>
  contentSearchStatistics;

export const contentSearchLoading = ({ contentSearchLoading }) =>
  contentSearchLoading;

export const showContentModal = ({ showContentModal }) => showContentModal;

export const shouldGetContentModalDetails = ({
  contentModalIsDiagnostic,
  contentModalId,
}) => contentModalId != null && contentModalIsDiagnostic != null;

export const contentModalDiagnosticChart = (
  {
    contentModalDiagnosticChart,
    contentModalDiagnosticChartIsLoading,
    contentModalIsDiagnostic,
  },
  { shouldGetContentModalDetails },
) => {
  if (
    !shouldGetContentModalDetails ||
    !contentModalIsDiagnostic ||
    contentModalDiagnosticChartIsLoading
  ) {
    return [];
  }
  return contentModalDiagnosticChart;
};

export const contentModalDiagnosticChartIsEmpty = ({
  contentModalDiagnosticChart,
  contentModalDiagnosticChartIsLoading,
}) =>
  !contentModalDiagnosticChartIsLoading &&
  contentModalDiagnosticChart != null &&
  Array.isArray(contentModalDiagnosticChart) &&
  !contentModalDiagnosticChart.length;

export const contentModalDetails = ({
  contentModalDetails,
  contentModalDetailsIsLoading,
}) => {
  if (contentModalDetailsIsLoading) {
    return {};
  }
  return contentModalDetails;
};

export const contentModalChartType = ({ contentModalChartType }) =>
  contentModalChartType;

export const contentModalChartTypeIsLine = (_, { contentModalChartType }) =>
  contentModalChartType === 'line';

export const contentModalChartData = (
  { contentModalLineChart, contentModalLineChartIsLoading },
  { contentModalChartTypeIsLine, analyticsDateRange: labels, mapSourceToLabel },
) => {
  if (
    contentModalLineChartIsLoading ||
    contentModalLineChart == null ||
    !contentModalLineChart.length
  ) {
    return {
      datasets: [],
      labels: [],
    };
  }

  return contentModalChartTypeIsLine
    ? rawDataToStackedLineChart(
        labels,
        contentModalLineChart,
        'label',
        'interactionDate',
        'count',
        mapSourceToLabel,
      )
    : rawDataToPieChart(
        contentModalLineChart,
        'label',
        'count',
        mapSourceToLabel,
      );
};

export const contentModalChartIsEmpty = ({
  contentModalLineChart,
  contentModalLineChartIsLoading,
}) =>
  !contentModalLineChartIsLoading &&
  contentModalLineChart != null &&
  !contentModalLineChart.length;

export const contentModalChartIsLoading = ({
  contentModalLineChartIsLoading,
}) => contentModalLineChartIsLoading;

export const contentModalDiagnosticChartIsLoading = ({
  contentModalDiagnosticChartIsLoading,
}) => contentModalDiagnosticChartIsLoading;

export const contentModalDetailsIsLoading = ({
  contentModalDetailsIsLoading,
}) => contentModalDetailsIsLoading;

export const contentModalIsDiagnostic = ({ contentModalIsDiagnostic }) =>
  contentModalIsDiagnostic;

export const mergeTerms = ({ filterAdditionalValues }) =>
  filterAdditionalValues.mergeTerms;
