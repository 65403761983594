import { MaydayAxiosInstance } from '@getmayday/mayday-api-instances';
import qs from 'qs';

const paramsSerializerArrayFormat = (params) =>
  qs.stringify(params, { arrayFormat: 'comma' });

export default function (tokenService) {
  const options =
    process.env.NODE_ENV === 'development'
      ? {
          commonHeaders: {
            [process.env.VUE_APP_CORS_LOCAL_HEADER]:
              process.env.VUE_APP_CORS_LOCAL_SECRET,
          },
        }
      : { commonHeaders: {} };

  options.commonHeaders['x-client-origin'] = 'MAYDAY_WEB';
  const maydayAxiosInstance = new MaydayAxiosInstance(tokenService, options);

  return {
    adminClient: maydayAxiosInstance.create(process.env.VUE_APP_BACK_URL),
    analyticsClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_ANALYTICS_URL}/api/analytics/`,
      { paramsSerializer: paramsSerializerArrayFormat },
    ),
    authClient: maydayAxiosInstance.create(`${process.env.VUE_APP_SSO_URL}/`),
    knowledgeClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_MONGO_BACK_URL}`,
    ),
    notificationClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_NOTIFICATIONS_URL}/api`,
    ),
    knowledgeIntegrationClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_MONGO_BACK_URL}/integration`,
    ),
    adminIntegrationClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_BACK_URL}/integration`,
    ),
    brainClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_BRAIN_URL}/api`,
    ),
    exportClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_EXPORT_URL}/api`,
    ),
    academyClient: maydayAxiosInstance.create(
      `${process.env.VUE_APP_ACADEMY_BACK_URL}/`,
    ),
  };
}
