export const isValidArray = (array) => array && array.length;

export const resolvePath = (path, context) => {
  if (!path.length) return null;
  const key = path[0];

  if (context[key] === undefined || context[key] === null) return context[key];
  if (path.length === 1) return String(context[key]);

  return resolvePath(path.slice(1), context[key]);
};

export const toUpperSnakeCase = (string) =>
  string.replace(/([A-Z])/g, '_$1').toUpperCase();

export const isDifferentFromOldConfig = (operation, publicConfig) => {
  const old = getOldValue(operation.path, publicConfig);

  return !(
    operation.value === old ||
    (operation.value === 'false' && old === null)
  );
};

export const isNew = (operation, focusKnowledge, type, nestedPath) => {
  if (type === 'preferences') {
    const formattedKey = operation.key.replace(/([A-Z])/g, '_$1').toUpperCase();
    const keyIdx = focusKnowledge[type].findIndex(
      (el) => el[nestedPath] === formattedKey,
    );
    return (
      (keyIdx > -1 && operation.value === 'false') ||
      (keyIdx === -1 && operation.value === 'true')
    );
  }

  return operation.value !== getOldPluginValue(operation.path, focusKnowledge);
};

const getOldPluginValue = (path, focusKnowledge) => {
  const formattedKey = path.split('.')[0];

  const plugin = focusKnowledge.plugins.find((p) => p.type === formattedKey);

  if (!plugin) return null;

  return resolvePath(plugin, path.split('.').slice(1));
};

const getOldValue = (path, publicConfig) => {
  const pathAsArray = path.split('.');
  const oldElementValue = pathAsArray.reduce((acc, val) => {
    if (acc) {
      if (val.includes('=')) {
        const valAsArray = val.split('=');
        const toGetIdx = acc.findIndex(
          (el) => el[valAsArray[0]] === valAsArray[1],
        );
        acc = acc[toGetIdx];
      } else {
        acc = acc[val];
      }
    }
    return acc;
  }, publicConfig);
  if (oldElementValue) return oldElementValue;
  else return null;
};
