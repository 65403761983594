import data from './contentParameterCategory.data.raw.js';

export class ContentParameterCategoryServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ QUERIES
   */
  async getContentParameterCategories(count = 10) {
    return this.client
      .mutate({
        mutation: data.getContentParameterCategories,
        variables: { count },
      })
      .then((response) => response.data.contentParameterCategories)
      .catch((error) => error);
  }

  /**
   * MUTATION QUERIES
   */

  async updateContentParameterCategory(oldName, newName) {
    return this.client
      .mutate({
        mutation: data.updateContentParameterCategory,
        variables: { oldName, newName },
      })
      .then((response) => response.data.updatedContentParameterCategory)
      .catch((error) => error);
  }

  async deleteContentParameterCategory(categoryName) {
    return this.client
      .mutate({
        mutation: data.deleteContentParameterCategory,
        variables: {
          categoryName,
        },
      })
      .then((response) => {
        return response.data.isContentParameterCategoryDeleted;
      })
      .catch((error) => error);
  }
}
