export const langOptions = [
  'fr-fr',
  'en-gb',
  'en-us',
  'de-de',
  'es-es',
  'it-it',
  'pt-pt',
  'nl-nl',
  'pl-pl',
];
