import * as types from './mutation-types';

export default {
  // APP VERSION
  [types.SET_BLOB_VERSION](state, payload) {
    state.blobVersion = payload;
  },
  [types.SET_VERSION_POLLING_TIMEOUT_ID](state, timeoutId) {
    if (state.versionPollingTimeoutId)
      clearTimeout(state.versionPollingTimeoutId);
    state.versionPollingTimeoutId = timeoutId;
  },
  // CUSTOM CONFIG
  [types.SET_CUSTOM_CONFIG](state, payload) {
    state.customConfig = payload;
  },
  // SPP
  [types.LOGIN_FROM_TOKEN](state, payload) {
    state.searchAccess = payload.searchAccess;
    state.searchIndex = payload.searchIndex;
    state.userId = payload.userId;
    state.userRole = payload.userRole;
    state.userPermissions = payload.userPermissions;
    state.isVerified = payload.isVerified;
    state.username = payload.username;
    state.avatar = payload.avatar;
    state.integrations = payload.integrations;
    state.rootId = payload.rootId;
    state.roots = payload.roots;
    state.languages = payload.languages;
    state.defaultLanguage = payload.defaultLanguage;
    state.focusKnowledge = payload.focusKnowledge;
    state.userGroups = payload.userGroups;
    state.roles = payload.roles;
    state.companyAttributes = payload.companyAttributes;
    state.companyPreferences = payload.companyPreferences;
    state.companyLogo = payload.logo;
    state.reachableUsers = payload.reachableUsers;
    state.reachableRoles = payload.reachableRoles;
  },
  [types.SET_AUTH](state, payload) {
    state.accessToken = payload.accessToken;
    state.refreshToken = payload.refreshToken;
    state.accessExpirationDate = payload.accessExpirationDate;

    // Mayday Desk - Login synchro
    const adminStore = JSON.parse(localStorage.getItem('md-admin') || '{}');

    adminStore.accessToken = payload.accessToken;
    adminStore.refreshToken = payload.refreshToken;

    localStorage.setItem('md-admin', JSON.stringify(adminStore));
  },
  [types.LOGIN](state, payload) {
    if (!Object.keys(payload).includes('user')) {
      state.userRole = payload.userRole;
    } else {
      const { accessToken, accessExpirationDate, refreshToken, user } = payload;
      const { username, avatar, isVerified, createdAt } = user;

      const aggregateRoles = payload.user.role.reduce((acc, role) => {
        if (!acc.id) return role;
        if (acc.id < role.id) acc.name = role.name;

        const permissions = [
          ...acc.customRolePermissions,
          ...role.customRolePermissions,
        ];
        acc.customRolePermissions = permissions;

        return acc;
      }, {});
      const { name: userRole, customRolePermissions: aggregatePermissions } =
        aggregateRoles;

      // duplicate permissions
      const userPermissions = [
        ...new Set(aggregatePermissions.map((p) => p.rolePermission)),
      ];

      const {
        id: companyId,
        userGroups,
        roles,
        attributes,
        logo,
        reachableUsers,
        reachableRoles,
      } = user.company;

      const integrations = user.company.integrations.map(
        (o) => o['integration'],
      );
      const { preferences } = user.company;
      state.userId = user.id;
      state.accessToken = accessToken;
      state.accessExpirationDate = accessExpirationDate;
      state.refreshToken = refreshToken;
      state.userRole = userRole;
      state.userPermissions = userPermissions;
      state.isVerified = isVerified;
      state.username = username;
      state.avatar = avatar;
      state.integrations = integrations;
      state.userGroups = userGroups;
      state.roles = roles;
      state.reachableUsers = reachableUsers;
      state.reachableRoles = reachableRoles;
      state.companyId = companyId;
      state.companyAttributes = attributes;
      state.companyPreferences = preferences;
      state.companyLogo = logo;
      state.userCreatedDate = createdAt;
    }
  },
  [types.NEW_AUTH_ATTEMPT](state) {
    if (!state.authAttempts) {
      state.authAttempts = 1;
    } else {
      state.authAttempts++;
    }
  },
  [types.RESET_AUTH_ATTEMPT](state) {
    state.authAttempts = 0;
    state.blockedLoginStartTime = null;
  },
  [types.SET_BLOCKED_LOGIN_START_TIME](state) {
    state.blockedLoginStartTime = Date.now();
  },
  [types.UPDATE_ASK_ACCESS](state, payload) {
    if (payload) {
      state.userPermissions.push('BRAIN_ENABLE_ASK_ADMIN_BETA');
    } else {
      state.userPermissions = state.userPermissions.filter(
        (p) => p !== 'BRAIN_ENABLE_ASK_ADMIN_BETA',
      );
    }
  },
  [types.LOGOUT](state) {
    state.userId = null;
    state.accessToken = null;
    state.accessExpirationDate = null;
    state.searchAccess = null;
    state.searchIndex = null;
    state.refreshToken = null;
    state.userRole = null;
    state.userPermissions = [];
    state.isVerified = false;
    state.username = null;
    state.avatar = null;
    state.status = null;
    state.integrations = null;
    state.headerSearch = null;
    state.rootId = null;
    state.roots = null;
    state.languages = null;
    state.defaultLanguage = null;
    state.focusKnowledge = null;
    state.userGroups = [];
    state.roles = [];
    state.companyAttributes = [];
    state.focusPath = [];
    state.focusTree = {};
    state.groups = [];
    state.magicAnswers = [];
    state.embeddableExtensionClickEvent = null;
    state.companyPreferences = [];
    state.checkedTreeNodes = [];
    state.treeNodes = [];
    state.nodesContent = [];
    state.knowledgeFilters = [];
    state.showKnowledgeFilters = false;
    state.collaboratorsFocus = [];
    state.analyticsFilters = null;
    state.companyColor = null;
    state.companyLogo = null;
  },
  [types.AUTH_ERROR](state, payload) {
    state.status = payload.message;
  },
  [types.REGISTRATION_ERROR](state, payload) {
    state.status = payload;
  },
  [types.UPDATE_INTEGRATIONS](state, newIntegrations) {
    state.integrations = newIntegrations;
  },
  [types.HEADER_SEARCH_CHOICE](state, payload) {
    state.headerSearch = payload;
  },
  [types.RESET_HEADER_SEARCH](state) {
    state.headerSearch = null;
  },
  [types.UPDATE_LANGUAGES](state, payload) {
    state.languages = payload.languages;
  },
  [types.UPDATE_DEFAULT_LANGUGAGE_ATTRIBUTES](state, payload) {
    state.searchAccess = payload.searchAccess;
    state.searchIndex = payload.searchIndex;
    state.rootId = payload.rootId;
    state.roots = payload.roots;
    state.defaultLanguage = payload.defaultLanguage;
  },
  [types.UPDATE_FOCUS_KNOWLEDGE](state, payload) {
    state.focusKnowledge = payload.knowledge;
  },
  [types.UPDATE_USER_GROUPS](state, payload) {
    state.userGroups = payload.userGroups;
  },
  [types.UPDATE_COMPANY_ATTRIBUTES](state, payload) {
    state.companyAttributes = payload.companyAttributes;
  },
  [types.UPDATE_ROOTS](state, payload) {
    state.roots = payload.roots;
  },
  [types.UPDATE_USERNAME](state, payload) {
    state.username = payload.username;
  },
  [types.UPDATE_FOCUS_PATH](state, payload) {
    state.focusPath = payload.focusPath;
  },
  [types.UPDATE_FOCUS_TREE](state, payload) {
    state.focusTree = Object.assign({}, state.focusTree, payload.focusTree);
  },
  [types.UPDATE_KNOWLEDGE_GROUPS](state, payload) {
    state.groups = payload.groups;
  },
  [types.UPDATE_MAGIC_ANSWERS](state, payload) {
    state.magicAnswers = payload.magicAnswers;
  },
  [types.UPDATE_EMBEDDABLE_EXTENSION_CLICK](state, payload) {
    state.embeddableExtensionClickEvent = payload;
  },
  [types.UPDATE_COMPANY_PREFERENCES](state, payload) {
    if (payload.companyPreferences)
      state.companyPreferences = payload.companyPreferences;
  },
  [types.SET_USER_PREFERENCES](state, payload) {
    state.userPreferences = payload;
  },
  [types.UPDATE_CHECKED_TREE_NODES](state, payload) {
    state.checkedTreeNodes = payload.checkedTreeNodes;
  },
  [types.UPDATE_TREE_NODES](state, payload) {
    state.treeNodes = payload.treeNodes;
  },
  [types.UPDATE_NODES_CONTENT](state, payload) {
    state.nodesContent = payload.nodesContent;
  },
  [types.UPDATE_KNOWLEDGE_FILTERS](state, payload) {
    state.knowledgeFilters = payload.knowledgeFilters;
  },
  [types.UPDATE_SHOW_KNOWLEDGE_FILTERS](state, payload) {
    state.showKnowledgeFilters = payload.showKnowledgeFilters;
  },
  [types.UPDATE_COLLABORATORS_FOCUS](state, payload) {
    state.collaboratorsFocus = payload.collaboratorsFocus;
  },
  [types.UPDATE_USER_ROLES](state, payload) {
    state.roles = payload.roles;
  },
  [types.UPDATE_APP_LANGUAGE](state, payload) {
    state.appLanguage = payload.appLanguage;
  },
  [types.SET_ANALYTICS_FILTERS](state, payload) {
    state.analyticsFilters = payload.analyticsFilters;
  },
  [types.UPDATE_SESSION](state, payload) {
    state.session = payload.session;
  },
  [types.SET_USER_RECENTLY_CONSULTED_CONTENTS](state, payload) {
    state.userRecentlyConsultedContents = payload;
  },
};
