export class IntegrationService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  async checkAuthExists(integration) {
    let status = 500;
    try {
      const check = await this.data.checkAuthExists(integration);
      status = check.status;
    } catch (err) {
      status = 404;
    }
    return status === 200;
  }

  async getOauthInitUrl(integration) {
    try {
      const { data } = await this.data.getOauthInitUrl(integration);
      return data.authorizationUrl;
    } catch (err) {
      return null;
    }
  }

  async getAvailableFields(integration) {
    try {
      const { data } = await this.data.getAvailableFields(integration);
      return data;
    } catch {
      return null;
    }
  }

  async getSalesforceAuth(companyId) {
    return this.data.getSalesforceAuth(companyId);
  }

  async getNotionAuth(companyId) {
    return this.data.getNotionAuth(companyId);
  }

  async getImportedArticles(page, query, source) {
    return this.data.getImportedArticles(page, query, source);
  }

  /**
   * CREATE METHODS
   */

  async initZendeskAuth(subdomain, clientId, clientSecret) {
    return this.data.addZendeskAuth(subdomain, clientId, clientSecret);
  }

  async initSalesforceAuth(instanceUrl, clientId, clientSecret) {
    return this.data.addSalesforceAuth(instanceUrl, clientId, clientSecret);
  }

  async initNotionAuth(token) {
    return this.data.addNotionAuth(token);
  }

  async initIadvizeAuth(token, projectId) {
    return this.data.addIadvizeAuth(token, projectId);
  }

  /**
   * UPDATE METHODS
   */

  /**
   * DELETE METHODS
   */

  async deleteAuth(integration) {
    return this.data.deleteAuth(integration);
  }
}
