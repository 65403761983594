import { LOGIN, LOGIN_FROM_TOKEN } from '../mutation-types';

export const websocketInitPlugin = (store) => {
  const moduleName = 'websocketModule';
  const { connectionUrl, connectionGroups } = store.state.websocketModule;

  if (connectionUrl && connectionGroups && connectionGroups.length > 0) {
    store.dispatch(`${moduleName}/createWebsocket`);
  }

  const loginMutations = [LOGIN, LOGIN_FROM_TOKEN];

  store.subscribe((mutation) => {
    const { payload, type } = mutation;

    if (!loginMutations.includes(type) || !payload || !payload.websocketData) {
      return;
    }

    store.dispatch(`${moduleName}/setWebsocketData`, payload.websocketData);
  });
};
