export const copyProperties = (source, target) => {
  const objectKeys = Object.keys(source);
  objectKeys.forEach((objectKey) => {
    if (source[objectKey] === undefined) return;

    target[objectKey] = source[objectKey];
  });
};

export const uniqueAddToChildrenOrder = (itemToAdd, childrenOrder, index) => {
  const { id, entityType } = itemToAdd;

  const itemAlreadyExists = childrenOrder.some(
    (orderItem) => orderItem.Id === id && orderItem.entityType === entityType,
  );

  if (itemAlreadyExists) return;

  if (isNaN(index)) {
    childrenOrder.push(itemToAdd);
  } else {
    childrenOrder.splice(index, 0, itemToAdd);
  }
};

export const extractCaseParametersFromAddRemove = (toAdd, toRemove) => {
  let caseParametersToAdd = [];
  let caseParametersToRemove = [];

  if (toAdd) {
    const tmp = toAdd.find(
      (item) => item.key === 'caseParameters' && item.values.length,
    );
    caseParametersToAdd = tmp ? tmp.values : [];
  }
  if (toRemove) {
    const tmp = toRemove.find(
      (item) => item.key === 'caseParameters' && item.values.length,
    );
    caseParametersToRemove = tmp ? tmp.values : [];
  }

  return { caseParametersToAdd, caseParametersToRemove };
};

export const appendChildren = (content, descendents) => {
  if (!content) return {};
  const children = content.path
    .map((cId) => descendents.find((d) => d.id === cId))
    .filter((c) => !!c);

  return {
    ...content,
    children,
  };
};

export const cleanLookupObject = (entity) => {
  return Object.assign({}, entity, { body: undefined, children: undefined });
};
