import {
  SET_FILTERS_VALUE,
  SET_FILTERS_ADDITIONAL_VALUE,
  RESET_FILTERS_VALUE,
  SET_FILTERS_LOADING,
  SET_ACTIVE_TAB,
} from './mutation-types';
import debounce from 'lodash.debounce';
import { shouldIgnoreKey } from './utils';

export const dashboardTabDataFetchPlugin = (store) => {
  const moduleName = 'dashboard';
  const generalFiltersMutations = [
    `${moduleName}/${RESET_FILTERS_VALUE}`,
    `${moduleName}/${SET_FILTERS_VALUE}`,
    `${moduleName}/${SET_FILTERS_ADDITIONAL_VALUE}`,
    `${moduleName}/${SET_FILTERS_LOADING}`,
    `${moduleName}/${SET_ACTIVE_TAB}`,
  ];
  const contentTableActions = [
    `${moduleName}/updateContentTableDataOptions`,
    `${moduleName}/updateContentTableIsWithViews`,
    `${moduleName}/updateContentTableSearch`,
  ];
  const usageTableActions = [
    `${moduleName}/updateUsageTableDataOptions`,
    `${moduleName}/updateUsageTableIsWithViews`,
    `${moduleName}/updateUsageTableSearch`,
  ];

  const filtersAreReadyKey = `${moduleName}/filtersAreReady`;
  const contentTableSearchChangedKey = `${moduleName}/contentTableSearchFilterChanged`;
  const usageTableSearchChangedKey = `${moduleName}/usageTableSearchFilterChanged`;
  const getAllActionBase = () => store.dispatch(`${moduleName}/getAllTabData`);
  const getAllAction = debounce(getAllActionBase, 700);
  let isInit = false;

  store.subscribe((mutation) => {
    if (!store.getters[filtersAreReadyKey]) return;

    if (generalFiltersMutations.includes(mutation.type)) {
      if (
        `${moduleName}/${SET_FILTERS_ADDITIONAL_VALUE}` &&
        shouldIgnoreKey(mutation.payload.key)
      ) {
        return;
      }
      const action = isInit ? getAllAction : getAllActionBase;
      action();
      if (!isInit) {
        isInit = true;
      }
    }
  });

  store.subscribeAction({
    after: (action) => {
      if (!store.getters[filtersAreReadyKey] || !isInit) return;
      // tables
      if (contentTableActions.includes(action.type)) {
        const actionName = store.getters[contentTableSearchChangedKey]
          ? 'debouncedGetContentTableData'
          : 'getContentTableData';
        store.dispatch(`${moduleName}/${actionName}`);
      }
      if (usageTableActions.includes(action.type)) {
        const actionName = store.getters[usageTableSearchChangedKey]
          ? 'debouncedGetUsageTableData'
          : 'getUsageTableData';
        store.dispatch(`${moduleName}/${actionName}`);
      }
      if (action.type === `${moduleName}/updateUsageModalTableDataOptions`) {
        store.dispatch(`${moduleName}/getUsageModalTableData`);
      }
      // content chart
      if (action.type === `${moduleName}/updateViewsFilterType`) {
        store.dispatch(`${moduleName}/getContentLineChartData`);
      }
      if (action.type === `${moduleName}/updateMergeTerms`) {
        store.dispatch(`${moduleName}/getContentSearchStatistics`);
      }
    },
  });
};
