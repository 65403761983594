import { MaydayApolloInstance } from '@getmayday/mayday-api-instances';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

export default function (tokenService) {
  const options =
    process.env.NODE_ENV === 'development'
      ? {
          commonHeaders: {
            [process.env.VUE_APP_CORS_LOCAL_HEADER]:
              process.env.VUE_APP_CORS_LOCAL_SECRET,
          },
        }
      : { commonHeaders: {} };
  options.commonHeaders['x-client-origin'] = 'MAYDAY_WEB';
  const maydayApolloInstance = new MaydayApolloInstance(tokenService, options);

  const adminApolloClient = maydayApolloInstance.create(
    process.env.VUE_APP_BACK_URL,
    {
      isProduction: process.env.NODE_ENV === 'production',
      inMemoryCacheOptions: {
        fragmentMatcher: new IntrospectionFragmentMatcher({
          introspectionQueryResultData: {
            __schema: {
              types: [
                {
                  kind: 'UNION',
                  name: 'TaskAssignee',
                  possibleTypes: [
                    {
                      name: 'SafeUser',
                    },
                    {
                      name: 'CustomRole',
                    },
                  ],
                },
              ],
            },
          },
        }),
      },
      refreshMessage: 'FORBIDDEN',
    },
  );

  const knowledgeApolloClient = maydayApolloInstance.create(
    process.env.VUE_APP_MONGO_BACK_URL,
    {
      isProduction: process.env.NODE_ENV === 'production',
    },
  );

  const academyApolloClient = maydayApolloInstance.create(
    process.env.VUE_APP_ACADEMY_BACK_URL,
    {
      isProduction: process.env.NODE_ENV === 'production',
    },
  );

  return {
    adminApolloClient,
    knowledgeApolloClient,
    academyApolloClient,
  };
}
