const resolveComparaison = ({ filter, comparedValue }) => {
  if (filter.type === 'Int') {
    switch (filter.operator) {
      case 'inf':
        return filter.value < comparedValue;
      case 'sup':
        return filter.value > comparedValue;
      case 'equal':
        return filter.value === comparedValue;
      case 'inBtw':
        return (
          filter.value[0] <= comparedValue && filter.value[1] >= comparedValue
        );
    }
  }
  if (filter.type === 'String') {
    return filter.value === comparedValue;
  }
  if (filter.type === 'Array') {
    if (Array.isArray(filter.value)) {
      if (filter.value.length === 0) return true;
      return filter.value.includes(comparedValue);
    }
  }
  return false;
};

export const customFilter = ({ arrayToFilter, filters }) => {
  return filters.reduce((acc, fil) => {
    return acc.filter((el) => {
      try {
        const comparedValue = fil.filter.path.split('.').reduce((acc, val) => {
          if (!acc) return acc;
          return acc[val];
        }, el);
        if (comparedValue)
          return resolveComparaison({ filter: fil.filter, comparedValue });
        return false;
      } catch (e) {
        return false;
      }
    });
  }, arrayToFilter);
};
