import retrieveLang from './retrieveLang';
import retrieveKnowledge from './retrieveKnowledge';
import retrieveCollectionId from './retrieveCollectionId';
import retrieveFocusContentId from './retrieveFocusContentId';

export default async function (route, store) {
  // For admin routes starting with '/knowledge'
  // await this middleware to make sure editingLanguage is properly set before going into
  // next middlewares
  await retrieveLang({ route, store });

  // For `/knowledge` route
  retrieveKnowledge({ route, store });

  // FOR COLLECTIONS ON ../cases/:caseId
  retrieveCollectionId({ route, store });

  // FOR CONTENTS ON ../contents/:contentId
  retrieveFocusContentId({ route, store });
}
