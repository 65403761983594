import gql from 'graphql-tag';

export const defaultKnowledge = gql`
  query defaultKnowledge {
    defaultKnowledge {
      id
      searchIndex
      searchAccess
      root {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            icon
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              icon
              parents {
                id
              }
              icon
            }
            actions
            type
            nodes {
              id
            }
          }
        }
      }
      groups {
        id
        alias
        type
        knowledgeId
        contentId
        ruleAttributes {
          id
          operator
          value
          companyAttribute {
            id
            source
            model
            label
            field
            type
            values
            path
          }
          companyAttributeValue {
            id
            value
            valueName
          }
        }
        node {
          id
          type
          content {
            id
            label
          }
        }
        ancestors {
          id
          content {
            id
            label
          }
        }
      }
      magicAnswers {
        id
        treeNodeId
        node {
          id
          content {
            id
            label
          }
        }
      }
    }
  }
`;

export const readKnowledge = gql`
  query readKnowledge($id: ID!) {
    readKnowledge(id: $id) {
      id
      searchIndex
      searchAccess
      root {
        id
        content {
          id
          label
          title
          body
          attachments
          tags
          attachedObjects
          published
          isReadOnly
          icon
          verificationPolicy {
            verificationRange
            verificationDueDate
            reviewerId
            groupReviewerId
          }
        }
        path
        type
        public
        actions
      }
      groups {
        id
        alias
        type
        knowledgeId
        contentId
        ruleAttributes {
          id
          operator
          value
          companyAttribute {
            id
            source
            model
            label
            field
            type
            values
            path
          }
          companyAttributeValue {
            id
            value
            valueName
          }
        }
        node {
          id
          type
          content {
            id
            label
          }
        }
        ancestors {
          id
          content {
            id
            label
          }
        }
      }
      magicAnswers {
        id
        treeNodeId
        node {
          id
          content {
            id
            label
          }
        }
      }
      allPrimaryDescendents {
        id
        parentId
        content {
          id
          label
          title
          body
          attachments
          tags
          attachedObjects
          published
          isReadOnly
          isOutdated
          icon
        }
        path
        type
        public
        actions
      }
    }
  }
`;

export const readKnowledgeCollections = gql`
  query readCollections($id: ID!) {
    readCollections(id: $id)
  }
`;

// -------------------- GROUP mutations --------------------
export const createKnowledgeGroup = gql`
  mutation createKnowledgeGroup(
    $alias: String!
    $rules: String!
    $knowledgeId: ID!
    $treeNodeId: ID!
  ) {
    createKnowledgeGroup: createGroup(
      alias: $alias
      rules: $rules
      knowledgeId: $knowledgeId
      treeNodeId: $treeNodeId
    ) {
      id
    }
  }
`;

export const updateKnowledgeGroup = gql`
  mutation updateKnowledgeGroup($id: ID!, $alias: String!, $rules: String!) {
    updateKnowledgeGroup: updateGroup(id: $id, alias: $alias, rules: $rules) {
      id
      alias
      type
      ruleAttributes {
        id
        operator
        value
        companyAttribute {
          id
        }
        companyAttributeValue {
          id
        }
      }
    }
  }
`;

export const deleteKnowledgeGroup = gql`
  mutation deleteKnowledgeGroup($id: ID!) {
    deleteKnowledgeGroup: deleteGroup(id: $id)
  }
`;

// -------------------- MagicAnswers mutations --------------------
export const createKnowledgeMagicAnswer = gql`
  mutation createKnowledgeMagicAnswer($knowledgeId: ID!, $treeNodeId: ID!) {
    createKnowledgeMagicAnswer: createMagicAnswer(
      knowledgeId: $knowledgeId
      treeNodeId: $treeNodeId
    )
  }
`;

export const deleteKnowledgeMagicAnswer = gql`
  mutation deleteKnowledgeMagicAnswer($id: ID!) {
    deleteKnowledgeMagicAnswer: deleteMagicAnswer(id: $id)
  }
`;

export const currentAgentKnowledge = gql`
  query defaultKnowledge {
    defaultKnowledge {
      id
      rootDescendents
      groups {
        id
        alias
        type
        knowledgeId
        contentId
        ruleAttributes {
          id
          operator
          value
          companyAttribute {
            id
            source
            model
            label
            field
            type
            values
            path
          }
          companyAttributeValue {
            id
            value
            valueName
          }
        }
        node {
          id
          type
          content {
            id
            label
          }
        }
        ancestors {
          id
          content {
            id
            label
          }
        }
      }
      magicAnswers {
        id
        treeNodeId
        node {
          id
          content {
            label
          }
          ancestors {
            id
            hierarchyLevel
          }
        }
      }
    }
  }
`;
