export class BaseBrainTask {
  constructor(type) {
    this.type = type;
  }

  // eslint-disable-next-line no-unused-vars
  _validatePayload(payload) {
    throw new Error('MISSING_PAYLOAD_VALIDATION');
  }

  validatePayload(payload) {
    if (!this._validatePayload(payload))
      throw new Error('INVALID_TASK_PAYLOAD');
  }

  // eslint-disable-next-line no-unused-vars
  _trigger(payload) {
    throw new Error('MISSING_TRIGGER_METHOD');
  }

  async trigger(payload) {
    try {
      this.validatePayload(payload);
      const lockId = await this._trigger(payload);
      return lockId;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  // eslint-disable-next-line no-unused-vars
  _getStatus(lockId) {
    throw new Error('MISSING_GET_SATUS_METHOD');
  }

  async getStatus(lockId) {
    if (!lockId) return null;
    if (lockId === 'skip') return null;
    if (!lockId.startsWith(`${this.type}:`)) throw new Error('INVALID_LOCK_ID');

    try {
      const statusPayload = await this._getStatus(lockId);
      return statusPayload;
    } catch (e) {
      console.log(e);
      return 'failed';
    }
  }

  async pollStatus({
    lockId,
    onStatusChangeCallback,
    status,
    frequency = 5000,
  }) {
    if (!lockId || !onStatusChangeCallback)
      throw new Error('INVALID_POLLER_PARAMS');

    try {
      const res = await this.getStatus(lockId);

      if (!res || !res.status) throw new Error('INVALID_STATUS_RESPONSE');

      if (onStatusChangeCallback && res.status !== status) {
        onStatusChangeCallback(res, { status });
      }

      if (['done', 'failed'].includes(res.status)) return;

      setTimeout(async () => {
        await this.pollStatus({
          lockId,
          onStatusChangeCallback,
          status: res.status,
          frequency,
        });
      }, frequency);
    } catch (err) {
      console.log(err);
      onStatusChangeCallback({ status: 'failed' }, { status });
      return;
    }
  }
}

export class FileImportTask extends BaseBrainTask {
  constructor(services) {
    super('file-import');
    this.services = services;
  }

  _validatePayload(payload) {
    if (!payload) return false;
    const { contentId, contentType, lang, importType, rawFiles } = payload;
    return contentId && contentType && lang && importType && rawFiles;
  }

  async _trigger(payload) {
    const data = await this.services.parametricContents.brainImportFiles(
      payload,
    );
    return data.statusId;
  }

  async _getStatus(lockId) {
    const status = await this.services.brainClient.getFileImportStatus(lockId);
    return { status };
  }
}

export class FilesMergeTask extends BaseBrainTask {
  constructor(services) {
    super('files-merge');
    this.services = services;
  }

  _validatePayload(payload) {
    if (!payload) return false;
    const { contentId, lang, rawFiles } = payload;
    return contentId && lang && rawFiles;
  }

  async _trigger(payload) {
    const data = await this.services.parametricContents.brainMergeFiles(
      payload,
    );
    return data.statusId;
  }

  async _getStatus(lockId) {
    const status = await this.services.brainClient.getFilesMergeStatus(lockId);
    return { status };
  }
}

export class GenerateFromPromptTask extends BaseBrainTask {
  constructor(services) {
    super('generation');
    this.services = services;
  }

  _validatePayload(payload) {
    if (!payload) return false;
    const { documentId, lang, prompt } = payload;
    return documentId && lang && prompt && prompt.length > 0;
  }

  async _trigger(payload) {
    const data = await this.services.brainClient.generateDocumentFromPrompt(
      payload,
    );
    return data.statusId;
  }

  async _getStatus(lockId) {
    const status = await this.services.brainClient.getGenerateFromPromptStatus(
      lockId,
    );
    return { status };
  }
}

export class TranslationTask extends BaseBrainTask {
  constructor(services) {
    super('translation');
    this.services = services;
  }

  _validatePayload(payload) {
    if (!payload) return false;
    const { documentId, sourceLanguage, targetLanguage } = payload;
    return documentId && sourceLanguage && targetLanguage;
  }

  _trigger(payload) {
    return this.services.brainClient.triggerContentTranslation(payload);
  }

  async _getStatus(lockId) {
    const status = await this.services.brainClient.getTranslationStatus(lockId);
    return { status };
  }
}

export class GenerateImageCoverTask extends BaseBrainTask {
  constructor(services) {
    super('banner-image');
    this.services = services;
  }

  _validatePayload(payload) {
    if (!payload) return false;
    const { text } = payload;
    return !!text;
  }

  _trigger(payload) {
    return this.services.brainClient.triggerImageCoverGeneration(payload);
  }

  async _getStatus(lockId) {
    const status =
      await this.services.brainClient.getImageCoverGenerationStatus(lockId);
    return status;
  }
}
