import gql from 'graphql-tag';

export default {
  getCompanyAttributes: gql`
    query getCompanyAttributesQuery {
      getCompanyAttributes: currentCompany {
        attributes {
          id
          source
          model
          label
          field
          type
          values
          companyAttributeValues {
            id
            value
            valueName
          }
        }
      }
    }
  `,
  setCompanyAttributes: gql`
    mutation setCompanyAttributesMutation(
      $attributes: [JSON!]
      $integration: String!
    ) {
      setCompanyAttributes(attributes: $attributes, integration: $integration) {
        attributes {
          id
          source
          model
          label
          field
          type
          values
          companyAttributeValues {
            id
            value
            valueName
          }
        }
      }
    }
  `,
};
