<template>
  <div class="d-inline-flex">
    <div v-if="isFa" class="logo-container">
      <font-awesome-icon
        class="d-flex icon-fa"
        :icon="[iconStyle, formattedIcon.value]"
        :style="{
          color: formattedIcon.color,
          fontSize: size + 'px',
        }"
      />
    </div>
    <div v-else-if="isImage" class="logo-container">
      <img
        :style="{ width: size + 'px', height: size + 'px' }"
        :src="formattedIcon.value"
      />
    </div>
    <div v-else-if="isEmoji" class="logo-container">
      {{ formattedIcon.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'icon-wrapper',
  props: {
    icon: {
      type: Object,
      default: () => ({}),
    },
    miniIcon: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: Number,
      default: 48,
    },
  },
  data() {
    return {
      defaultIcon: {
        iconType: 'fa',
        value: 'folder',
        color: null,
      },
    };
  },
  computed: {
    isFa() {
      return this.formattedIcon.iconType === 'fa';
    },
    isImage() {
      return this.formattedIcon.iconType === 'url';
    },
    isEmoji() {
      return this.formattedIcon.iconType === 'emoji';
    },
    shouldUseMainIcon() {
      return !this.miniIcon || !this.miniIcon.value;
    },
    noIconsDefined() {
      return !this.icon.value && !this.miniIcon.value;
    },
    formattedIcon() {
      if (this.noIconsDefined) return this.defaultIcon;
      if (this.shouldUseMainIcon) return this.icon;
      return this.miniIcon;
    },
    iconStyle() {
      return this.formattedIcon.value === this.defaultIcon.value
        ? 'fas'
        : 'fad';
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-fa {
  color: $grey-4-mayday;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
