import * as types from './mutation-types';
import initState from './state';

export default {
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  [types.SET_MODULE_LABELS_CATEGORIES](state, payload) {
    state.moduleLabelsCategories = payload;
  },
  [types.SET_MODULE_LABELS_CATEGORIES_LOADING](state, payload) {
    state.moduleLabelsCategoriesLoading = payload;
  },
};
