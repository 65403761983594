import gql from 'graphql-tag';

export const createCompanyGroupMutation = gql`
  mutation createCompanyGroupMutation(
    $name: String!
    $reachable: Boolean
    $knowledgeIds: [JSON]!
  ) {
    createCompanyGroup(
      name: $name
      reachable: $reachable
      knowledgeIds: $knowledgeIds
    ) {
      userGroups {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  }
`;

export const updateCompanyGroupMutation = gql`
  mutation updateCompanyGroupMutation(
    $id: ID!
    $name: String!
    $reachable: Boolean
    $knowledgeIds: [JSON]!
  ) {
    updateCompanyGroup(
      id: $id
      name: $name
      reachable: $reachable
      knowledgeIds: $knowledgeIds
    ) {
      userGroups {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  }
`;

export const deleteCompanyGroupMutation = gql`
  mutation deleteCompanyGroupMutation($id: ID!) {
    deleteCompanyGroup(id: $id) {
      userGroups {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  }
`;

export const deleteCompanyGroups = gql`
  mutation deleteCompanyGroupsMutation($ids: [ID!]) {
    deleteCompanyGroups(ids: $ids) {
      employees {
        id
        username
        email
        jobtitle
        helpdeskId
        role {
          id
          name
        }
        groups {
          id
          name
        }
      }
      userGroups {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  }
`;

export const addCompanyKnowledgeMutation = gql`
  mutation addCompanyKnowledgeMutation($language: String!, $nodeIds: [ID]) {
    addCompanyKnowledge(language: $language, nodeIds: $nodeIds) {
      knowledges {
        id
        root {
          id
          nodes {
            id
            content {
              id
              label
            }
          }
        }
        searchAccess
        searchIndex
        language
        isDefault
      }
    }
  }
`;

export const deleteCompanyKnowledgeMutation = gql`
  mutation deleteCompanyKnowledgeMutation($knowledgeId: ID!) {
    deleteCompanyKnowledge(knowledgeId: $knowledgeId) {
      knowledges {
        id
        root {
          id
          nodes {
            id
            content {
              id
              label
            }
          }
        }
        searchAccess
        searchIndex
        language
        isDefault
      }
    }
  }
`;

export const updateDefaultKowledgeMutation = gql`
  mutation updateDefaultKowledgeMutation($knowledgeId: ID!) {
    updateDefaultKowledge(knowledgeId: $knowledgeId) {
      knowledges {
        id
        root {
          id
          nodes {
            id
            content {
              id
              label
            }
          }
        }
        searchAccess
        searchIndex
        language
        isDefault
      }
    }
  }
`;

export const setCompanyAttributesMutation = gql`
  mutation setCompanyAttributesMutation($attributes: [JSON!]) {
    setCompanyAttributes(attributes: $attributes) {
      attributes {
        id
        source
        model
        label
        field
        type
        values
        companyAttributeValues {
          id
          value
          valueName
        }
      }
    }
  }
`;

export const getKnowledgeBaseInfo = gql`
  query getKnowledgeBaseInfo {
    currentCompany {
      knowledges {
        id
        language
        root {
          id
          content {
            id
            label
          }
          nodes {
            id
            content {
              id
              label
              title
              body
            }
          }
        }
        groups {
          id
          alias
          type
          knowledgeId
          contentId
          ruleAttributes {
            id
            operator
            value
            companyAttribute {
              id
            }
            companyAttributeValue {
              id
            }
          }
          node {
            id
            type
            content {
              id
              label
            }
          }
          ancestors {
            id
            hierarchyLevel
            content {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export const getCompanyRulesQuery = gql`
  query getCompanyRulesQuery {
    currentCompany {
      knowledges {
        id
        language
        groups {
          id
          alias
          type
          knowledgeId
          contentId
          ruleAttributes {
            operator
            value
            companyAttribute {
              id
              source
              model
              label
              field
              type
              values
              path
            }
            companyAttributeValue {
              id
              value
              valueName
            }
          }
          node {
            id
            type
            content {
              id
              label
            }
          }
          ancestors {
            id
            content {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export const updateCompanyLogo = gql`
  mutation updateCompanyLogoMutation($url: String!) {
    updateCompanyLogo(url: $url) {
      logo
    }
  }
`;

export const deleteCompanyLogo = gql`
  mutation deleteCompanyLogoMutation {
    deleteCompanyLogo {
      logo
    }
  }
`;

export const addCompanyPreference = gql`
  mutation addCompanyPreferenceMutation(
    $preferenceType: String!
    $preferenceValue: String
  ) {
    addCompanyPreference(
      preferenceType: $preferenceType
      preferenceValue: $preferenceValue
    ) {
      preferences {
        id
        type
        value
      }
    }
  }
`;

export const updateCompanyPreference = gql`
  mutation updateCompanyPreferenceMutation(
    $preferenceId: ID!
    $preferenceValue: String
  ) {
    updateCompanyPreference(
      preferenceId: $preferenceId
      preferenceValue: $preferenceValue
    ) {
      preferences {
        id
        type
        value
      }
    }
  }
`;

export const removeCompanyPreference = gql`
  mutation removeCompanyPreferenceMutation($preferenceId: ID!) {
    removeCompanyPreference(preferenceId: $preferenceId) {
      preferences {
        id
        type
        value
      }
    }
  }
`;
