import {
  UPDATE_NOTIFICATIONS,
  UPDATE_CREATED_AGENT_NOTIFICATIONS,
} from './mutation-types';

export const notificationPlugin = (store) => {
  const moduleName = 'notificationModule';
  const usersMutations = [`${moduleName}/${UPDATE_NOTIFICATIONS}`];
  const agentNotificationMutations = [
    `${moduleName}/${UPDATE_CREATED_AGENT_NOTIFICATIONS}`,
  ];

  store.subscribe((mutation) => {
    if (usersMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/fetchUsers`, 'notifications');
      store.dispatch(`${moduleName}/fetchContents`);
      store.dispatch(`${moduleName}/fetchCases`);
      store.dispatch(`${moduleName}/fetchTasks`);
    }

    if (agentNotificationMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/fetchUsers`, 'createdAgentNotifications');
    }
  });
};
