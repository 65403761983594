import { handleCancelService } from '@/adapters/axiosClient';

export class AskAnalyticServiceData {
  constructor(analyticsClient, serverClient) {
    this.analyticsClient = analyticsClient;
    this.serverClient = serverClient;
    this.handleCancelService = handleCancelService;

    this.askMetricsCancelService = null;

  }

  getAskMetrics(params) {
    this.askMetricsCancelService = this.handleCancelService(
      this.askMetricsCancelService,
    );
    return this.analyticsClient
      .get('/ask-history/metrics', {
        params,
        cancelToken: this.askMetricsCancelService.token,
      })
      .then((res) => res.data);
  }

  getAskAnalyticsExport(isPending, params) {
      const fileName = `ask_mayday_usage_${params.startDate}_${params.endDate}.xlsx`;
      return this.analyticsClient
      .get('/ask-history', {
        responseType: 'blob',
        params: { isPending, ...params },
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
    
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          fileName
        );
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }
  
}
