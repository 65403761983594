export default () => ({
  companyPublicConfigs: [],
  //FOCUS
  focusKnowledge: {},
  focusCompanyPublicConfig: {},
  //MODIFS
  operations: {},
  //DEPLOYMENT
  deploymentStatus: '',
  poolingTimer: null,
  //HTTPS STATUS
  httpsStatus: true,
  httpsPoolingTimer: null,
  httpsPooling: false,
  //LANGUAGES
  multilingualLoader: false,
  // ERRORS
  customHostnameError: {},
});
