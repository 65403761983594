import { MaydayFetchInstance } from '@getmayday/mayday-api-instances';

export default function (tokenService) {
  return {
    brainFetchClient: new MaydayFetchInstance(
      tokenService,
      `${process.env.VUE_APP_BRAIN_URL}/api`,
    ),
  };
}
