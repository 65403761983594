import gql from 'graphql-tag';

export default {
  readContentParents: gql`
    query readContentQuery($id: ID!) {
      readContentParents: readContent(id: $id) {
        id
        label
        title
        attachments
        parents {
          id
          content {
            id
            label
          }
          parents {
            id
            content {
              id
              label
            }
            parents {
              id
              content {
                id
                label
              }
              parents {
                id
                content {
                  id
                  label
                }
                parents {
                  id
                  content {
                    id
                    label
                  }
                  parents {
                    id
                    content {
                      id
                      label
                    }
                    parents {
                      id
                      content {
                        id
                        label
                      }
                      parents {
                        id
                        content {
                          id
                          label
                        }
                        parents {
                          id
                          parents {
                            id
                            content {
                              id
                              label
                            }
                            parents {
                              id
                              parents {
                                id
                                content {
                                  id
                                  label
                                }
                                parents {
                                  id
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  readContentVersions: gql`
    query readContentVersionsQuery($id: ID!) {
      readContent(id: $id) {
        versions {
          createdAt
          title
          label
          body
          author {
            username
          }
          icon
        }
      }
    }
  `,
  updateNodeContent: gql`
    mutation updateNodeContent(
      $id: ID!
      $label: String
      $title: String
      $body: String
      $attachments: [String]
      $tags: [String]
      $attachedObjects: [ID]
      $icon: String
    ) {
      updateContent(
        id: $id
        label: $label
        title: $title
        body: $body
        attachments: $attachments
        tags: $tags
        attachedObjects: $attachedObjects
        icon: $icon
      ) {
        id
        label
        title
        body
        attachments
        attachedObjects
        tags
        icon
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        parents {
          id
          ancestors {
            id
            hierarchyLevel
          }
        }
        published
      }
    }
  `,
  createContentVerificationPolicy: gql`
    mutation createContentVerificationPolicy(
      $contentId: ID!
      $verificationRange: Int!
      $userId: ID
      $customRoleId: ID
    ) {
      createContentVerificationPolicy(
        contentId: $contentId
        verificationRange: $verificationRange
        userId: $userId
        customRoleId: $customRoleId
      ) {
        contentId
        verificationRange
        verificationDueDate
        userId
        customRoleId
      }
    }
  `,
  setContentAsTrusted: gql`
    mutation setVerificationPolicyTrusted($contentId: ID!) {
      verificationPolicy: setVerificationPolicyTrusted(contentId: $contentId) {
        contentId
        verificationRange
        verificationDueDate
        userId
        customRoleId
      }
    }
  `,
  setContentAsUntrusted: gql`
    mutation setVerificationPolicyUntrusted($contentId: ID!) {
      verificationPolicy: setVerificationPolicyUntrusted(
        contentId: $contentId
      ) {
        contentId
        verificationRange
        verificationDueDate
        userId
        customRoleId
      }
    }
  `,
  updateContentVerificationPolicy: gql`
    mutation updateContentVerificationPolicy(
      $contentId: ID!
      $verificationRange: Int!
      $userId: ID
      $customRoleId: ID
    ) {
      updateContentVerificationPolicy(
        contentId: $contentId
        verificationRange: $verificationRange
        userId: $userId
        customRoleId: $customRoleId
      ) {
        contentId
        verificationRange
        verificationDueDate
        userId
        customRoleId
      }
    }
  `,
  deleteContentVerificationPolicy: gql`
    mutation deleteContentVerificationPolicy($contentId: ID!) {
      deleteContentVerificationPolicy(contentId: $contentId)
    }
  `,
  updateContentPublishStatus: gql`
    mutation updateContentPublishStatus($id: ID!, $published: Boolean) {
      updateContentPublishStatus(id: $id, published: $published) {
        id
        label
        title
        body
        attachments
        attachedObjects
        icon
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        parents {
          id
          ancestors {
            id
          }
        }
        published
      }
    }
  `,
  bulkUpdateContentPublishStatus: gql`
    mutation bulkUpdateContentPublishStatusMutation(
      $ids: [ID!]
      $published: Boolean
    ) {
      bulkUpdateContentPublishStatus(ids: $ids, published: $published)
    }
  `,
  bulkUpdateContentOutdatedStatus: gql`
    mutation bulkUpdateContentOutdatedStatusMutation(
      $ids: [ID!]
      $isOutdated: Boolean
    ) {
      bulkUpdateContentOutdatedStatus(ids: $ids, isOutdated: $isOutdated)
    }
  `,
};
