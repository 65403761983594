import { cleanPluginEntities } from './helper';
import * as types from './mutation-types';

export default {
  async updateCompanyFavorite({ commit }, { isCompanyFavorite, entityId }) {
    try {
      commit(types.UPDATE_COMPANY_FAVORITE_LOADING, true);
      const res = isCompanyFavorite
        ? await this.$services.userDirectory.addCompanyFavorite(entityId)
        : await this.$services.userDirectory.removeCompanyFavorite(entityId);
      commit(types.UPDATE_COMPANY_FAVORITE_LOADING, false);
      return res;
    } catch (e) {
      return e;
    }
  },
  async getIsCompanyFavorite(_, entityId) {
    return this.$services.userDirectory.isCompanyFavorite(entityId);
  },

  async getPluginEntities(_, entityType) {
    try {
      if (!entityType) {
        throw new Error(`No entityType provided`);
      }

      const response =
        await this.$services.userDirectory.getSettingsPluginEntities(
          entityType,
        );

      const pluginEntities = response;

      if (!pluginEntities) {
        throw new Error(`No plugin entity found with type ${entityType}`);
      }

      return cleanPluginEntities(pluginEntities);
    } catch (e) {
      return null;
    }
  },

  updatePluginEntitiesState({ commit }, payload) {
    commit(types.UPDATE_PLUGIN, cleanPluginEntities(payload));
  },

  async updatePluginEntities(_, pluginEntities) {
    try {
      const sanitizedPluginEntities = pluginEntities.map(
        ({ id: entityId, ...payload }) => ({
          entityId,
          ...payload,
        }),
      );
      return cleanPluginEntities(
        await this.$services.userDirectory.updatePluginEntities(
          sanitizedPluginEntities,
        ),
      );
    } catch (e) {
      return null;
    }
  },

  async updateAccessRestrictions(_, payload) {
    try {
      const res = await this.$services.userDirectory.updateAccessRestrictions(
        payload,
      );
      return res;
    } catch (e) {
      return null;
    }
  },
};
