import * as types from './mutation-types';
import { copyProperties } from './utils';

const ENTITY_TO_ADMIN_ROUTE_NAME = {
  Case: 'knowledge-case',
  Article: 'knowledge-content',
  Diagnostic: 'knowledge-content',
  Content: 'knowledge-content',
  Step: 'knowledge-content-step',
  keyStep: 'knowledge-content-step',
  Knowledge: 'knowledge-home',
  Product: 'knowledge-product',
};

// WARNING : agent web-parametric uses admin knowledgeModule
// agent web-parametric have no case navigation
const ENTITY_TO_PARAMETRIC_ROUTE_NAME = {
  Article: 'web-content',
  Diagnostic: 'web-content',
  Content: 'web-content',
  Step: 'web-content-step',
  keyStep: 'web-content-step',
  Knowledge: 'web-home',
};

export default {
  // TMP only for web-parametric bug feedbacks on steps
  setFocusStep({ commit, dispatch }, focusStep) {
    commit(types.SET_FOCUS_STEP, focusStep);
    dispatch('storeReadEvent', focusStep);
  },
  /**
   * GENERAL
   */
  logout({ commit }) {
    commit(types.LOGOUT);
  },
  async getTranslatedEntityUrl(
    { dispatch, getters },
    { entityId, entityType, parentId, lang, hash, query },
  ) {
    if (!entityType) throw new Error('Invalid Type');

    const targetRouteNames = this.$router.currentRoute.fullPath.includes(
      'web-parametric',
    )
      ? ENTITY_TO_PARAMETRIC_ROUTE_NAME
      : ENTITY_TO_ADMIN_ROUTE_NAME;

    const routeName = targetRouteNames[entityType];

    if (!routeName)
      throw new Error(`Unknown route name for entityType ${entityType}`);

    if (!lang) lang = await dispatch('resolveLanguage');

    hash = hash || '';

    const params = { lang };
    const { focusKnowledgeId } = getters;

    if (routeName.includes('step')) {
      if (!parentId) {
        const content =
          await this.$services.parametricContents.getWithAncestors(entityId);

        if (!content) {
          return dispatch('flagRedirectionStepsAsDeleted', entityId);
        }
        parentId = content.ancestorsContent[0].id;
      }
      params.knowledgeId = focusKnowledgeId;
      params.contentId = parentId;
      params.stepId = entityId;
    } else if (routeName.includes('content')) {
      params.knowledgeId = focusKnowledgeId;
      params.contentId = entityId;
    } else if (routeName.includes('case')) {
      params.knowledgeId = focusKnowledgeId;
      params.caseId = entityId;
    } else if (routeName.includes('product')) {
      params.productId = entityId;
    } else if (routeName.includes('home')) {
      params.knowledgeId = entityId || focusKnowledgeId;
    }

    const { href } = this.$router.resolve({ name: routeName, params, query });

    return `${href}${hash}`;
  },

  async goToTranslatedEntity({ dispatch }, args) {
    const { open, ...rest } = args;
    const url = await dispatch('getTranslatedEntityUrl', rest);
    return open ? window.open(url) : this.$router.push(url);
  },

  resolveLanguage({ getters }, lang) {
    if (lang) return lang;

    const currentLanguage = this.$router.history.current.params.lang;

    return currentLanguage ? currentLanguage : getters.editingLanguage;
  },

  /**
   * This action is should be called only after focusKnowledge has been set in state
   * It returns a boolean telling if there is a lang mismatch with focusKnowledgeLanguages
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  resolveLangBasedOnFocusKnowledge({ getters }, { lang }) {
    const { focusKnowledgeLanguages } = getters;

    return !focusKnowledgeLanguages.includes(lang);
  },

  async forceReloadPage({ getters, dispatch, commit }, forceSource) {
    const { focusKnowledgeDefaultLanguage } = getters;

    const { params, name, query } = this.$router.history.current;

    let resolvedName = name;
    let resolvedParams = params;

    // Coming from URL, router is broken and we need to manually resolve route using state
    if (!name) {
      if (forceSource === 'Case') {
        resolvedName = 'knowledge-case';
        resolvedParams = { caseId: getters.focusCollectionId };
      } else {
        resolvedName = 'knowledge-content';
        resolvedParams = { contentId: getters.focusContentId };
      }
      await dispatch('setEditingLanguage', focusKnowledgeDefaultLanguage);
    } else {
      commit(types.UPDATE_LANGUAGE_TRANSLATION, focusKnowledgeDefaultLanguage);
      commit(types.UPDATE_NAVIGATION_LANGUAGE, focusKnowledgeDefaultLanguage);
    }

    return this.$router.push({
      name: resolvedName,
      query,
      params: { ...resolvedParams, lang: focusKnowledgeDefaultLanguage },
    });
  },

  /**
   * Action to mutate push and add it as query params
   * Triggers a rerender of the route and dispatch entityTranslation (through knowledgeModule plugin)
   * @param {*} param0
   * @param {*} language
   */
  switchEditingLanguage({ getters }, language) {
    if (language && getters.editingLanguage === language) return;
    this.$router.push({
      params: { ...this.$router.history.current.params, lang: language },
    });
  },

  setEditingLanguage({ commit, getters, dispatch }, language) {
    if (language && getters.editingLanguage === language) return;

    commit(types.UPDATE_LANGUAGE_TRANSLATION, language);
    commit(types.UPDATE_NAVIGATION_LANGUAGE, language);
    dispatch('getKnowledgeRootsWithChildren');
  },

  setDefaultLanguage({ commit }, language) {
    commit(types.SET_DEFAULT_LANGUAGE_TRANSLATION, language);
    commit(types.UPDATE_NAVIGATION_LANGUAGE, language);
  },

  setFocusKnowledgeAsMultilingual({ commit }, language) {
    commit(types.SET_FOCUS_KNOWLEDGE_AS_MULTILINGUAL, language);
  },

  addNewLanguage({ commit }, language) {
    commit(types.ADD_NEW_LANGUAGE_TO_FOCUS_KNOWLEDGE, language);
  },

  addNewLanguageToKnowledge({ commit }, { id, lang, labelTranslations }) {
    commit(types.ADD_NEW_LANGUAGE_TO_KNOWLEDGE, {
      id,
      lang,
      labelTranslations,
    });
  },

  editKnowledgeLabel({ commit }, { id, newLabel, labelTranslations }) {
    commit(types.EDIT_KNOWLEDGE_LABEL, { id, newLabel, labelTranslations });
  },

  /**
   * KNOWLEDGE
   */
  async getKnowledges({ commit, state, dispatch }) {
    try {
      await dispatch('flushNavigatorLookupMap');
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, true);
      const knowledges = await this.$services.hierarchies.getKnowledges();
      commit(types.SET_KNOWLEDGES, knowledges);

      if (
        !state.focusKnowledge ||
        !knowledges.some(
          (knowledge) => knowledge.id === state.focusKnowledge.id,
        )
      ) {
        const focusKnowledgeIdx = knowledges.findIndex(
          (knowledge) => knowledge.isDefault,
        );

        const focusKnowledge =
          focusKnowledgeIdx === -1
            ? knowledges[0]
            : knowledges[focusKnowledgeIdx];

        dispatch('setFocusKnowledge', focusKnowledge);
      }
      return knowledges;
    } catch (err) {
      return err;
    } finally {
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, false);
    }
  },

  async setFocusKnowledge({ commit, dispatch, getters }, knowledge) {
    commit(types.SET_FOCUS_KNOWLEDGE, knowledge);
    commit(types.SET_FOCUS_COLLECTION, null);
    commit(types.RESET_NAVIGATOR_LOOKUP_MAP);
    if (getters.editingLanguage === knowledge.defaultLanguage)
      dispatch('getKnowledgeRootsWithChildren');
    return dispatch('addKnowledgeToNavigationLookupMap', knowledge);
  },

  async setFocusKnowledgeFromUrl({ commit, getters, dispatch }, payload) {
    const { knowledgeId, forceFetch } = payload;
    const { focusKnowledge, knowledges } = getters;

    let fetchedKnowledges = knowledges;
    if (!fetchedKnowledges || !fetchedKnowledges.length) {
      fetchedKnowledges = await dispatch('getKnowledges');
    }

    if (!forceFetch && focusKnowledge && focusKnowledge.value === knowledgeId)
      return false;

    const newFocusKnowledge = fetchedKnowledges.find(
      (knowledge) => knowledge.value === knowledgeId,
    );

    commit(types.SET_NAVIGATOR_NODE_IS_LOADING, true);
    try {
      await dispatch('setFocusKnowledge', newFocusKnowledge);
      await dispatch('getKnowledgeRootsWithChildren');
    } catch (err) {
      console.log({ err });
    } finally {
      commit(types.SET_NAVIGATOR_NODE_IS_LOADING, false);
    }
    return !forceFetch;
  },

  async updateHierarchyChildrenOrder({ dispatch }, { id, childrenOrder }) {
    await this.$services.hierarchies.updateHierarchyChildrenOrder(
      id,
      childrenOrder,
    );

    dispatch('updateKnowledgeInNavigationLookupMap', { id, childrenOrder });
  },

  async getPinnedContents(_, knowledgeId) {
    return this.$services.hierarchies.getPinnedContents(knowledgeId);
  },

  updatePinnedContents(_, { knowledgeId, pinnedContents }) {
    this.$services.hierarchies.updatePinnedContents(
      knowledgeId,
      pinnedContents,
    );
  },

  /**
   * KNOWLEDGE ROOTS
   */

  async createKnowledgeRoot({ commit, getters, dispatch }, label) {
    const {
      focusKnowledgeValue,
      focusKnowledgeId,
      focusKnowledgeRoots,
      editingLanguage: lang,
    } = getters;

    try {
      if (focusKnowledgeValue) {
        const newKnowledgeRoots = await this.$services.cases.createRoot(
          label,
          focusKnowledgeValue,
          focusKnowledgeId,
          lang,
        );
        const updatedKnowledgeRoots = [
          ...focusKnowledgeRoots,
          newKnowledgeRoots,
        ];
        commit(types.SET_FOCUS_KNOWLEDGE_ROOTS, updatedKnowledgeRoots);

        dispatch('addCaseToNavigationLookupMap', {
          collection: newKnowledgeRoots,
        });
        return newKnowledgeRoots;
      }
    } catch (err) {
      return err;
    }
  },

  async getKnowledgeRootsWithChildren({ commit, getters, dispatch }) {
    const { focusKnowledgeValue, editingLanguage } = getters;
    if (!focusKnowledgeValue) return;

    let lang = editingLanguage;

    try {
      commit(types.SET_FOCUS_KNOWLEDGE_ROOTS_IS_LOADING, true);
      const focusKnowledgeRoots =
        await this.$services.cases.getRootsWithChildren(
          focusKnowledgeValue,
          lang,
        );

      focusKnowledgeRoots.forEach((root) => {
        dispatch('addCaseToNavigationLookupMap', {
          collection: root,
          skip: true,
        });
        return root;
      });

      commit(types.SET_FOCUS_KNOWLEDGE_ROOTS, focusKnowledgeRoots);
    } catch (err) {
      console.log(err);
      console.log(err.response.data.message);
    } finally {
      commit(types.SET_FOCUS_KNOWLEDGE_ROOTS_IS_LOADING, false);
      commit(types.UPDATE_NAVIGATION_LANGUAGE, lang);
    }
  },

  async updateKnowledgeRoot(
    { commit, getters, rootGetters },
    { caseId, label, body, childrenOrder },
  ) {
    const { focusKnowledgeRoots, editingLanguage: lang } = getters;
    const { isSplitKnowledgeViewer } = rootGetters;

    try {
      const updatedCollection = await this.$services.cases.update(
        caseId,
        label,
        body,
        childrenOrder,
        lang,
        isSplitKnowledgeViewer,
      );

      const idx = focusKnowledgeRoots.findIndex((el) => el.id === caseId);
      focusKnowledgeRoots[idx].label = updatedCollection.label;
      commit(types.SET_FOCUS_KNOWLEDGE_ROOTS, focusKnowledgeRoots);
    } catch (err) {
      return err;
    }
  },

  async deleteKnowledgeRoot({ commit, getters, dispatch }, payload) {
    const { caseId, softDeleted } = payload;
    const { focusKnowledgeRoots, focusKnowledge } = getters;
    try {
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, true);
      await this.$services.cases.delete(caseId, softDeleted);
      commit(
        types.SET_FOCUS_KNOWLEDGE_ROOTS,
        focusKnowledgeRoots.filter((root) => root.id !== caseId),
      );
      dispatch('deleteCaseFromNavigatorLookupMap', {
        caseIdToDelete: caseId,
        parentIdToDeleteFrom: focusKnowledge.id,
      });
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, false);
    } catch (err) {
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, false);
      return err;
    }
  },

  async deleteCaseIcon({ commit, getters }, { caseId, field }) {
    const { focusKnowledgeRoots } = getters;
    try {
      await this.$services.cases.deleteIcon(caseId, field);
      const idx = focusKnowledgeRoots.findIndex((el) => el.id === caseId);
      focusKnowledgeRoots[idx][field] = null;
      commit(types.SET_FOCUS_KNOWLEDGE_ROOTS, focusKnowledgeRoots);
    } catch (err) {
      return err;
    }
  },

  async updateCaseIcon({ commit, getters }, { caseId, icon, field = 'icon' }) {
    const { iconType, value, color } = icon;
    const { focusKnowledgeRoots } = getters;
    try {
      const updatedCollection = await this.$services.cases.updateIcon(
        caseId,
        iconType,
        value,
        color,
        field,
      );

      const idx = focusKnowledgeRoots.findIndex((el) => el.id === caseId);
      focusKnowledgeRoots[idx][field] = updatedCollection[field];
      commit(types.SET_FOCUS_KNOWLEDGE_ROOTS, focusKnowledgeRoots);
    } catch (err) {
      return err;
    }
  },

  async createContent({ dispatch, getters }, { lang, ...content }) {
    const { knowledges, editingLanguage } = getters;

    const targetKnowledge = knowledges.find(
      (knowledge) => knowledge.value === content.knowledge,
    );

    content.knowledgeId = targetKnowledge.id;
    content.lang = editingLanguage || lang;

    let newContent;

    if (content.templateId) {
      const targetCaseId = content.caseParameters[0].split('/').pop();

      newContent = await this.$services.contentTemplates.createContent(
        content.templateId,
        targetCaseId,
        targetKnowledge.value,
        targetKnowledge.id,
      );
    } else {
      newContent = await this.$services.parametricContents.createContent(
        content,
      );
    }

    if (editingLanguage !== content.lang)
      dispatch('switchEditingLanguage', content.lang);

    dispatch('addNewContentInNavigatorLookupMap', {
      isOutdated: true,
      published: false,
      ...content,
      ...newContent,
    });

    dispatch('updateNavigatorCaseParents', {
      caseParameters: newContent.caseParameters[0],
      lang,
    });

    dispatch('storeMutationsEvent', {
      content: { ...content, ...newContent },
      eventName: 'create',
    });

    return newContent;
  },

  async createSyncContent(
    { getters, commit, dispatch },
    { id, source, externalId },
  ) {
    const { focusContent } = getters;

    const { editingLanguage, focusKnowledgeDefaultLanguage } = getters;
    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    try {
      commit(types.SET_IMPORT_ARTICLE_IS_LOADING, true);
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);
      const newContent =
        await this.$services.parametricContents.createSyncContent({
          id,
          source,
          externalId: String(externalId),
          lang,
        });
      copyProperties(newContent, focusContent);

      if (focusContent.forceRefreshCount === undefined) {
        focusContent.forceRefreshCount = 0;
      }

      focusContent.forceRefreshCount++;

      commit(types.SET_FOCUS_CONTENT, focusContent);
      dispatch('updateContentInNavigationLookupMap', newContent);
    } catch (err) {
      throw err;
    } finally {
      commit(types.SET_IMPORT_ARTICLE_IS_LOADING, false);
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);
    }
  },

  async syncContent({ getters, commit, dispatch }, id) {
    const { focusContent } = getters;

    const { editingLanguage, focusKnowledgeDefaultLanguage } = getters;
    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    try {
      commit(types.SET_IMPORT_ARTICLE_IS_LOADING, true);
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);
      commit(types.SET_IMPORT_ARTICLE_IS_LOADING, true);
      const newContent = await this.$services.parametricContents.syncContent(
        id,
        lang,
      );
      copyProperties(newContent, focusContent);

      if (focusContent.forceRefreshCount === undefined) {
        focusContent.forceRefreshCount = 0;
      }

      focusContent.forceRefreshCount++;

      commit(types.SET_FOCUS_CONTENT, focusContent);
      dispatch('updateContentInNavigationLookupMap', newContent);
    } catch (err) {
      throw err;
    } finally {
      commit(types.SET_IMPORT_ARTICLE_IS_LOADING, false);
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);
      commit(types.SET_IMPORT_ARTICLE_IS_LOADING, false);
    }
  },

  async unlinkSyncContent({ getters, commit }, id) {
    const { focusContent, editingLanguage } = getters;
    try {
      const newContent =
        await this.$services.parametricContents.unlinkSyncContent(
          id,
          editingLanguage,
        );
      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);
    } catch (err) {
      return err;
    }
  },

  async cloneCaseToTarget(
    { getters, commit, dispatch, rootGetters },
    {
      caseId,
      caseName,
      targetCaseId,
      targetKnowledge,
      targetKnowledgeId,
      languagesMatch,
    },
  ) {
    const { focusKnowledgeValue, focusKnowledgeRoots, focusKnowledge } =
      getters;
    const { isSplitKnowledgeViewer } = rootGetters;

    // Duplicate Contents feature
    if (!languagesMatch) {
      languagesMatch = focusKnowledge.supportedLanguages.map((lang) => ({
        source: lang,
        target: lang,
      }));
    }

    const clonedCase = await this.$services.clone.cloneCaseToTarget(
      caseId,
      caseName,
      targetCaseId,
      targetKnowledge,
      targetKnowledgeId,
      languagesMatch,
      isSplitKnowledgeViewer,
    );
    if (!clonedCase.id) throw 'Error';
    if (focusKnowledgeValue === targetKnowledge) {
      dispatch('addCaseToNavigationLookupMap', {
        collection: clonedCase,
        parentId: targetCaseId,
      });

      if (clonedCase.root) {
        commit(types.SET_FOCUS_KNOWLEDGE_ROOTS, [
          ...focusKnowledgeRoots,
          clonedCase,
        ]);
      }
    }

    return clonedCase;
  },

  async cloneContentsToTarget(
    { dispatch, getters },
    {
      contentIds,
      targetCaseId,
      targetKnowledge,
      targetKnowledgeId,
      languagesMatch,
    },
  ) {
    const { focusKnowledgeId, focusKnowledge } = getters;

    // Duplicate Contents feature
    if (!languagesMatch) {
      languagesMatch = focusKnowledge.supportedLanguages.map((lang) => ({
        source: lang,
        target: lang,
      }));
    }

    const contents = await this.$services.clone.cloneContentsToTarget(
      contentIds,
      targetCaseId,
      targetKnowledge,
      targetKnowledgeId,
      languagesMatch,
    );

    if (targetKnowledgeId !== focusKnowledgeId) return contents;

    const promisedContents = contents.map(async (content) =>
      dispatch('addNewContentInNavigatorLookupMap', content),
    );
    return Promise.all(promisedContents);
  },

  async duplicateContents({ dispatch }, contentIds) {
    const contents = await this.$services.clone.duplicateContents(contentIds);

    const promisedContents = contents.map(async (content) =>
      dispatch('addNewContentInNavigatorLookupMap', content),
    );
    return Promise.all(promisedContents);
  },

  /**
   * COLLECTIONS NAVIGATION
   */

  setFocusCollectionId({ commit }, collectionId) {
    commit(types.SET_FOCUS_COLLECTION_ID, collectionId);
    commit(types.SET_FOCUS_CONTENT_ID, null);
  },

  async setFocusContentId({ commit }, id) {
    commit(types.SET_FOCUS_CONTENT_ID, id);
    commit(types.SET_FOCUS_COLLECTION_ID, null);
  },

  resetFocusContent({ commit }) {
    commit(types.SET_FOCUS_CONTENT_ID, null);
    commit(types.SET_FOCUS_CONTENT, null);
    commit(types.SET_FOCUS_CONTENT_BACKLINKS, []);
  },

  entityTranslation({ dispatch }) {
    const { path } = this.$router.currentRoute;
    const forceFetch = true;
    if (path.includes('case'))
      dispatch('getFocusCollectionDetails', forceFetch);
    else if (path.includes('content'))
      dispatch('getFocusContentDetails', forceFetch);
    else if (path.includes('knowledge'))
      dispatch('getKnowledgeRootsWithChildren');
  },

  async getFocusCollectionDetails(
    { commit, dispatch, getters, rootGetters },
    forceFetch = false,
  ) {
    const {
      focusCollectionId,
      editingLanguage,
      focusKnowledgeDefaultLanguage,
    } = getters;
    const { isSplitKnowledgeViewer } = rootGetters;
    let lang = editingLanguage || focusKnowledgeDefaultLanguage;

    if (!focusCollectionId) return;

    commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);
    try {
      const focusCollection = await this.$services.cases.getById(
        focusCollectionId,
        isSplitKnowledgeViewer,
        false,
        lang,
      );

      await dispatch('setFocusKnowledgeFromUrl', {
        knowledgeId: focusCollection.knowledge,
        forceFetch,
      });

      const shouldReloadPage = await dispatch(
        'resolveLangBasedOnFocusKnowledge',
        {
          lang,
        },
      );

      if (shouldReloadPage) {
        return dispatch('forceReloadPage', 'Case');
      }

      await dispatch('kbStore/updateCasePath', focusCollection.path, {
        root: true,
      });
      commit(types.SET_FOCUS_COLLECTION, focusCollection);
      dispatch('storeReadEvent', focusCollection);
      dispatch('getFocusCollectionContents');
      dispatch('addCaseToNavigationLookupMap', { collection: focusCollection });
    } catch (err) {
      return err;
    } finally {
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);
    }
  },

  async getCaseById(
    { getters, dispatch, commit },
    { caseId, updateFocusRootState },
  ) {
    const { editingLanguage, focusKnowledgeDefaultLanguage } = getters;
    const lang = editingLanguage || focusKnowledgeDefaultLanguage;
    const collection = await this.$services.cases.getById(
      caseId,
      false,
      false,
      lang,
    );
    if (updateFocusRootState) {
      commit(types.UPDATE_SINGLE_KNOWLEDGE_ROOT, collection);
    }
    dispatch('addCaseToNavigationLookupMap', { collection });
  },

  async updateNavigatorCaseParents(
    { dispatch, getters },
    { caseParameters, lang },
  ) {
    const { focusKnowledgeIsMultilinguale } = getters;

    if (!focusKnowledgeIsMultilinguale || !caseParameters) return;

    const caseIds = caseParameters.split('/');
    const cases = await this.$services.cases.getCasesTranslationState(
      caseIds,
      lang,
    );
    cases.map((collection) => {
      dispatch('addCaseToNavigationLookupMap', {
        collection,
        skip: true,
      });
    });
  },

  updateCasePath({ dispatch, rootGetters }, { id, label }) {
    const casePath = [...rootGetters['kbStore/casePath']];
    const pathIndex = casePath.findIndex((c) => c.id === id);
    const level = {
      id,
      label,
    };

    casePath[pathIndex] = level;
    dispatch('kbStore/updateCasePath', casePath, {
      root: true,
    });
  },

  async getRebuiltCasePathFromCaseId({ getters }, id) {
    const { focusKnowledgeDefaultLanguage, editingLanguage } = getters;

    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    try {
      const casePath = await this.$services.cases.getRebuiltCasePathFromCaseId(
        id,
        lang,
      );

      return casePath;
    } catch (err) {
      return err;
    }
  },

  async getContent({ getters }, { id, lang }) {
    const { focusKnowledgeDefaultLanguage, editingLanguage } = getters;

    const queryLang = lang || editingLanguage || focusKnowledgeDefaultLanguage;

    try {
      const content = await this.$services.parametricContents.get(
        id,
        queryLang,
      );

      return content;
    } catch (err) {
      return err;
    }
  },
  async getContentWithAncestors({ getters }, id) {
    const { focusKnowledgeDefaultLanguage, editingLanguage } = getters;

    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    try {
      const content = await this.$services.parametricContents.getWithAncestors(
        id,
        lang,
      );

      return content;
    } catch (err) {
      return err;
    }
  },

  async getFocusContentDetails(
    { getters, commit, dispatch, rootGetters },
    forceFetch = false,
  ) {
    const { focusContentId, focusKnowledgeDefaultLanguage, editingLanguage } =
      getters;
    const { isParametric } = rootGetters;

    let lang = editingLanguage || focusKnowledgeDefaultLanguage;
    if (!focusContentId) return;

    commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);
    try {
      const focusContent = await this.$services.parametricContents.get(
        focusContentId,
        lang,
      );

      // Rewrite url logic from admin vue3, in case we target a step without known content ancestor
      if (
        focusContent &&
        ['Step', 'keyStep'].includes(focusContent.type) &&
        !this.$router.currentRoute.fullPath.includes('/step')
      )
        dispatch('handleOnClickInlineContent', {
          id: focusContentId,
          lang,
          type: 'Content',
          newTab: false,
        });

      const contentLanguage = focusContent.returnLanguage;

      if (forceFetch) {
        focusContent.forceRefreshCount = getters.focusContent.forceRefreshCount
          ? getters.focusContent.forceRefreshCount + 1
          : 1;
      }

      dispatch(
        'setUserRecentlyConsultedContent',
        { content: focusContent, lang },
        { root: true },
      );

      if (!isParametric) {
        const { knowledgeId } = focusContent;
        const fromUrl = await dispatch('setFocusKnowledgeFromUrl', {
          knowledgeId,
          forceFetch,
        });

        const shouldReloadPage = await dispatch(
          'resolveLangBasedOnFocusKnowledge',
          {
            lang,
          },
        );

        if (shouldReloadPage) {
          return dispatch('forceReloadPage', 'Content');
        }

        // To set language as default language of the content in case we load content from url.
        // If current focus knowledge is not that of the content.
        lang = fromUrl ? contentLanguage : lang;

        const cases = focusContent.caseParameters[0].split('/');
        const caseId = cases[cases.length - 1];
        const skipOrdered = false;
        const skipPath = false;

        const collection = await this.$services.cases.getById(
          caseId,
          skipOrdered,
          skipPath,
          lang,
        );

        await dispatch('kbStore/updateCasePath', collection.path, {
          root: true,
        });
      }

      if (focusContent.type === 'Diagnostic') {
        const automations =
          await this.$services.automations.getContentAutomations(
            focusContentId,
          );
        focusContent.automations = automations;
      }

      if (isParametric) {
        dispatch('storeParametricReadEvent', focusContent);
      } else {
        dispatch('storeReadEvent', focusContent);
      }

      commit(types.SET_FOCUS_CONTENT, focusContent);
    } catch (err) {
      return err;
    } finally {
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);
    }
  },

  async getFocusContentBacklinks({ getters, commit }) {
    const { focusContentId } = getters;

    if (!focusContentId) return;

    const backlinks = await this.$services.parametricContents.getBacklinks(
      focusContentId,
    );
    commit(types.SET_FOCUS_CONTENT_BACKLINKS, backlinks);
  },

  async getContentBacklinks(_, contentId) {
    if (!contentId) return;

    return this.$services.parametricContents.getBacklinks(contentId);
  },

  flagRedirectionStepsAsDeleted({ commit, getters }, contentId) {
    const { focusContent } = getters;

    const newChildren = focusContent.children.map((c) => {
      const { redirection } = c;
      if (redirection && redirection.entityId === contentId)
        redirection.isDeleted = true;
      return { ...c, redirection };
    });

    commit(types.SET_FOCUS_CONTENT, { ...focusContent, children: newChildren });
  },

  async createAutomation({ commit, getters }, automation) {
    const { focusContent } = getters;

    const newAutomation = await this.$services.automations.create(automation);

    const automationsState = focusContent.automations
      ? [...focusContent.automations]
      : [];
    automationsState.push(newAutomation);
    focusContent.automations = automationsState;
    commit(types.SET_FOCUS_CONTENT, focusContent);
    return newAutomation;
  },

  async updateAutomation({ commit, getters }, automation) {
    const { focusContent } = getters;

    const newAutomation = await this.$services.automations.update(automation);

    const automationsState = [...focusContent.automations];

    const index = automationsState.findIndex(
      (automationItem) => automationItem.id === automation.id,
    );

    if (index === -1) return;

    automationsState.splice(index, 1, newAutomation);

    focusContent.automations = automationsState;
    commit(types.SET_FOCUS_CONTENT, focusContent);

    return newAutomation;
  },

  async deleteAutomation({ commit, getters }, id) {
    const { focusContent } = getters;

    const result = await this.$services.automations.delete(id);

    const automationsState = [...focusContent.automations];

    focusContent.automations = automationsState.filter(
      (automation) => automation.id !== id,
    );
    commit(types.SET_FOCUS_CONTENT, focusContent);

    return result;
  },

  insertImportedFilesInCollection({ commit, state, dispatch }, contents) {
    const { focusCollection } = state;
    try {
      contents.forEach((newContent) => {
        commit(types.SET_FOCUS_COLLECTION, {
          ...focusCollection,
          children: focusCollection.children.concat([newContent]),
        });
        dispatch('addNewContentInNavigatorLookupMap', {
          id: newContent.id,
          isOutdated: false,
          published: false,
          ...newContent,
        });
      });
      return contents.map((c) => c.id);
    } catch (err) {
      return err;
    }
  },

  async addCollectionChild(
    { commit, state, getters, dispatch },
    { parentId, label },
  ) {
    const { focusCollection } = state;
    const { editingLanguage: lang } = getters;
    try {
      const newChild = await this.$services.cases.add(parentId, label, lang);
      newChild.children = [];
      const updatedCollection = {
        ...focusCollection,
        children: focusCollection.children.concat([newChild]),
      };
      commit(types.SET_FOCUS_COLLECTION, updatedCollection);
      dispatch('addCaseToNavigationLookupMap', {
        collection: newChild,
        parentId,
      });
      return newChild.id;
    } catch (err) {
      return err;
    }
  },

  async updateCollection(
    { state, getters, commit, dispatch, rootGetters },
    { caseId, label, body, lang, childrenOrder },
  ) {
    const { focusCollectionId, focusCollection } = state;
    const { editingLanguage } = getters;
    const { isSplitKnowledgeViewer } = rootGetters;
    lang = lang || editingLanguage;
    try {
      const updatedCollection = await this.$services.cases.update(
        caseId,
        label,
        body,
        childrenOrder,
        lang,
        isSplitKnowledgeViewer,
      );
      if (caseId === focusCollectionId) {
        commit(types.SET_FOCUS_COLLECTION, updatedCollection);
      } else {
        const childrenIds = focusCollection.children.map((el) => el.id);
        const childIdx = childrenIds.findIndex((id) => id === caseId);
        if (childIdx > -1) {
          const newChildren = [...focusCollection.children];
          newChildren[childIdx] = updatedCollection;
          commit(types.SET_FOCUS_COLLECTION, {
            ...focusCollection,
            children: newChildren,
          });
        }
      }

      dispatch('addCaseToNavigationLookupMap', {
        collection: updatedCollection,
      });

      // When update collection come from setLanguageModal we want focus on the new set language
      if (lang !== editingLanguage) {
        dispatch('switchEditingLanguage', lang);
      }

      dispatch('updateCasePath', updatedCollection);

      return updatedCollection;
    } catch (err) {
      return err;
    }
  },

  async unsetCaseLanguage({ commit, getters, dispatch }, { caseId, lang }) {
    try {
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);
      const {
        label,
        defaultLanguage,
        returnLanguage,
        setLanguages,
        untranslatedWithTranslatedContents,
      } = await this.$services.cases.unsetLanguage(caseId, lang);

      const updatedCollection = {
        ...getters.focusCollection,
        label,
        defaultLanguage,
        returnLanguage,
        setLanguages,
        untranslatedWithTranslatedContents,
      };
      commit(types.SET_FOCUS_COLLECTION, updatedCollection);
      dispatch('addCaseToNavigationLookupMap', {
        collection: updatedCollection,
      });
    } finally {
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);
    }
  },

  async unsetContentLanguage(
    { commit, getters, dispatch },
    { contentId, lang },
  ) {
    try {
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);
      const { label, body, defaultLanguage, returnLanguage, setLanguages } =
        await this.$services.parametricContents.unsetLanguage(contentId, lang);

      const { focusContent } = getters;
      const updatedContent = {
        ...focusContent,
        label,
        body,
        defaultLanguage,
        returnLanguage,
        setLanguages,
      };

      dispatch('updateNavigatorCaseParents', {
        caseParameters: updatedContent.caseParameters[0],
        lang,
      });

      if (focusContent && focusContent.id === contentId) {
        commit(types.SET_FOCUS_CONTENT, updatedContent);
      }

      dispatch('getFocusCollectionContents');
      dispatch('updateContentInNavigationLookupMap', updatedContent);
    } finally {
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);
    }
  },

  /**
   * The deleteCollection method handles both deletion on focus view and navigator view
   * @param {*} caseId
   */
  async deleteCollection(
    { commit, state, getters, dispatch },
    { caseId, softDeleted } = {},
  ) {
    const { focusCollection } = state;
    const { getNavigatorLookupMapCaseParent } = getters;
    // const { getNavigatorLookupMapCase } = getters;
    const childrenIds = focusCollection.children.map((el) => el.id);

    try {
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, true);

      // 1. delete Case
      await this.$services.cases.delete(caseId, softDeleted);

      // 2. Update Focus Collection is in Focus Case View
      if (childrenIds.includes(caseId)) {
        commit(types.SET_FOCUS_COLLECTION, {
          ...focusCollection,
          children: focusCollection.children.filter((c) => c.id !== caseId),
        });
        dispatch('deleteCaseFromNavigatorLookupMap', {
          caseIdToDelete: caseId,
          parentIdToDeleteFrom: focusCollection.id,
        });
      }
      // 3. Update Navigator Lookup Map if not in focusView
      else {
        const parentToDeleteFrom = getNavigatorLookupMapCaseParent(caseId);
        if (parentToDeleteFrom)
          dispatch('deleteCaseFromNavigatorLookupMap', {
            caseIdToDelete: caseId,
            parentIdToDeleteFrom: parentToDeleteFrom.id,
          });
      }
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, false);
    } catch (err) {
      commit(types.KNOWLEDGE_EDITOR_IS_LOADING, false);
      return err;
    }
  },

  async fetchDirectOrderedChildren(
    { dispatch, getters, rootGetters },
    { caseId, childrenOrder },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapCase, editingLanguage: lang } = getters;
    const directOrderedChildren =
      await this.$services.cases.getOrderedChildrenAndContents(
        caseId,
        getters.focusKnowledgeValue,
        childrenOrder,
        lang,
      );

    const parentCollection = getNavigatorLookupMapCase(caseId);
    parentCollection.orderedChildrenAndContents = directOrderedChildren;
    dispatch('addCaseToNavigationLookupMap', { collection: parentCollection });

    return directOrderedChildren;
  },

  async getFocusCollectionContents({ commit, getters, rootGetters }) {
    const { focusCollectionId, collectionSearch } = getters;
    const { isSplitKnowledgeViewer } = rootGetters;

    if (!focusCollectionId || !isSplitKnowledgeViewer) return;

    const { filter, page } = collectionSearch;

    commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);

    try {
      const focusCollectionContents =
        await this.$services.cases.getContentsByCaseId(
          focusCollectionId,
          20,
          page,
          filter,
        );
      commit(types.SET_FOCUS_COLLECTION_CONTENTS, focusCollectionContents);
      commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);
      return focusCollectionContents;
    } catch (err) {
      return err;
    }
  },

  updateCollectionSearch({ commit, dispatch }, { filter, page }) {
    if (filter !== undefined) {
      commit(types.SET_COLLECTION_SEARCH_FILTER, filter);
    }
    if (page !== undefined) {
      commit(types.SET_COLLECTION_SEARCH_PAGE, page);
    }

    dispatch('getFocusCollectionContents');
  },

  setToggleNavigationBar({ commit }, { toggleNavigationBar }) {
    commit(types.SET_TOGGLE_NAVIGATION_BAR, toggleNavigationBar);
  },

  /**
   * WRAPPERS ACTIONS TO UPDATE NAVIGATOR STATE WHEN NECESSARY
   */

  async updateContent({ dispatch, commit, getters }, { id, payload }) {
    const { focusContent, editingLanguage } = getters;
    let {
      label,
      body,
      lang,
      mentions,
      summary,
      shortSummary,
      kmRecommendations,
      actionItems,
      published,
      isNewLanguage,
      isHidden,
    } = payload;
    lang = lang || editingLanguage;
    let newContent;
    if (
      label ||
      body ||
      summary ||
      shortSummary ||
      kmRecommendations ||
      actionItems ||
      published !== undefined ||
      isHidden !== undefined
    ) {
      const updatePayload = {
        id,
        lang,
        label,
        body,
        mentions,
        summary,
        shortSummary,
        kmRecommendations,
        actionItems,
        published,
        isHidden,
      };
      newContent = await this.$services.parametricContents.updateTranslation(
        updatePayload,
      );
      dispatch('updateNavigatorCaseParents', {
        caseParameters: newContent.caseParameters[0],
        lang,
      });
      dispatch('storeUpdatesEvent', updatePayload);
    } else {
      newContent = await this.$services.parametricContents.update({
        id,
        ...payload,
        lang,
      });
    }

    if (lang === editingLanguage) {
      if (focusContent && focusContent.id === id) {
        copyProperties(newContent, focusContent);
        commit(types.SET_FOCUS_CONTENT, focusContent);
      }

      dispatch('getFocusCollectionContents');
      dispatch('updateContentInNavigationLookupMap', newContent);
    }

    // When update content come from setLanguageModal we want focus on the new set language
    if (isNewLanguage) {
      dispatch('switchEditingLanguage', lang);
      commit(types.UPDATE_NAVIGATION_LANGUAGE, lang);
    }

    return newContent;
  },

  async toggleKeyStep(context, { id, type }) {
    return this.$services.parametricContents.toggleKeyStep(id, type);
  },

  async upsertContentAction({ commit, getters }, payload) {
    const { focusContent } = getters;
    const { contentId, actionId, actionPayload, langs, type, title } = payload;

    const newContent =
      await this.$services.parametricContents.upsertContentAction(
        contentId,
        actionId,
        actionPayload,
        langs,
        type,
        title,
      );

    if (focusContent && focusContent.id === contentId) {
      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);
    }

    return newContent.actions;
  },

  async deleteContentAction({ commit, getters }, payload) {
    const { focusContent } = getters;
    const { contentId, actionId } = payload;

    const newContent =
      await this.$services.parametricContents.deleteContentAction(
        contentId,
        actionId,
      );

    if (focusContent && focusContent.id === contentId) {
      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);
    }

    return newContent.actions;
  },

  async updateContentSettingsAction(
    { dispatch, getters, commit },
    { id, payload },
  ) {
    commit(types.SET_COLLECTION_VIEWER_IS_LOADING, true);
    const { focusContent } = getters;
    const contentPayload = { id, ...payload };
    const newContent = await this.$services.parametricContents.updateSettings(
      contentPayload,
    );

    if (focusContent && focusContent.id === newContent.id) {
      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);
    }

    dispatch('updateContentExistenceInCasesNavigationLookupMap', {
      newContent,
      newIndex: payload.newIndex,
    });

    commit(types.SET_COLLECTION_VIEWER_IS_LOADING, false);

    return newContent;
  },

  async setContentAccess({ getters, commit }, accessType) {
    const { focusContent, editingLanguage: lang } = getters;

    try {
      const newContent =
        await this.$services.parametricContents.setContentAccess(
          focusContent.id,
          accessType,
          lang,
        );

      if (!newContent.publicData && newContent.publicData !== null)
        throw new Error(newContent);

      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);

      return newContent;
    } catch (err) {
      return null;
    }
  },

  async setPublicDescription({ getters, commit }, { description, metaLabel }) {
    const { focusContent, editingLanguage: lang } = getters;
    try {
      const newContent =
        await this.$services.parametricContents.setPublicDescription(
          focusContent.id,
          description,
          metaLabel,
          lang,
        );

      if (!newContent.publicData) throw new Error(newContent);

      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);

      return newContent;
    } catch (err) {
      return null;
    }
  },

  async addAccessToken({ getters, commit }, { name, expirationDate }) {
    const { focusContent } = getters;
    try {
      const newContent = await this.$services.parametricContents.addAccessToken(
        focusContent.id,
        name,
        expirationDate,
      );

      if (!newContent.publicData) throw new Error(newContent);

      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);

      return newContent;
    } catch (err) {
      return null;
    }
  },

  async revokeAccessToken({ getters, commit }, { accessToken }) {
    const { focusContent } = getters;
    try {
      const newContent =
        await this.$services.parametricContents.revokeAccessToken(
          focusContent.id,
          accessToken,
        );

      if (!newContent.publicData) throw new Error(newContent);

      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);

      return newContent;
    } catch (err) {
      return null;
    }
  },

  async setRelatedContents({ getters, commit }, relatedContents) {
    const { focusContent } = getters;
    try {
      const newContent =
        await this.$services.parametricContents.setRelatedContents(
          focusContent.id,
          relatedContents,
        );

      if (!newContent.relatedContents) throw new Error(newContent);

      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);

      return newContent;
    } catch (err) {
      return null;
    }
  },

  async countRelatedContentReferences(_, { contentId, knowledgeId }) {
    try {
      const count =
        await this.$services.parametricContents.countRelatedContentReferences(
          contentId,
          knowledgeId,
        );

      return count;
    } catch (err) {
      return null;
    }
  },

  /**
   * UPDATE CONTENT PLUGINS
   */

  async updateContentPlugins({ commit, getters }, { id, isStep = false }) {
    const { focusContent: focusContentCopy, focusStep: focusStepCopy } =
      getters;
    const contentToModify = isStep ? focusStepCopy : focusContentCopy;
    const newContent = await this.$services.parametricContents.updatePlugins(
      contentToModify.id,
      id,
    );

    copyProperties(newContent, contentToModify);
    commit(
      isStep ? types.SET_FOCUS_STEP : types.SET_FOCUS_CONTENT,
      contentToModify,
    );
  },

  /**
   * FOCUS COLLECTION CONTENTS
   */
  async updateContentSettings({ dispatch, getters, commit }, updatedContent) {
    try {
      const { focusContent } = getters;

      await this.$services.parametricContents.updateSettings(updatedContent);

      if (focusContent.id === updatedContent.id) {
        copyProperties(updatedContent, focusContent);
        commit(types.SET_FOCUS_CONTENT, focusContent);
      }
      return dispatch('getFocusCollectionContents');
    } catch (err) {
      return err;
    }
  },

  async upsertVerificationPolicy(
    { dispatch, getters, commit },
    {
      contentId,
      verificationRange,
      verificationDueDate,
      setToDraftAtDueDate,
      customRoleId,
      userId,
    },
  ) {
    const { focusContent, editingLanguage } = getters;

    const verificationPolicy =
      await this.$services.parametricContents.upsertVerificationPolicy({
        contentId,
        verificationRange,
        customRoleId,
        userId,
        verificationDueDate,
        setToDraftAtDueDate,
        lang: editingLanguage,
      });

    if (!focusContent || focusContent.id !== contentId) {
      return verificationPolicy;
    }

    const isOutdatedPreviousState = focusContent.isOutdated;
    const verificationPolicyPreviousState = focusContent.verificationPolicy;

    focusContent.verificationPolicy = verificationPolicy;
    focusContent.isOutdated = false;

    commit(types.SET_FOCUS_CONTENT, focusContent);

    // Existing verificationPolicy meaning upsert is an update
    const eventName =
      verificationPolicyPreviousState &&
      (verificationPolicyPreviousState.userId ||
        verificationPolicyPreviousState.customRoleId)
        ? 'verificationPolicyUpdate'
        : 'verificationPolicyAdd';

    const baseEventPayload = {
      content: { id: contentId },
      lang: editingLanguage,
    };

    dispatch('storeMutationsEvent', {
      payload: { ...baseEventPayload, verificationPolicy },
      eventName,
    });

    if (isOutdatedPreviousState) {
      dispatch('storeMutationsEvent', {
        payload: { ...baseEventPayload, isOutdated: false },
        eventName: 'contentIsOutdatedUpdate',
      });
    }

    dispatch('updateContentInNavigationLookupMap', {
      id: focusContent.id,
      isOutdated: false,
    });

    return verificationPolicy;
  },

  async deleteVerificationPolicy({ dispatch, getters, commit }, { contentId }) {
    const { focusContent, editingLanguage } = getters;

    const verificationPolicyPreviousState = focusContent.verificationPolicy;

    const verificationPolicyRemoved =
      await this.$services.parametricContents.removeVerificationPolicy(
        contentId,
        editingLanguage,
      );

    if (
      !focusContent ||
      focusContent.id !== contentId ||
      !verificationPolicyRemoved
    ) {
      return verificationPolicyRemoved;
    }

    focusContent.verificationPolicy = {
      customRoleId: null,
      userId: null,
      verificationDueDate: null,
      verificationRange: null,
    };
    commit(types.SET_FOCUS_CONTENT, focusContent);

    dispatch('updateContentInNavigationLookupMap', {
      id: focusContent.id,
      isOutdated: true,
    });

    dispatch('storeMutationsEvent', {
      payload: {
        content: { id: contentId },
        lang: editingLanguage,
        verificationPolicy: verificationPolicyPreviousState,
      },
      eventName: 'verificationPolicyRemove',
    });

    return verificationPolicyRemoved;
  },

  async upsertPublicationPolicy({ getters, commit }, payload) {
    const { editingLanguage, focusContent } = getters;
    const { contentId, publicationDueDate } = payload;

    await this.$services.parametricContents.upsertPublicationPolicy({
      contentId,
      publicationDueDate,
      lang: editingLanguage,
    });

    focusContent.publicationPolicy = { publicationDueDate };
    commit(types.SET_FOCUS_CONTENT, focusContent);
  },

  async deletePublicationPolicy({ getters, commit }, { contentId }) {
    const { editingLanguage, focusContent } = getters;

    const publicationPolicyRemoved =
      await this.$services.parametricContents.removePublicationPolicy(
        contentId,
        editingLanguage,
      );

    if (!publicationPolicyRemoved) return;

    focusContent.publicationPolicy = null;
    commit(types.SET_FOCUS_CONTENT, focusContent);
  },

  async bulkUpdateAttributes(
    { dispatch, getters },
    { contentIds, toAdd, toRemove, isGranularAccessRestrictions },
  ) {
    const { focusCollection } = getters;

    try {
      await this.$services.parametricContents.bulkUpdateAttributes(
        contentIds,
        toAdd,
        toRemove,
        isGranularAccessRestrictions,
      );
      if (focusCollection) {
        dispatch('fetchDirectOrderedChildren', {
          caseId: focusCollection.id,
          childrenOrder: focusCollection.childrenOrder,
        });
      }

      dispatch('bulkUpdateContentExistenceInCasesNavigationLookupMap', {
        contentIds,
        toAdd,
        toRemove,
      });

      return dispatch('getFocusCollectionContents');
    } catch (err) {
      return err;
    }
  },

  async bulkUpdateStatus(
    { dispatch, getters, commit },
    { contentIds, key, value },
  ) {
    const { focusContent, editingLanguage } = getters;
    try {
      await this.$services.parametricContents.bulkUpdateStatus(
        contentIds,
        key,
        value,
        editingLanguage,
      );

      if (focusContent && contentIds.includes(focusContent.id)) {
        focusContent[key] = value;

        if (key === 'isOutdated') {
          const copyPolicy = focusContent.verificationPolicy
            ? { ...focusContent.verificationPolicy }
            : {};
          if (
            copyPolicy.verificationDueDate !== null &&
            copyPolicy.verificationRange
          ) {
            copyPolicy.verificationDueDate = value
              ? copyPolicy.verificationDueDate
              : Date.now() + parseInt(copyPolicy.verificationRange, 10) * 1000;
          } else if (copyPolicy.verificationRange === 0 && !value) {
            copyPolicy.verificationDueDate = null;
            copyPolicy.verificationRange = null;
            copyPolicy.userId = null;
            copyPolicy.customRoleId = null;
            copyPolicy.setToDraftAtDueDate = null;
          }
          focusContent.verificationPolicy = copyPolicy;
        }
        commit(types.SET_FOCUS_CONTENT, focusContent);
      }

      contentIds.forEach((id) => {
        dispatch('updateContentInNavigationLookupMap', {
          id,
          [key]: value,
        });
        dispatch('storeUpdatesEvent', {
          id,
          lang: editingLanguage,
          [key]: value,
        });
      });

      return dispatch('getFocusCollectionContents');
    } catch (err) {
      return err;
    }
  },

  async bulkUpdateParameters(
    { dispatch },
    { contentIds, knowledge, parameters },
  ) {
    try {
      await this.$services.parametricContents.bulkUpdateParameters(
        contentIds,
        knowledge,
        parameters,
      );
      return dispatch('getFocusCollectionContents');
    } catch (err) {
      return err;
    }
  },

  async bulkArchiveContents({ dispatch }, payload) {
    const { contentIds, softDeleted } = payload;
    try {
      await this.$services.parametricContents.bulkArchiveContents(
        contentIds,
        softDeleted,
      );
      dispatch('deleteContentsNavigatorLookup', contentIds);
      contentIds.forEach((id) =>
        dispatch('storeMutationsEvent', {
          content: { id },
          eventName: 'archive',
        }),
      );
      return dispatch('getFocusCollectionContents');
    } catch (err) {
      return err;
    }
  },

  /**
   * ARCHIVE FOCUS
   */

  async loadFocusArchiveById({ commit, getters }, { archiveId }) {
    const { editingLanguage: lang } = getters;
    commit(types.RESET_FOCUS_ARCHIVE, false);
    try {
      const archive = await this.$services.parametricContents.getArchiveById(
        archiveId,
        lang,
      );
      if (archive) {
        commit(types.SET_FOCUS_ARCHIVE, archive);
      } else {
        commit(types.SET_FOCUS_ARCHIVE_ERROR);
      }
      return archive;
    } catch (err) {
      return err;
    }
  },

  resetFocusArchive({ commit }) {
    commit(types.RESET_FOCUS_ARCHIVE);
  },

  async restoreFocusArchive({ commit, state }, caseParameters = null) {
    const { archiveId, archiveContent } = state;
    await this.$services.parametricContents.restoreArchiveContent(
      archiveId,
      caseParameters,
    );
    commit(types.RESET_FOCUS_ARCHIVE);
    return archiveContent.id;
  },

  async deleteFocusArchive({ commit, state }) {
    const { archiveId } = state;
    await this.$services.parametricContents.bulkDeleteContents([archiveId]);
    commit(types.RESET_FOCUS_ARCHIVE);
  },

  async bulkDeleteContentArchive({ commit }, ids) {
    await this.$services.parametricContents.bulkDeleteContents(ids);
    commit(types.RESET_FOCUS_ARCHIVE);
  },

  /**
   * CASE ARCHIVE FOCUS
   */

  async loadFocusCaseArchiveById(
    { commit, getters, dispatch },
    { caseArchiveId, isMain = false, target = null },
  ) {
    const { editingLanguage: lang } = getters;

    commit(types.SET_CASE_ARCHIVE_VIEWER_IS_LOADING, true);
    commit(types.SET_CASE_ARCHIVE_ERROR, false);
    try {
      if (isMain || target) {
        dispatch('setFocusArchiveAncestors', []);
      }
      const archive = await this.$services.cases.getCaseArchiveById(
        target || caseArchiveId,
        !target,
        lang,
      );

      if (archive) {
        if (target) {
          const { caseArchiveAncestors } = archive;
          commit(
            types.SET_MAIN_FOCUS_CASE_ARCHIVE,
            caseArchiveAncestors[0] || archive,
          );
          commit(types.SET_FOCUS_CASE_ARCHIVE, archive);
          commit(types.SET_FOCUS_CASE_ARCHIVE_ANCESTORS, caseArchiveAncestors);
        } else {
          if (isMain) {
            commit(types.SET_MAIN_FOCUS_CASE_ARCHIVE, archive);
          }
          commit(types.SET_FOCUS_CASE_ARCHIVE, archive);
        }
      } else {
        commit(types.SET_CASE_ARCHIVE_ERROR, true);
      }
    } catch (err) {
      return err;
    } finally {
      commit(types.SET_CASE_ARCHIVE_VIEWER_IS_LOADING, false);
    }
  },

  setFocusCaseArchiveFromAncestors({ commit, state }, idx) {
    const { focusCaseArchiveAncestors } = state;
    const caseArchive = focusCaseArchiveAncestors[idx];
    commit(types.SET_FOCUS_CASE_ARCHIVE, caseArchive);

    const newFocusCaseArchiveAncestors = focusCaseArchiveAncestors.slice(
      0,
      idx,
    );
    commit(
      types.SET_FOCUS_CASE_ARCHIVE_ANCESTORS,
      newFocusCaseArchiveAncestors,
    );
  },

  resetFocusCaseArchiveAncestors({ commit, state }) {
    const { mainCaseArchive } = state;

    commit(types.SET_FOCUS_CASE_ARCHIVE, mainCaseArchive);
    commit(types.SET_FOCUS_CASE_ARCHIVE_ANCESTORS, []);
  },

  setFocusArchiveAncestors({ commit }, payload) {
    commit(types.SET_FOCUS_CASE_ARCHIVE_ANCESTORS, payload);
  },

  async restoreMainCaseArchive({ commit, getters }, parent) {
    const { mainCaseArchiveId } = getters;
    const newCase = await this.$services.cases.restoreCaseArchive(
      mainCaseArchiveId,
      parent,
    );
    commit(types.RESET_CASE_ARCHIVE);
    return newCase.id;
  },

  async deleteCaseArchive({ commit, getters }) {
    const { mainCaseArchiveId } = getters;
    await this.$services.cases.deleteCaseArchive(mainCaseArchiveId);
    commit(types.RESET_CASE_ARCHIVE);
  },

  async bulkDeleteCaseArchive({ commit }, ids) {
    await this.$services.cases.bulkDeleteCaseArchive(ids);
    commit(types.RESET_CASE_ARCHIVE);
  },

  async fetchContentParameterLabels({ commit }) {
    const labels =
      await this.$services.contentParameterLabels.getContentParameterLabels();

    commit(types.SET_CONTENT_PARAMETER_LABELS, labels);

    return labels;
  },

  async setContentCover({ commit, getters }, { contentId, cover }) {
    const { value } = cover;
    const { focusContent } = getters;
    try {
      const updatedContent =
        await this.$services.parametricContents.updateCover(contentId, value);

      const newContent = { ...focusContent };
      newContent.cover = updatedContent.cover;
      copyProperties(newContent, focusContent);
      commit(types.SET_FOCUS_CONTENT, focusContent);
      return true;
    } catch (err) {
      return false;
    }
  },

  // KNOWLEDGE LOGS

  storeParametricReadEvent(_, content) {
    const { path, query } = this.$router.currentRoute;
    this.$services.events.content.read(
      { ...content, source: query.from },
      path,
      'WEB_PARAMETRIC',
    );
  },

  // eslint-disable-next-line no-unused-vars
  storeReadEvent({ getters }, content) {
    const { focusKnowledge } = getters;
    const { path, query, params } = this.$router.currentRoute;
    const { lang, stepId } = params;

    // Invalid lang params
    if (!focusKnowledge || !focusKnowledge.supportedLanguages.includes(lang)) {
      return;
    }

    // Avoid logging root content when focused on a step
    if (stepId && !['Step', 'keyStep'].includes(content.type)) {
      return;
    }

    this.$services.events.content.read(
      { ...content, source: query.from, lang },
      path,
      path.includes('web-parametric') ? 'WEB_PARAMETRIC' : 'ADMIN',
    );
  },

  storeMutationsEvent(_, { payload, eventName }) {
    const { fullPath: route } = this.$router.currentRoute;

    this.$services.events.content[eventName](payload, route, 'ADMIN');
  },

  storeUpdatesEvent({ dispatch }, payload) {
    if (!payload) return;

    const { id, lang, published, isOutdated } = payload;

    if (published !== undefined && published !== null) {
      dispatch('storeMutationsEvent', {
        payload: { content: { id }, lang, published },
        eventName: 'contentPublishedUpdate',
      });
    } else if (isOutdated !== undefined && isOutdated !== null) {
      dispatch('storeMutationsEvent', {
        payload: { content: { id }, lang, isOutdated },
        eventName: 'contentIsOutdatedUpdate',
      });
    }
  },

  // SEARCH
  async handleSearchRelatedContent({ getters, dispatch }, query) {
    const {
      focusKnowledgeValue: knowledge,
      focusKnowledgeDefaultLanguage,
      editingLanguage,
    } = getters;

    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    const results = await dispatch(
      'searchAction',
      {
        searchType: 'lightSearchContentFromKnowledge',
        searchArgs: {
          knowledge,
          query,
          size: 10,
          lang,
        },
      },
      { root: true },
    );
    return results.documents;
  },
  async handleSearchContents({ getters, dispatch }, { query, contentTypes }) {
    const {
      focusKnowledgeValue: knowledge,
      focusKnowledgeDefaultLanguage,
      editingLanguage,
    } = getters;

    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    const results = await dispatch(
      'searchAction',
      {
        searchType: 'lightSearchContentFromKnowledge',
        searchArgs: {
          knowledge,
          query,
          size: 10,
          lang,
          type: contentTypes,
          published: ['true', null, 'false'],
        },
      },
      { root: true },
    );
    return results.documents;
  },

  async searchAutocomplete(
    { getters },
    { queryText, pre, isQuestion, correlationId },
  ) {
    const { focusKnowledgeDefaultLanguage, editingLanguage } = getters;

    const lang = editingLanguage || focusKnowledgeDefaultLanguage;
    try {
      const res = await this.$services.search.autocomplete({
        queryText,
        pre,
        language: lang,
        isQuestion,
        correlationId,
      });

      if (!res) return false;

      return res;
    } catch (err) {
      return false;
    }
  },

  storeSearchEvent({ getters }, { eventMethod, event }) {
    try {
      const { focusKnowledgeId } = getters;
      event.knowledgeId = focusKnowledgeId;
      this.$services.events.content[eventMethod](
        event,
        window.location.href,
        'ADMIN',
      );
    } catch (e) {
      console.log(e);
    }
  },

  // TEMPLATE
  async getTemplate({ commit, getters }, id) {
    try {
      const lang = getters.editingLanguage;
      const template = await this.$services.contentTemplates.get(id, lang);
      commit(types.SET_TEMPLATE, template);
    } catch (err) {
      return false;
    }
  },
  async getTemplates({ commit, getters }) {
    const lang = getters.editingLanguage;
    const templates = await this.$services.contentTemplates.getAll(lang);
    commit(types.SET_TEMPLATES, templates);
  },
  async createTemplateByContentId({ commit, getters }, template) {
    const templates = [...getters.templates];

    const { focusKnowledgeDefaultLanguage, editingLanguage } = getters;

    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    try {
      const newTemplate =
        await this.$services.contentTemplates.createByContentId({
          ...template,
          lang,
        });

      if (!newTemplate) throw new Error('BAD_RESPONSE');

      commit(types.SET_TEMPLATES, [...templates, newTemplate]);

      return true;
    } catch (e) {
      return false;
    }
  },
  async createTemplate({ commit, getters }, template) {
    const templates = [...getters.templates];

    const {
      focusKnowledgeId,
      focusKnowledgeValue,
      focusKnowledgeDefaultLanguage,
      editingLanguage,
    } = getters;

    const lang = editingLanguage || focusKnowledgeDefaultLanguage;

    try {
      const newTemplate = await this.$services.contentTemplates.create({
        ...template,
        lang,
        knowledgeId: focusKnowledgeId,
        knowledge: focusKnowledgeValue,
      });

      if (!newTemplate) throw new Error('BAD_RESPONSE');

      commit(types.SET_TEMPLATES, [...templates, newTemplate]);

      return true;
    } catch (e) {
      return false;
    }
  },
  async deleteTemplate({ commit, getters }, id) {
    const templates = [...getters.templates];

    const res = await this.$services.contentTemplates.delete(id);

    if (res) {
      const newTemplates = templates.filter((template) => template.id !== id);

      commit(types.SET_TEMPLATES, newTemplates);
    }
  },

  async updateTemplate({ commit, getters }, { id, payload }) {
    const templates = [...getters.templates];

    try {
      const newTemplate = await this.$services.contentTemplates.update({
        id,
        payload,
      });

      const templateIndex = templates.findIndex(
        (template) => template.id === id,
      );
      if (templateIndex === -1) return false;

      templates[templateIndex] = newTemplate;
      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  async deleteTemplateDescendent(
    { commit, getters },
    { id, descendentId, parentId },
  ) {
    const templates = [...getters.templates];

    try {
      const newTemplate =
        await this.$services.contentTemplates.deleteDescendent({
          id,
          descendentId,
          parentId,
        });

      const templateIndex = templates.findIndex(
        (template) => template.id === id,
      );
      if (templateIndex === -1) return false;

      templates[templateIndex] = newTemplate;
      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  async createTemplateDescendent(
    { commit, getters },
    { id, parentId, ancestors, label, lang },
  ) {
    const templates = [...getters.templates];
    const { editingLanguage } = getters;
    lang = lang || editingLanguage;

    try {
      const newTemplate =
        await this.$services.contentTemplates.createDescendent({
          id,
          parentId,
          ancestors,
          label,
          lang,
        });

      const templateIndex = templates.findIndex(
        (template) => template.id === id,
      );
      if (templateIndex === -1) return false;

      templates[templateIndex] = newTemplate;
      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  async orderTemplateDescendents(
    { commit, getters },
    { id, descendentId, path },
  ) {
    const templates = [...getters.templates];

    try {
      const newTemplate =
        await this.$services.contentTemplates.orderDescendents({
          id,
          descendentId,
          path,
        });

      const templateIndex = templates.findIndex(
        (template) => template.id === id,
      );
      if (templateIndex === -1) return false;

      templates[templateIndex] = newTemplate;
      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  async updateTemplateTranslation(
    { commit, getters, dispatch },
    { id, descendentId, key, value, lang },
  ) {
    const templates = [...getters.templates];
    const { editingLanguage } = getters;

    try {
      const newTemplate =
        await this.$services.contentTemplates.updateTranslation({
          id,
          descendentId,
          key,
          value,
          lang,
        });

      const templateIndex = templates.findIndex(
        (template) => template.id === id,
      );
      if (templateIndex === -1) return false;

      templates[templateIndex] = newTemplate;

      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      // When update content come from setLanguageModal we want focus on the new set language
      if (lang !== editingLanguage) {
        dispatch('switchEditingLanguage', lang);
        commit(types.UPDATE_NAVIGATION_LANGUAGE, lang);
      }

      return true;
    } catch (e) {
      return false;
    }
  },

  async unsetTranslation(
    { commit, getters },
    { id, descendentId, lang: langToUnset },
  ) {
    const templates = [...getters.templates];
    const { editingLanguage: lang } = getters;

    try {
      const newTemplate =
        await this.$services.contentTemplates.unsetTranslation({
          id,
          descendentId,
          langToUnset,
          lang,
        });

      const templateIndex = templates.findIndex(
        (template) => template.id === id,
      );
      templates[templateIndex] = newTemplate;

      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  async createTemplateRedirection(
    { commit, getters },
    { id, descendentId, entityId, entityType },
  ) {
    const templates = [...getters.templates];

    try {
      const newTemplate =
        await this.$services.contentTemplates.createRedirection({
          id,
          descendentId,
          entityId,
          entityType,
        });

      const templateIndex = templates.findIndex(
        (template) => template.id === id,
      );
      if (templateIndex === -1) return false;

      templates[templateIndex] = newTemplate;

      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  async updateTemplatesPath({ commit }, path) {
    return commit(types.SET_TEMPLATES_PATH, path);
  },

  async upsertTemplateAction({ commit, getters }, payload) {
    const {
      templateId,
      descendentId,
      actionId,
      actionPayload,
      langs,
      type,
      title,
    } = payload;
    const templates = [...getters.templates];

    try {
      const newTemplate = await this.$services.contentTemplates.upsertAction(
        templateId,
        descendentId,
        actionId,
        actionPayload,
        langs,
        type,
        title,
      );

      const templateIndex = templates.findIndex(
        (template) => template.id === templateId,
      );

      templates[templateIndex] = newTemplate;

      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  async deleteTemplateAction({ commit, getters }, payload) {
    const { templateId, descendentId, actionId } = payload;
    const templates = [...getters.templates];

    try {
      const newTemplate = await this.$services.contentTemplates.deleteAction(
        templateId,
        descendentId,
        actionId,
      );

      const templateIndex = templates.findIndex(
        (template) => template.id === templateId,
      );

      templates[templateIndex] = newTemplate;

      commit(types.SET_TEMPLATE, newTemplate);
      commit(types.SET_TEMPLATES, templates);

      return true;
    } catch (e) {
      return false;
    }
  },

  // PRODUCT
  setCollectionParents({ commit }, path) {
    commit(types.SET_COLLECTION_PARENTS, path);
  },

  //CONTENT HISTORY
  async fetchContentHistory(_, { id, limit, page, lang }) {
    return await this.$services.contentHistory.getContentHistory(
      id,
      limit,
      page,
      lang,
    );
  },

  async getContributorIds({ getters, commit }) {
    try {
      const {
        focusContentId,
        focusKnowledgeId,
        editingLanguage,
        focusKnowledgeDefaultLanguage,
      } = getters;

      const lang = editingLanguage || focusKnowledgeDefaultLanguage;

      const contributors =
        await this.$services.contentVersions.getContributorIds(
          focusContentId,
          focusKnowledgeId,
          lang,
        );

      if (!contributors) return false;

      commit(types.SET_FOCUS_CONTENT_CONTRIBUTORS, contributors);

      return true;
    } catch (err) {
      return false;
    }
  },

  // TRIGGERS
  getEligibleLocations(_, location) {
    return this.$services.parametricContents.getEligibleActionsFromLocation(
      location,
    );
  },

  triggerKnowledgeAction(_, { contentId, actionId }) {
    return this.$services.parametricContents.triggerContentAction(
      contentId,
      actionId,
    );
  },

  // WORKFLOW ACTIONS
  async createWorkflowAction(_, { contentId, workflowAction }) {
    const workflow =
      await this.$services.parametricContents.createWorkflowActions(
        contentId,
        workflowAction,
      );
    return workflow;
  },

  async updateWorkflowAction(
    { commit, state, dispatch },
    { contentId, workflowActionId, workflowAction, formerWorkflowAction = {} },
  ) {
    const { focusStep } = state;
    const event = {
      contentId,
      id: workflowActionId,
      type: workflowAction.type,
      payload: workflowAction.payload,
    };
    try {
      let newContent;
      if (workflowActionId) {
        newContent =
          await this.$services.parametricContents.updateWorkflowAction(
            contentId,
            workflowActionId,
            workflowAction,
          );
        dispatch('storeMutationsEvent', {
          payload: { ...event, formerPayload: formerWorkflowAction.payload },
          eventName: 'updateWorkflowAction',
        });
      } else {
        newContent =
          await this.$services.parametricContents.createWorkflowAction(
            contentId,
            workflowAction,
          );
        dispatch('storeMutationsEvent', {
          payload: {
            ...event,
            id: newContent.workflowActions.slice(-1)[0]._id,
          },
          eventName: 'createWorkflowAction',
        });
      }

      commit(types.SET_FOCUS_STEP, {
        ...focusStep,
        workflowActions: newContent.workflowActions,
      });
      return newContent;
    } catch (err) {
      console.log(err);
    }
  },

  async deleteWorkflowAction(
    { commit, state, dispatch },
    { contentId, workflowActionId, workflowAction },
  ) {
    let { focusStep } = state;
    const event = {
      contentId,
      id: workflowActionId,
      type: workflowAction.type,
      payload: workflowAction.payload,
    };
    try {
      const newContent =
        await this.$services.parametricContents.deleteWorkflowAction(
          contentId,
          workflowActionId,
        );
      dispatch('storeMutationsEvent', {
        payload: event,
        eventName: 'deleteWorkflowAction',
      });
      commit(types.SET_FOCUS_STEP, {
        ...focusStep,
        workflowActions: newContent.workflowActions,
      });
      return newContent;
    } catch (err) {
      console.log(err);
    }
  },

  async orderWorkflowActions(
    { commit, state, dispatch },
    { contentId, orderedWorkflowActionIds, formerOrderedWorkflowActionIds },
  ) {
    let { focusStep } = state;
    try {
      const newContent =
        await this.$services.parametricContents.orderWorkflowActions(
          contentId,
          orderedWorkflowActionIds,
        );
      dispatch('storeMutationsEvent', {
        payload: {
          contentId,
          order: orderedWorkflowActionIds,
          formerOrder: formerOrderedWorkflowActionIds,
        },
        eventName: 'orderWorkflowAction',
      });
      commit(types.SET_FOCUS_STEP, {
        ...focusStep,
        workflowActions: newContent.workflowActions,
      });
      return newContent;
    } catch (err) {
      console.log(err);
    }
  },

  async getWorkflowActionLibrary() {
    return this.$services.parametricContents.getWorkflowActionLibrary();
  },

  async addWorkflowActionLibraryItem({ dispatch }, item) {
    const libraryItem =
      await this.$services.parametricContents.addWorkflowActionLibraryItem(
        item,
      );
    dispatch('storeMutationsEvent', {
      payload: {
        id: libraryItem._id,
        title: libraryItem.title,
        type: libraryItem.type,
        payload: libraryItem.payload,
      },
      eventName: 'addWorkflowActionLibrary',
    });
    return libraryItem;
  },

  async updateWorkflowActionLibraryItem(
    { dispatch },
    { id, item, formerItem },
  ) {
    const libraryItem =
      await this.$services.parametricContents.updateWorkflowActionLibraryItem(
        id,
        item,
      );
    dispatch('storeMutationsEvent', {
      payload: {
        id,
        title: item.title,
        type: item.type,
        payload: item.payload,
        formerPayload: formerItem.payload,
      },
      eventName: 'updateWorkflowActionLibrary',
    });
    return libraryItem;
  },

  // Resources
  async getCompanyResources() {
    try {
      return this.$services.resources.getAll();
    } catch (err) {
      console.log(err);
    }
  },

  // Context Sessions
  async filterContextSessions(_, { searchQuery, pageSize, page }) {
    try {
      return this.$services.contextSessions.filter(searchQuery, pageSize, page);
    } catch (err) {
      console.log(err);
    }
  },

  // Context Variables
  async getContextVariables() {
    try {
      return this.$services.contextVariables.getAll();
    } catch (err) {
      console.log(err);
    }
  },

  async createContextVariable(_, contextVariable) {
    try {
      return this.$services.contextVariables.create(contextVariable);
    } catch (err) {
      console.log(err);
    }
  },

  async updateContextVariable(_, { variableId, contextVariable }) {
    try {
      return this.$services.contextVariables.update(
        variableId,
        contextVariable,
      );
    } catch (err) {
      console.log(err);
    }
  },

  async deleteContextVariable(_, variableId) {
    try {
      return this.$services.contextVariables.delete(variableId);
    } catch (err) {
      console.log(err);
    }
  },

  // Validate content concept

  async acceptContentConcept({ getters, commit }, { conceptId }) {
    const { focusContentId, focusKnowledgeDefaultLanguage } = getters;

    if (!focusContentId) return;

    // And we need to retrieve the result from knowledge
    await this.$services.brainClient.acceptConcept({
      documentId: focusContentId,
      conceptId,
    });
    // And we need to retrieve the result from knowledge
    const concepts = await this.$services.parametricContents.getConcepts(
      focusContentId,
      focusKnowledgeDefaultLanguage,
    );

    commit(types.SET_CONTENT_CONCEPTS, concepts.denormalizedConcepts);
  },

  async rejectContentConcept({ getters, commit }, { conceptId }) {
    const { focusContentId, focusKnowledgeDefaultLanguage } = getters;

    if (!focusContentId) return;

    // And we need to retrieve the result from knowledge
    await this.$services.brainClient.rejectConcept({
      documentId: focusContentId,
      conceptId,
    });
    // And we need to retrieve the result from knowledge
    const concepts = await this.$services.parametricContents.getConcepts(
      focusContentId,
      focusKnowledgeDefaultLanguage,
    );

    commit(types.SET_CONTENT_CONCEPTS, concepts.denormalizedConcepts);
  },

  async searchConcepts(_, payload) {
    const result = await this.$services.brainClient.searchConcepts(payload);
    return result.data;
  },
  async deleteConcept(_, conceptId) {
    const result = await this.$services.brainClient.deleteConcept(conceptId);
    return result.data;
  },
  async createConcept(_, { concept }) {
    const { sessionId, contents } =
      await this.$services.brainClient.createConcept({
        ...concept,
      });
    return { sessionId, contents };
  },
  async sendSuggestedDocs(_, { answers, sessionId }) {
    const { done, contents } =
      await this.$services.brainClient.sendSuggestedDocs({
        answers,
        sessionId,
      });
    return { done, contents };
  },
};
