import data from './product.data.raw.js';

export class ProductServiceData {
  constructor(client, axiosClient) {
    this.client = client;
    this.axiosClient = axiosClient;
  }

  /**
   * FILTER QUERIES
   */

  async getProductById(id, count = 10, search = '', skipContents = false) {
    return this.client
      .mutate({
        mutation: data.getProductById,
        variables: { id, count, search, skipContents },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getProductById)
      .catch((error) => {
        throw error;
      });
  }

  async getProductByExternalId(id, count = 10, skipContents = false) {
    return this.client
      .mutate({
        mutation: data.getProductByExternalId,
        variables: { id, count, skipContents },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getProductByExternalId)
      .catch((error) => {
        throw error;
      });
  }

  async getPartialProduct(
    family,
    brand,
    count = 10,
    search = '',
    skipContents = false,
  ) {
    return this.client
      .mutate({
        mutation: data.getPartialProduct,
        variables: { family, brand, count, search, skipContents },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getPartialProduct)
      .catch((error) => {
        throw error;
      });
  }

  async filterProductsByHierarchy(filters, pageSize, page, search = '') {
    return this.client
      .mutate({
        mutation: data.filterProductsByHierarchy,
        variables: {
          filters,
          pageSize,
          page,
          search,
        },
      })
      .then((response) => response.data.products)
      .catch((error) => error);
  }

  async filterProductsByHierarchyCount(filters, search = '') {
    return this.client
      .mutate({
        mutation: data.filterProductsByHierarchyCount,
        variables: {
          filters,
          search,
        },
      })
      .then((response) => {
        return response.data.productCount;
      })
      .catch((error) => error);
  }

  async getContentsByProductId(productId, count = 20, search = '') {
    return this.client
      .mutate({
        mutation: data.getContentsByProductId,
        variables: {
          productId,
          count,
          search,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.contents;
      })
      .catch((error) => error);
  }

  async getAttachedFilesByProductId(productId, count) {
    return this.client
      .mutate({
        mutation: data.getAttachedFilesByProductId,
        variables: {
          productId,
          count,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.product.attachedResolutions;
      })
      .catch((error) => error);
  }

  async regexProductParameters(key, input, parameters) {
    return this.client
      .mutate({
        mutation: data.regexProductParameters,
        variables: {
          key,
          input,
          parameters,
        },
      })
      .then((response) => {
        return response.data.regexProductParameters;
      })
      .catch((error) => error);
  }

  async regexProductFacets(input) {
    return this.client
      .mutate({
        mutation: data.regexProductFacets,
        variables: { input },
      })
      .then((response) => {
        return response.data.regexProductFacets;
      })
      .catch((error) => error);
  }

  async getDocumentation(externalId) {
    try {
      const res = await this.axiosClient.get(
        `products/${externalId}/documentation`,
      );
      return res.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
