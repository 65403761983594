import { TokenService } from '@getmayday/mayday-api-instances';
import prepareAxios from './axios';
import prepareApollo from './apollo';
import prepareBrainFetch from './brainFetch';

export const prepareApis = function (store) {
  const tokenService = new TokenService(
    () => {
      const rawStorage = localStorage.getItem('mayday-vuex');
      const accessToken = JSON.parse(rawStorage)['accessToken'];
      return accessToken;
    },
    () => {
      const rawStorage = localStorage.getItem('mayday-vuex');
      const refreshToken = JSON.parse(rawStorage)['refreshToken'];
      return refreshToken;
    },
    () => store.dispatch('refreshToken'),
  );

  const {
    adminClient,
    analyticsClient,
    authClient,
    knowledgeClient,
    notificationClient,
    knowledgeIntegrationClient,
    adminIntegrationClient,
    brainClient,
    exportClient,
    academyClient,
  } = prepareAxios(tokenService);

  const { adminApolloClient, knowledgeApolloClient, academyApolloClient } =
    prepareApollo(tokenService);

  const { brainFetchClient } = prepareBrainFetch(tokenService);

  return {
    adminClient,
    adminApolloClient,
    analyticsClient,
    authClient,
    knowledgeClient,
    knowledgeApolloClient,
    notificationClient,
    knowledgeIntegrationClient,
    adminIntegrationClient,
    brainClient,
    exportClient,
    tokenService,
    brainFetchClient,
    academyClient,
    academyApolloClient,
  };
};
