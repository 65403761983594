import data from './productNotification.data.raw.js';

export class ProductNotificationServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ QUERIES
   */
  async getProductNotifications() {
    return this.client
      .mutate({
        mutation: data.getProductNotifications,
      })
      .then((response) => response.data.productNotifications)
      .catch((error) => error);
  }

  /**
   * MUTATION QUERIES
   */
  async createProductNotification(productNotification) {
    return this.client
      .mutate({
        mutation: data.createProductNotification,
        variables: {
          title: productNotification.title,
          description: productNotification.description,
          urgent: productNotification.urgent,
          parameters: productNotification.parameters,
          accessRestrictions: productNotification.accessRestrictions,
        },
      })
      .then((response) => response.data.createdNotification)
      .catch((error) => error);
  }

  async deleteProductNotification(productNotificationId) {
    return this.client
      .mutate({
        mutation: data.deleteProductNotification,
        variables: {
          id: productNotificationId,
        },
      })
      .then((response) => {
        return response.data.isProductNotificationDeleted
      })
      .catch((error) => error);
  }
}
