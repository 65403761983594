export class CompanyPublicConfigService {
  constructor(data) {
    this.data = data;
  }

  async getAllCompanyPublicConfigs() {
    return this.data.getAllCompanyPublicConfigs();
  }

  async getCompanyPublicConfigById(id) {
    return this.data.getCompanyPublicConfigById(id);
  }

  async getCompanyPublicConfigByKnowledgeId(id) {
    return this.data.getCompanyPublicConfigByKnowledgeId(id);
  }

  async publishOperations(id, operations) {
    return this.data.publishOperations(id, operations);
  }

  async getPendingDeploymentStatus(companyPublicConfigId) {
    return this.data.getPendingDeploymentStatus(companyPublicConfigId);
  }

  async createCompanyPublicConfig(subdomain, lang, operations, groups) {
    const result = await this.data.createCompanyPublicConfig(
      subdomain,
      lang,
      operations,
      groups,
    );
    return result;
  }

  async uploadPublicFile(fileName, fileData) {
    return this.data.uploadPublicFile(fileName, fileData);
  }
  async checkHostname(hostname) {
    return this.data.checkHostname(hostname);
  }
  async checkCdnEndpointHttpsStatus(hostname) {
    return this.data.checkCdnEndpointHttpsStatus(hostname);
  }
  async checkCdnEndpointValidity(customHostname, companyPublicConfigId) {
    try {
      await this.data.checkCdnEndpointValidity(
        customHostname,
        companyPublicConfigId,
      );
    } catch (e) {
      throw e;
    }
  }
}
