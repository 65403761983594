import * as types from './mutation-types';
import {
  copyProperties,
  uniqueAddToChildrenOrder,
  extractCaseParametersFromAddRemove,
} from './utils';

export default {
  /**
   * CONTENTS
   */
  addNewContentInNavigatorLookupMap(
    { commit, dispatch, rootGetters },
    newContent,
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, newContent);
    dispatch('addContentInCaseNavigatorLookupMapFromCaseParameters', {
      newCaseParameters: newContent.caseParameters,
      stateCaseParameters: [],
      contentId: newContent.id,
    });
  },

  deleteContentsNavigatorLookup({ commit, getters, rootGetters }, contentIds) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapCase, getNavigatorLookupMapContent } = getters;

    contentIds.forEach((contentId) => {
      const contentState = getNavigatorLookupMapContent(contentId);

      if (!contentState) return;

      commit(types.REMOVE_CONTENT_FROM_NAVIGATOR_LOOKUP_MAP, contentId);

      const { caseParameters } = contentState;

      if (!caseParameters || !caseParameters.length) return;

      caseParameters.forEach((path) => {
        const caseId = path.split('/').pop();
        const caseState = getNavigatorLookupMapCase(caseId);

        if (!caseState) return;

        caseState.childrenOrder = caseState.childrenOrder.filter(
          ({ id, entityType }) => entityType !== 'Content' || id !== contentId,
        );
        commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, caseState);
      });
    });
  },

  updateContentInNavigationLookupMap(
    { getters, commit, rootGetters },
    newContent,
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapContent } = getters;
    const contentState = getNavigatorLookupMapContent(newContent.id);

    if (!contentState) {
      commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, newContent);
      return;
    }

    copyProperties(newContent, contentState);
    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, contentState);
  },

  /**
   * CONTENTS IN CASES
   */

  bulkUpdateContentExistenceInCasesNavigationLookupMap(
    { getters, rootGetters, dispatch },
    { contentIds, toAdd, toRemove },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { caseParametersToAdd, caseParametersToRemove } =
      extractCaseParametersFromAddRemove(toAdd, toRemove);

    const { getNavigatorLookupMapContent } = getters;

    contentIds.forEach((contentId) => {
      const contentState = getNavigatorLookupMapContent(contentId);

      if (!contentState) return;

      const { caseParameters: stateCaseParameters } = contentState;

      const newCaseParameters = stateCaseParameters.filter(
        (path) => !caseParametersToRemove.includes(path),
      );
      newCaseParameters.push(...caseParametersToAdd);

      dispatch('removeContentInCaseNavigatorLookupMapFromCaseParameters', {
        newCaseParameters,
        stateCaseParameters,
        contentId,
      });

      dispatch('addContentInCaseNavigatorLookupMapFromCaseParameters', {
        newCaseParameters,
        stateCaseParameters,
        contentId,
      });
    });
  },

  updateContentExistenceInCasesNavigationLookupMap(
    { getters, commit, rootGetters, dispatch },
    { newContent, newIndex },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapContent } = getters;
    const { caseParameters: newCaseParameters } = newContent;

    const contentState = getNavigatorLookupMapContent(newContent.id);

    if (!contentState) return;

    const { caseParameters: stateCaseParameters } = contentState;

    const caseParametersAreEqual =
      newCaseParameters.length === stateCaseParameters.length &&
      newCaseParameters.every((path) => stateCaseParameters.includes(path));

    if (caseParametersAreEqual) return;

    dispatch('removeContentInCaseNavigatorLookupMapFromCaseParameters', {
      newCaseParameters,
      stateCaseParameters,
      contentId: newContent.id,
    });

    dispatch('addContentInCaseNavigatorLookupMapFromCaseParameters', {
      newCaseParameters,
      stateCaseParameters,
      contentId: newContent.id,
      newIndex,
    });

    copyProperties(newContent, contentState);
    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, contentState);
  },

  removeContentInCaseNavigatorLookupMapFromCaseParameters(
    { getters, commit, rootGetters },
    { newCaseParameters, stateCaseParameters, contentId },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapCase } = getters;

    const caseParametersToRemove = stateCaseParameters.filter(
      (path) => !newCaseParameters.includes(path),
    );

    caseParametersToRemove.forEach((path) => {
      const caseId = path.split('/').pop();
      const caseState = getNavigatorLookupMapCase(caseId);
      caseState.childrenOrder = caseState.childrenOrder.filter(
        (orderItem) => orderItem.id !== contentId,
      );
      commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, caseState);
    });
  },

  addContentInCaseNavigatorLookupMapFromCaseParameters(
    { getters, commit, rootGetters },
    { newCaseParameters, stateCaseParameters, contentId, newIndex },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapCase } = getters;

    const caseParametersToAdd = newCaseParameters.filter(
      (path) => !stateCaseParameters.includes(path),
    );
    caseParametersToAdd.forEach((path) => {
      const caseId = path.split('/').pop();
      const caseState = getNavigatorLookupMapCase(caseId);
      const childrenOrder = [...caseState.childrenOrder];
      const itemToAdd = {
        id: contentId,
        entityType: `Content`,
      };

      uniqueAddToChildrenOrder(itemToAdd, childrenOrder, newIndex);
      caseState.childrenOrder = childrenOrder;
      commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, caseState);
    });
  },

  /**
   * CASES
   */

  // ALSO HANDLES UPDATE
  addCaseToNavigationLookupMap(
    { commit, dispatch, getters, rootGetters },
    { collection, parentId, skip },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapCase } = getters;
    const collectionState = getNavigatorLookupMapCase(collection.id) || {
      id: '',
      label: '',
      childrenOrder: [],
      root: false,
    };
    const { orderedChildrenAndContents } = collection;
    if (!skip) {
      collection.childrenOrder = orderedChildrenAndContents
        ? orderedChildrenAndContents.map((child) => {
            return {
              id: child.id,
              entityType: child.type ? 'Content' : 'Case',
            };
          })
        : [];
      if (orderedChildrenAndContents) {
        commit(
          types.ADD_MANY_TO_NAVIGATOR_LOOKUP_MAP,
          orderedChildrenAndContents,
        );
      }
    }
    copyProperties(collection, collectionState);
    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, collectionState);

    if (collectionState.root) {
      dispatch('addRootToKnowledgeNavigationLookupMapValue', collectionState);
    } else if (parentId) {
      dispatch('addNewCaseToNavigationLookupMap', {
        newChild: collectionState,
        parentId,
      });
    }
  },

  addNewCaseToNavigationLookupMap(
    { commit, getters, rootGetters },
    { newChild, parentId },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapCase } = getters;
    const caseState = getNavigatorLookupMapCase(parentId);

    if (!caseState) return;

    const childrenOrder = [...caseState.childrenOrder];

    uniqueAddToChildrenOrder(
      { id: newChild.id, entityType: 'Case' },
      childrenOrder,
    );

    caseState.childrenOrder = childrenOrder;

    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, caseState);
  },

  deleteCaseFromNavigatorLookupMap(
    { commit, getters, rootGetters },
    { caseIdToDelete, parentIdToDeleteFrom },
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapCase, getNavigatorLookupMapKnowledge } =
      getters;

    const caseStateToDelete = getNavigatorLookupMapCase(caseIdToDelete);

    commit(types.REMOVE_CASE_FROM_NAVIGATOR_LOOKUP_MAP, caseIdToDelete);

    if (!caseStateToDelete) return;

    const parentState = caseStateToDelete.isRoot
      ? getNavigatorLookupMapKnowledge(parentIdToDeleteFrom)
      : getNavigatorLookupMapCase(parentIdToDeleteFrom);

    if (!parentState) return;

    parentState.childrenOrder = parentState.childrenOrder.filter(
      ({ id, entityType }) => entityType !== 'Case' || id !== caseIdToDelete,
    );

    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, parentState);
  },

  /**
   * KNOWLEDGES
   */

  addKnowledgeToNavigationLookupMap({ commit, rootGetters }, knowledge) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    knowledge.childrenOrder = [];
    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, knowledge);
  },

  updateKnowledgeInNavigationLookupMap(
    { commit, getters, rootGetters },
    knowledge,
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { getNavigatorLookupMapKnowledge } = getters;

    const knowledgeState = getNavigatorLookupMapKnowledge(knowledge.id);

    if (!knowledgeState) return;

    knowledgeState.childrenOrder = knowledge.childrenOrder;
    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, knowledgeState);
  },

  /**
   * CASES IN KNOWLEDGES
   */

  addRootToKnowledgeNavigationLookupMapValue(
    { commit, getters, rootGetters },
    rootCollection,
  ) {
    const { isSplitKnowledgeViewer } = rootGetters;
    if (isSplitKnowledgeViewer) return;

    const { focusKnowledge, getNavigatorLookupMapKnowledge } = getters;
    const knowledgeState = getNavigatorLookupMapKnowledge(focusKnowledge.id);

    if (!knowledgeState || rootCollection.knowledgeId !== focusKnowledge.id)
      return;

    const { childrenOrder } = knowledgeState;

    const uniqueRootMap = childrenOrder.reduce((acc, root) => {
      acc.set(root.id, {
        id: root.id,
        entityType: 'Case',
      });
      return acc;
    }, new Map());

    uniqueRootMap.set(rootCollection.id, {
      id: rootCollection.id,
      entityType: 'Case',
    });

    knowledgeState.childrenOrder = Array.from(uniqueRootMap.values());

    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, knowledgeState);
  },

  /**
   * DRAG AND DROP ACTIONS
   */

  addNodeToCase({ getters, commit }, payload) {
    const { getDragAndDropNode } = getters;

    const { nodeId, nodeType, path, isKnowledge, newIndex } = payload;

    const dragFromToStateData = getDragAndDropNode(nodeId, nodeType) || {};

    dragFromToStateData.dragTo = {
      entityType: isKnowledge ? 'Knowledge' : 'Case',
      path,
      newIndex,
      isKnowledge,
    };

    commit(types.ADD_TO_DRAG_AND_DROP, {
      id: nodeId,
      type: nodeType,
      dragFromToData: dragFromToStateData,
    });
  },

  removeNodeFromCase({ getters, commit }, payload) {
    const { getDragAndDropNode } = getters;

    const { nodeId, nodeType, path, isKnowledge } = payload;

    const dragFromToStateData = getDragAndDropNode(nodeId, nodeType) || {};

    dragFromToStateData.dragFrom = {
      entityType: isKnowledge ? 'Knowledge' : 'Case',
      path,
      isKnowledge,
    };

    commit(types.ADD_TO_DRAG_AND_DROP, {
      id: nodeId,
      type: nodeType,
      dragFromToData: dragFromToStateData,
    });
  },

  /**
   * This is called from plugin listening to ADD_TO_DRAG_AND_DROP mutation
   * It will execute only if we have a dragTo and dragFrom key.
   * @param {*} param0
   */
  executeDragAndDrop({ getters, dispatch, commit }) {
    const { dragAndDrop, getNavigatorLookupMapNode } = getters;

    Object.entries(dragAndDrop).forEach(async ([key, dragAndDropData]) => {
      const dragAndDropPending =
        !dragAndDropData ||
        !dragAndDropData.dragTo ||
        !dragAndDropData.dragFrom;

      if (dragAndDropPending) return;

      const draggedNodeState = getNavigatorLookupMapNode(key);

      if (!draggedNodeState) return;

      const nodeCopy = { ...draggedNodeState };

      if (nodeCopy.type) {
        await dispatch('executeContentDragAndDrop', {
          draggedContent: nodeCopy,
          dragAndDropData,
        });
      } else {
        await dispatch('executeCaseDragAndDrop', {
          draggedCase: nodeCopy,
          dragAndDropData,
        });
      }

      // To update translation indicator of case parents
      const { editingLanguage } = getters;
      dispatch('updateNavigatorCaseParents', {
        caseParameters: dragAndDropData.dragFrom.path,
        lang: editingLanguage,
      });
      dispatch('updateNavigatorCaseParents', {
        caseParameters: dragAndDropData.dragTo.path,
        lang: editingLanguage,
      });

      commit(types.REMOVE_FROM_DRAG_AND_DROP, key);
    });
  },

  /**
   * Drag and dropping a content in another collection is the same
   * as updating its caseParameters.
   * We just need to be carefull if the content already exists in the target case
   *
   * @param {*} param0
   * @param {*} param1
   */
  executeContentDragAndDrop(
    { getters, dispatch },
    { draggedContent, dragAndDropData },
  ) {
    const { focusKnowledgeValue } = getters;

    const { dragTo, dragFrom } = dragAndDropData;

    let newCaseParameters = [...draggedContent.caseParameters];

    newCaseParameters = newCaseParameters.filter(
      (path) => path !== dragFrom.path,
    );

    // Avoid dragging a content that is already in the target case
    if (!newCaseParameters.includes(dragTo.path)) {
      newCaseParameters.push(dragTo.path);
    }

    draggedContent.caseParameters = newCaseParameters;

    return dispatch('updateContentSettingsAction', {
      id: draggedContent.id,
      payload: {
        ...draggedContent,
        labels: draggedContent.labels
          ? draggedContent.labels.map((label) => label.id)
          : [],
        knowledge: focusKnowledgeValue,
        newIndex: dragTo.newIndex,
      },
    });
  },

  /**
   *
   * We do not want to call recursively the moveCase mutation for each case.
   * Instead we let the backend perform all the updates on the database and we just
   * update the local store to reflect those changes
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async executeCaseDragAndDrop(
    { dispatch, getters, commit },
    { draggedCase, dragAndDropData },
  ) {
    const { dragTo, dragFrom } = dragAndDropData;
    const { getNavigatorLookupMapCase, focusKnowledgeRoots } = getters;

    try {
      commit(types.SET_NAVIGATOR_NODE_IS_LOADING, true);

      const newCase = await this.$services.cases.moveCase(
        draggedCase.id,
        dragTo.path,
        dragTo.isKnowledge,
        dragTo.newIndex,
      );

      dispatch('updateLocalDraggedCase', {
        draggedCase,
        dragAndDropData,
        isFirst: true,
      });

      const caseState = getNavigatorLookupMapCase(newCase.id);

      if (!caseState) return;

      copyProperties(newCase, caseState);
      commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, caseState);

      // Update focusKnowledgeRoots if necessary
      if (dragTo.isKnowledge) {
        commit(types.SET_FOCUS_KNOWLEDGE_ROOTS, [
          ...focusKnowledgeRoots,
          caseState,
        ]);
      } else if (dragFrom.isKnowledge) {
        commit(
          types.SET_FOCUS_KNOWLEDGE_ROOTS,
          focusKnowledgeRoots.filter((root) => root.id !== caseState.id),
        );
      }
      return commit(types.SET_NAVIGATOR_NODE_IS_LOADING, false);
    } catch (err) {
      commit(types.SET_NAVIGATOR_NODE_IS_LOADING, false);
      return err;
    }
  },

  /**
   * Recursively update a draggedCase and its children.
   *
   * 1   - Move draggedCase from/to if it's first iteration
   * 2   - Loop over children
   * 3.a - Content = updateContentExistenceInCasesNavigationLookupMap
   * 3.b - Case = call updateLocalDraggedCase as if we had performed a drag and drop
   *
   * dragToPath = previousReceivedPath.concat(draggedCase.id) since we go one level deeper
   * in folder structure
   *
   */
  updateLocalDraggedCase(
    { dispatch, getters, commit },
    { draggedCase, dragAndDropData, isFirst },
  ) {
    const { getNavigatorLookupMapContent, getNavigatorLookupMapCase } = getters;

    const { dragTo, dragFrom } = dragAndDropData;

    const draggedFromCaseId = dragFrom.path.split('/').pop();
    const draggedToCaseId = dragTo.path.split('/').pop();

    // We only need to update childrenOrder for the dragged case
    // because all children cases (not matter how deep they are in hierarchy)
    // still have same parent
    if (isFirst) {
      dispatch('removeCaseFromChildrenOrder', {
        parentId: draggedFromCaseId,
        caseId: draggedCase.id,
        isKnowledge: dragFrom.isKnowledge,
      });
      dispatch('addCaseToChildrenOrder', {
        parentId: draggedToCaseId,
        caseId: draggedCase.id,
        isKnowledge: dragTo.isKnowledge,
        newIndex: dragTo.newIndex,
      });
    }

    // This variable is recursively built using previous dragTo.path + draggedCase.id
    let newPathToCurrentCase;

    // When dragging to root, we don't want to take knowledge.id
    // in the new caseParameters.
    if (dragTo.isKnowledge) {
      newPathToCurrentCase = draggedCase.id;
    } else {
      newPathToCurrentCase = [dragTo.path, draggedCase.id].join('/');
    }

    draggedCase.childrenOrder.forEach((child) => {
      if (child.entityType === 'Content') {
        // We only need to update caseParameters field in case user wants to perform
        // drags and drops on the content
        const contentState = getNavigatorLookupMapContent(child.id);

        if (!contentState) return;

        const { caseParameters: stateCaseParameters } = contentState;

        // Remove the old path that contains the draggedCaseId
        const newCaseParameters = [...stateCaseParameters].filter(
          (path) => !path.includes(draggedCase.id),
        );

        // Add the new path = concatenation of dragToPath + draggedCaseId
        newCaseParameters.push(newPathToCurrentCase);
        contentState.caseParameters = newCaseParameters;
        commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, contentState);
      } else {
        // Recusively call updateLocalDraggedCase as to update its children contents
        // and simulate a drag and drop
        const caseState = getNavigatorLookupMapCase(child.id);
        if (!caseState) return;
        dispatch('updateLocalDraggedCase', {
          draggedCase: caseState,
          dragAndDropData: {
            dragFrom: {
              path: dragFrom.path,
              isKnowledge: false,
            },
            dragTo: {
              path: newPathToCurrentCase,
              isKnowledge: false,
            },
          },
        });
      }
    });
  },

  /**
   * Only called for the case that is directly dragged and dropped
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  removeCaseFromChildrenOrder(
    { commit, getters },
    { parentId, caseId, isKnowledge },
  ) {
    const { getNavigatorLookupMapKnowledge, getNavigatorLookupMapCase } =
      getters;

    const parentStateToRemoveFrom = isKnowledge
      ? getNavigatorLookupMapKnowledge(parentId)
      : getNavigatorLookupMapCase(parentId);

    if (!parentStateToRemoveFrom) return;

    const copy = { ...parentStateToRemoveFrom };

    const { childrenOrder } = copy;

    copy.childrenOrder = [...childrenOrder].filter(
      ({ id, entityType }) => entityType !== 'Case' || id !== caseId,
    );

    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, copy);
  },

  /**
   * Only called for the case that is directly dragged and dropped
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  addCaseToChildrenOrder(
    { commit, getters },
    { parentId, caseId, isKnowledge, newIndex },
  ) {
    const { getNavigatorLookupMapKnowledge, getNavigatorLookupMapCase } =
      getters;

    const parentStateToAddTo = isKnowledge
      ? getNavigatorLookupMapKnowledge(parentId)
      : getNavigatorLookupMapCase(parentId);

    const childrenOrder = [...parentStateToAddTo.childrenOrder];

    const itemToAdd = {
      id: caseId,
      entityType: `Case`,
    };

    uniqueAddToChildrenOrder(itemToAdd, childrenOrder, newIndex);

    parentStateToAddTo.childrenOrder = childrenOrder;
    commit(types.ADD_TO_NAVIGATOR_LOOKUP_MAP, parentStateToAddTo);
  },

  setNavigatorNodeIsLoading({ commit }, value) {
    commit(types.SET_NAVIGATOR_NODE_IS_LOADING, value);
  },

  /**
   * The flushNavigatorLookupMap method flushes all the data
   * from the navigatorNode with the exception of:
   * - the focusKnowledge key
   * - the focusKnowledge roots keys
   * The above mentioned keys are kept in order to ensure a
   * well functioning navigator node from "cold start"
   * @param {store} param0
   */
  flushNavigatorLookupMap({ commit, state }) {
    const { focusKnowledge, focusKnowledgeRoots } = state;

    if (!focusKnowledge) return;

    const keysToKeep = [
      `Knowledge-${focusKnowledge.id}`,
      ...focusKnowledgeRoots.map((el) => `Case-${el.id}`),
    ];
    return commit(types.FLUSH_NAVIGATOR_LOOKUP_MAP, keysToKeep);
  },
};
