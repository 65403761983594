import gql from 'graphql-tag';

export default {
  getContentGroups: gql`
    query getContentGroups($contentId: String!) {
      getContentGroups: readGroupByContentId(contentId: $contentId) {
        id
        alias
        type
        ruleAttributes {
          id
          operator
          value
          companyAttribute {
            id
          }
          companyAttributeValue {
            id
          }
        }
      }
    }
  `,
  createKnowledgeGroup: gql`
    mutation createKnowledgeGroup(
      $alias: String!
      $rules: String!
      $knowledgeId: ID!
      $contentId: ID!
    ) {
      createKnowledgeGroup: createGroup(
        alias: $alias
        rules: $rules
        knowledgeId: $knowledgeId
        contentId: $contentId
      ) {
        id
        alias
        type
        ruleAttributes {
          id
          operator
          value
          companyAttribute {
            id
          }
          companyAttributeValue {
            id
          }
        }
      }
    }
  `,
  updateKnowledgeGroup: gql`
    mutation updateKnowledgeGroup($id: ID!, $alias: String!, $rules: String!) {
      updateKnowledgeGroup: updateGroup(id: $id, alias: $alias, rules: $rules) {
        id
        alias
        type
        ruleAttributes {
          id
          operator
          value
          companyAttribute {
            id
          }
          companyAttributeValue {
            id
          }
        }
      }
    }
  `,
  deleteKnowledgeGroup: gql`
    mutation deleteKnowledgeGroup($id: ID!) {
      deleteKnowledgeGroup: deleteGroup(id: $id)
    }
  `,
};
