export const LOGOUT = 'LOGOUT';

export const GET_COMPANY_PUBLIC_CONFIGS = 'GET_COMPANY_PUBLIC_CONFIGS';

export const GET_FOCUS_PUBLIC_CONFIG = 'GET_FOCUS_PUBLIC_CONFIG';

export const SET_FOCUS_KNOWLEDGE = 'SET_FOCUS_KNOWLEDGE';

export const NEW_OPERATION = 'NEW_OPERATION';
export const DELETE_OPERATION = 'DELETE_OPERATION';
export const DESTROY_OPERATIONS = 'DESTROY_OPERATIONS';

export const SET_DEPLOYMENT_STATUS = 'SET_DEPLOYMENT_STATUS';
export const SET_POLLING_TIMER = 'SET_POLLING_TIMER';
export const SET_DEPLOYING = 'SET_DEPLOYING';

export const SET_HTTPS_STATUS = 'SET_HTTPS_STATUS';
export const SET_HTTPS_POLLING_TIMER = 'SET_HTTPS_POLLING_TIMER';
export const SET_HTTPS_POOLING = 'SET_HTTPS_POOLING';

export const ADD_LANGUAGE = 'ADD_LANGUAGE';
export const SET_MULTILINGUAL = 'SET_MULTILINGUAL';
export const SET_MULTILINGUAL_LOADER = 'SET_MULTILINGUAL_LOADER';

export const SET_CUSTOM_HOSTNAME_ERROR = 'SET_CUSTOM_HOSTNAME_ERROR';
