import data from './userLabel.data.raw';

export class UserLabelServiceData {
  constructor(client) {
    this.client = client;
  }

  findAllCategories() {
    return this.client
      .mutate({
        mutation: data.findAllCategories,
      })
      .then((response) => {
        return response.data.companyUserLabelCategories;
      })
      .catch((error) => error);
  }

  createUserLabelCategory(params) {
    return this.client
      .mutate({
        mutation: data.createCompanyUserLabelCategory,
        variables: params,
      })
      .then((response) => {
        return response.data.createCompanyUserLabelCategory;
      })
      .catch((error) => error);
  }

  deleteCategory(id) {
    return this.client
      .mutate({
        mutation: data.deleteCompanyUserLabelCategory,
        variables: { id },
      })
      .then((response) => {
        return response.data.deleteCompanyUserLabelCategory;
      })
      .catch((error) => error);
  }

  updateUserLabelCategory(params) {
    return this.client
      .mutate({
        mutation: data.updateCompanyUserLabelCategory,
        variables: params,
      })
      .then((response) => {
        return response.data.updateCompanyUserLabelCategory;
      })
      .catch((error) => error);
  }

  createUserLabel(params) {
    return this.client
      .mutate({
        mutation: data.createCompanyUserLabel,
        variables: params,
      })
      .then((response) => {
        return response.data.createCompanyUserLabel;
      })
      .catch((error) => error);
  }

  updateUserLabel(params) {
    return this.client
      .mutate({
        mutation: data.updateCompanyUserLabel,
        variables: params,
      })
      .then((response) => {
        return response.data.updateCompanyUserLabel;
      })
      .catch((error) => error);
  }

  deleteUserLabel(id) {
    return this.client
      .mutate({
        mutation: data.deleteCompanyUserLabel,
        variables: { id },
      })
      .then((response) => {
        return response.data.deleteCompanyUserLabel;
      })
      .catch((error) => error);
  }
}
