import {
  SET_EMPLOYEES_TABLE_FILTERS,
  SET_PENDING_EMPLOYEES_TABLE_FILTERS,
  SET_SHOW_MODAL_EMPLOYEES,
  SET_MODAL_EMPLOYEES_TABLE_FILTERS,
  SET_SHOW_MODAL_PENDING_EMPLOYEES,
  SET_MODAL_PENDING_EMPLOYEES_TABLE_FILTERS,
  ADD_TO_USERS_ADMINISTRATION_FILTER,
  ADD_SEARCH_TO_FILTERS,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  EMPTY_FILTERS_SEARCH,
} from './mutation-types';

export const employeesTableFetchPlugin = (store) => {
  const moduleName = 'adminModule';

  // EMPLOYEES

  const employeesFiltersMutations = [
    `${moduleName}/${SET_EMPLOYEES_TABLE_FILTERS}`,
    `${moduleName}/${SET_MODAL_EMPLOYEES_TABLE_FILTERS}`,
  ];

  // EMPLOYEES MODAL

  const showModalEmployeesMutations = [
    `${moduleName}/${SET_SHOW_MODAL_EMPLOYEES}`,
  ];
  // const modalEmployeesFiltersMutations = [
  //   `${moduleName}/${SET_MODAL_EMPLOYEES_TABLE_FILTERS}`,
  // ];

  // PENDING EMPLOYEES

  const pendingEmployeesFiltersMutations = [
    `${moduleName}/${SET_PENDING_EMPLOYEES_TABLE_FILTERS}`,
  ];

  // PENDING EMPLOYEES MODAL

  const showModalPendingEmployeesMutations = [
    `${moduleName}/${SET_SHOW_MODAL_PENDING_EMPLOYEES}`,
  ];
  const modalPendingEmployeesFiltersMutations = [
    `${moduleName}/${SET_MODAL_PENDING_EMPLOYEES_TABLE_FILTERS}`,
  ];

  // USERS ADMINISTRATION

  const filtersForUserAdministration = [
    `${moduleName}/${ADD_TO_USERS_ADMINISTRATION_FILTER}`,
    `${moduleName}/${ADD_SEARCH_TO_FILTERS}`,
    `${moduleName}/${SET_CURRENT_PAGE}`,
    `${moduleName}/${SET_PAGE_SIZE}`,
    `${moduleName}/${EMPTY_FILTERS_SEARCH}`,
  ];

  store.subscribe((mutation) => {
    // EMPLOYEES
    if (employeesFiltersMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/getEmployeesDetails`);
    }

    // EMPLOYEES MODAL
    if (showModalEmployeesMutations.includes(mutation.type)) {
      if (mutation.payload) {
        store.dispatch(`${moduleName}/getEmployeesDetails`);
      } else {
        // On modal closure, we reupload employees data in order to avoid
        // deprecated data
        store.dispatch(`${moduleName}/getEmployeesDetails`);
      }
    }

    // PENDING EMPLOYEES
    if (pendingEmployeesFiltersMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/getPendingEmployeesDetails`);
    }

    // PENDING EMPLOYEES MODAL
    if (modalPendingEmployeesFiltersMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/getPendingEmployeesDetails`);
    }
    if (showModalPendingEmployeesMutations.includes(mutation.type)) {
      if (mutation.payload) {
        store.dispatch(`${moduleName}/getPendingEmployeesDetails`);
      } else {
        // On modal closure, we reupload employees data in order to avoid
        // deprecated data
        store.dispatch(`${moduleName}/getPendingEmployeesDetails`);
      }
    }

    //USER ADMINISTRATION
    if (filtersForUserAdministration.includes(mutation.type)) {
      store.dispatch(`${moduleName}/getUsersForAdministrationFiltered`);
    }
  });
};
