import gql from 'graphql-tag';

export default {
  readDeletedTreeNodes: gql`
    query readDeletedTreeNodes {
      readDeletedTreeNodes {
        id
        content {
          id
          label
        }
        type
        formerParentNode {
          id
          content {
            id
            label
          }
          ancestors {
            id
            content {
              id
              label
            }
            hierarchyLevel
          }
        }
      }
    }
  `,
  restoreDeletedTreeNode: gql`
    mutation restoreDeletedTreeNode($id: ID!) {
      restoreDeletedTreeNode(id: $id) {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              icon
              parents {
                id
              }
              attachedTreeNodes {
                id
                content {
                  id
                  label
                }
                type
              }
              published
              icon
            }
            actions
            type
            nodes {
              id
            }
          }
        }
      }
    }
  `,
  deleteTreeNode: gql`
    mutation deleteTreeNode($id: ID!) {
      deleteTreeNode(id: $id) {
        id
      }
    }
  `,
  emptyTrash: gql`
    mutation emptyTrash {
      emptyTrash
    }
  `,
};
