import gql from 'graphql-tag';

export default {
  currentTreeNode: gql`
    query currentTreeNode($id: ID!) {
      readTreeNode(id: $id) {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          tags
          parents {
            id
          }
          icon
          updatedAt
          author {
            id
            username
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          reviewers {
            id
            username
          }
          groupReviewers {
            id
            name
          }
          verificationPolicy {
            verificationRange
            verificationDueDate
            userId
            customRoleId
          }
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            tags
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
            updatedAt
            author {
              id
              username
            }
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              tags
              icon
              parents {
                id
              }
              attachedTreeNodes {
                id
                content {
                  id
                  label
                }
                type
              }
              published
              icon
              updatedAt
              author {
                id
                username
              }
            }
            actions
            type
            nodes {
              id
            }
          }
        }
      }
    }
  `,
  dragndrop: gql`
    mutation dragndrop(
      $id: ID!
      $contentId: ID!
      $path: [ID]
      $type: String
      $public: Boolean
      $actions: String
    ) {
      dragndrop(
        id: $id
        contentId: $contentId
        path: $path
        type: $type
        public: $public
        actions: $actions
      )
    }
  `,

  updateTreeNode: gql`
    mutation updateTreeNode(
      $id: ID!
      $contentId: ID!
      $path: [ID]
      $type: String
      $public: Boolean
      $actions: String
    ) {
      updateTreeNode(
        id: $id
        contentId: $contentId
        path: $path
        type: $type
        public: $public
        actions: $actions
      ) {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              icon
              parents {
                id
              }
              attachedTreeNodes {
                id
                content {
                  id
                  label
                }
                type
              }
              published
              icon
            }
            actions
            type
            nodes {
              id
            }
          }
        }
      }
    }
  `,
  linkTreeNode: gql`
    mutation updateTreeNode(
      $id: ID!
      $contentId: ID!
      $path: [ID]
      $type: String
      $public: Boolean
      $actions: String
    ) {
      updateTreeNode(
        id: $id
        contentId: $contentId
        path: $path
        type: $type
        public: $public
        actions: $actions
      ) {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          parents {
            id
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              icon
              parents {
                id
              }
              attachedTreeNodes {
                id
                content {
                  id
                  label
                }
                type
              }
              published
              icon
            }
            actions
            type
            nodes {
              id
            }
          }
        }
      }
    }
  `,
  unlinkTreeNode: gql`
    mutation unlinkTreeNode($treeNodeId: ID!, $newLabel: String!) {
      unlinkTreeNode(treeNodeId: $treeNodeId, newLabel: $newLabel) {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
      }
    }
  `,
  createTreeNodeChildren: gql`
    mutation createTreeNodeChildren(
      $parentId: ID!
      $type: String!
      $label: String
      $actions: String
    ) {
      createTreeNodeChildren(
        parentId: $parentId
        type: $type
        label: $label
        actions: $actions
      ) {
        id
        path
      }
    }
  `,
  duplicateTreeNodeChildren: gql`
    mutation duplicateTreeNodeChildren(
      $id: ID!
      $parentId: ID!
      $position: Int
    ) {
      duplicateTreeNodeChildren(
        id: $id
        parentId: $parentId
        position: $position
      ) {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              icon
              parents {
                id
              }
              attachedTreeNodes {
                id
                content {
                  id
                  label
                }
                type
              }
              published
              icon
            }
            actions
            type
            nodes {
              id
            }
          }
        }
      }
    }
  `,
  translateTreeNode: gql`
    mutation translateTreeNode(
      $nodeId: ID!
      $parentId: ID!
      $newLabel: String!
    ) {
      translateTreeNode(
        nodeId: $nodeId
        parentId: $parentId
        newLabel: $newLabel
      )
    }
  `,
  removeTreeNodeChildren: gql`
    mutation removeTreeNodeChildren($parentId: ID!, $childrenId: ID!) {
      removeTreeNodeChildren(parentId: $parentId, childrenId: $childrenId) {
        id
      }
    }
  `,
  readTreeNodes: gql`
    query readTreeNodesQuery($idsList: [ID!]) {
      readTreeNodes: readTreeNodes(idsList: $idsList) {
        id
        type
      }
    }
  `,
  readTreeNodesAncestors: gql`
    query readTreeNodesAncestors($idsList: [ID!]) {
      readTreeNodes: readTreeNodes(idsList: $idsList) {
        id
        content {
          id
          label
        }
        ancestors {
          id
          content {
            id
            label
            published
          }
          hierarchyLevel
        }
      }
    }
  `,
  readDeletedNodes: gql`
    query readDeletedNodes {
      readDeletedTreeNodes {
        id
        content {
          id
          label
        }
        type
        formerParentNode {
          id
          content {
            id
            label
          }
          ancestors {
            id
            content {
              id
              label
            }
            hierarchyLevel
          }
        }
      }
    }
  `,
  restoreDeletedTreeNode: gql`
    mutation restoreDeletedTreeNode($id: ID!) {
      restoreDeletedTreeNode(id: $id) {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              icon
              parents {
                id
              }
              attachedTreeNodes {
                id
                content {
                  id
                  label
                }
                type
              }
              published
              icon
            }
            actions
            type
            nodes {
              id
            }
          }
        }
      }
    }
  `,
  deleteTreeNode: gql`
    mutation deleteTreeNode($id: ID!) {
      deleteTreeNode(id: $id) {
        id
        content {
          id
          label
          published
        }
        type
        formerParentNode {
          id
          content {
            id
            label
            published
          }
          ancestors {
            id
            content {
              id
              label
              published
            }
            hierarchyLevel
          }
        }
      }
    }
  `,
  emptyTrash: gql`
    mutation emptyTrash {
      emptyTrash
    }
  `,
  readTreeNodesContent: gql`
    query readTreeNodesQuery($idsList: [ID!]) {
      readTreeNodes(idsList: $idsList) {
        id
        type
        content {
          label
          body
          attachedTreeNodes {
            id
          }
          published
          icon
        }
      }
    }
  `,
  getTreeNodeDescendents: gql`
    query readTreeNode($id: ID!) {
      readTreeNode(id: $id) {
        id
        descendents
      }
    }
  `,
  bulkTranslateTreeNodes: gql`
    mutation bulkTranslateTreeNodesMutation($ids: [ID!], $parentId: ID!) {
      bulkTranslateTreeNodes(ids: $ids, parentId: $parentId)
    }
  `,
  bulkRemoveTreeNodes: gql`
    mutation bulkRemoveTreeNodesMutation($ids: [ID!]) {
      bulkRemoveTreeNodes(ids: $ids)
    }
  `,
};
