import data from './content.data.raw';

export class ContentServiceData {
  constructor(client) {
    this.client = client;
  }

  async readContentVersions(id) {
    return this.client
      .mutate({
        mutation: data.readContentVersions,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.readContent.versions)
      .catch((error) => error);
  }

  async updateNodeContent(new_content) {
    return this.client
      .mutate({
        mutation: data.updateNodeContent,
        variables: new_content,
      })
      .then((response) => response.data.updateContent)
      .catch((error) => error);
  }

  async createVerificationPolicy(
    contentId,
    userId,
    customRoleId,
    verificationRange,
  ) {
    return this.client
      .mutate({
        mutation: data.createContentVerificationPolicy,
        variables: {
          contentId,
          userId,
          customRoleId,
          verificationRange,
        },
      })
      .then((response) => response.data.createContentVerificationPolicy)
      .catch((error) => error);
  }

  async setContentAsTrusted(contentId) {
    return this.client
      .mutate({
        mutation: data.setContentAsTrusted,
        variables: {
          contentId,
        },
      })
      .then((response) => response.data.setContentAsTrusted)
      .catch((error) => error);
  }

  async setContentAsUntrusted(contentId) {
    return this.client
      .mutate({
        mutation: data.setContentAsUntrusted,
        variables: {
          contentId,
        },
      })
      .then((response) => response.data.setContentAsUntrusted)
      .catch((error) => error);
  }

  async updateVerificationPolicy(
    contentId,
    userId,
    customRoleId,
    verificationRange,
  ) {
    return this.client
      .mutate({
        mutation: data.updateContentVerificationPolicy,
        variables: {
          contentId,
          userId,
          customRoleId,
          verificationRange,
        },
      })
      .then((response) => response.data.updateContentVerificationPolicy)
      .catch((error) => error);
  }

  async deleteVerificationPolicy(contentId) {
    return this.client
      .mutate({
        mutation: data.deleteContentVerificationPolicy,
        variables: {
          contentId,
        },
      })
      .then((response) => response.data.deleteContentVerificationPolicy)
      .catch((error) => error);
  }

  async updateContentPublishStatus(id, published) {
    return this.client
      .mutate({
        mutation: data.updateContentPublishStatus,
        variables: {
          id,
          published,
        },
      })
      .then((response) => response.data.updateContentPublishStatus)
      .catch((error) => error);
  }

  async bulkUpdateContentPublishStatus(ids, published) {
    return this.client
      .mutate({
        mutation: data.bulkUpdateContentPublishStatus,
        variables: {
          ids,
          published,
        },
      })
      .then((response) => response.data.bulkUpdateContentPublishStatus)
      .catch((error) => error);
  }

  async bulkUpdateContentOutdatedStatus(ids, isOutdated) {
    return this.client
      .mutate({
        mutation: data.bulkUpdateContentOutdatedStatus,
        variables: {
          ids,
          isOutdated,
        },
      })
      .then((response) => response.data.bulkUpdateContentOutdatedStatus)
      .catch((error) => error);
  }
}
