import data from './plugin.data.raw.js';

export class PluginServiceData {
  constructor(client) {
    this.client = client;
  }

  updatePlugins(knowledgeId, plugins) {
    return this.client
      .mutate({
        mutation: data.updatePlugins,
        variables: { knowledgeId, plugins },
      })
      .then((response) => response.data.updatePlugins)
      .catch((error) => error);
  }
}
