import data from './notification.data.raw';

export class AdminNotificationServiceData {
  constructor(client) {
    this.client = client;
  }

  getNotifications() {
    return this.client
      .query({
        query: data.getNotifications,
      })
      .then((response) => response.data.getNotifications)
      .catch((error) => error);
  }

  getNotificationById(id) {
    return this.client
      .query({
        query: data.getNotificationById,
        variables: { id },
      })
      .then((response) => response.data.getNotificationById)
      .catch((error) => error);
  }

  readNotification(id) {
    return this.client
      .mutate({
        mutation: data.readNotification,
        variables: { id },
      })
      .then((response) => response.data.readNotification)
      .catch((error) => error);
  }

  removeNotification(id) {
    return this.client
      .mutate({
        mutation: data.removeNotification,
        variables: { id },
      })
      .then((response) => response.data.removeNotification)
      .catch((error) => error);
  }

  addThumbsUp(id) {
    return this.client
      .mutate({
        mutation: data.addThumbsUp,
        variables: { id },
      })
      .then((response) => response.data.addThumbsUp)
      .catch((error) => error);
  }

  removeThumbsUp(id) {
    return this.client
      .mutate({
        mutation: data.removeThumbsUp,
        variables: { id },
      })
      .then((response) => response.data.removeThumbsUp)
      .catch((error) => error);
  }
}
