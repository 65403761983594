export default async function ({ route, store }) {
  if (route.path.startsWith('/knowledge')) {
    let lang = route.params.lang;

    const editingLanguage = await store.dispatch(
      'knowledgeModule/resolveLanguage',
      lang,
    );

    await store.dispatch('knowledgeModule/setEditingLanguage', editingLanguage);
  }
}
