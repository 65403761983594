export const initGeneralState = () => ({
  filters: [],
  filtersLoading: false,
  filterValues: [],
  filterAdditionalValues: {},
  defaultFilterValues: [],
  defaultFilterAdditionalValues: {},
  filterDateRangeShortcutValues: {},
  tableFile: null,
  tableFileIsLoading: false,
  activeTab: null,
  rawContentLineChartByCollection: [],
  rawContentLineChartBySource: [],

  // VIEW GRAPH
  additionalViewsGraphFilters: [],

  // DOWNLOAD ANALYTICS
  exports: [],
  exportSearchLoading: { fail: false, success: false },
});

export const initContentState = () => ({
  // CONTENT DATA
  rawContentStatCards: {},
  rawContentPublicStatCards: {},
  rawContentPublicStatCardsLoading: false,

  // CONTENT TABLE DATA
  contentTableData: [],
  contentTableDataIsLoading: false,
  additionalContentTableFilters: [],
  contentTableSearchFilterChanged: false,
  contentTableCollectionsFilterChanged: false,
  contentTableCurrentPageChanged: false,
  contentTableSortOptions: {},

  // CONTENT MODAL
  showContentModal: false,
  contentModalId: null,
  contentModalIsDiagnostic: false,
  contentModalDiagnosticChartIsLoading: false,
  contentModalDiagnosticChart: [],
  contentModalLineChart: {},
  contentModalLineChartIsLoading: false,
  contentModalChartType: null,
  contentModalDetails: {},
  contentModalDetailsIsLoading: false,

  contentSearchStatistics: {},
});

export const initUsageState = () => ({
  // USAGE DATA
  rawUsageStatCards: {},
  rawUsageStatCardsLoading: false,
  rawUsageAdoptionRateChartData: [],
  rawUsageAdoptionRateChartDataLoading: false,

  // USAGE TABLE DATA
  usageTableData: [],
  usageTableDataIsLoading: false,
  additionalUsageTableFilters: [],
  usageTableSearchFilterChanged: false,
  usageTableCurrentPageChanged: false,
  usageTableSortOptions: {},

  // USAGE MODAL
  showUsageModal: false,
  usageModalId: null,
  usageModalLineChart: [],
  usageModalLineChartIsLoading: false,
  usageModalDetails: {},
  usageModalDetailsIsLoading: false,

  // USAGE MODAL TABLE DATA
  usageModalTableData: [],
  usageModalTableIsLoading: false,
  additionalUsageModalTableFilters: [],

  usageModalFeedbacks: [],
  usageModalFeedbacksIsLoading: false,
});

export const initAskState = () => ({
  askExportLoading: {
    pending: false,
    notPending: false,
  },
});

export const initWorkflowState = () => ({
  workflowMetricsIsLoading: false
});
