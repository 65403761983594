export const LOGOUT = 'LOGOUT';

/**
 * ARTICLE TO GUIDE
 */
export const UPDATE_ARTICLE_TO_GUIDE_PROCESSES =
  'UPDATE_ARTICLE_TO_GUIDE_PROCESSES';

export const SET_DISPLAY_ASK_IN_SEARCH = 'SET_DISPLAY_ASK_IN_SEARCH';

/**
 * ASK IN SEARCH
 */
export const SET_LAST_ASK_QUERY = 'SET_LAST_ASK_QUERY';
