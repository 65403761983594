export default {
  /**
   * ARTICLE TO GUIDE
   */
  articleToGuideProcesses: (state) => state.articleToGuideProcesses,
  displayAskInSearch: (state) => state.displayAskInSearch,
  lastAskQuery: (state) => state.lastAskQuery,
  hasPreviousAskQuery: (state) => {
    return state.lastAskQuery.answer && state.lastAskQuery.question;
  },
};
