import data from './clone.data.raw.js';

export class CloneServiceData {
  constructor(client) {
    this.client = client;
  }

  async cloneCaseToTarget(
    caseId,
    caseName,
    targetCaseId,
    targetKnowledge,
    targetKnowledgeId,
    languagesMatch,
    skipOrdered,
  ) {
    return this.client
      .mutate({
        mutation: data.cloneCaseToTarget,
        variables: {
          caseId,
          caseName,
          targetCaseId,
          targetKnowledge,
          targetKnowledgeId,
          languagesMatch,
          skipOrdered,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.cloneCaseToTarget)
      .catch((error) => error);
  }

  async cloneContentsToTarget(
    contentIds,
    targetCaseId,
    targetKnowledge,
    targetKnowledgeId,
    languagesMatch,
  ) {
    return this.client
      .mutate({
        mutation: data.cloneContentsToTarget,
        variables: {
          contentIds,
          targetCaseId,
          targetKnowledge,
          targetKnowledgeId,
          languagesMatch,
        },
      })
      .then((response) => response.data.cloneContentsToTarget)
      .catch((error) => error);
  }

  async duplicateContents(contentIds) {
    return this.client
      .mutate({
        mutation: data.duplicateContents,
        variables: {
          contentIds,
        },
      })
      .then((response) => response.data.duplicateContents)
      .catch((error) => error);
  }
}
