export class WorkflowAnalyticService {
  constructor(data) {
    this.data = data;
  }

  static paramsParsing(arr) {
    return arr.reduce((acc, val) => {
      acc[val.key] = val.value;
      return acc;
    }, {});
  }

  getWorkflowMetrics(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getWorkflowMetrics(params);
  }

  getContextSessionLogsExport(sessionId) {
    return this.data.getContextSessionLogsExport(sessionId);
  }
}
