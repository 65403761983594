<template>
  <span class="notification__title-content">
    <img v-if="type === 'Diagnostic'" src="~assets/diag-icon-v2.svg" />
    <img v-else-if="type === 'Article'" src="~assets/article-icon-v2.svg" />
    <img
      v-else-if="type === 'Step' || type === 'keyStep'"
      src="~assets/diag-step-v2.svg"
    />
    <font-awesome-icon
      v-else-if="type === 'Case'"
      :icon="['fa', 'folder']"
      class="case-icon"
    />

    <span v-if="type === 'Error' || !type" class="disabled">{{
      $t('notifications.info.content-error')
    }}</span>

    <span v-else @click="$emit('go-to-content')">{{ label }}</span>
  </span>
</template>

<script>
export default {
  name: 'notification-title-content',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.notification__title-content {
  font-weight: 600;
  padding: 0 4px;
  border-radius: 2px;
  cursor: pointer;

  img {
    margin-bottom: 3px;
    margin-right: 3px;
    height: 16px;
    width: 10px;
  }

  //underline animation
  background-image: linear-gradient($grey-3-mayday, $grey-3-mayday);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position-x: right;

  transition: background-size 300ms;
  &:hover {
    // text-decoration: underline;
    background-size: 100% 100%;
    background-position-x: left;
  }
}
.disabled {
  cursor: not-allowed;
  span {
    pointer-events: none;
  }
}

.case-icon {
  margin-right: 4px;
  color: $grey-5-mayday;
}
</style>
