export class ExportUsers {
  constructor(axiosClient) {
    this.axiosClient = axiosClient;
  }

  exportUsers(isPending) {
    return this.axiosClient
      .get('/api/users/export-users', {
        responseType: 'blob',
        params: { isPending },
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          `${isPending ? 'pendingUsers.csv' : 'users.csv'}`,
        );
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }
}
