import data from './task.data.raw';

export class TaskServiceData {
  constructor(client) {
    this.client = client;
  }

  getCompanyTaskStatus(filters) {
    return this.client
      .query({
        query: data.getCompanyTaskStatus,
        variables: { filters },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.CompanyTaskStatus;
      })
      .catch((error) => error);
  }

  getCompanyTasks({ statusId, filters, page }) {
    return this.client
      .query({
        query: data.getCompanyTasks,
        variables: { statusId, filters, page },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.CompanyTasks;
      })
      .catch((error) => error);
  }

  getCompanyTaskById(id, skipModalFields = true) {
    return this.client
      .query({
        query: data.getCompanyTaskById,
        variables: { id, skipModalFields },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.CompanyTask)
      .catch((error) => error);
  }

  getBacklogTasksCount() {
    return this.client
      .query({ query: data.getBacklogTasksCount, fetchPolicy: 'no-cache' })
      .then((response) => response.data.backlogTasksCount)
      .catch((error) => error);
  }

  setCompanyTaskStatus(taskId, newCompanyTaskStatusId) {
    return this.client
      .mutate({
        mutation: data.setCompanyTaskStatus,
        variables: {
          id: taskId,
          companyTaskStatusId: newCompanyTaskStatusId,
        },
      })
      .then((response) => response.data.changedRows)
      .catch((error) => error);
  }

  setAssignee(taskId, assigneeId) {
    return this.client
      .mutate({
        mutation: data.setAssignee,
        variables: {
          id: taskId,
          assigneeId,
        },
      })
      .then((response) => response.data.changedRows)
      .catch((error) => error);
  }

  bulkSetIsArchive(ids, isArchive) {
    return this.client
      .mutate({
        mutation: data.bulkSetIsArchive,
        variables: {
          ids,
          isArchive,
        },
      })
      .then((response) => response.data.changedRows)
      .catch((error) => error);
  }

  upvoteTask(taskId) {
    return this.client
      .mutate({ mutation: data.upvoteTask, variables: { id: taskId } })
      .then((response) => response.data.upvotedTask)
      .catch((error) => error);
  }

  removeUpvoteTask(taskId) {
    return this.client
      .mutate({ mutation: data.removeUpvoteTask, variables: { id: taskId } })
      .then((response) => response.data.upvotedTask)
      .catch((error) => error);
  }

  newTaskComment(taskId, comment, attachments, mentions, isInternal = false) {
    return this.client
      .mutate({
        mutation: data.newTaskComment,
        variables: { taskId, comment, attachments, isInternal, mentions },
      })
      .then((response) => response.data.newTaskComment)
      .catch((error) => console.error(error));
  }
  deleteTaskComment(id) {
    return this.client
      .mutate({
        mutation: data.deleteTaskComment,
        variables: { id },
      })
      .then((response) => response.data.isDeleted)
      .catch((error) => console.error(error));
  }
  updateTaskComment({ id, comment, attachments, mentions }) {
    return this.client
      .mutate({
        mutation: data.updateTaskComment,
        variables: { id, comment, attachments, mentions },
      })
      .then((response) => response.data.updatedTaskComment)
      .catch((error) => console.error(error));
  }

  setTaskRating(taskId, rating) {
    return this.client
      .mutate({
        mutation: data.setTaskRating,
        variables: { taskId, rating },
      })
      .then((response) => response.data.setTaskRating)
      .catch((error) => console.error(error));
  }

  deleteTaskRating(taskId) {
    return this.client
      .mutate({
        mutation: data.deleteTaskRating,
        variables: { taskId },
      })
      .then((response) => response.data.deleteTaskRating)
      .catch((error) => console.error(error));
  }

  // Task views
  getSettingsTaskViews(page = 1) {
    return this.client
      .query({
        query: data.getSettingsTaskViews,
        variables: { page },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getSettingsTaskViews)
      .catch((error) => console.log(error));
  }

  getTaskView(id) {
    return this.client
      .query({
        query: data.getTaskView,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.taskView)
      .catch((error) => console.log(error));
  }

  getTaskViews() {
    return this.client
      .query({
        query: data.getTaskViews,
        fetchPolicy: 'no-cache',
        variables: {},
      })
      .then((response) => response.data.getTaskViews)
      .catch((error) => console.log(error));
  }

  createTaskView({ name, filters, description }) {
    return this.client
      .mutate({
        mutation: data.createTaskView,
        variables: { input: { name, filters, description } },
      })
      .then((response) => response.data.createTaskView)
      .catch((error) => console.log(error));
  }

  updateTaskView({ name, filters, description, id }) {
    return this.client
      .mutate({
        mutation: data.updateTaskView,
        variables: { input: { name, filters, description, id } },
      })
      .then((response) => response.data.updateTaskView)
      .catch((error) => console.log(error));
  }

  deleteTaskView(id) {
    return this.client
      .mutate({
        mutation: data.deleteTaskView,
        variables: { id },
      })
      .then((response) => response.data.deleteTaskView)
      .catch((error) => console.log(error));
  }
}
