<template>
  <modal class="content-publication-policy-modal" :show.sync="localDisplay">
    <div class="row d-flex mt-5 justify-content-center">
      <div class="col-11 text-center">
        <modal-header
          :style-name="{ backgroundColor: '#0081F91A' }"
          class="mb-3"
        >
          <div slot="icon" class="d-inline-flex">
            <font-awesome-icon
              class="icon blue-mayday"
              :icon="['fas', 'badge-check']"
            />
          </div>
          <div class="mt-3">
            <div>
              <p class="mb-0 font-weight-bold">
                {{ $t('components.modals.publication-policy.title') }}
              </p>
            </div>
            <el-divider class="my-3"></el-divider>
            <small class="text-primary">
              {{ $t('components.modals.publication-policy.description') }}
            </small>
          </div>
        </modal-header>

        <div class="flex-column d-flex justify-content-center subcontent">
          <p class="m-2">
            {{ $t('components.modals.publication-policy.action-title') }}
          </p>
          <div
            class="d-flex flex-row align-items-center justify-content-between m-2 publication-switch px-3 py-3"
          >
            <p class="text-left mb-0">
              {{ $t('components.modals.publication-policy.action') }}
            </p>
            <el-switch
              class="publication-switch-input my-3"
              v-model="switchValue"
            ></el-switch>
          </div>

          <div
            v-if="switchValue"
            class="container flex-column d-flex justify-content-center px-4"
          >
            <label
              class="label row text-left text-bold mb-1 pl-1"
              for="precise-date"
            >
              {{
                $t('components.modals.publication-policy.precise-date.label')
              }}
            </label>
            <el-date-picker
              v-model="localPublicationDueDate"
              type="date"
              :placeholder="
                $t(
                  'components.modals.publication-policy.precise-date.placeholder',
                )
              "
              class="mb-3 row date-picker"
              value-format="timestamp"
              :picker-options="datePickerOptions"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <div class="container text-right">
        <div class="justify-content-end row d-flex flex-end col-12 px-0">
          <base-button
            size="sm"
            :type="buttonProps.type"
            :disabled="disabled"
            @click="buttonProps.action"
          >
            {{ buttonProps.label }}
          </base-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';

export default {
  name: 'content-publication-policy-modal',
  components: {
    Modal,
    ModalHeader,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    publicationDueDate: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.localDisplay = this.display;
    this.localPublicationDueDate = this.publicationDueDate;
    this.switchValue = !!this.publicationDueDate;
  },
  data() {
    return {
      localDisplay: false,
      localPublicationDueDate: '',
      switchValue: false,
      datePickerOptions: {
        disabledDate(time) {
          const enrichedDate = new Date();
          return time.getTime() < enrichedDate;
        },
      },
    };
  },
  computed: {
    buttonProps() {
      if (this.publicationDueDate && !this.switchValue) {
        return {
          type: 'danger',
          label: this.$t('components.modals.publication-policy.submit.delete'),
          action: this.delete,
        };
      }
      return {
        type: 'primary',
        label: this.$t('components.modals.publication-policy.submit.validate'),
        action: this.upsert,
      };
    },
    disabled() {
      return !this.localPublicationDueDate;
    },
  },
  methods: {
    upsert() {
      this.$emit('events', {
        eventName: 'upsert-publication-policy',
        eventData: {
          publicationDueDate: String(this.localPublicationDueDate),
        },
      });
    },
    delete() {
      this.$emit('events', {
        eventName: 'delete-publication-policy',
        eventData: null,
      });
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('events', {
          eventName: 'close',
          eventData: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-publication-policy-modal {
  .modal-title {
    width: 100%;
    border-bottom: 1px solid $grey-4-mayday;
  }

  :deep() .modal-body {
    max-height: 80vh;
    overflow: auto;
    .selector {
      .el-input__prefix {
        display: flex;
        align-items: center;
        margin-left: 6px;
      }
    }
  }

  .modal-footer {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  :deep() .publication-switch-input.is-checked :deep() .el-switch__core {
    background-color: $blue-mayday;
    &:after {
      color: $blue-mayday;
    }
  }

  :deep() .el-switch__core:after {
    // content: '|';
    line-height: 1.2em;
    color: $grey-5-mayday;
    font-weight: bold;
    font-size: 0.8em;
    width: 14px;
    height: 14px;
    top: 2px;
  }
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.footer-row {
  justify-content: flex-end;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}

.publication-switch {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  border: 1px solid $grey-4-mayday;
}

.date-picker {
  &:deep() .el-input__prefix {
    color: rgba($blue-mayday, 0.4);
  }
}
</style>
