export const percentage = (value, decimals) => {
  if (value == null) {
    value = 0;
  }
  if (!decimals) {
    decimals = 0;
  }

  return `${(value * 100).toFixed(decimals)} %`;
};

export const possiblyDeleted = (value, emptyText) => {
  return value != null && value.length ? value : emptyText;
};
