/* eslint-disable max-len */
import * as types from '../mutation-types';

export const loadKnowledge = function (
  { state, dispatch },
  { knowledgeId, routeAction, onCreated },
) {
  return new Promise((resolve) => {
    this.$services.knowledges
      .getOneById(knowledgeId)
      .then(({ root, groups, magicAnswers, allPrimaryDescendents }) => {
        // 1. Groups and magicAnswers update
        dispatch('updateKnowledgeGroups', groups);
        dispatch('updateMagicAnswers', magicAnswers);

        // 2. Business logic wants us to check :
        //   -  is a filter required at initialization - this is due to system
        //  notifications allowing users to check obsolete nodes for instance
        //   - checking if it is due to a knowledge switch or not (checking onCreated)
        if (routeAction !== 'filter' || !onCreated) {
          if (state.focusPath.length === 0) {
            dispatch('updateFocusPath', [String(root.id)]);
          }
          dispatch('updateKnowledgeFilters', []);
        }

        // Updating store with updated nodes and contents
        dispatch('updateTreeNodes', {
          treeNodes: [root]
            .concat(allPrimaryDescendents)
            .map(({ content, ...o }) => ({
              contentId: content.id,
              ...o,
            })),
          focusPath: [root.id],
        });
        dispatch(
          'updateNodesContent',
          [root.content].concat([
            ...new Map(
              allPrimaryDescendents.map(({ content }) => [content.id, content]),
            ).values(),
          ]),
        );

        // Resetting checkedTreeNodes
        dispatch('resetCheckedTreeNodes');

        resolve();
      });
  });
};

export const updateDefaultKnowledge = function ({ commit }, knowledgeId) {
  return new Promise((resolve) => {
    this.$services.knowledges
      .updateDefault(knowledgeId)
      .then((response) => {
        const { knowledges } = response.data.updateDefaultKowledge;
        const { searchAccess, searchIndex, language, root } = knowledges.find(
          (o) => o.isDefault,
        );
        const defaultAttributes = {
          searchAccess,
          searchIndex,
          defaultLanguage: language,
          rootId: root.id,
          roots: root.nodes,
        };
        commit(types.UPDATE_DEFAULT_LANGUGAGE_ATTRIBUTES, defaultAttributes);
        resolve();
      })
      .catch((err) => err);
  });
};

export const updateKnowledgeFocus = ({ commit }, knowledge) =>
  commit(types.UPDATE_FOCUS_KNOWLEDGE, {
    knowledge,
  });
