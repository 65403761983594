<template>
  <div
    :class="['attachment', { 'simple-attachment': !isInput }]"
    :style="dynamicStyle"
    @click="triggerDownload"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="flex align-items-center attachment-title">
      <div class="icon-wrapper mr-1">
        <font-awesome-icon
          v-if="!error"
          :icon="['fal', icon]"
          class="w-100 h-100"
        ></font-awesome-icon>
        <font-awesome-icon
          v-else-if="isInput"
          class="icon-error"
          :icon="['fal', 'exclamation-triangle']"
        ></font-awesome-icon>
      </div>
      <TextEllipsis :text="name" fontSize="11px" class="attachment-name mr-1" />
      <div v-if="size && isInput" class="attachment-size">({{ size }})</div>
    </div>
    <div v-if="!isInput" class="download">
      <font-awesome-icon
        :icon="['fal', 'external-link']"
        class="w-100 h-100"
      ></font-awesome-icon>
    </div>
    <div
      v-else
      class="close"
      @click.stop="deleteAttachment"
      :class="{ 'ml-1': size }"
    >
      <font-awesome-icon
        v-if="!showLoading"
        :icon="['fal', 'times']"
        class="close-icon"
      ></font-awesome-icon>
      <font-awesome-icon
        v-else
        :icon="['fas', 'spinner-third']"
        class="close-icon icon-loading"
        spin
      ></font-awesome-icon>
    </div>
  </div>
</template>

<script>
import TextEllipsis from '@/components/TextEllipsis';

export default {
  name: 'attachment-display',
  components: { TextEllipsis },
  props: {
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    isInput: {
      type: Boolean,
      default: false,
    },
    attachmentWidth: {
      type: String,
      default: '39%',
    },
  },
  data() {
    return { hover: false };
  },

  computed: {
    extension() {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(this.name)[1];
    },
    showLoading() {
      return !this.hover && this.loading;
    },
    dynamicStyle() {
      return {
        '--attachment-width': this.attachmentWidth,
      };
    },
    icon() {
      switch (this.extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
          return 'file-image';
        case 'pdf':
          return 'file-pdf';
        case 'doc':
        case 'docx':
          return 'file-alt';
        default:
          return 'file-alt';
      }
    },
  },
  methods: {
    triggerDownload() {
      if (!this.isInput) window.open(this.url, '_blank');
      if (this.error) this.$emit('disable-click', 'ask.disable-file-upload');
    },
    deleteAttachment() {
      this.$emit('delete-attachment', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  display: none;
  color: $blue-mayday;
  width: 10%;
  height: 12px;
  align-items: center;
  justify-content: center;
}

.attachment:hover .download {
  display: flex;
}

.attachment {
  padding: 4px;
  width: calc(var(--attachment-width) - (8px / 3));
  height: 22px;
  background-color: $grey-2-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.simple-attachment:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.close {
  display: flex;
  width: 10%;
  height: 12px;
  &:hover {
    cursor: pointer;
  }
  .close-icon {
    width: 100%;
    height: 100%;
  }
  .icon-loading {
    color: $blue-mayday;
  }
}

.icon-error {
  color: $red-mayday;
  cursor: pointer;
}

.icon-wrapper {
  width: 10%;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachment-title {
  width: 90%;
  height: 100%;
}

.attachment-name {
  width: 90%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.attachment-size {
  white-space: nowrap;
  font-size: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
