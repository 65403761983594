import store from '@/store';

export class NotificationEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  async read(read, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'NOTIFICATION_READ',
      event: read,
      url,
    });
  }

  async readAll(read, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'NOTIFICATION_READ_ALL',
      event: read,
      url,
    });
  }

  async comment(comment, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'NOTIFICATION_COMMENT',
      event: comment,
      url,
    });
  }
}
