import data from './contentHistory.data.raw';

export class ContentHistoryServiceData {
  constructor(client) {
    this.client = client;
  }
  async getContentHistory(contentId, pageSize, page, lang) {
    return this.client
      .query({
        query: data.getContentHistory,
        variables: { contentId, pageSize, page, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentHistory)
      .catch((error) => error);
  }
}
