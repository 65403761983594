<template>
  <div class="notifications">
    <audio
      id="toast-sound"
      ref="toastSound"
      preload="auto"
      v-if="newNotification"
      autoplay
    >
      <source src="@/assets/sounds/toast.mp3" type="audio/mpeg" />
    </audio>
    <div class="toast__wrapper">
      <NotificationToast
        v-for="notification in displayedNotifications"
        :key="notification.timestamp"
        :timestamp="notification.timestamp"
        :title="notification.title"
        :body="notification.body"
        :content="notification.content"
        :author="notification.author"
        :id="notification.id"
        :urgent="notification.urgent"
        :tags="notification.tags"
        :attachments="notification.attachments"
        :notification="notification"
        @close="handleClose"
        @go-to-content="goToContent(notification)"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import NotificationToast from 'components/Notifications/NotificationToast.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    NotificationToast,
  },
  created() {
    window.addEventListener(
      'md-toastNotificationsUpdate',
      this.handleToastNotificationsUpdate,
    );
  },
  destroyed() {
    window.removeEventListener(
      'md-toastNotificationsUpdate',
      this.handleToastNotificationsUpdate,
    );
  },
  data: () => ({
    newNotification: false,
    toastNotifications: [],
  }),
  computed: {
    displayedNotifications() {
      if (this.$route.path === '/login') return [];
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 2);

      return this.toastNotifications.filter((notification) => {
        const notificationDate = new Date(notification.timestamp).toISOString();
        return (
          notificationDate > startDate.toISOString() &&
          notificationDate > this.userCreatedDate
        );
      });
    },
    ...mapGetters(['companyAllowToastSoundPreference']),
    ...mapState(['userId', 'userCreatedDate']),
  },
  methods: {
    handleToastNotificationsUpdate({ detail }) {
      this.toastNotifications = detail;
    },
    handleClose(id) {
      window.dispatchEvent(
        new CustomEvent('md-readNotifications', { detail: [id] }),
      );
    },
    async goToContent(notification) {
      try {
        const { id, metadata } = notification;
        this.handleClose(id);
        const { entityId, entityType, lang, blockId, threadId, messageId } =
          metadata;

        if (entityType === 'Task') return this.goToTask(entityId);

        this.goToTranslatedEntity({
          entityId,
          entityType,
          lang,
          hash: blockId ? `#${blockId}` : '',
          query: {
            from: 'notification',
            ...(threadId ? { threadId } : {}),
            ...(messageId ? { messageId } : {}),
          },
        });
        this.visible = false;
      } catch (e) {
        this.$message({
          message: this.$t('notification.toasts.content-problem'),
          type: 'error',
        });
      }
    },
    goToTask(taskId) {
      window.location.href = `/hub?taskId=${taskId}`;

      this.visible = false;
    },
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
  },
  watch: {
    toastNotifications(newValue, oldValue) {
      if (!oldValue || !newValue) return;

      if (
        this.companyAllowToastSoundPreference &&
        oldValue.length < newValue.length
      ) {
        this.newNotification = true;
        setTimeout(() => {
          this.newNotification = false;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: absolute;
  top: 0;
  right: 0;
  max-height: 100vh;
  height: fit-content;
  pointer-events: none;
}

.toast__wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: all;
  z-index: 2000;
  width: 330px;
  margin: 0 auto;
  padding: 0 20px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: auto;
}
</style>
