import Vue from 'vue';
import { provider } from '@/services';

export const prepareServices = (
  store,
  gql,
  mongoGql,
  parametricAxios,
  analyticsClient,
  apiAxios,
  logClient,
  authClient,
  brainClient,
  exportClient,
  brainFetchClient,
  academyGql,
) => {
  store.$services = provider(
    gql,
    mongoGql,
    parametricAxios,
    analyticsClient,
    apiAxios,
    logClient,
    authClient,
    brainClient,
    exportClient,
    brainFetchClient,
    academyGql,
  );
  Vue.mixin({
    beforeCreate() {
      this.$services = store.$services;
    },
  });
};
