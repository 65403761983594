import gql from 'graphql-tag';

export default {
  updatePlugins: gql`
    mutation updatePluginsMutation($knowledgeId: ID, $plugins: [PluginInput]) {
      updatePlugins(knowledgeId: $knowledgeId, plugins: $plugins) {
        id
        type
      }
    }
  `,
};
