import gql from 'graphql-tag';

export const currentTreeNode = gql`
  query currentTreeNode($id: ID!) {
    readTreeNode(id: $id) {
      id
      content {
        id
        label
        title
        body
        attachments
        attachedObjects
        tags
        parents {
          id
        }
        icon
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        published
      }
      actions
      type
      nodes {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          tags
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            tags
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const updateNodeContent = gql`
  mutation updateNodeContent(
    $id: ID!
    $label: String
    $title: String
    $body: String
    $attachments: [String]
    $tags: [String]
    $attachedObjects: [ID]
    $icon: String
  ) {
    updateContent(
      id: $id
      label: $label
      title: $title
      body: $body
      attachments: $attachments
      tags: $tags
      attachedObjects: $attachedObjects
      icon: $icon
    ) {
      id
      label
      title
      body
      attachments
      attachedObjects
      tags
      icon
      attachedTreeNodes {
        id
        content {
          id
          label
        }
        type
      }
      parents {
        id
        ancestors {
          id
          hierarchyLevel
        }
      }
      published
    }
  }
`;

export const updateContentPublishStatus = gql`
  mutation updateContentPublishStatus($id: ID!, $published: Boolean) {
    updateContentPublishStatus(id: $id, published: $published) {
      id
      label
      title
      body
      attachments
      attachedObjects
      icon
      attachedTreeNodes {
        id
        content {
          id
          label
        }
        type
      }
      parents {
        id
        ancestors {
          id
        }
      }
      published
    }
  }
`;

export const dragndrop = gql`
  mutation dragndrop(
    $id: ID!
    $contentId: ID!
    $path: [ID]
    $type: String
    $public: Boolean
    $actions: String
  ) {
    dragndrop(
      id: $id
      contentId: $contentId
      path: $path
      type: $type
      public: $public
      actions: $actions
    )
  }
`;

export const updateTreeNode = gql`
  mutation updateTreeNode(
    $id: ID!
    $contentId: ID!
    $path: [ID]
    $type: String
    $public: Boolean
    $actions: String
  ) {
    updateTreeNode(
      id: $id
      contentId: $contentId
      path: $path
      type: $type
      public: $public
      actions: $actions
    ) {
      id
      content {
        id
        label
        title
        body
        attachments
        attachedObjects
        icon
        parents {
          id
        }
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        published
        icon
      }
      actions
      type
      nodes {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const linkTreeNode = gql`
  mutation updateTreeNode(
    $id: ID!
    $contentId: ID!
    $path: [ID]
    $type: String
    $public: Boolean
    $actions: String
  ) {
    updateTreeNode(
      id: $id
      contentId: $contentId
      path: $path
      type: $type
      public: $public
      actions: $actions
    ) {
      id
      content {
        id
        label
        title
        body
        attachments
        attachedObjects
        icon
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        parents {
          id
        }
        published
        icon
      }
      actions
      type
      nodes {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const unlinkTreeNode = gql`
  mutation unlinkTreeNode($treeNodeId: ID!, $newLabel: String!) {
    unlinkTreeNode(treeNodeId: $treeNodeId, newLabel: $newLabel) {
      id
      content {
        id
        label
        title
        body
        attachments
        attachedObjects
        icon
        parents {
          id
        }
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        published
        icon
      }
      actions
      type
    }
  }
`;

export const createTreeNodeChildren = gql`
  mutation createTreeNodeChildren(
    $parentId: ID!
    $type: String!
    $label: String
    $actions: String
  ) {
    createTreeNodeChildren(
      parentId: $parentId
      type: $type
      label: $label
      actions: $actions
    ) {
      id
      path
    }
  }
`;

export const duplicateTreeNodeChildren = gql`
  mutation duplicateTreeNodeChildren($id: ID!, $parentId: ID!, $position: Int) {
    duplicateTreeNodeChildren(
      id: $id
      parentId: $parentId
      position: $position
    ) {
      id
      content {
        id
        label
        title
        body
        attachments
        attachedObjects
        icon
        parents {
          id
        }
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        published
        icon
      }
      actions
      type
      nodes {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const translateTreeNode = gql`
  mutation translateTreeNode($nodeId: ID!, $parentId: ID!, $newLabel: String!) {
    translateTreeNode(nodeId: $nodeId, parentId: $parentId, newLabel: $newLabel)
  }
`;

export const removeTreeNodeChildren = gql`
  mutation removeTreeNodeChildren($parentId: ID!, $childrenId: ID!) {
    removeTreeNodeChildren(parentId: $parentId, childrenId: $childrenId) {
      id
    }
  }
`;

export const readTreeNodes = gql`
  query readTreeNodesQuery($idsList: [ID!]) {
    readTreeNodes: readTreeNodes(idsList: $idsList) {
      id
      type
    }
  }
`;

export const readTreeNodesAncestors = gql`
  query readTreeNodesAncestors($idsList: [ID!]) {
    readTreeNodes: readTreeNodes(idsList: $idsList) {
      id
      content {
        id
        label
      }
      ancestors {
        id
        content {
          id
          label
          published
        }
        hierarchyLevel
      }
    }
  }
`;

export const readDeletedNodes = gql`
  query readDeletedNodes {
    readDeletedTreeNodes {
      id
      content {
        id
        label
      }
      type
      formerParentNode {
        id
        content {
          id
          label
        }
        ancestors {
          id
          content {
            id
            label
          }
          hierarchyLevel
        }
      }
    }
  }
`;

export const restoreDeletedTreeNode = gql`
  mutation restoreDeletedTreeNode($id: ID!) {
    restoreDeletedTreeNode(id: $id) {
      id
      content {
        id
        label
        title
        body
        attachments
        attachedObjects
        icon
        parents {
          id
        }
        attachedTreeNodes {
          id
          content {
            id
            label
          }
          type
        }
        published
        icon
      }
      actions
      type
      nodes {
        id
        content {
          id
          label
          title
          body
          attachments
          attachedObjects
          icon
          parents {
            id
          }
          attachedTreeNodes {
            id
            content {
              id
              label
            }
            type
          }
          published
          icon
        }
        actions
        type
        nodes {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            attachedTreeNodes {
              id
              content {
                id
                label
              }
              type
            }
            published
            icon
          }
          actions
          type
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const deleteTreeNode = gql`
  mutation deleteTreeNode($id: ID!) {
    deleteTreeNode(id: $id) {
      id
      content {
        id
        label
        published
      }
      type
      formerParentNode {
        id
        content {
          id
          label
          published
        }
        ancestors {
          id
          content {
            id
            label
            published
          }
          hierarchyLevel
        }
      }
    }
  }
`;

export const emptyTrash = gql`
  mutation emptyTrash {
    emptyTrash
  }
`;

export const readTreeNodesContent = gql`
  query readTreeNodesQuery($idsList: [ID!]) {
    readTreeNodes(idsList: $idsList) {
      id
      type
      content {
        label
        body
        attachedTreeNodes {
          id
        }
        published
        icon
      }
    }
  }
`;

export const getTreeNodeDescendents = gql`
  query readTreeNode($id: ID!) {
    readTreeNode(id: $id) {
      id
      descendents
    }
  }
`;

export const bulkTranslateTreeNodes = gql`
  mutation bulkTranslateTreeNodesMutation($ids: [ID!], $parentId: ID!) {
    bulkTranslateTreeNodes(ids: $ids, parentId: $parentId)
  }
`;

export const bulkRemoveTreeNodes = gql`
  mutation bulkRemoveTreeNodesMutation($ids: [ID!]) {
    bulkRemoveTreeNodes(ids: $ids)
  }
`;
