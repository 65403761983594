export class CompanyGroupService {
  constructor(data) {
    this.data = data;
  }

  async getByIds(ids, isVerified) {
    return this.data.getByIds(ids, isVerified);
  }

  async getLight() {
    return this.data.getLightGroups();
  }

  async get() {
    return this.data.getGroups();
  }

  async create({ name, knowledgeIds, reachable }) {
    return this.data.createGroup({ name, knowledgeIds, reachable });
  }

  async update({ id, name, knowledgeIds, reachable }) {
    return this.data.updateGroup({
      id,
      name,
      knowledgeIds,
      reachable,
    });
  }

  async delete({ id }) {
    return this.data.deleteGroup({ id });
  }
}
