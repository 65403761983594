<template>
  <div>
    <div
      v-if="attachments.length"
      class="attachments mt-1"
      :class="{ 'custom-border-red mb-1 pb-1': displayDisableFileUpload }"
    >
      <div class="title" v-if="displayHeader">
        {{ $tc('task-view.task-focus-modal.attachment', attachments.length) }}
      </div>
      <Attachment
        v-for="attachment in attachmentsToDisplay"
        :key="attachment.id"
        :name="attachment.name"
        :id="attachment.id"
        :url="attachment.url"
        :size="attachment.size"
        :loading="attachment.loading"
        :error="attachment.error"
        :isInput="isInput"
        :attachment-width="attachmentWidth"
        @disable-click="$emit('disable-click', $event)"
        @delete-attachment="$emit('delete-attachment', $event)"
      >
      </Attachment>
    </div>
    <div v-if="displayDisableFileUpload" class="disable-file-upload-message">
      {{ $t(errorText) }}
    </div>
  </div>
</template>

<script>
import Attachment from './Attachment';
import resolveFilesNames from '../../utils/resolveFilesNames';
import { mapActions } from 'vuex';

export default {
  name: 'attachment-list',
  components: {
    Attachment,
  },

  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    isInput: {
      type: Boolean,
      default: false,
    },
    displayDisableFileUpload: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
    attachmentWidth: {
      type: String,
      default: '37%',
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    enrichedAttachments() {
      return resolveFilesNames(this.attachments);
    },
    attachmentsToDisplay() {
      return this.isInput ? this.attachments : this.enrichedAttachments;
    },
  },
  methods: {
    ...mapActions('attachmentModule', ['fetchFilesByIds']),
  },
};
</script>

<style lang="scss" scoped>
.attachments {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
.title {
  font-size: 12px;
  font-weight: 700;
}
.custom-border-red {
  border-bottom: 2px solid $red-mayday;
}

.disable-file-upload-message {
  font-size: 9px;
  color: $red-mayday;
  text-align: left;
}
</style>
