import gql from 'graphql-tag';

export const CONTENT_FRAGMENT = gql`
  fragment ContentFragment on Content {
    id
    type
    knowledgeId: knowledge
    label
    body
    tags
    attachedObjects
    published
    isOutdated
    icon
    cover
    relatedContents
    path
    parameters
    enrichedParameters
    caseParameters
    author
    accessRestrictions
    isGeneric
    labels {
      id
      labelName
      icon
      color
    }
    satisfactionRates {
      thumbUpPercent
      thumbUpCount
      thumbDownPercent
      thumbDownCount
      totalThumbCount
    }
    parents
    isReadOnly
    verificationPolicy {
      verificationRange
      verificationDueDate
      userId
      customRoleId
    }
    setLanguages
    returnLanguage
  }
`;

export const PUBLIC_DATA_FRAGMENT = gql`
  fragment publicDataFragment on PublicData {
    id
    description
    slug
    isShareable
    metaLabel
    tokens {
      accessToken
      name
      expirationDate
    }
  }
`;

export const WORKFLOW_ACTION_FRAGMENT = gql`
  fragment workflowActionFragment on WorkflowAction {
    _id
    type
    title
    payload
    library
    libraryId
    createdAt
    updatedAt
  }
`;
