import gql from 'graphql-tag';

export const CONTENT_THREAD_FRAGMENT = gql`
  fragment contentThreadFragment on ContentThread {
    id
    status
    entityId
    entityType
    lang
    messages {
      id
      authorId
      body
      votes
      mentions {
        targetId
        targetType
      }
      attachments
      edited
      editedDate
      createdAt
      updatedAt
    }
    contributors {
      id
      username
      isDeleted
    }
  }
`;
