import gql from 'graphql-tag';

export const MODULE_LABEL = gql`
  fragment ModuleLabelFragment on ModuleLabel {
    id
    title
    icon {
      type
      value
      color
    }
    parentId
    childrenIds
  }
`;
