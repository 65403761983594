export class ContentParameterLabelService {
  constructor(data) {
    this.data = data;
  }

  /**
   *
   * CREATE METHODS
   */
  async createContentParameterLabel({ labelName, icon, color, categoryName }) {
    return this.data.createContentParameterLabel({
      labelName,
      icon,
      color,
      categoryName,
    });
  }

  /**
   * READ METHODS
   */
  async getContentParameterLabels() {
    return this.data.getContentParameterLabels();
  }

  /**
   *
   * UPDATE METHODS
   */
  async updateContentParameterLabel({
    id,
    labelName,
    icon,
    color,
    categoryName,
  }) {
    return this.data.updateContentParameterLabel({
      id,
      labelName,
      icon,
      color,
      categoryName,
    });
  }

  /**
   * DELETE METHODS
   */
  async deleteContentParameterLabel(labelId) {
    return this.data.deleteContentParameterLabel(labelId);
  }
}
