export class UserLabelService {
  constructor(data) {
    this.data = data;
  }

  createUserLabelCategory(params) {
    return this.data.createUserLabelCategory(params);
  }

  findAllCategories() {
    return this.data.findAllCategories();
  }

  deleteCategory(id) {
    return this.data.deleteCategory(id);
  }
  updateUserLabelCategory(params) {
    return this.data.updateUserLabelCategory(params);
  }

  createUserLabel(params) {
    return this.data.createUserLabel(params);
  }

  updateUserLabel(params) {
    return this.data.updateUserLabel(params);
  }

  deleteUserLabel(id) {
    return this.data.deleteUserLabel(id);
  }
}
