import data from './resource.data.raw.js';

export class ResourceServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ QUERIES
   */
  async getAll() {
    return this.client
      .query({
        query: data.getAll,
      })
      .then((response) => response.data.resources)
      .catch((error) => error);
  }
}
