import gql from 'graphql-tag';

export default {
  readKnowledge: gql`
    query readKnowledge($id: ID!) {
      readKnowledge(id: $id) {
        id
        searchIndex
        searchAccess
        root {
          id
          content {
            id
            label
            title
            body
            attachments
            tags
            attachedObjects
            published
            isReadOnly
            icon
          }
          path
          type
          public
          actions
        }
        groups {
          id
          alias
          type
          knowledgeId
          contentId
          ruleAttributes {
            id
            operator
            value
            companyAttribute {
              id
              source
              model
              label
              field
              type
              values
              path
            }
            companyAttributeValue {
              id
              value
              valueName
            }
          }
          node {
            id
            type
            content {
              id
              label
            }
          }
          ancestors {
            id
            content {
              id
              label
            }
          }
        }
        magicAnswers {
          id
          treeNodeId
          node {
            id
            content {
              id
              label
            }
          }
        }
        allPrimaryDescendents {
          id
          parentId
          content {
            id
            label
            title
            body
            attachments
            tags
            attachedObjects
            published
            isReadOnly
            isOutdated
            icon
            updatedAt
            author {
              id
              username
            }
            reviewers {
              id
              username
            }
            groupReviewers {
              id
              name
            }
          }
          path
          type
          public
          actions
        }
      }
    }
  `,
  defaultKnowledge: gql`
    query defaultKnowledge {
      defaultKnowledge {
        id
        searchIndex
        searchAccess
        root {
          id
          content {
            id
            label
            title
            body
            attachments
            attachedObjects
            icon
            parents {
              id
            }
            icon
          }
          actions
          type
          nodes {
            id
            content {
              id
              label
              title
              body
              attachments
              attachedObjects
              icon
              parents {
                id
              }
              icon
            }
            actions
            type
            nodes {
              id
              content {
                id
                label
                title
                body
                attachments
                attachedObjects
                icon
                parents {
                  id
                }
                icon
              }
              actions
              type
              nodes {
                id
              }
            }
          }
        }
        groups {
          id
          alias
          type
          knowledgeId
          contentId
          ruleAttributes {
            id
            operator
            value
            companyAttribute {
              id
              source
              model
              label
              field
              type
              values
              path
            }
            companyAttributeValue {
              id
              value
              valueName
            }
          }
          node {
            id
            type
            content {
              id
              label
            }
          }
          ancestors {
            id
            content {
              id
              label
            }
          }
        }
        magicAnswers {
          id
          treeNodeId
          node {
            id
            content {
              id
              label
            }
          }
        }
      }
    }
  `,
  readKnowledgeCollections: gql`
    query readCollections($id: ID!) {
      readCollections(id: $id)
    }
  `,
  updateDefaultKnowledgeMutation: gql`
    mutation updateDefaultKnowledgeMutation($knowledgeId: ID!) {
      updateDefaultKowledge(knowledgeId: $knowledgeId) {
        knowledges {
          id
          root {
            id
            nodes {
              id
              content {
                id
                label
              }
            }
          }
          searchAccess
          searchIndex
          language
          isDefault
        }
      }
    }
  `,
  deleteKnowledgeMutation: gql`
    mutation deleteCompanyKnowledgeMutation($knowledgeId: ID!) {
      deleteCompanyKnowledge(knowledgeId: $knowledgeId) {
        knowledges {
          id
          root {
            id
            nodes {
              id
              content {
                id
                label
              }
            }
          }
          searchAccess
          searchIndex
          language
          isDefault
        }
      }
    }
  `,
};
