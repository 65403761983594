export class ArchiveService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async getArchives(
    knowledge,
    entities = [],
    page = 1,
    count = 10,
    search = '',
    lang,
  ) {
    return this.data.getArchives(
      knowledge,
      entities,
      page,
      count,
      search,
      lang,
    );
  }
}
