<template>
  <router-link :to="{ path: redirectToHome }">
    <img class="mayday-logo" src="/img/brand/mayday-logo-rounded.svg" />
  </router-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { permissionsRedirection } from '@/utils/permissionsRedirection';

export default {
  name: 'home-redirection',
  computed: {
    redirectToHome() {
      const routeName = permissionsRedirection(
        this.userPermissions,
        this.isParametric,
      );

      const routeData = this.$router.resolve(
        {
          name: routeName,
          params: { lang: this.editingLanguage },
        },
        this.$route,
      );
      return routeData.href;
    },
    ...mapState(['userPermissions']),
    ...mapGetters(['isParametric']),
    ...mapGetters('knowledgeModule', ['editingLanguage']),
  },
};
</script>

<style lang="scss" scoped>
.mayday-logo {
  margin-top: 4px !important;
  max-width: 60px;
  margin: auto;
}
</style>
