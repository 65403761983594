import {
  FileImportTask,
  FilesMergeTask,
  GenerateFromPromptTask,
  GenerateImageCoverTask,
  TranslationTask,
} from './brainTasks.tasks';

export const BrainTaskSelector = (taskType, services) => {
  switch (taskType) {
    case 'file-import':
      return new FileImportTask(services);
    case 'files-merge':
      return new FilesMergeTask(services);
    case 'translation':
      return new TranslationTask(services);
    case 'generation':
      return new GenerateFromPromptTask(services);
    case 'banner-image':
      return new GenerateImageCoverTask(services);
    default:
      throw new Error('UNKNOWN_TASK_TYPE');
  }
};
