import {
  createFilterValueAccessor,
  isNotEmptyParam,
  mapTrendToBadgeType,
  rawDataToStackedLineChart,
  rawDataToMiniLineChart,
  rawDataToLineChart,
} from '../utils';
import { EXTERNAL_DOC_ANCHORS } from '../constants';

export const usageStatsLoading = ({ rawUsageStatCardsLoading }) =>
  rawUsageStatCardsLoading;

export const usageStats = (
  { rawUsageStatCards, rawUsageStatCardsLoading },
  { adoptionRateChart, adoptionRateDataIsReady },
  _,
  rootGetters,
) => {
  if (
    rawUsageStatCardsLoading ||
    rawUsageStatCards == null ||
    !rawUsageStatCards.hasOwnProperty('views') ||
    !rawUsageStatCards.hasOwnProperty('userCount') ||
    !rawUsageStatCards.hasOwnProperty('groupCount') ||
    !rawUsageStatCards.hasOwnProperty('notificationCount') ||
    !rawUsageStatCards.hasOwnProperty('feedbacks') ||
    !rawUsageStatCards.hasOwnProperty('adoptionRate') ||
    !rawUsageStatCards.hasOwnProperty('satisfactions') ||
    !rawUsageStatCards.hasOwnProperty('userReturns') ||
    !rawUsageStatCards.hasOwnProperty('contributions') ||
    !adoptionRateDataIsReady
  ) {
    return null;
  }
  return [
    {
      name: 'new-dashboard.stat-card.name.analytics-usage-stats.views',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-usages.overview-stats.views',
      icon: 'eye',
      footerSlot: 'trendText',
      stat: rawUsageStatCards.views.count,
      badgeType: mapTrendToBadgeType[rawUsageStatCards.views.trend],
      badgeSlot: 'trendBadge',
      additionalStat: rawUsageStatCards.views.trend,
      footerStat: rawUsageStatCards.views.trend,
      isEmpty: rawUsageStatCards.views.count === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageViewCount,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-usage-stats.users',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-usages.overview-stats.users',
      icon: 'users',
      footerSlot: 'groupRepartition',
      stat: rawUsageStatCards.userCount,
      footerStat: rawUsageStatCards.groupCount,
      isEmpty: rawUsageStatCards.userCount === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageUserCount,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-usage-stats.created-users',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-usages.overview-stats.created-users',
      icon: 'users',
      footerSlot: 'createdUsers',
      stat: rawUsageStatCards.userCreatedCount,
      footerStat: rawUsageStatCards.userDeletedCount,
      isEmpty: rawUsageStatCards.userCount === 0,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-usage-stats.notifications',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-usages.overview-stats.notifications',
      icon: 'paper-plane',
      stat: rawUsageStatCards.notificationCount,
      isEmpty: rawUsageStatCards.notificationCount === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageNotificationCount,
    },
    ...(rootGetters.hasContributions
      ? [
          {
            name: 'new-dashboard.stat-card.name.analytics-usage-stats.user-returns',
            tooltipContent:
              'new-dashboard.header.tooltip.analytics-usages.overview-stats.user-returns',
            icon: 'megaphone',
            footerSlot: 'userReturnsRepartition',
            footerStat: {
              feedbackCount: rawUsageStatCards.feedbacks,
              satisfactionCount: rawUsageStatCards.satisfactions,
            },
            stat: rawUsageStatCards.userReturns.count,
            badgeType: mapTrendToBadgeType[rawUsageStatCards.userReturns.trend],
            badgeSlot: 'trendBadge',
            additionalStat: rawUsageStatCards.userReturns.trend,
            isEmpty: rawUsageStatCards.userReturns.count === 0,
            externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageFeedbackCount,
          },
          {
            name: 'new-dashboard.stat-card.name.analytics-usage-stats.contributions',
            tooltipContent:
              'new-dashboard.header.tooltip.analytics-usages.overview-stats.contributions',
            icon: 'money-check-edit',
            footerSlot: 'trendText',
            stat: rawUsageStatCards.contributions.count,
            badgeType:
              mapTrendToBadgeType[rawUsageStatCards.contributions.trend],
            badgeSlot: 'trendBadge',
            additionalStat: rawUsageStatCards.contributions.trend,
            footerStat: rawUsageStatCards.contributions.trend,
            isEmpty: rawUsageStatCards.contributions.count === 0,
            externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageFeedbackCount,
          },
        ]
      : [
          {
            name: 'new-dashboard.stat-card.name.analytics-usage-stats.feedbacks',
            tooltipContent:
              'new-dashboard.header.tooltip.analytics-usages.overview-stats.feedbacks',
            icon: 'megaphone',
            stat: rawUsageStatCards.feedbacks,
            isEmpty: rawUsageStatCards.feedbacks === 0,
            externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageFeedbackCount,
          },
        ]),
    {
      name: 'new-dashboard.stat-card.name.analytics-usage-stats.adoption-rate',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-usages.overview-stats.adoption-rate',
      stat: (rawUsageStatCards.adoptionRate * 100).toFixed(2) + '%',
      additionalStat: adoptionRateChart,
      iconSlot: 'adoptionRateChart',
      footerSlot: 'adoptionRateDetail',
      footerStat: {
        userCount: rawUsageStatCards.userCount,
        userCountWithInteractions: rawUsageStatCards.userCountWithInteractions,
      },
      isEmpty: rawUsageStatCards.adoptionRate == null,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageAdoptionRate,
    },
  ];
};

export const rawUsageAdoptionRateChartData = (
  { rawUsageAdoptionRateChartData },
  { adoptionRateDataIsReady },
) => {
  if (!adoptionRateDataIsReady) {
    return [];
  }
  return rawUsageAdoptionRateChartData;
};

export const adoptionRateDataIsReady = ({
  rawUsageAdoptionRateChartData,
  rawUsageAdoptionRateChartDataLoading,
}) =>
  !rawUsageAdoptionRateChartDataLoading &&
  rawUsageAdoptionRateChartData != null;

export const adoptionRateChart = (
  _,
  {
    rawUsageAdoptionRateChartData,
    analyticsDateRange: labels,
    mapSourceToLabel,
  },
) => {
  return rawDataToMiniLineChart(
    labels,
    rawUsageAdoptionRateChartData,
    'label',
    'interactionDate',
    'count',
    mapSourceToLabel,
    true,
  );
};

export const adoptionRateModalChart = (
  _,
  {
    rawUsageAdoptionRateChartData,
    analyticsDateRange: labels,
    mapSourceToLabel,
  },
) => {
  return rawDataToLineChart(
    labels,
    rawUsageAdoptionRateChartData,
    'label',
    'interactionDate',
    'count',
    mapSourceToLabel,
    true,
  );
};

export const usageTableData = ({ usageTableData: { results = [] } = {} }) =>
  results;

export const usageTableDataIsLoading = ({ usageTableDataIsLoading }) =>
  usageTableDataIsLoading;

export const usageTableIsEmpty = (
  _,
  { usageTableDataIsLoading, usageTableData },
) =>
  !usageTableDataIsLoading && usageTableData != null && !usageTableData.length;

export const queryParamsForUsageTable = (state, getters) => {
  return getters.queryParamsFromFilters
    .concat(state.additionalUsageTableFilters)
    .concat(getters.usageTableSortQueryParam)
    .filter(({ value }) => isNotEmptyParam(value));
};

export const usageTableIsWithViews = (_, getters) => {
  return getters.usageTableViewsFilterType;
};

export const usageTableViewsFilterType = ({ additionalUsageTableFilters }) => {
  return createFilterValueAccessor(additionalUsageTableFilters, 'isWithViews');
};

export const additionalUsageTableFiltersArePopulated = ({
  additionalUsageTableFilters,
}) =>
  additionalUsageTableFilters != null &&
  Array.isArray(additionalUsageTableFilters) &&
  !!additionalUsageTableFilters.length;

export const usageTableMetadata = ({ usageTableData = {} }) => {
  // eslint-disable-next-line no-unused-vars
  const { results, ...metadata } = usageTableData;

  return metadata;
};

export const usageTableTotalCount = (
  _,
  { usageTableMetadata, usageTableDataIsReady },
) => {
  if (!usageTableDataIsReady) return 0;
  return usageTableMetadata.total;
};

export const usageTableTotalPages = (
  _,
  { usageTableMetadata, usageTableDataIsReady },
) => {
  if (!usageTableDataIsReady) return 1;
  return usageTableMetadata.pages;
};

export const usageTableCurrentPage = ({
  additionalUsageTableFilters,
  usageTableDataIsReady,
}) => {
  if (!usageTableDataIsReady) return 1;
  return createFilterValueAccessor(additionalUsageTableFilters, 'page') || 1;
};

export const usageTableDataIsReady = (_, { usageTableData }) =>
  usageTableData != null && usageTableData.length;

export const usageTableIsReady = (_, { usageTableDataOptions }) => {
  return (
    usageTableDataOptions.hasOwnProperty('currentPage') &&
    usageTableDataOptions.hasOwnProperty('totalPageCount') &&
    usageTableDataOptions.hasOwnProperty('sort') &&
    usageTableDataOptions.hasOwnProperty('search')
  );
};

export const usageTableDataOptions = (
  _,
  {
    additionalUsageTableFiltersArePopulated,
    usageTableSortOptionsArePopulated,
    usageTableCurrentPage,
    usageTableTotalPages,
    usageTableSearchFilter,
    usageTableSortOptions,
  },
) => {
  if (
    !additionalUsageTableFiltersArePopulated ||
    !usageTableSortOptionsArePopulated
  ) {
    return {};
  }

  return {
    currentPage: usageTableCurrentPage,
    totalPageCount: usageTableTotalPages,
    search: usageTableSearchFilter,
    sort: usageTableSortOptions,
  };
};

export const usageTableSortOptions = (
  { usageTableSortOptions },
  { usageTableSortOptionsArePopulated },
) => {
  return usageTableSortOptionsArePopulated ? usageTableSortOptions : {};
};

export const usageTableSortOptionsArePopulated = ({ usageTableSortOptions }) =>
  usageTableSortOptions != null;

export const usageTableSortQueryParam = (
  _,
  { usageTableSortOptions, usageTableSortOptionsArePopulated },
) => {
  if (!usageTableSortOptionsArePopulated) {
    return [];
  }

  return [
    {
      key: 'sortDirection',
      value: usageTableSortOptions.ascending ? 'ASC' : 'DESC',
    },
    { key: 'sortOption', value: usageTableSortOptions.prop },
  ];
};

export const usageTableSearchFilter = ({ additionalUsageTableFilters }) => {
  return createFilterValueAccessor(additionalUsageTableFilters, 'search');
};

export const usageTableSearchFilterChanged = ({
  usageTableSearchFilterChanged,
}) => usageTableSearchFilterChanged;

// USAGE MODAL
export const showUsageModal = ({ showUsageModal }) => showUsageModal;

export const usageModalDetailsIsLoading = ({ usageModalDetailsIsLoading }) =>
  usageModalDetailsIsLoading;

export const usageModalDetails = (
  { usageModalDetails },
  { usageModalDetailsIsLoading, shouldGetUsageModalDetails },
) => {
  if (
    usageModalDetailsIsLoading ||
    !shouldGetUsageModalDetails ||
    usageModalDetails == null
  ) {
    return {};
  }
  return usageModalDetails;
};

export const usageModalUsername = (
  { usageModalDetails },
  { usageModalDetailsIsLoading, shouldGetUsageModalDetails },
) => {
  if (
    usageModalDetailsIsLoading ||
    !shouldGetUsageModalDetails ||
    usageModalDetails == null ||
    !usageModalDetails.hasOwnProperty('user') ||
    usageModalDetails.user == null
  ) {
    return '';
  }

  const {
    user: { title },
  } = usageModalDetails;

  return title;
};

export const usageModalDeletedAt = (
  { usageModalDetails },
  { usageModalDetailsIsLoading, shouldGetUsageModalDetails },
) => {
  if (
    usageModalDetailsIsLoading ||
    !shouldGetUsageModalDetails ||
    usageModalDetails == null ||
    !usageModalDetails.hasOwnProperty('user') ||
    usageModalDetails.user == null
  ) {
    return '';
  }

  const {
    user: { tagAttributes },
  } = usageModalDetails;

  return tagAttributes && tagAttributes.deletedAt;
};

export const usageModalGroupName = (
  { usageModalDetails },
  { usageModalDetailsIsLoading, shouldGetUsageModalDetails },
) => {
  if (
    usageModalDetailsIsLoading ||
    !shouldGetUsageModalDetails ||
    usageModalDetails == null ||
    !usageModalDetails.hasOwnProperty('group') ||
    usageModalDetails.user == null
  ) {
    return '';
  }
  const {
    group: { title },
  } = usageModalDetails;

  return title;
};

export const usageModalLabels = (
  { usageModalDetails },
  { usageModalDetailsIsLoading, shouldGetUsageModalDetails },
) => {
  if (
    usageModalDetailsIsLoading ||
    !shouldGetUsageModalDetails ||
    usageModalDetails == null ||
    !usageModalDetails.hasOwnProperty('labels') ||
    usageModalDetails.user == null
  ) {
    return [];
  }
  const { labels } = usageModalDetails;

  return labels;
};

export const usageModalStats = (
  { usageModalDetails },
  { usageModalDetailsIsLoading, shouldGetUsageModalDetails },
  _,
  rootGetters,
) => {
  if (
    usageModalDetailsIsLoading ||
    !shouldGetUsageModalDetails ||
    usageModalDetails == null ||
    !usageModalDetails.hasOwnProperty('notifications') ||
    !usageModalDetails.hasOwnProperty('views') ||
    !usageModalDetails.hasOwnProperty('feedbacks') ||
    !usageModalDetails.hasOwnProperty('satisfactions') ||
    !usageModalDetails.hasOwnProperty('userReturns') ||
    !usageModalDetails.hasOwnProperty('contributions')
  ) {
    return [];
  }

  const {
    notifications,
    views,
    languages,
    feedbacks,
    satisfactions,
    userReturns,
    contributions,
  } = usageModalDetails;

  return [
    {
      name: 'new-dashboard.stat-card.name.analytics-usage-stats.views',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-usages.user-stats.views',
      icon: 'eye',
      footerSlot: 'trendText',
      footerStat: views.trend,
      stat: views.count,
      tooltipStat: languages,
      badgeType: mapTrendToBadgeType[views.trend],
      badgeSlot: 'trendBadge',
      additionalStat: views.trend,
      isEmpty: views.count === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageModalViewCount,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-usage-stats.notifications',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-usages.user-stats.notifications',
      icon: 'paper-plane',
      stat: (notifications * 100).toFixed(2) + '%',
      isEmpty: notifications === 0,
      externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageModalNotificationCount,
    },
    ...(rootGetters.hasContributions
      ? [
          {
            name: 'new-dashboard.stat-card.name.analytics-usage-stats.user-returns',
            tooltipContent:
              'new-dashboard.header.tooltip.analytics-usages.user-stats.user-returns',
            icon: 'megaphone',
            footerSlot: 'userReturnsRepartition',
            footerStat: {
              feedbackCount: feedbacks.count,
              satisfactionCount: satisfactions,
            },
            stat: userReturns,
            isEmpty: userReturns === 0,
            externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageModalFeedbackCount,
          },
          {
            name: 'new-dashboard.stat-card.name.analytics-usage-stats.contributions',
            tooltipContent:
              'new-dashboard.header.tooltip.analytics-usages.overview-stats.contributions',
            icon: 'money-check-edit',
            stat: contributions,
            isEmpty: contributions === 0,
            externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageFeedbackCount,
          },
        ]
      : [
          {
            name: 'new-dashboard.stat-card.name.analytics-usage-stats.feedbacks',
            tooltipContent:
              'new-dashboard.header.tooltip.analytics-usages.user-stats.feedbacks',
            icon: 'megaphone',
            stat: feedbacks.count,
            footerSlot: 'averageRating',
            footerStat: {
              averageRating: feedbacks.averageRating,
              ratingCount: feedbacks.ratingCount,
            },
            isEmpty: feedbacks.count === 0,
            externalDocAnchor: EXTERNAL_DOC_ANCHORS.usageFeedbackCount,
          },
        ]),
  ];
};

export const usageModalTableIsLoading = ({ usageModalTableIsLoading }) =>
  usageModalTableIsLoading;

export const usageModalTableData = ({
  usageModalTableData: { results = [] } = {},
}) => results;

export const usageModalTableIsEmpty = (
  _,
  { usageModalTableData, usageModalTableIsLoading },
) =>
  !usageModalTableIsLoading &&
  usageModalTableData != null &&
  !usageModalTableData.length;

export const queryParamsForUsageModalTable = (state, getters) => {
  return getters.queryParamsFromFilters
    .concat(state.additionalUsageModalTableFilters)
    .concat(getters.usageModalTableSortQueryParam)
    .filter(({ value }) => isNotEmptyParam(value));
};

export const additionalUsageTableModalFiltersArePopulated = ({
  additionalUsageModalTableFilters,
}) =>
  additionalUsageModalTableFilters != null &&
  !!additionalUsageModalTableFilters.length;

export const usageModalTableMetadata = ({ usageModalTableData = {} }) => {
  // eslint-disable-next-line no-unused-vars
  const { results, ...metadata } = usageModalTableData;

  return metadata;
};

export const usageModalTableTotalCount = (
  _,
  { usageModalTableMetadata, usageModalTableDataIsReady },
) => {
  if (!usageModalTableDataIsReady) return 0;
  return usageModalTableMetadata.total;
};

export const usageModalTableTotalPages = (
  _,
  { usageModalTableMetadata, usageModalTableDataIsReady },
) => {
  if (!usageModalTableDataIsReady) return 1;
  return usageModalTableMetadata.pages;
};

export const usageModalTableCurrentPage = ({
  additionalModalUsageTableFilters,
  usageModalTableDataIsReady,
}) => {
  if (!usageModalTableDataIsReady) return 1;
  return (
    createFilterValueAccessor(additionalModalUsageTableFilters, 'page') || 1
  );
};

export const usageModalTableIsReady = (_, { usageModalTableDataOptions }) => {
  return (
    !!Object.keys(usageModalTableDataOptions).length &&
    usageModalTableDataOptions.hasOwnProperty('currentPage') &&
    usageModalTableDataOptions.hasOwnProperty('totalPageCount') &&
    usageModalTableDataOptions.hasOwnProperty('sort') &&
    usageModalTableDataOptions.hasOwnProperty('search')
  );
};

export const usageModalTableDataIsReady = (_, { usageModalTableData }) =>
  usageModalTableData != null && !!usageModalTableData.length;

export const usageModalTableDataOptions = (
  _,
  {
    usageModalTableDataIsReady,
    usageModalTableCurrentPage,
    usageModalTableTotalPages,
    usageModalTableSearchFilter,
    usageModalTableSortOptions,
  },
) => {
  if (!usageModalTableDataIsReady) {
    return {};
  }

  return {
    currentPage: usageModalTableCurrentPage,
    totalPageCount: usageModalTableTotalPages,
    search: usageModalTableSearchFilter,
    sort: usageModalTableSortOptions,
  };
};

export const usageModalTableSortOptions = (
  { usageModalTableSortOptions },
  { usageModalTableSortOptionsArePopulated },
) => {
  return usageModalTableSortOptionsArePopulated
    ? usageModalTableSortOptions
    : {};
};

export const usageModalTableSortOptionsArePopulated = ({
  usageModalTableSortOptions,
}) => usageModalTableSortOptions != null;

export const usageModalTableSortQueryParam = (
  _,
  { usageModalTableSortOptions, usageModalTableSortOptionsArePopulated },
) => {
  if (!usageModalTableSortOptionsArePopulated) {
    return [];
  }

  return [
    {
      key: 'sortDirection',
      value: usageModalTableSortOptions.ascending ? 'ASC' : 'DESC',
    },
    { key: 'sortOption', value: usageModalTableSortOptions.prop },
  ];
};

export const usageModalChartData = (
  { usageModalLineChart, usageModalLineChartIsLoading },
  { analyticsDateRange: labels, mapSourceToLabel, shouldGetUsageModalDetails },
) => {
  if (
    !shouldGetUsageModalDetails ||
    usageModalLineChartIsLoading ||
    !labels ||
    !labels.length ||
    usageModalLineChart == null ||
    !usageModalLineChart.length
  ) {
    return {};
  }

  return rawDataToStackedLineChart(
    labels,
    usageModalLineChart,
    'label',
    'interactionDate',
    'count',
    mapSourceToLabel,
  );
};

export const usageModalChartIsLoading = ({ usageModalLineChartIsLoading }) =>
  usageModalLineChartIsLoading;

export const usageModalChartIsEmpty = (
  _,
  { usageModalChartIsLoading, usageModalChartData },
) => !usageModalChartIsLoading && !usageModalChartData.length;

export const shouldGetUsageModalDetails = ({ usageModalId }) =>
  usageModalId != null;

export const usageModalFeedbacks = ({ usageModalFeedbacks }) =>
  usageModalFeedbacks;

export const usageModalFeedbacksIsLoading = ({
  usageModalFeedbacksIsLoading,
}) => usageModalFeedbacksIsLoading;
