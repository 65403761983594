export class ContextVariableServiceData {
  constructor(axiosClient) {
    this.axiosClient = axiosClient;
  }

  async getAll() {
    try {
      const response = await this.axiosClient.get('context/variables');
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async create(contextVariable) {
    try {
      const response = await this.axiosClient.post(
        'context/variables',
        contextVariable,
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async update(variableId, contextVariable) {
    try {
      const response = await this.axiosClient.put(
        `context/variables/${variableId}`,
        contextVariable,
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async delete(variableId) {
    try {
      const response = await this.axiosClient.delete(
        `context/variables/${variableId}`,
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
}
