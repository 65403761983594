import gql from 'graphql-tag';

export const getCompanyNotifications = gql`
  query currentCompany {
    company: currentCompany {
      notifications {
        id
        title
        message
        tags
        urgent
        wasUrgent
        createdAt
        author {
          username
        }
        readersCount
        groups {
          id
        }
      }
      employees {
        total
      }
    }
  }
`;

export const getNotificationReadersDetail = gql`
  query getNotificationReadersDetailQuery($id: ID!, $hasGroups: Boolean) {
    getNotificationReadersDetail(id: $id, hasGroups: $hasGroups)
  }
`;

export const addNotification = gql`
  mutation addNotificationMutation(
    $title: String!
    $message: String!
    $tags: [String]
    $urgent: Int
    $attachedObjectId: ID
    $groupIds: [ID]
  ) {
    addNotification(
      title: $title
      message: $message
      tags: $tags
      urgent: $urgent
      attachedObjectId: $attachedObjectId
      groupIds: $groupIds
    ) {
      id
      title
      message
      tags
      urgent
      wasUrgent
      createdAt
      author {
        username
      }
      readersCount
      groups {
        id
      }
    }
  }
`;

export const updateNotification = gql`
  mutation upadteNotificationMutation(
    $id: ID!
    $new_title: String!
    $new_message: String!
    $new_tags: [String]
    $new_urgent: Int
    $groupIds: [ID]
  ) {
    updateNotification(
      id: $id
      new_title: $new_title
      new_message: $new_message
      new_tags: $new_tags
      new_urgent: $new_urgent
      groupIds: $groupIds
    ) {
      id
      title
      message
      tags
      urgent
      wasUrgent
      createdAt
      author {
        username
      }
      readersCount
      groups {
        id
      }
    }
  }
`;

export const deleteNotification = gql`
  mutation deleteNotificationMutation($id: ID!) {
    deleteNotification(id: $id) {
      id
      title
      message
      tags
      urgent
      wasUrgent
      createdAt
      author {
        username
      }
      readersCount
      groups {
        id
      }
    }
  }
`;

export const deleteNotifications = gql`
  mutation deleteNotificationsMutation($ids: [ID!]) {
    deleteNotifications(ids: $ids) {
      id
      title
      message
      tags
      urgent
      wasUrgent
      createdAt
      author {
        username
      }
      readersCount
      groups {
        id
      }
    }
  }
`;
