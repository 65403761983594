var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropDown),expression:"closeDropDown"}],tag:"component",staticClass:"dropdown",class:[
    { show: _vm.isOpen },
    { rightDirection: _vm.right },
    { dropdown: _vm.direction === 'down' },
    { dropup: _vm.direction === 'up' },
  ],attrs:{"aria-haspopup":"true","aria-expanded":_vm.isOpen},on:{"click":function($event){$event.stopPropagation();return _vm.toggleDropDown.apply(null, arguments)}}},[_vm._t("title",function(){return [_c('a',{staticClass:"dropdown-toggle nav-link",class:{ 'no-caret': _vm.hideArrow },attrs:{"data-toggle":"dropdown"}},[_c('i',{class:_vm.icon}),_c('span',{staticClass:"no-icon"},[_vm._v(_vm._s(_vm.title))])])]}),(_vm.isOpen)?_c('ul',{ref:"menu",staticClass:"dropdown-menu",class:[
      { 'dropdown-menu-right': _vm.position === 'right' },
      { show: _vm.isOpen },
      _vm.menuClasses,
    ],attrs:{"id":"dropdown"}},[_vm._t("default")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }