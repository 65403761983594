import data from './moduleLabel.raw';

export class ModuleLabelServiceData {
  constructor(client) {
    this.client = client;
  }

  async getModuleLabelsCategories() {
    return this.client
      .query({
        query: data.moduleLabelsCategories,
        variables: {},
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.moduleLabelsCategories)
      .catch((error) => error);
  }

  async createModuleLabel({ title, icon, parentId }) {
    return this.client
      .mutate({
        mutation: data.createModuleLabel,
        variables: { input: { title, icon, parentId } },
      })
      .then((response) => response.data.createModuleLabel)
      .catch((error) => error);
  }

  async updateModuleLabelTitle({ id, title }) {
    return this.client
      .mutate({
        mutation: data.updateModuleLabelTitle,
        variables: { input: { id, title } },
      })
      .then((response) => response.data.updateModuleLabelTitle)
      .catch((error) => error);
  }

  async updateModuleLabelIcon({ id, icon }) {
    return this.client
      .mutate({
        mutation: data.updateModuleLabelIcon,
        variables: { input: { id, icon } },
      })
      .then((response) => response.data.updateModuleLabelIcon)
      .catch((error) => error);
  }

  async deleteModuleLabel(id) {
    return this.client
      .mutate({
        mutation: data.deleteModuleLabel,
        variables: {
          id,
        },
      })
      .then((response) => response.data.deleteModuleLabel)
      .catch((error) => error);
  }
}
