/**
 * APP VERSION
 */
export const SET_BLOB_VERSION = 'SET_BLOB_VERSION';
export const SET_VERSION_POLLING_TIMEOUT_ID = 'SET_VERSION_POLLING_TIMEOUT_ID';
/**
 * CUSTOM CONFIG
 */
export const SET_CUSTOM_CONFIG = 'SET_CUSTOM_CONFIG';
/**
 * ADMIN
 */
export const LOGIN_FROM_TOKEN = 'LOGIN_FROM_TOKEN';
export const LOGIN = 'LOGIN';
export const SET_AUTH = 'SET_AUTH';
export const LOGOUT = 'LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const NEW_AUTH_ATTEMPT = 'NEW_AUTH_ATTEMPT';
export const RESET_AUTH_ATTEMPT = 'RESET_AUTH_ATTEMPT';
export const UPDATE_USER_GROUPS = 'UPDATE_USER_GROUPS';
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';
export const UPDATE_COMPANY_PREFERENCES = 'UPDATE_COMPANY_PREFERENCES';
export const UPDATE_APP_LANGUAGE = 'UPDATE_APP_LANGUAGE';
export const SET_ANALYTICS_FILTERS = 'SET_ANALYTICS_FILTERS';
export const SET_BLOCKED_LOGIN_START_TIME = 'SET_BLOCKED_LOGIN_START_TIME';
export const UPDATE_ASK_ACCESS = 'UPDATE_ASK_ACCESS';
export const SET_USER_RECENTLY_CONSULTED_CONTENTS =
  'SET_USER_RECENTLY_CONSULTED_CONTENTS';
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
/**
 * INTEGRATION
 */
export const UPDATE_INTEGRATIONS = 'UPDATE_INTEGRATIONS';
export const UPDATE_COMPANY_ATTRIBUTES = 'UPDATE_COMPANY_ATTRIBUTES';

/**
 * GLOBAL SEARCH
 */
export const HEADER_SEARCH_CHOICE = 'HEADER_SEARCH_CHOICE';
export const RESET_HEADER_SEARCH = 'RESET_HEADER_SEARCH';
export const UPDATE_SEARCH_OUTPUT = 'UPDATE_SEARCH_OUTPUT';

/**
 * KNOWLEDGE
 */
export const UPDATE_LANGUAGES = 'UPDATE_LANGUAGES';
export const UPDATE_DEFAULT_LANGUGAGE_ATTRIBUTES =
  'UPDATE_DEFAULT_LANGUGAGE_ATTRIBUTES';
export const UPDATE_FOCUS_KNOWLEDGE = 'UPDATE_FOCUS_KNOWLEDGE';
export const UPDATE_ROOTS = 'UPDATE_ROOTS';
export const UPDATE_FOCUS_PATH = 'UPDATE_FOCUS_PATH';
export const UPDATE_FOCUS_TREE = 'UPDATE_FOCUS_TREE';
export const UPDATE_KNOWLEDGE_GROUPS = 'UPDATE_KNOWLEDGE_GROUPS';
export const UPDATE_MAGIC_ANSWERS = 'UPDATE_MAGIC_ANSWERS';
export const UPDATE_EMBEDDABLE_EXTENSION_CLICK =
  'UPDATE_EMBEDDABLE_EXTENSION_CLICK';
export const UPDATE_TREE_NODES = 'UPDATE_TREE_NODES';
export const UPDATE_NODES_CONTENT = 'UPDATE_NODES_CONTENT';
export const UPDATE_CHECKED_TREE_NODES = 'UPDATE_CHECKED_TREE_NODES';
export const UPDATE_KNOWLEDGE_FILTERS = 'UPDATE_KNOWLEDGE_FILTERS';
export const UPDATE_SHOW_KNOWLEDGE_FILTERS = 'UPDATE_SHOW_KNOWLEDGE_FILTERS';
export const UPDATE_SESSION = 'UPDATE_SESSION';

/**
 * SOCKETS
 */
export const SOCKET_CONNECT = 'SOCKET_connect';
export const SOCKET_ERROR = 'SOCKET_error';
export const SOCKET_CREATED_TREE_NODE = 'SOCKET_CREATED_TREE_NODE';
export const SOCKET_UPDATED_TREE_NODE = 'SOCKET_UPDATED_TREE_NODE';
export const SOCKET_DUPLICATED_TREE_NODE = 'SOCKET_duplicated-tree-node';
export const SOCKET_UNLINKED_TREE_NODE = 'SOCKET_unliked-tree-node';
export const SOCKET_REMOVED_TREE_NODE = 'SOCKET_removed-tree-node';
export const SOCKET_UPDATED_CONTENT = 'SOCKET_updated-content';
export const SOCKET_UPDATED_CONTENT_PUBLISH_STATUS =
  'SOCKET_updated-content-publish-status';
export const UPDATE_COLLABORATORS_FOCUS = 'UPDATE_COLLABORATORS_FOCUS';
export const SOCKET_UPDATED_VERIFICATION_POLICY =
  'SOCKET_UPDATED_VERIFICATION_POLICY';
export const SOCKET_DELETED_VERIFICATION_POLICY =
  'SOCKET_DELETED_VERIFICATION_POLICY';
