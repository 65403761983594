const countries = () => ({
  fr: 'Français',
  en: 'English',
  es: 'Español',
  it: 'Italian',
  pt: 'Portugese',
  de: 'German',
  ru: 'Russian',
  pl: 'Polsk',
});

export {
  countries,
};