import data from './automation.data.raw';

export class AutomationServiceData {
  constructor(client) {
    this.client = client;
  }

  async getContentAutomations(contentId) {
    return this.client
      .query({
        query: data.getContentGroups,
        variables: { contentId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentGroups)
      .catch((error) => error);
  }

  async createAutomation(automation) {
    return this.client
      .mutate({
        mutation: data.createKnowledgeGroup,
        variables: automation,
      })
      .then((response) => response.data.createKnowledgeGroup)
      .catch((error) => error);
  }

  async updateAutomation(newAutomation) {
    return this.client
      .mutate({
        mutation: data.updateKnowledgeGroup,
        variables: newAutomation,
      })
      .then((response) => response.data.updateKnowledgeGroup)
      .catch((error) => error);
  }

  async deleteAutomation(id) {
    return this.client
      .mutate({
        mutation: data.deleteKnowledgeGroup,
        variables: {
          id,
        },
      })
      .then((response) => response.data.deleteKnowledgeGroup)
      .catch((error) => error);
  }
}
