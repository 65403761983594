import initState from './state';
import * as types from './mutation-types';

export default {
  /**
   * GENERAL
   */

  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },

  /**
   * PRODUCT
   */

  [types.SET_PRODUCT](state, payload) {
    state.product = payload;
    state.lastProductLoadingTimestamp = Date.now();
  },
  [types.SET_PRODUCT_LOADING](state, payload) {
    state.productLoading = payload;
  },
  [types.SET_IS_PARTIAL](state, payload) {
    state.isPartial = payload;
  },
  [types.SET_LAST_PRODUCT_LOADING_TIMESTAMP](state, payload) {
    state.lastProductLoadingTimestamp = payload;
  },
  [types.SET_PRODUCT_ERROR](state, payload) {
    state.productError = payload;
  },

  /**
   * PRODUCT RESOLUTIONS
   */

  [types.SET_RESOLUTIONS](state, payload) {
    state.resolutions = payload.results;
    state.resolutionsCount = payload.total;
  },
  [types.SET_RESOLUTIONS_LOADING](state, payload) {
    state.resolutionsLoading = payload;
  },

  /**
   * PRODUCT ATTACEHD RESOLUTIONS
   */

  [types.SET_ATTACHED_RESOLUTIONS](state, payload) {
    state.attachedResolutions = payload.results;
    state.attachedResolutionsCount = payload.total;
  },
  [types.SET_ATTACHED_RESOLUTIONS_LOADING](state, payload) {
    state.attachedResolutionsLoading = payload;
  },

  /**
   * PRODUCT COLLECTIONS
   */

  [types.SET_COLLECTIONS](state, payload) {
    state.collections = payload;
  },
  [types.SET_COLLECTION_PARENTS](state, payload) {
    state.collectionParents = payload;
  },
  [types.SET_COLLECTIONS_LOADING](state, payload) {
    state.collectionsLoading = payload;
  },
};
