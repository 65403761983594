export class CompanyAttributeService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async get(integration) {
    const attributes = await this.data.getAll(integration);
    if (!integration) return attributes;
    return attributes.attributes.filter((attr) => attr.source === integration);
  }

  /**
   * UPDATE METHODS
   */
  async set(integration, companyAttributes) {
    return this.data.setCompanyAttributes(integration, companyAttributes);
  }
}
