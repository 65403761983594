import {
  GET_FOCUS_PUBLIC_CONFIG,
  SET_DEPLOYMENT_STATUS,
  SET_HTTPS_STATUS,
} from './mutation-types';

import { SET_KNOWLEDGES } from '../knowledgeModule/mutation-types';

export const publicKnowledgePlugin = (store) => {
  const publicKnowledgeModuleName = 'publicKnowledgeModule';
  const knowledgeModuleName = 'knowledgeModule';
  const focusPublicConfigMutations = [
    `${publicKnowledgeModuleName}/${GET_FOCUS_PUBLIC_CONFIG}`,
  ];
  const getDeploymentStatusMutations = [
    `${publicKnowledgeModuleName}/${SET_DEPLOYMENT_STATUS}`,
  ];
  const getHttpsStatusMutations = [
    `${publicKnowledgeModuleName}/${SET_HTTPS_STATUS}`,
  ];
  const getKnowledgesMutations = [`${knowledgeModuleName}/${SET_KNOWLEDGES}`];

  store.subscribe((mutation) => {
    if (focusPublicConfigMutations.includes(mutation.type)) {
      if (mutation.payload.knowledgeId) {
        store.dispatch(
          `${publicKnowledgeModuleName}/setFocusKnowledge`,
          mutation.payload.knowledgeId,
        );
      }
    }
    if (getKnowledgesMutations.includes(mutation.type)) {
      const { focusKnowledge } = store.state.publicKnowledgeModule;
      if (focusKnowledge)
        store.dispatch(
          `${publicKnowledgeModuleName}/setFocusKnowledge`,
          focusKnowledge.id,
        );
    }
    if (getDeploymentStatusMutations.includes(mutation.type)) {
      if (
        mutation.payload.deploymentStatus === 'KNOWLEDGE_INIT' ||
        mutation.payload.deploymentStatus === 'TRIGGERED' ||
        mutation.payload.deploymentStatus === 'STARTED' ||
        mutation.payload.deploymentStatus === 'BUILT' ||
        mutation.payload.deploymentStatus === 'DEPLOYED'
      ) {
        const { deploying } = store.state.publicKnowledgeModule;
        if (!deploying) {
          store.dispatch(`${publicKnowledgeModuleName}/setDeploying`, true);
          store.dispatch(`${publicKnowledgeModuleName}/startStatusPolling`);
        }
      } else {
        const { deploying } = store.state.publicKnowledgeModule;
        if (deploying) {
          store.dispatch(`${publicKnowledgeModuleName}/stopStatusPolling`);
          store.dispatch(`${publicKnowledgeModuleName}/setDeploying`, false);
        }
      }
    }
    if (getHttpsStatusMutations.includes(mutation.type)) {
      if (mutation.payload === 'Enabled' || mutation.payload === 'Failed') {
        const { httpsPooling } = store.state.publicKnowledgeModule;
        if (httpsPooling) {
          store.dispatch(`${publicKnowledgeModuleName}/setHttpsPooling`, false);
          store.dispatch(`${publicKnowledgeModuleName}/stopHttpsStatusPolling`);
        }
      } else {
        const { httpsPooling } = store.state.publicKnowledgeModule;
        if (!httpsPooling) {
          store.dispatch(`${publicKnowledgeModuleName}/setHttpsPooling`, true);
          store.dispatch(
            `${publicKnowledgeModuleName}/startHttpsStatusPolling`,
          );
        }
      }
    }
  });
};
