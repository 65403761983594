import { urlCurator } from './event.utils';
import store from '../../../store';

export class ProductEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  static eventProcessing(product) {
    return {
      product: {
        id: product.id,
        reference: product.reference,
        knowledgeId: product.knowledge,
        externalId: product.external_id,
        facets: product.facets,
        parameters: product.parameters,
        ancestors: product.ancestors,
      },
    };
  }

  async read(product, url, origin = 'WEB_PARAMETRIC') {
    const event = this.constructor.eventProcessing(product);
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'PRODUCT_READ',
      event,
      url: urlCurator(url),
    });
  }
}
