export default {
  async triggerBrainImportFile(
    _,
    { contentId, contentType, lang, importType, rawFiles, settings },
  ) {
    if (!contentId || !contentType || !lang || !importType || !rawFiles) return;

    const data = await this.$services.parametricContents.brainImportFiles({
      contentId,
      contentType,
      lang,
      importType,
      rawFiles,
      settings,
    });

    if (!data) return;

    return data.statusId;
  },
};
