import store from '@/store';

export class VersionEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  async done(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_CREATE_DONE',
      event,
      url,
    });
  }
  async review(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_CREATE_REVIEW',
      event,
      url,
    });
  }
  async trigger(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_CREATE_TRIGGER',
      event,
      url,
    });
  }
  async openModal(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_CREATE_OPEN_MODAL',
      event,
      url,
    });
  }
  async updateAvailable(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_UPDATE_AVAILABLE',
      event,
      url,
    });
  }
  async updateOpenModal(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_UPDATE_OPEN_MODAL',
      event,
      url,
    });
  }
  async updateHideBanner(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_UPDATE_HIDE_BANNER',
      event,
      url,
    });
  }
  async updateTrigger(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'VERSION_UPDATE_TRIGGER',
      event,
      url,
    });
  }
}
