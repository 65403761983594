export class ContentAnalyticService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  static paramsParsing(arr) {
    return arr.reduce((acc, val) => {
      acc[val.key] = val.value;
      return acc;
    }, {});
  }

  getContentsLineChart(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getContentsLineChart(params);
  }

  getStatCards(knowledgeId) {
    return this.data.getKnowledgeById(knowledgeId);
  }

  getPublicStatCards(knowledgeId, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getPublicStatCards(knowledgeId, params);
  }

  getTableData(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getTableData(params);
  }

  getSearchStatistics(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getSearchStatistics(params);
  }

  exportSearchStatistics(rawParams, isMissed) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.exportSearchStatistics(params, isMissed);
  }

  getContent(id, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getContent(id, params);
  }

  getContentLineChart(id, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getContentLineChart(id, params);
  }

  getContentDiagnosticChart(id, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getContentDiagnosticChart(id, params);
  }

  getContentDiagnosticNodes(id, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getContentDiagnosticNodes(id, params);
  }

  getContentTableFile(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getContentTableFile(params);
  }

  // USAGE
  getUsageStatCards(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getUsageGlobalStats(params);
  }

  getAdoptionRateData(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getAdoptionRateData(params);
  }

  getUsageTableData(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getUsageTableData(params);
  }

  getUsageTableFile(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getUsageTableFile(params);
  }

  // USAGE MODAL
  getUsageForUser(id, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getUsageForUser(id, params);
  }

  getUsageModalTableDataForUser(id, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getUsageModalTableDataForUser(id, params);
  }

  getUsageLineChartForUser(id, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getUsageLineChartForUser(id, params);
  }
}
