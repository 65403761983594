import data from './contentParameterLabel.data.raw.js';

export class ContentParameterLabelServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ QUERIES
   */
  async getContentParameterLabels(count = 10) {
    return this.client
      .mutate({
        mutation: data.getContentParameterLabels,
        variables: { count },
      })
      .then((response) => response.data.labels)
      .catch((error) => error);
  }

  /**
   * MUTATION QUERIES
   */
  async createContentParameterLabel({ labelName, icon, color, categoryName }) {
    return this.client
      .mutate({
        mutation: data.createContentParameterLabel,
        variables: { labelName, icon, color, categoryName },
      })
      .then((response) => response.data.createdContentParameterLabel)
      .catch((error) => error);
  }

  async updateContentParameterLabel({ id, labelName, icon, color, categoryName }) {
    return this.client
      .mutate({
        mutation: data.updateContentParameterLabel,
        variables: { id, labelName, icon, color, categoryName },
      })
      .then((response) => response.data.updatedContentParameterLabel)
      .catch((error) => error);
  }

  async deleteContentParameterLabel(labelId) {
    return this.client
      .mutate({
        mutation: data.deleteContentParameterLabel,
        variables: {
          id: labelId,
        },
      })
      .then((response) => {
        return response.data.isContentParameterLabelDeleted;
      })
      .catch((error) => error);
  }
}
