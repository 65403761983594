import data from './companyAttribute.data.raw.js';

export class CompanyAttributeServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ METHODS
   */

  async getAll() {
    return this.client
      .query({
        query: data.getCompanyAttributes,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCompanyAttributes)
      .catch((error) => error);
  }

  /**
   * UPDATE METHODS
   */

  async setCompanyAttributes(integration, attributes) {
    return this.client
      .query({
        query: data.setCompanyAttributes,
        variables: {
          integration,
          attributes,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.setCompanyAttributes)
      .catch((error) => error);
  }
}
