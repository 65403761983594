import data from './customRole.data.raw';

export class CustomRoleServiceData {
  constructor(client) {
    this.client = client;
  }

  readCustomRoles() {
    return this.client
      .query({
        query: data.readCustomRoles,
      })
      .then((response) => {
        return response.data.readCustomRoles;
      })
      .catch((error) => error);
  }

  updateCustomRole(id, customRole) {
    return this.client
      .mutate({
        mutation: data.updateCustomRole,
        variables: { id, customRole },
      })
      .then((response) => {
        return response.data.updateCustomRole;
      })
      .catch((error) => error);
  }

  createCustomRole(customRole) {
    return this.client
      .mutate({
        mutation: data.createCustomRole,
        variables: { customRole },
      })
      .then((response) => {
        return response.data.createCustomRole;
      })
      .catch((error) => error);
  }

  deleteCustomRole(id) {
    return this.client
      .mutate({
        mutation: data.deleteCustomRole,
        variables: { id },
      })
      .then((response) => {
        return response.data.deleteCustomRole;
      })
      .catch((error) => error);
  }
}
