import * as types from './mutation-types';
import initState from './state';

export default {
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  [types.SET_CONTENT_THREADS](state, payload) {
    state.contentThreads = payload;
  },
  [types.SET_DISPLAY_CONTENT_THREADS](state, payload) {
    state.displayContentThreads = payload;
  },
};
