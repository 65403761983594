export const LOGOUT = 'LOGOUT';

/**
 * NOTIFICATION
 */
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export const UPDATE_URGENT_NOTIFICATIONS = 'UPDATE_URGENT_NOTIFICATION';

export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT';

export const IS_LOADING = 'IS_LOADING';

export const TASK_COMMENT_IS_LOADING = 'TASK_COMMENT_IS_LOADING';

export const UPDATE_FOCUS_TAB = 'UPDATE_FOCUS_TAB';

export const UPDATE_CURSOR = 'UPDATE_CURSOR';

export const RESET_STORE = 'RESET_STORE';

// CACHE

export const ADD_USER = 'ADD_USER';

export const ADD_CONTENT = 'ADD_CONTENT';

export const ADD_TASK = 'ADD_TASK';

export const ADD_CASE = 'ADD_CASE';
/**
 * ADMIN PANEL CREATE NOTIFICATIONS
 */

export const UPDATE_CREATED_AGENT_NOTIFICATIONS =
  'UPDATE_CREATED_AGENT_NOTIFICATIONS';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';

export const IS_LOADING_AGENT_NOTIFICATIONS = 'IS_LOADING_AGENT_NOTIFICATIONS';

export const IS_DELETING_AGENT_NOTIFICATION = 'IS_DELETING_AGENT_NOTIFICATION';
