export class ExportService {
  constructor(data) {
    this.data = data;
  }

  async putKBExport({ knowledgeId, type }) {
    if (type === 'overview') {
      const response = await this.data.putKBOverviewExport({ knowledgeId });
      return response.data;
    }
    if (type === 'contents') {
      const response = await this.data.putKBContentsExport({ knowledgeId });
      return response.data;
    }
  }
  async getKBExport({ knowledgeId, type }) {
    if (type === 'overview') {
      const response = await this.data.getKBOverviewExport({ knowledgeId });
      return response.data;
    }
    if (type === 'contents') {
      const response = await this.data.getKBContentsExport({ knowledgeId });
      return response.data;
    }
  }
}
