<template>
  <div :class="['ai-badge', size]">
    <font-awesome-icon
      :icon="['fas', 'sparkles']"
      v-if="icon"
      :class="{ 'mr-1': text === 'Ask' }"
    />
    <div class="h-100 d-flex align-items-center">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'AiBadge',
  props: {
    size: {
      type: String,
      default: 'small',
    },
    icon: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: 'AI',
    },
  },
};
</script>

<style scoped lang="scss">
.ai-badge {
  background-color: $purple-5-mayday;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  font-weight: 500;
  gap: 2px;
  padding: 0 4px;

  &.small {
    height: 14px;
    font-size: 8px;
    border-radius: 4px;
  }

  &.medium {
    height: 18px;
    font-size: 10px;
    border-radius: 4px;
    font-weight: 700;
  }
  &.large {
    height: 24px;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 500;
  }
}
</style>
