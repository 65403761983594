import data from './archive.data.raw.js';

export class ArchiveServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ QUERIES
   */

  async getArchives(
    knowledge,
    entities = [],
    page = 1,
    count = 10,
    search = '',
    lang,
  ) {
    return this.client
      .query({
        query: data.getArchives,
        variables: { knowledge, entities, page, count, search, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getStructuredArchives)
      .catch((error) => error);
  }
}
