export class AuthService {
  constructor(data) {
    this.data = data;
  }

  /**
   * AUTH METHOS
   */

  login(loginType, args) {
    if (!args) throw new Error('INVALID_PARAMS');

    switch (loginType) {
      case 'oboToken':
        return this.loginFromOboToken(args.token);
      case 'credentials':
        return this.loginWithCredentials(args.email, args.password);
      default:
        throw new Error('UNKNOWN_LOGIN_TYPE');
    }
  }

  loginWithCredentials(email, password) {
    if (!email || !password) throw new Error('INVALID_PARAMS');
    return this.data.loginWithCredentials(email, password);
  }

  loginFromOboToken(token) {
    if (!token) throw new Error('INVALID_PARAMS');
    return this.data.loginFromOboToken(token);
  }

  logout() {
    return this.data.logout();
  }

  refreshToken() {
    return this.data.refreshToken();
  }
}
