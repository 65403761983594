import gql from 'graphql-tag';

export const currentAgent = gql`
  query currentUserQuery {
    currentUser {
      company {
        logo
        notifications {
          id
          title
          message
          tags
          urgent
          createdAt
        }
      }
      notificationsRead {
        id
      }
    }
  }
`;

export const userLoginFromToken = gql`
  query getUserInformationsQuery {
    getUserInformations {
      id
      username
      createdAt
      role {
        id
        name
        customRolePermissions {
          rolePermission
        }
      }
      isVerified
      auth {
        websocketData {
          connectionUrl
          connectionGroups
        }
      }
      company {
        id
        integrations {
          integration
        }
        reachableUsers {
          id
          username
        }
        reachableRoles {
          id
          name
        }
        preferences {
          id
          type
          value
        }
        logo
      }
    }
  }
`;

export const userRegistration = gql`
  mutation registerMutation(
    $username: String!
    $email: String!
    $password: String!
    $company: String!
    $onlyNotify: Boolean!
  ) {
    register(
      username: $username
      email: $email
      password: $password
      company: $company
      onlyNotify: $onlyNotify
    )
  }
`;

export const invalidateToken = gql`
  mutation invalidateTokenMutation {
    invalidateTokens
  }
`;

export const addUserToGroupMutation = gql`
  mutation addUserToGroupMutation($groupId: ID!, $userId: ID!) {
    addUserToGroup(groupId: $groupId, userId: $userId) {
      groups {
        id
        name
        reachable
        usersCount
      }
    }
  }
`;

export const setUsersGroup = gql`
  mutation setUsersGroupMutation($groupId: [ID], $userIds: [ID!]) {
    setUsersGroup(groupId: $groupId, userIds: $userIds) {
      employees {
        id
        username
        email
        jobtitle
        helpdeskId
        isVerified
        role {
          id
          name
        }
        groups {
          id
          name
          reachable
        }
      }
      userGroups {
        id
        name
        reachable
        usersCount
      }
    }
  }
`;

export const setPendingUsersGroup = gql`
  mutation setUsersGroupMutation($groupId: [ID], $userIds: [ID!]) {
    setUsersGroup(groupId: $groupId, userIds: $userIds) {
      pendingEmployees {
        id
        groups {
          id
          name
        }
      }
    }
  }
`;

export const removeUserFromGroupMutation = gql`
  mutation removeUserFromGroupMutation($groupId: ID!, $userId: ID!) {
    removeUserFromGroup(groupId: $groupId, userId: $userId) {
      groups {
        id
        name
        reachable
        usersCount
      }
    }
  }
`;

export const retrieveUserHelpdeskId = gql`
  mutation retrieveUserHelpdeskIdMutation($id: ID!, $helpdesk: String!) {
    retrieveUserHelpdeskId(id: $id, helpdesk: $helpdesk) {
      id
      username
      email
      jobtitle
      helpdeskId
      isVerified
      role {
        id
        name
      }
      groups {
        id
        name
      }
    }
  }
`;
export const createUsersInvitation = gql`
  mutation createUsersInvitationMutation($users: [JSON]) {
    createUsersInvitation(users: $users) {
      id
      email
      role {
        id
        name
      }
      groups {
        id
        name
      }
      isVerified
      invitationToken {
        isExpired
        invitationLink
      }
    }
  }
`;

export const renewUserInvitation = gql`
  mutation renewUserInvitationMutation($invitedUser: JSON) {
    renewUserInvitation(invitedUser: $invitedUser)
  }
`;

export const renewUserInvitations = gql`
  mutation renewUserInvitationsMutation($invitedUsers: [JSON]) {
    renewUserInvitations(invitedUsers: $invitedUsers) {
      pendingEmployees {
        id
        email
        isVerified
        role {
          id
          name
        }
        groups {
          id
          name
        }
        invitationToken {
          isExpired
        }
      }
    }
  }
`;

export const updateSettings = gql`
  mutation updateSettingsMutation(
    $username: String
    $email: String
    $jobtitle: String
    $password: String
  ) {
    updateSettings(
      username: $username
      email: $email
      jobtitle: $jobtitle
      password: $password
    ) {
      username
      email
      jobtitle
    }
  }
`;

export const userQuery = gql`
  query GetUser {
    currentUser {
      username
      email
      jobtitle
      isVerified
      company {
        logo
      }
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      employees {
        id
        username
        email
        jobtitle
        helpdeskId
        isVerified
        role {
          id
          name
        }
        groups {
          id
          name
        }
      }
      pendingEmployees {
        id
        email
        role {
          id
          name
        }
        groups {
          id
          name
        }
        isVerified
        invitationToken {
          isExpired
        }
      }
    }
  }
`;

export const deleteUsers = gql`
  mutation deleteUsersMutation($ids: [ID!]) {
    deleteUsers(ids: $ids) {
      employees {
        id
        username
        email
        jobtitle
        helpdeskId
        isVerified
        role {
          id
          name
        }
        groups {
          id
          name
        }
      }
      pendingEmployees {
        id
        email
        isVerified
        role {
          id
          name
        }
        groups {
          id
          name
        }
        invitationToken {
          isExpired
        }
      }
    }
  }
`;

export const readAgentNotification = gql`
  mutation notificationReadStatusMutation($notificationId: ID!) {
    notificationReadStatus(notificationId: $notificationId) {
      notificationsRead {
        id
      }
    }
  }
`;
