import { DIRECTIONS } from '../PublicKnowledgeMenuConfiguration';

function getTranslationValue(translation, operations, lang, type) {
  if (operations && operations.length) {
    const filteredOperations = operations.reduce((acc, op) => {
      const splittedPath = op.path.split('.');
      if (
        op.direction === DIRECTIONS.TRANSLATIONS &&
        splittedPath[0] === lang &&
        splittedPath[splittedPath.length - 1] === type
      ) {
        acc.push(op);
      }
      return acc;
    }, []);
    if (filteredOperations.length) {
      return filteredOperations[0].value;
    }
  }
  return translation.meta[type];
}

export function isMonolingual(supportedLanguages) {
  return supportedLanguages[0] === 'default';
}

export function getCollapsableItems(
  translations,
  languages,
  defaultLanguage,
  operations,
) {
  return languages.reduce((acc, lang) => {
    const trIdx = translations.findIndex((t) => t.key === lang);
    if (trIdx === -1) return;
    const translation = translations[trIdx];
    acc.push({
      info: {
        lang: `${lang}`,
        default: defaultLanguage === lang,
      },
      title: {
        label: `metaTagTranslationTitle`,
        title: {
          text: 'public-knowledge.configuration.items.language.meta-tag-title-title',
          param: lang,
        },
        description:
          'public-knowledge.configuration.items.language.meta-tag-title-description',
        inputType: 'text',
        path: `${lang}.meta.title`,
        emptyPlaceholder:
          'public-knowledge.configuration.items.seo.meta-tag-title-empty-placeholder',
        value: getTranslationValue(translation, operations, lang, 'title'),
        component: 'TextInput',
        width: '30%',
        direction: DIRECTIONS.TRANSLATIONS,
      },
      description: {
        label: `metaTagTranslationDescription`,
        title: {
          text: 'public-knowledge.configuration.items.language.meta-tag-description-title',
          param: lang,
        },
        description:
          'public-knowledge.configuration.items.language.meta-tag-description-description',
        inputType: 'textArea',
        path: `${lang}.meta.description`,
        emptyPlaceholder:
          'public-knowledge.configuration.items.seo.meta-tag-description-empty-placeholder',
        value: getTranslationValue(
          translation,
          operations,
          lang,
          'description',
        ),
        component: 'TextAreaInput',
        width: '30%',
        direction: DIRECTIONS.TRANSLATIONS,
      },
    });

    return acc;
  }, []);
}
