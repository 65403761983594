import { urlCurator } from './event.utils';
import store from '../../../store';

export class CompanyEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }


  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  async addIntegration(integration, url, origin = 'ADMIN') {
    const event = { integration: { name: integration } };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'COMPANY_INTEGRATION_ADD',
      event,
      url: urlCurator(url),
    });
  }

  async removeIntegration(integration, url, origin = 'ADMIN') {
    const event = { integration: { name: integration } };
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'COMPANY_INTEGRATION_REMOVE',
      event,
      url: urlCurator(url),
    });
  }
}
