import gql from 'graphql-tag';

export default {
  getCompanySynonyms: gql`
    query getCompanySynonymsQuery {
      getCompanySynonyms {
        id
        aSynonym
        bSynonym
      }
    }
  `,
  createCompanySynonyms: gql`
    mutation createCompanySynonymsMutation($synonyms: [CompanySynonymInput!]!) {
      createCompanySynonyms(synonyms: $synonyms) {
        id
        aSynonym
        bSynonym
      }
    }
  `,
  updateCompanySynonyms: gql`
    mutation updateCompanySynonymsMutation($ids: [ID!]!, $aSynonym: String!) {
      updateCompanySynonyms(ids: $ids, aSynonym: $aSynonym) {
        id
        aSynonym
        bSynonym
      }
    }
  `,
  deleteCompanySynonyms: gql`
    mutation deleteCompanySynonymsMutation($ids: [ID!]!) {
      deleteCompanySynonyms(ids: $ids) {
        id
        aSynonym
        bSynonym
      }
    }
  `,
};
