import store from '@/store';

export class ThreadEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  async createMessage(message, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'THREAD_MESSAGE_CREATE',
      event: message,
      url,
    });
  }

  async editMessage(message, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'THREAD_MESSAGE_EDIT',
      event: message,
      url,
    });
  }

  async deleteMessage(message, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'THREAD_MESSAGE_DELETE',
      event: message,
      url,
    });
  }

  async voteMessage(message, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'THREAD_MESSAGE_VOTE',
      event: message,
      url,
    });
  }

  async updateStatus(thread, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'THREAD_STATUS',
      event: thread,
      url,
    });
  }
}
