import gql from 'graphql-tag';

export default {
  getProductById: gql`
    query getProductByIdQuery(
      $id: ID!
      $count: Int
      $search: String
      $skipContents: Boolean!
    ) {
      getProductById(id: $id) {
        id
        external_id
        reference
        knowledge
        parameters
        uniqueIds
        enrichedParameters
        facets
        pictures
        strengths
        notifications {
          results {
            ... on ProductNotification {
              id
              title
              description
              urgent
              wasUrgent
              parameters
              tags
              accessRestrictions
              createdAt
            }
          }
          total
        }
        resolutionsFilter(count: $count, search: $search)
          @skip(if: $skipContents) {
          results {
            ... on Content {
              id
              type
              label
              parameters
              caseParameters
              isOutdated
              published
              labels {
                id
                labelName
                color
                icon
                categoryName
              }
            }
          }
          total
        }
        attachedResolutions @skip(if: $skipContents) {
          results {
            ... on Content {
              id
              type
              label
              isOutdated
              published
              labels {
                id
                labelName
                color
                icon
                categoryName
              }
            }
          }
          total
        }
      }
    }
  `,
  getProductByExternalId: gql`
    query getProductByExternalIdQuery(
      $id: String!
      $count: Int
      $search: String
      $skipContents: Boolean!
    ) {
      getProductByExternalId(id: $id) {
        id
        external_id
        reference
        knowledge
        parameters
        uniqueIds
        enrichedParameters
        facets
        pictures
        strengths
        notifications {
          results {
            ... on ProductNotification {
              id
              title
              description
              urgent
              wasUrgent
              parameters
              tags
              accessRestrictions
              createdAt
            }
          }
          total
        }
        resolutionsFilter(count: $count, search: $search)
          @skip(if: $skipContents) {
          results {
            ... on Content {
              id
              type
              label
              parameters
              caseParameters
              isOutdated
              published
              labels {
                id
                labelName
                color
                icon
                categoryName
              }
            }
          }
          total
        }
        attachedResolutions @skip(if: $skipContents) {
          results {
            ... on Content {
              id
              type
              label
              isOutdated
              published
              labels {
                id
                labelName
                color
                icon
                categoryName
              }
            }
          }
          total
        }
      }
    }
  `,
  getPartialProduct: gql`
    query getPartialProductQuery(
      $family: String!
      $brand: String!
      $count: Int
      $search: String
      $skipContents: Boolean!
    ) {
      getPartialProduct(family: $family, brand: $brand) {
        id
        reference
        knowledge
        parameters
        enrichedParameters
        facets
        pictures
        strengths
        notifications {
          results {
            ... on ProductNotification {
              id
              title
              description
              urgent
              wasUrgent
              parameters
              tags
              accessRestrictions
              createdAt
            }
          }
          total
        }
        resolutionsFilter(count: $count, search: $search)
          @skip(if: $skipContents) {
          results {
            ... on Content {
              id
              type
              label
              parameters
              caseParameters
              isOutdated
              published
              labels {
                id
                labelName
                color
                icon
                categoryName
              }
            }
          }
          total
        }
        attachedResolutions @skip(if: $skipContents) {
          results {
            ... on Content {
              id
              type
              label
              isOutdated
              published
              labels {
                id
                labelName
                color
                icon
                categoryName
              }
            }
          }
          total
        }
      }
    }
  `,
  filterProductsByHierarchy: gql`
    query filterProductsByHierarchyQuery(
      $filters: [JSON]
      $pageSize: Int
      $page: Int
      $search: String
    ) {
      products: filterProductsByHierarchy(
        filters: $filters
        pageSize: $pageSize
        page: $page
        search: $search
      ) {
        results {
          ... on Product {
            id
            reference
            parameters
            facets
          }
        }
        total
        pages
      }
    }
  `,
  filterProductsByHierarchyCount: gql`
    query filterProductsByHierarchyCount($filters: [JSON], $search: String) {
      productCount: filterProductsByHierarchyCount(
        filters: $filters
        search: $search
      )
    }
  `,
  getContentsByProductId: gql`
    query getContentsByProductId(
      $productId: ID!
      $count: Int
      $search: String
    ) {
      contents: getContentsByProductId(
        productId: $productId
        count: $count
        search: $search
      ) {
        id
        type
        label
        parameters
        isOutdated
        published
      }
    }
  `,
  getAttachedFilesByProductId: gql`
    query getAttachedFilesByProductId($productId: ID!, $count: Int) {
      product: getProductById(id: $productId) {
        id
        attachedResolutions(count: $count) {
          results {
            ... on Content {
              id
              type
              label
              isOutdated
              published
              labels {
                id
                labelName
                color
                icon
                categoryName
              }
            }
          }
          total
        }
      }
    }
  `,
  regexProductParameters: gql`
    query regexProductParametersQuery(
      $key: String!
      $input: String!
      $parameters: [JSON!]
    ) {
      regexProductParameters(key: $key, input: $input, parameters: $parameters)
    }
  `,
  regexProductFacets: gql`
    query regexProductFacetsQuery($input: String!) {
      regexProductFacets(input: $input)
    }
  `,
};
