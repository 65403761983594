export class AskAnalyticService {
  constructor(data) {
    this.data = data;
  }


  static paramsParsing(arr) {
    return arr.reduce((acc, val) => {
      acc[val.key] = val.value;
      return acc;
    }, {});
  }

  getAskMetrics(rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getAskMetrics(params);
  }

  exportAskAnalytics(isPending, rawParams) {
    const params = this.constructor.paramsParsing(rawParams);
    return this.data.getAskAnalyticsExport(isPending, params)
  }
}
