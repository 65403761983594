import gql from 'graphql-tag';

export default {
  createKnowledgeMagicAnswer: gql`
    mutation createKnowledgeMagicAnswer($knowledgeId: ID!, $treeNodeId: ID!) {
      createKnowledgeMagicAnswer: createMagicAnswer(
        knowledgeId: $knowledgeId
        treeNodeId: $treeNodeId
      )
    }
  `,
  deleteKnowledgeMagicAnswer: gql`
    mutation deleteKnowledgeMagicAnswer($id: ID!) {
      deleteKnowledgeMagicAnswer: deleteMagicAnswer(id: $id)
    }
  `,
};
