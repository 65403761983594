import data from './filter.data.raw';

export class DashboardFilterServiceData {
  constructor(client) {
    this.client = client;
  }

  getDashboardFilters() {
    return this.client
      .query({
        query: data.getDashboardFilters,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getFilters)
      .catch((error) => error);
  }
}
