import apollo from '../adapters/apolloClient';
import {
  updateNodeContent,
  currentTreeNode,
  updateTreeNode,
  dragndrop,
  createTreeNodeChildren,
  removeTreeNodeChildren,
  duplicateTreeNodeChildren,
  readTreeNodesAncestors,
  linkTreeNode,
  unlinkTreeNode,
  updateContentPublishStatus,
  getTreeNodeDescendents,
  bulkTranslateTreeNodes,
  bulkRemoveTreeNodes,
} from './gqlQueries/treeNodeQueries';
import {
  createKnowledgeGroup,
  updateKnowledgeGroup,
  deleteKnowledgeGroup,
  createKnowledgeMagicAnswer,
  deleteKnowledgeMagicAnswer,
} from 'utils/gqlQueries/knowledgeQueries';
import {
  readContentVersions,
  bulkUpdateContentPublishStatus,
  bulkUpdateContentOutdatedStatus,
} from '../utils/gqlQueries/contentQueries';
import { readKnowledge } from '../utils/gqlQueries/knowledgeQueries';
import {
  addCompanyPreference,
  updateCompanyPreference,
  removeCompanyPreference,
  deleteCompanyGroups,
  setCompanyAttributesMutation,
} from '../utils/gqlQueries/companyQueries';
import {
  retrieveUserHelpdeskId,
  createUsersInvitation,
  updateSettings,
  renewUserInvitation,
  deleteUser,
  deleteUsers,
  renewUserInvitations,
  setUsersGroup,
  setPendingUsersGroup,
  readAgentNotification,
  userLoginFromToken,
} from '../utils/gqlQueries/userQueries';
import {
  createRole,
  updateCustomRole,
  deleteCustomRole,
  updateUsersRole,
  updateRole,
  updateRoles,
} from './gqlQueries/roleQueries';
import {
  getCompanyNotifications,
  getNotificationReadersDetail,
  addNotification,
  updateNotification,
  deleteNotifications,
} from './gqlQueries/notificationQueries';

import {
  delinkFromSource,
  getImportedArticle,
  selectedImportedArticle,
  updateImportedArticleContent,
} from './gqlQueries/importedArticleQueries';

export default {
  knowledge: {
    /**
     ************ KNOWLEDGE QUERIES ************
     */
    async readKnowledge(id) {
      return apollo
        .query({
          query: readKnowledge,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.readKnowledge)
        .catch((error) => error);
    },
    /**
     ************ CONTENT QUERIES ************
     */
    async readContentVersions(id) {
      return apollo
        .mutate({
          mutation: readContentVersions,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.readContent.versions)
        .catch((error) => error);
    },
    async updateNodeContent(new_content) {
      return apollo
        .mutate({
          mutation: updateNodeContent,
          variables: new_content,
        })
        .then((response) => response.data.updateContent)
        .catch((error) => error);
    },
    async updateContentPublishStatus(id, published) {
      return apollo
        .mutate({
          mutation: updateContentPublishStatus,
          variables: {
            id,
            published,
          },
        })
        .then((response) => response.data.updateContentPublishStatus)
        .catch((error) => error);
    },
    async bulkUpdateContentPublishStatus(ids, published) {
      return apollo
        .mutate({
          mutation: bulkUpdateContentPublishStatus,
          variables: {
            ids,
            published,
          },
        })
        .then((response) => response.data.bulkUpdateContentPublishStatus)
        .catch((error) => error);
    },
    async bulkUpdateContentOutdatedStatus(ids, isOutdated) {
      return apollo
        .mutate({
          mutation: bulkUpdateContentOutdatedStatus,
          variables: {
            ids,
            isOutdated,
          },
        })
        .then((response) => response.data.bulkUpdateContentOutdatedStatus)
        .catch((error) => error);
    },
    /**
     ************ TREE NODE QUERIES ************
     */
    async readTreeNode(id) {
      return apollo
        .mutate({
          mutation: currentTreeNode,
          variables: {
            id,
          },
        })
        .then((response) => response.data.readTreeNode)
        .catch((error) => error);
    },
    async createTreeNodeChildren(parentId, type, label) {
      return apollo
        .mutate({
          mutation: createTreeNodeChildren,
          variables: {
            parentId,
            type,
            label,
          },
        })
        .then((response) => response.data.createTreeNodeChildren)
        .catch((error) => error);
    },
    async updateTreeNode(new_tree_node) {
      return apollo
        .mutate({
          mutation: updateTreeNode,
          variables: new_tree_node,
        })
        .then((response) => response.data.updateTreeNode)
        .catch((error) => error);
    },
    async dragndrop(new_tree_node) {
      return apollo
        .mutate({
          mutation: dragndrop,
          variables: new_tree_node,
        })
        .then((response) => response.data.dragndrop)
        .catch((error) => error);
    },
    async removeTreeNodeChildren(parentId, childrenId) {
      return apollo
        .mutate({
          mutation: removeTreeNodeChildren,
          variables: {
            parentId,
            childrenId,
          },
        })
        .then((response) => response.data.removeTreeNodeChildren)
        .catch((error) => error);
    },
    async duplicateTreeNodeChildren(id, parentId, position) {
      return apollo
        .mutate({
          mutation: duplicateTreeNodeChildren,
          variables: {
            id,
            parentId,
            position,
          },
        })
        .then((response) => response.data.duplicateTreeNodeChildren)
        .catch((error) => error);
    },
    async treeNodesAncestors(idsList) {
      return apollo
        .mutate({
          mutation: readTreeNodesAncestors,
          variables: {
            idsList,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.readTreeNodes)
        .catch((error) => error);
    },
    async linkTreeNode(new_tree_node) {
      return apollo
        .mutate({
          mutation: linkTreeNode,
          variables: new_tree_node,
        })
        .then((response) => response.data.updateTreeNode)
        .catch((error) => error);
    },
    async unlinkTreeNode(treeNodeId, newLabel) {
      return apollo
        .mutate({
          mutation: unlinkTreeNode,
          variables: {
            treeNodeId,
            newLabel,
          },
        })
        .then((response) => response.data.unlinkTreeNode)
        .catch((error) => error);
    },
    async getTreeNodeDescendents(id) {
      return apollo
        .query({
          query: getTreeNodeDescendents,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.readTreeNode)
        .catch((error) => error);
    },
    async bulkTranslateTreeNodes(ids, parentId) {
      return apollo
        .mutate({
          mutation: bulkTranslateTreeNodes,
          variables: {
            ids,
            parentId,
          },
        })
        .then((response) => response.data.bulkTranslateTreeNodes)
        .catch((error) => error);
    },
    async bulkRemoveTreeNodes(ids) {
      return apollo
        .mutate({
          mutation: bulkRemoveTreeNodes,
          variables: {
            ids,
          },
        })
        .then((response) => response.data.bulkRemoveTreeNodes)
        .catch((error) => error);
    },
    /**
     ************ KNOWLEDGE GROUPS QUERIES ************
     */
    async createKnowledgeGroup(new_group) {
      return apollo
        .mutate({
          mutation: createKnowledgeGroup,
          variables: new_group,
        })
        .then((response) => response.data.createKnowledgeGroup)
        .catch((error) => error);
    },
    async updateKnowledgeGroup(new_group) {
      return apollo
        .mutate({
          mutation: updateKnowledgeGroup,
          variables: new_group,
        })
        .then((response) => response.data.updateKnowledgeGroup)
        .catch((error) => error);
    },
    async deleteKnowledgeGroup(id) {
      return apollo
        .mutate({
          mutation: deleteKnowledgeGroup,
          variables: {
            id,
          },
        })
        .then((response) => response.data.deleteKnowledgeGroup)
        .catch((error) => error);
    },
    /**
     ************ KNOWLEDGE MAGIC ANSWERS QUERIES ************
     */
    async createKnowledgeMagicAnswer(new_magic_anwer) {
      return apollo
        .mutate({
          mutation: createKnowledgeMagicAnswer,
          variables: new_magic_anwer,
        })
        .then(
          (response) => response.data.createKnowledgeMagicAnswer.magicAnswers,
        )
        .catch((error) => error);
    },
    async deleteKnowledgeMagicAnswer(id) {
      return apollo
        .mutate({
          mutation: deleteKnowledgeMagicAnswer,
          variables: {
            id,
          },
        })
        .then(
          (response) => response.data.deleteKnowledgeMagicAnswer.magicAnswers,
        )
        .catch((error) => error);
    },
  },
  company: {
    /**
     ************ COMPANY QUERIES ************
     */
    async addCompanyPreference(preferenceType, preferenceValue) {
      return apollo
        .mutate({
          mutation: addCompanyPreference,
          variables: {
            preferenceType,
            preferenceValue,
          },
        })
        .then((response) => response.data.addCompanyPreference)
        .catch((error) => error);
    },
    async updateCompanyPreference(preferenceId, preferenceValue) {
      return apollo
        .mutate({
          mutation: updateCompanyPreference,
          variables: {
            preferenceId,
            preferenceValue,
          },
        })
        .then((response) => response.data.updateCompanyPreference)
        .catch((error) => error);
    },
    async removeCompanyPreference(preferenceId) {
      return apollo
        .mutate({
          mutation: removeCompanyPreference,
          variables: {
            preferenceId,
          },
        })
        .then((response) => response.data.removeCompanyPreference)
        .catch((error) => error);
    },
    async deleteCompanyGroups(ids) {
      return apollo
        .mutate({
          mutation: deleteCompanyGroups,
          variables: {
            ids,
          },
        })
        .then((response) => response.data.deleteCompanyGroups)
        .catch((error) => error);
    },
    async setCompanyAttributes(attributes) {
      return apollo
        .mutate({
          mutation: setCompanyAttributesMutation,
          variables: {
            attributes,
          },
        })
        .then((response) => response.data.setCompanyAttributes)
        .catch((error) => error);
    },
  },
  user: {
    async userLoginFromToken() {
      return apollo
        .query({
          query: userLoginFromToken,
        })
        .then((response) => response.data.getUserInformations)
        .catch((e) => e);
    },
    async retrieveUserHelpdeskId(id, helpdesk) {
      return apollo
        .mutate({
          mutation: retrieveUserHelpdeskId,
          variables: {
            id,
            helpdesk,
          },
        })
        .then((response) => response.data.retrieveUserHelpdeskId)
        .catch((error) => error);
    },
    async createUsersInvitation(users) {
      return apollo
        .mutate({
          mutation: createUsersInvitation,
          variables: {
            users: users,
          },
        })
        .then((response) => response.data.createUsersInvitation);
    },
    async renewUserInvitation(invitedUser) {
      return apollo
        .mutate({
          mutation: renewUserInvitation,
          variables: {
            invitedUser,
          },
        })
        .then((response) => response.data.renewUserInvitation)
        .catch((error) => error);
    },
    async renewUserInvitations(invitedUsers) {
      return apollo
        .mutate({
          mutation: renewUserInvitations,
          variables: {
            invitedUsers,
          },
        })
        .then((response) => response.data.renewUserInvitations);
    },
    async updateSettings(username, email, jobtitle, password) {
      return apollo
        .mutate({
          mutation: updateSettings,
          variables: {
            username,
            email,
            jobtitle,
            password,
          },
        })
        .then((response) => response.data.updateSettings)
        .catch((error) => error);
    },
    async deleteUser(userId) {
      return apollo
        .mutate({
          mutation: deleteUser,
          variables: {
            id: userId,
          },
        })
        .then((response) => response.data.deleteUser);
    },
    async deleteUsers(userIds) {
      return apollo
        .mutate({
          mutation: deleteUsers,
          variables: {
            ids: userIds,
          },
        })
        .then((response) => response.data.deleteUsers);
    },
    async setUsersGroup(groupId, userIds) {
      return apollo
        .mutate({
          mutation: setUsersGroup,
          variables: {
            groupId,
            userIds,
          },
        })
        .then((response) => response.data.setUsersGroup);
    },
    async setPendingUsersGroup(groupId, userIds) {
      return apollo
        .mutate({
          mutation: setPendingUsersGroup,
          variables: {
            groupId,
            userIds,
          },
        })
        .then((response) => response.data.setUsersGroup);
    },
    async readAgentNotification(notificationId) {
      return apollo
        .mutate({
          mutation: readAgentNotification,
          variables: {
            notificationId,
          },
        })
        .then((response) => response.data.notificationReadStatus);
    },
  },
  role: {
    async createRole(name, customRolePermissionNames) {
      return apollo
        .mutate({
          mutation: createRole,
          variables: {
            name,
            customRolePermissionNames,
          },
        })
        .then((response) => response.data.createRole)
        .catch((error) => error);
    },
    async updateCustomRole(id, name, customRolePermissionNames) {
      return apollo
        .mutate({
          mutation: updateCustomRole,
          variables: {
            id,
            name,
            customRolePermissionNames,
          },
        })
        .then((response) => response.data.updateCustomRole)
        .catch((error) => error);
    },
    async deleteCustomRole(id) {
      return apollo
        .mutate({
          mutation: deleteCustomRole,
          variables: {
            id,
          },
        })
        .then((response) => response.data.deleteCustomRole)
        .catch((error) => error);
    },
    async updateUsersRole(ids, customRoleId) {
      return apollo
        .mutate({
          mutation: updateUsersRole,
          variables: {
            ids,
            customRoleId,
          },
        })
        .then((response) => response.data.updateUsersRole)
        .catch((error) => error);
    },
    async updateRole(userId, newPermission) {
      return apollo
        .mutate({
          mutation: updateRole,
          variables: {
            id: userId,
            newPermission,
          },
        })
        .then((response) => response.data.updateRole)
        .catch((error) => error);
    },
    async updateRoles(ids, newPermission) {
      return apollo
        .mutate({
          mutation: updateRoles,
          variables: {
            ids,
            newPermission,
          },
        })
        .then((response) => response.data.updateRoles)
        .catch((error) => error);
    },
  },
  notification: {
    async getCompanyNotifications() {
      return apollo
        .query({
          query: getCompanyNotifications,
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.company);
    },
    async getNotificationReadersDetail(id, hasGroups) {
      return apollo
        .query({
          query: getNotificationReadersDetail,
          variables: {
            id,
            hasGroups,
          },
        })
        .then((response) => response.data.getNotificationReadersDetail);
    },
    async addNotification(notification) {
      return apollo
        .mutate({
          mutation: addNotification,
          variables: notification,
        })
        .then((response) => response.data.addNotification);
    },
    async updateNotification(
      id,
      new_title,
      new_message,
      new_tags,
      new_urgent,
      attachedObjectId,
      groupIds,
    ) {
      return apollo
        .mutate({
          mutation: updateNotification,
          variables: {
            id,
            new_title,
            new_message,
            new_tags,
            new_urgent,
            attachedObjectId,
            groupIds,
          },
        })
        .then((response) => response.data.updateNotification);
    },
    async deleteNotifications(ids) {
      return apollo
        .mutate({
          mutation: deleteNotifications,
          variables: {
            ids,
          },
        })
        .then((response) => response.data.deleteNotifications);
    },
  },
  importedArticle: {
    async selectedImportedArticle(sourceArticleId, contentId, source) {
      return apollo
        .mutate({
          mutation: selectedImportedArticle,
          variables: {
            sourceArticleId,
            contentId,
            source,
          },
        })
        .then((response) => response.data.selectedImportedArticle);
    },
    async updateImportedArticleContent(contentId) {
      return apollo
        .mutate({
          mutation: updateImportedArticleContent,
          variables: {
            contentId,
          },
        })
        .then((response) => response.data.updateImportedArticleContent);
    },
    async delinkFromSource(contentId) {
      return apollo
        .mutate({
          mutation: delinkFromSource,
          variables: {
            contentId,
          },
        })
        .then((response) => response.data.delinkFromSource);
    },
    async getImportedArticle(sourceArticleId, source) {
      return apollo
        .query({
          query: getImportedArticle,
          variables: {
            sourceArticleId,
            source,
          },
        })
        .then((response) => response.data.getImportedArticle);
    },
  },
};
