export class ModuleLabelService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  async getCategories() {
    return this.data.getModuleLabelsCategories();
  }

  /**
   * CREATE METHODS
   */

  async create({ title, icon, parentId }) {
    return this.data.createModuleLabel({ title, icon, parentId });
  }

  /**
   * UPDATE METHODS
   */

  async updateTitle({ id, title }) {
    return this.data.updateModuleLabelTitle({ id, title });
  }

  async updateIcon({ id, icon }) {
    return this.data.updateModuleLabelIcon({ id, icon });
  }

  /**
   * DELETE METHODS
   */

  async delete(id) {
    return this.data.deleteModuleLabel(id);
  }
}
