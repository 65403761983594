<template>
  <div v-if="!isError">
    <div class="d-flex justify-content-center text-center section">
      <p>{{ $t('concepts.new-concept-modal.summary.congrats') }}</p>
    </div>

    <div class="section-group">
      <div class="section">
        <h4 class="section-title">
          {{ $t('concepts.new-concept-modal.label-title') }}
        </h4>
        <h3>{{ concept.label }}</h3>
      </div>

      <div class="section" v-if="concept.body">
        <h4 class="section-title">
          {{ $t('concepts.new-concept-modal.body-title') }}
        </h4>

        {{ concept.body }}
      </div>
    </div>
  </div>
  <div
    v-else
    class="d-flex justify-content-center text-center section text-red"
  >
    <p>{{ $t('concepts.new-concept-modal.summary.failure') }}</p>
  </div>
</template>
<script>
export default {
  name: 'concept-creation-summary',

  props: {
    concept: {
      type: Object,
      default: () => ({
        label: '',
        body: '',
      }),
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  text-align: left;
  margin-bottom: 1rem;
  .section-title {
    color: $grey-7-mayday;
    text-transform: uppercase;
  }
}
.section-group {
  border: 1px solid $grey-4-mayday;
  border-radius: 5px;
  padding: 0.8rem;
}
.section-message {
  font-size: 12px;
}
.tags-container {
  max-height: 100px;
  overflow: auto;
}

.notification__reviewer {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
  background-color: $grey-1-mayday;

  :deep() p {
    font-size: 0.9rem !important;
    margin: 4px 0 !important;
  }

  :deep() li {
    margin-bottom: 6px !important;
  }
}
</style>
