<template>
  <div class="delete-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-5 justify-content-center">
        <div class="col-11 text-center">
          <div class="content-danger-text-container">
            <img
              class="deletion-warning-icon"
              src="~assets/empty-states/deletion-warning.svg"
            />
            <p class="mb-0 pt-1">
              {{ content.dangerText }}
            </p>
            <p :class="{ 'font-weight-bold': label }">
              {{ labelDisplay }}
            </p>
            <small class="text-danger" v-if="content.linked">
              {{ content.linked }}
            </small>
          </div>
          <div class="content-linked-text-container">
            <p class="">{{ content.instructions }}</p>
            <small v-if="caseCount" class="mb-2 text-muted text-red">{{
              content.count
            }}</small>
            <p>
              <small class="mb-2 text-muted">{{ content.consequences }}</small>
            </p>

            <base-input
              id="validationInput"
              v-model="validationInput"
              :placeholder="`DELETE-${this.toDeleteName.toUpperCase()}`"
              type="text"
              class="col-10 mx-auto mt-4 mb-2"
            >
            </base-input>
          </div>
        </div>
      </div>
      <div slot="footer">
        <base-button
          class="mx-auto"
          type="danger"
          @click="userChoice(true)"
          :disabled="!validate"
          >{{
            $t('components.modals.soft-delete-modal.content.cta')
          }}</base-button
        >
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  name: 'soft-delete-modal',
  props: {
    type: String,
    display: Boolean,
    toDeleteName: String,
    caseCount: {
      type: Number,
      required: false,
    },
    label: String,
    bulkCount: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Modal,
  },
  computed: {
    content() {
      return this.contents[this.type];
    },
    validate() {
      return (
        this.validationInput === `DELETE-${this.toDeleteName.toUpperCase()}`
      );
    },
    labelDisplay() {
      if (this.label) {
        return `${this.label}.`;
      } else if (this.bulkCount === 1) {
        return this.$t(
          'components.modals.soft-delete-modal.content.default-label',
        );
      } else return '';
    },
  },
  data() {
    return {
      contents: {
        content: {
          dangerText: this.$tc(
            'components.modals.soft-delete-modal.content.description',
            this.bulkCount,
            { count: this.bulkCount },
          ),
          count: this.$tc(
            'components.modals.soft-delete-modal.content.count',
            this.caseCount,
            { count: this.caseCount },
          ),
          consequences: this.$t(
            'components.modals.soft-delete-modal.content.consequences',
          ),
          instructions: this.$t(
            'components.modals.soft-delete-modal.content.action',
            {
              toDeleteName: `DELETE-${this.toDeleteName.toUpperCase()}`,
            },
          ),
        },
      },
      localDisplay: this.display,
      validationInput: '',
    };
  },
  methods: {
    userChoice(choice) {
      this.$emit('events', {
        eventName: 'choice',
        eventData: choice,
      });
      this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-modal {
  :deep() .modal-body {
    max-height: 65vh;
    overflow: auto;
  }
  :deep() .modal-footer {
    justify-content: center;
    padding-top: 0;
  }
}
.deletion-warning-icon {
  max-height: 60px;
  margin-top: -30px;
}
.content-danger-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($red-mayday, 0.05);
}
.content-linked-text-container {
  margin-top: 24px;
  padding: 16px;
  border: 1px solid $red-mayday;
  border-radius: 4px;
}
</style>
