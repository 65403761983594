import gql from 'graphql-tag';

export const CONTENT_TEMPLATE_FRAGMENT = gql`
  fragment contentTemplateFragment on ContentTemplate {
    id
    description
    content {
      id
      label
      type
      body
      labels {
        id
        labelName
        categoryName
        icon
        color
      }
      path
      setLanguages
      actions {
        id
        payload
        type
        langs
        mongoId
        title
      }
      ancestors
      defaultLanguage
      returnLanguage
      labelTranslations
      enrichedAuthor {
        id
        username
      }
      createdAt
    }
    descendents {
      id
      label
      type
      body
      path
      redirection {
        entityId
        entityType
      }
      setLanguages
      actions {
        id
        payload
        type
        langs
        mongoId
        title
      }
      ancestors
      defaultLanguage
      returnLanguage
      labelTranslations
    }
  }
`;
