import data from './treeNode.data.raw';

export class TreeNodeServiceData {
  constructor(client) {
    this.client = client;
  }

  async getTreeNodeById(id) {
    return this.client
      .mutate({
        mutation: data.currentTreeNode,
        variables: {
          id,
        },
      })
      .then((response) => response.data.readTreeNode)
      .catch((error) => error);
  }

  async getTreeNodesAncestors(idsList) {
    return this.client
      .mutate({
        mutation: data.readTreeNodesAncestors,
        variables: {
          idsList,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.readTreeNodes)
      .catch((error) => error);
  }

  async getTreeNodeDescendents(id) {
    return this.client
      .query({
        query: data.getTreeNodeDescendents,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.readTreeNode)
      .catch((error) => error);
  }

  async addTreeNodeChildren(parentId, type, label) {
    return this.client
      .mutate({
        mutation: data.createTreeNodeChildren,
        variables: {
          parentId,
          type,
          label,
        },
      })
      .then((response) => response.data.createTreeNodeChildren)
      .catch((error) => error);
  }

  async duplicateTreeNodeChildren(id, parentId, position) {
    return this.client
      .mutate({
        mutation: data.duplicateTreeNodeChildren,
        variables: {
          id,
          parentId,
          position,
        },
      })
      .then((response) => response.data.duplicateTreeNodeChildren)
      .catch((error) => error);
  }

  async bulkTranslateTreeNodes(ids, parentId) {
    return this.client
      .mutate({
        mutation: data.bulkTranslateTreeNodes,
        variables: {
          ids,
          parentId,
        },
      })
      .then((response) => response.data.bulkTranslateTreeNodes)
      .catch((error) => error);
  }

  async updateTreeNode(new_tree_node) {
    return this.client
      .mutate({
        mutation: data.updateTreeNode,
        variables: new_tree_node,
      })
      .then((response) => response.data.updateTreeNode)
      .catch((error) => error);
  }

  async dragndrop(new_tree_node) {
    return this.client
      .mutate({
        mutation: data.dragndrop,
        variables: new_tree_node,
      })
      .then((response) => response.data.dragndrop)
      .catch((error) => error);
  }

  async removeTreeNodeChildren(parentId, childrenId) {
    return this.client
      .mutate({
        mutation: data.removeTreeNodeChildren,
        variables: {
          parentId,
          childrenId,
        },
      })
      .then((response) => response.data.removeTreeNodeChildren)
      .catch((error) => error);
  }

  async linkTreeNode(new_tree_node) {
    return this.client
      .mutate({
        mutation: data.linkTreeNode,
        variables: new_tree_node,
      })
      .then((response) => response.data.updateTreeNode)
      .catch((error) => error);
  }

  async unlinkTreeNode(treeNodeId, newLabel) {
    return this.client
      .mutate({
        mutation: data.unlinkTreeNode,
        variables: {
          treeNodeId,
          newLabel,
        },
      })
      .then((response) => response.data.unlinkTreeNode)
      .catch((error) => error);
  }

  async bulkRemoveTreeNodes(ids) {
    return this.client
      .mutate({
        mutation: data.bulkRemoveTreeNodes,
        variables: {
          ids,
        },
      })
      .then((response) => response.data.bulkRemoveTreeNodes)
      .catch((error) => error);
  }
}
