import gql from 'graphql-tag';

import { CONTENT_THREAD_FRAGMENT } from './contentThread.fragments';

export default {
  getAllContentThread: gql`
    ${CONTENT_THREAD_FRAGMENT}
    query getAllContentThread(
      $entityId: ID!
      $entityType: String!
      $lang: String!
    ) {
      getAllContentThread(
        entityId: $entityId
        entityType: $entityType
        lang: $lang
      ) {
        ...contentThreadFragment
      }
    }
  `,
  getContentThread: gql`
    ${CONTENT_THREAD_FRAGMENT}
    query getContentThread(
      $threadId: ID!
      $entityId: ID!
      $entityType: String!
      $lang: String!
    ) {
      getContentThread(
        threadId: $threadId
        entityId: $entityId
        entityType: $entityType
        lang: $lang
      ) {
        ...contentThreadFragment
      }
    }
  `,
  createContentThread: gql`
    ${CONTENT_THREAD_FRAGMENT}
    mutation createContentThread(
      $entityId: ID!
      $entityType: String!
      $lang: String!
      $body: String
      $mentions: [JSON!]
      $attachments: [String!]
      $contributors: [String!]
    ) {
      createContentThread(
        entityId: $entityId
        entityType: $entityType
        lang: $lang
        body: $body
        mentions: $mentions
        attachments: $attachments
        contributors: $contributors
      ) {
        ...contentThreadFragment
      }
    }
  `,
  createContentThreadMessage: gql`
    ${CONTENT_THREAD_FRAGMENT}
    mutation createContentThreadMessage(
      $threadId: ID!
      $body: String
      $mentions: [JSON!]
      $attachments: [String!]
      $contributors: [String!]
    ) {
      createContentThreadMessage(
        threadId: $threadId
        body: $body
        mentions: $mentions
        attachments: $attachments
        contributors: $contributors
      ) {
        ...contentThreadFragment
      }
    }
  `,
  editContentThreadMessage: gql`
    ${CONTENT_THREAD_FRAGMENT}
    mutation editContentThreadMessage(
      $threadId: ID!
      $messageId: ID!
      $body: String
      $mentions: [JSON!]
      $attachments: [String!]
      $contributors: [String!]
    ) {
      editContentThreadMessage(
        threadId: $threadId
        messageId: $messageId
        body: $body
        mentions: $mentions
        attachments: $attachments
        contributors: $contributors
      ) {
        ...contentThreadFragment
      }
    }
  `,
  voteContentThreadMessage: gql`
    ${CONTENT_THREAD_FRAGMENT}
    mutation voteContentThreadMessage($threadId: ID!, $messageId: ID!) {
      voteContentThreadMessage(threadId: $threadId, messageId: $messageId) {
        ...contentThreadFragment
      }
    }
  `,
  deleteContentThreadMessage: gql`
    ${CONTENT_THREAD_FRAGMENT}
    mutation deleteContentThreadMessage(
      $threadId: ID!
      $messageId: ID!
      $contributors: [String!]
    ) {
      deleteContentThreadMessage(
        threadId: $threadId
        messageId: $messageId
        contributors: $contributors
      ) {
        ...contentThreadFragment
      }
    }
  `,
  updateContentThreadStatus: gql`
    ${CONTENT_THREAD_FRAGMENT}
    mutation updateContentThreadStatus(
      $threadId: ID!
      $status: String!
      $contributors: [String!]
    ) {
      updateContentThreadStatus(
        threadId: $threadId
        status: $status
        contributors: $contributors
      ) {
        ...contentThreadFragment
      }
    }
  `,
};
