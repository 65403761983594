export default {
  async getExportStatus(_, { knowledgeId, type }) {
    if (type) {
      const result = await this.$services.exportClient.getKBExport({
        knowledgeId,
        type,
      });
      return result;
    }
  },

  async requestExport(_, { knowledgeId, type }) {
    if (type) {
      const result = await this.$services.exportClient.putKBExport({
        knowledgeId,
        type,
      });
      return result;
    }
  },
};
