export class ContextSessionService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  async filter(searchQuery, pageSize, page) {
    return this.data.filter(searchQuery, pageSize, page);
  }
}
