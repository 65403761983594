<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': error },
      { focused: focused },
      { 'has-label': label || $slots.label },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
    ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
        <el-tooltip v-if="tooltip" placement="top" effect="light">
          <template #content>
            <slot name="tooltip-content"></slot>
          </template>
          <i class="fa fa-info-circle info"></i>
        </el-tooltip>
      </label>
    </slot>

    <div
      class="main-input input-group"
      :class="[
        { 'is-valid': valid === true },
        { 'is-invalid': valid === false },
      ]"
    >
      <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i class="plop" :class="addonLeftIcon"></i>
          </slot>
        </span>
      </div>
      <slot v-bind="slotData">
        <input
          :value="value"
          v-on="listeners"
          v-bind="$attrs"
          class="form-control"
          :ref="name"
          :placeholder="placeholder"
          :class="[
            { 'is-valid': valid === true },
            { 'is-invalid': valid === false },
            inputClasses,
          ]"
          aria-describedby="addon-right addon-left"
        />
      </slot>
      <div v-if="showOrHide || $slots.showOrHide" class="input-group-append">
        <span class="input-group-text">
          <button @click.prevent="changeShowOrHide" class="show-or-hide-button">
            <i :class="[isHidden ? showIcon : hideIcon]" class="text-muted"></i>
          </button>
        </span>
      </div>
      <div
        v-if="addonRightIcon || $slots.addonRight"
        class="input-group-append"
      >
        <span class="input-group-text">
          <slot name="addonRight">
            <i :class="addonRightIcon"></i>
          </slot>
        </span>
      </div>
    </div>

    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-if="error"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'base-input',
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)',
    },
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: undefined,
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
    },
    name: {
      type: String,
      description: 'Input name',
    },
    tooltip: {
      type: Boolean,
      description: 'Input tooltip',
    },
    error: {
      type: String,
      description: 'Input error (below input)',
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
    },
    value: {
      type: [String, Number],
      description: 'Input value',
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon',
    },
    showOrHide: {
      type: Boolean,
      description: 'Show/Hide button for password field',
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon',
    },
    placeholder: {
      type: String,
      description: 'Input placeholder',
    },
  },
  data() {
    return {
      focused: false,
      isHidden: !!this.showOrHide,
      showIcon: 'fa fa-eye',
      hideIcon: 'fa fa-eye-slash',
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit('input', value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit('blur', value);
    },
    changeShowOrHide() {
      const type = this.$refs[this.name].attributes.type.value;
      this.$refs[this.name].attributes.type.value =
        type === 'password' ? 'text' : 'password';
      this.isHidden = !this.isHidden;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.form-control-label {
  margin-bottom: 0;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.form-control {
  color: $grey-8-mayday;
}
.form-control.is-valid,
.form-control.is-invalid {
  border: none;
}

.main-input {
  display: flex;
  border: none;
}

.show-or-hide-button,
.show-or-hide-button:focus {
  background-color: transparent;
  border: none;
  outline: none;
}

.form-control,
.input-group-text {
  border-radius: 2px;
}

.has-success:after,
.has-danger:after {
  display: none;
}

.has-success .input-group-prepend .input-group-text .fa {
  color: rgba($blue-mayday, 0.4);
}

.has-danger .input-group-prepend .input-group-text .fa {
  color: rgba($red-mayday, 0.4);
}

.info {
  display: none;
  font-size: 0.7em;
  margin-left: 0.5em;
  cursor: pointer;
}

.has-danger .info {
  display: block;
  color: $red-mayday;
}
</style>
<style>
.popper__arrow {
  display: none !important;
}
.info-content-icon {
  margin-right: 6px;
}
</style>
