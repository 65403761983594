/**
 * CREATE METHODS
 */

export const createMagicAnswer = function(_context, magicAnswer) {
  return new Promise((resolve, reject) => {
    const { knowledgeId, treeNodeId } = magicAnswer;
    if (!knowledgeId || !treeNodeId) return reject();
    return this.$services.magicAnswers
      .create(magicAnswer)
      .then(() => {
        return resolve();
      })
      .catch(() => reject());
  });
};

/**
 * DELETE METHODS
 */

export const deleteMagicAnswer = function(_context, id) {
  return new Promise((resolve, reject) => {
    if (!id) return reject();
    return this.$services.magicAnswers
      .delete(id)
      .then(() => {
        return resolve();
      })
      .catch(() => reject());
  });
};
