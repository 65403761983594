export class ResourceService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async getAll() {
    return this.data.getAll();
  }
}
