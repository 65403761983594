import store from '@/store';

export class AskEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  async question(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'ASK_QUESTION',
      event,
      url,
    });
  }

  async answer(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'ASK_ANSWER',
      event,
      url,
    });
  }

  async error(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'ASK_ERROR',
      event,
      url,
    });
  }

  async useSource(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'ASK_USE_SOURCE',
      event,
      url,
    });
  }
  async copyAnswer(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'ASK_COPY_ANSWER',
      event,
      url,
    });
  }
  async feedback(event, url, origin = 'ADMIN') {
    this.updateLoggerAttributes(origin);
    return this.logClient.send({
      topic: 'ASK_FEEDBACK',
      event,
      url,
    });
  }
}
