import gql from 'graphql-tag';

export default {
  addZendeskAuth: gql`
    mutation zendeskAuthMutation(
      $subdomain: String!
      $clientId: String!
      $clientSecret: String!
    ) {
      initializeZendeskAuth(
        subdomain: $subdomain
        clientId: $clientId
        clientSecret: $clientSecret
      )
    }
  `,
  addSalesforceAuth: gql`
    mutation salesforceAuthMutation(
      $instanceUrl: String!
      $clientId: String!
      $clientSecret: String!
    ) {
      initializeSalesforceAuth(
        instanceUrl: $instanceUrl
        clientId: $clientId
        clientSecret: $clientSecret
      )
    }
  `,
  getSalesforceAuth: gql`
    query salesforceAuthQuery($companyId: ID!) {
      getSalesforceAuth(companyId: $companyId) {
        id
        accessToken
      }
    }
  `,
  getNotionAuth: gql`
    query notionAuthQuery($companyId: ID!) {
      getNotionAuth(companyId: $companyId) {
        id
        token
      }
    }
  `,
  addNotionAuth: gql`
    mutation notionAuthMutation($token: String!) {
      initializeNotionAuth(token: $token)
    }
  `,
  addIadvizeAuth: gql`
    mutation iadvizeAuthMutation($token: String!, $projectId: String!) {
      initializeIadvizeAuth(token: $token, projectId: $projectId)
    }
  `,

  getImportedArticles: gql`
    query getImportedArticlesQuery(
      $page: Int
      $query: String
      $source: String
    ) {
      getImportedArticles(page: $page, query: $query, source: $source)
    }
  `,
};
