<template>
  <div class="new-concept-modal">
    <modal
      :show-close="!localDisplay"
      class="new-concept-modal"
      :show.sync="localDisplay"
    >
      <div class="row d-flex mt-3 justify-content-center">
        <div class="pl-4 pr-4 w-full text-center">
          <modal-header :style-name="modalHeaderComputedStyle" class="mb-3">
            <div slot="icon" class="d-flex justify-content-center">
              <font-awesome-icon
                class="icon blue-mayday"
                :icon="['fal', 'brain']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{ $t('concepts.new-concept-modal.title') }}
                </p>
              </div>
            </div>
          </modal-header>
        </div>
      </div>
      <div
        class="flex-column d-flex justify-content-center subcontent p-2"
        v-loading="contentsLoading"
      >
        <!-- CONCEPT EDITION -->
        <ConceptEdition
          class="notification-edition-options"
          v-if="currentStep === 0"
          @update-concept="concept = $event"
        />
        <!-- CONTENTS CHECKER -->
        <ContentChecker
          :contents="contents"
          v-if="currentStep === 1"
          @update-content-check-list="contentChecklist = $event"
        />
        <!-- SUMMARY -->
        <ConceptCreationSummary
          v-if="currentStep === 2"
          :isError="isError"
          :concept="concept"
        ></ConceptCreationSummary>
      </div>
      <div class="section d-flex justify-content-center mt-4">
        <span
          v-for="(step, index) in steps"
          :key="index"
          :class="[
            'notification-step-item',
            step === currentStep ? 'notification-step-item-selected' : '',
          ]"
        ></span>
      </div>
      <template slot="footer">
        <!-- Body actions -->
        <div class="d-flex justify-content-end">
          <base-button
            type="secondary"
            outline
            v-if="currentStep === 0"
            @click="localDisplay = false"
            size="sm"
            >{{ $t('concepts.new-concept-modal.cta.cancel') }}</base-button
          >
          <base-button
            class="ml-2"
            v-if="currentStep === 0"
            @click="handleClickOnNext"
            type="primary"
            size="sm"
            :disabled="!concept.label || !concept.defaultLanguage"
            >{{ $t('concepts.new-concept-modal.cta.next') }}</base-button
          >
          <base-button
            v-else-if="currentStep === 1"
            @click="handleValidateContentChecks"
            type="primary"
            size="sm"
            :disabled="Object.keys(contentChecklist).length === 0"
            >{{ $t('concepts.new-concept-modal.cta.send') }}</base-button
          >
          <base-button
            v-else-if="currentStep === 2"
            @click="localDisplay = false"
            type="primary"
            size="sm"
            >{{ $t('concepts.new-concept-modal.cta.end') }}</base-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import ModalHeader from '@/components/Modals/ModalHeader';
import Modal from '@/components/Modal';
import ConceptEdition from './ConceptEdition.vue';
import { mapActions } from 'vuex';
import ContentChecker from './ContentChecker.vue';
import ConceptCreationSummary from './ConceptCreationSummary.vue';

export default {
  name: 'new-concept-modal',
  props: {
    display: Boolean,
  },
  data() {
    return {
      localDisplay: this.display,
      concept: {},
      contentChecklist: {},
      steps: [0, 1, 2],
      currentStep: 0,
      contents: [],
      contentChecks: {},
      sessionId: '',
      contentsLoading: false,
      isError: false,
    };
  },

  components: {
    Modal,
    ModalHeader,
    ConceptEdition,
    ContentChecker,
    ConceptCreationSummary,
  },
  computed: {
    modalHeaderComputedStyle() {
      return { backgroundColor: '#0081F91A' };
    },
  },
  methods: {
    userChoice(choice) {
      this.$emit('choice', choice);
      this.localDisplay = false;
      this.$emit('close');
    },
    async handleClickOnNext() {
      try {
        this.contentsLoading = true;
        const { contents, sessionId } = await this.createConcept({
          concept: this.concept,
        });
        this.contents = contents;
        this.sessionId = sessionId;
        this.currentStep = 1;
        this.contentsLoading = false;
      } catch (e) {
        this.isError = true;
        this.currentStep = 2;
        this.contentsLoading = false;
      }
    },
    async handleValidateContentChecks() {
      try {
        this.contentsLoading = true;
        const { contents, done } = await this.sendSuggestedDocs({
          answers: this.contentChecklist,
          sessionId: this.sessionId,
        });
        if (done) {
          this.currentStep = 2;
          this.contentsLoading = false;
          return;
        }
        this.contentChecklist = {};
        this.contents = contents;
        this.contentsLoading = false;
      } catch (e) {
        this.isError = true;
        this.currentStep = 2;
        this.contentsLoading = false;
      }
    },
    ...mapActions('knowledgeModule', ['createConcept', 'sendSuggestedDocs']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('events', {
          eventName: 'close',
          eventData: null,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-step-item {
  height: 10px;
  width: 10px;
  background-color: $grey-5-mayday;
  border-radius: 50%;
  &:not(:last-child) {
    margin-right: 5px;
  }
}
.notification-step-item-selected {
  background-color: $blue-mayday;
}
.icon {
  width: 24px;
  height: 24px;
}
</style>
