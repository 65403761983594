import * as types from './mutation-types';

export default {
  // KNOWLEDGE
  [types.SET_FOCUS_KNOWLEDGE](state, payload) {
    state.focusKnowledge = payload;
  },
  [types.INIT_KNOWLEDGE](state, payload) {
    state.focusKnowledge = payload.focusKnowledge;
  },
  [types.UPDATE_KNOWLEDGE](state, payload) {
    state.focusKnowledge = payload.focusKnowledge;
  },
};
