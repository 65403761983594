import * as types from './mutation-types';
import { DIRECTIONS } from '../../views/Settings/PublicKnowledge/PublicKnowledgeMenuConfiguration';
import {
  isValidArray,
  toUpperSnakeCase,
  isDifferentFromOldConfig,
  isNew,
} from './utils';

const LOGS_TOPIC = {
  add: 'PUBLIC_SETTINGS_ADD',
  update: 'PUBLIC_SETTINGS_UPDATE',
  delete: 'PUBLIC_SETTINGS_REMOVE',
};

export default {
  async getCompanyPublicConfigs({ commit }) {
    try {
      const companyPublicConfigs =
        await this.$services.companyPublicConfig.getAllCompanyPublicConfigs();
      commit(types.GET_COMPANY_PUBLIC_CONFIGS, companyPublicConfigs);
    } catch (e) {
      e;
    }
  },
  async getFocusCompanyPublicConfig({ commit }, id) {
    try {
      const companyPublicConfig =
        await this.$services.companyPublicConfig.getCompanyPublicConfigById(id);
      commit(types.GET_FOCUS_PUBLIC_CONFIG, companyPublicConfig);
    } catch (e) {
      e;
    }
  },
  async getFocusCompanyPublicConfigByKnowledgeId({ commit }, id) {
    try {
      const companyPublicConfig =
        await this.$services.companyPublicConfig.getCompanyPublicConfigByKnowledgeId(
          id,
        );
      commit(types.GET_FOCUS_PUBLIC_CONFIG, companyPublicConfig);
    } catch (e) {
      e;
    }
  },
  async getPendingDeploymentStatus({ commit }, companyPublicConfigId) {
    try {
      const deploymentStatus =
        await this.$services.companyPublicConfig.getPendingDeploymentStatus(
          companyPublicConfigId,
        );
      commit(types.SET_DEPLOYMENT_STATUS, deploymentStatus);
      return true;
    } catch (e) {
      return false;
    }
  },

  async startStatusPolling({ dispatch, commit, getters }) {
    const { focusCompanyPublicConfig } = getters;
    dispatch('getPendingDeploymentStatus', focusCompanyPublicConfig.id);
    const poolingTimer = setTimeout(() => {
      dispatch('startStatusPolling');
    }, 5000);

    commit(types.SET_POLLING_TIMER, poolingTimer);
  },

  stopStatusPolling({ commit }) {
    commit(types.SET_POLLING_TIMER, null);
  },

  setDeploying({ commit }, deploying) {
    commit(types.SET_DEPLOYING, deploying);
  },

  async checkCdnEndpointHttpsStatus({ commit }, hostname) {
    try {
      const httpsStatus =
        await this.$services.companyPublicConfig.checkCdnEndpointHttpsStatus(
          hostname,
        );
      commit(types.SET_HTTPS_STATUS, httpsStatus);
      return;
    } catch (e) {
      e;
    }
  },

  async checkCdnEndpointValidity({ commit, getters }, customHostname) {
    try {
      await this.$services.companyPublicConfig.checkCdnEndpointValidity(
        customHostname,
        getters.focusCompanyPublicConfig.id,
      );
      commit(types.SET_CUSTOM_HOSTNAME_ERROR, {});
      return customHostname;
    } catch ({ graphQLErrors }) {
      commit(types.SET_CUSTOM_HOSTNAME_ERROR, {
        ...graphQLErrors[0].extensions,
        message: graphQLErrors[0].message,
      });
      return { errorKey: 'getCustomHostnameError' };
    }
  },

  async startHttpsStatusPolling({ dispatch, commit, getters }) {
    const { focusCompanyPublicConfig } = getters;
    dispatch('checkCdnEndpointHttpsStatus', focusCompanyPublicConfig.hostname);
    const poolingTimer = setTimeout(() => {
      dispatch('startHttpsStatusPolling');
    }, 10000);

    commit(types.SET_HTTPS_POLLING_TIMER, poolingTimer);
  },

  stopHttpsStatusPolling({ commit }) {
    commit(types.SET_HTTPS_POLLING_TIMER, null);
  },

  setHttpsPooling({ commit }, pooling) {
    commit(types.SET_HTTPS_POOLING, pooling);
  },

  setFocusKnowledge({ commit, rootGetters }, knowledgeId) {
    const knowledge = rootGetters['adminModule/adminKnowledges'].filter(
      (k) => k.id === knowledgeId,
    );
    commit(types.SET_FOCUS_KNOWLEDGE, knowledge[0]);
  },
  newOperation({ commit, getters }, payload) {
    const { focusCompanyPublicConfig, focusKnowledge } = getters;
    if (payload.operationType === 'update') {
      if (
        payload.direction === DIRECTIONS.PUBLIC_CONFIG &&
        isDifferentFromOldConfig(payload, focusCompanyPublicConfig)
      ) {
        return commit(types.NEW_OPERATION, payload);
      }
      if (
        payload.direction === DIRECTIONS.HIERARCHY_PREFERENCES &&
        isNew(payload, focusKnowledge, 'preferences', 'name')
      ) {
        return commit(types.NEW_OPERATION, payload);
      }
      if (
        payload.direction === DIRECTIONS.PLUGINS &&
        isNew(payload, focusKnowledge, 'plugins', 'type')
      ) {
        return commit(types.NEW_OPERATION, payload);
      }
      if (payload.direction === DIRECTIONS.TRANSLATIONS) {
        return commit(types.NEW_OPERATION, payload);
      } else commit(types.DELETE_OPERATION, payload.key);
    } else return commit(types.NEW_OPERATION, payload);
  },

  deleteOperation({ commit }, payload) {
    commit(types.DELETE_OPERATION, payload);
  },

  async sendLogs({ getters }, operations) {
    try {
      const {
        companyPublicConfigId,
        createdAt,
        deploymentId,
        deploymentStatus,
        publicConfigVersion,
        publicConfigBuild,
      } = getters.deploymentStatus;

      const { knowledgeId } = getters.focusCompanyPublicConfig;

      const url = this.$router.currentRoute.fullPath;

      const operationsLogs = operations.map((op) => {
        const { operationType, key, value, path, inputType } = op;
        return {
          topic: LOGS_TOPIC[operationType],
          event: {
            key,
            value,
            path,
            inputType,
            knowledgeId,
            deploymentId: deploymentId,
          },
          url,
        };
      });

      const deploymentLog = {
        topic: 'PUBLIC_SETTINGS_DEPLOYMENT_START',
        event: {
          companyPublicConfigId,
          createdAt,
          deploymentId,
          deploymentStatus,
          publicConfigVersion,
          publicConfigBuild,
          knowledgeId,
        },
        url,
      };

      operationsLogs.unshift(deploymentLog);

      await this.$services.events.publicSettings.sendMany(operationsLogs);

      return true;
    } catch (error) {
      return false;
    }
  },

  async publishOperations({ commit, getters, dispatch }) {
    const { getAllOperations, focusCompanyPublicConfig } = getters;

    const sortedOperations = getAllOperations.reduce((acc, op) => {
      if (!acc[op.direction]) {
        acc[op.direction] = [];
      }
      acc[op.direction].push(op);
      return acc;
    }, {});
    if (isValidArray(sortedOperations[DIRECTIONS.PUBLIC_CONFIG])) {
      const formattedPublicConfigOperations = sortedOperations[
        DIRECTIONS.PUBLIC_CONFIG
      ].map((op) => {
        delete op.direction;
        return op;
      });

      try {
        await this.$services.companyPublicConfig.publishOperations(
          focusCompanyPublicConfig.id,
          formattedPublicConfigOperations,
        );
      } catch (e) {
        throw e;
      }
    }
    if (isValidArray(sortedOperations[DIRECTIONS.HIERARCHY_PREFERENCES])) {
      const formattedHierarchyPreferences = sortedOperations[
        DIRECTIONS.HIERARCHY_PREFERENCES
      ].reduce((acc, op) => {
        const formattedName = toUpperSnakeCase(op.key);
        acc.push({ name: formattedName, value: op.value });

        return acc;
      }, []);

      await this.$services.hierarchies.updateHierarchyPreferences(
        focusCompanyPublicConfig.knowledgeId,
        formattedHierarchyPreferences,
      );
    }

    if (isValidArray(sortedOperations[DIRECTIONS.PLUGINS])) {
      const formattedPlugins = sortedOperations[DIRECTIONS.PLUGINS].reduce(
        (acc, op) => {
          const formattedType = op.path.split('.').shift();
          const strictPath = op.path.split('.').slice(1).join('.');
          acc.push({
            type: formattedType,
            value: op.value,
            path: strictPath,
          });

          return acc;
        },
        [],
      );

      await this.$services.plugins.updatePlugins(
        focusCompanyPublicConfig.knowledgeId,
        formattedPlugins,
      );
    }

    if (isValidArray(sortedOperations[DIRECTIONS.TRANSLATIONS])) {
      const formattedTranslationsUpdate = sortedOperations[
        DIRECTIONS.TRANSLATIONS
      ].reduce(
        (acc, op) => {
          if (
            op.key === 'metaTagTranslationTitle' ||
            op.key === 'metaTagTranslationDescription'
          ) {
            acc.meta.push({
              lang: op.path.split('.')[0],
              type: op.path.split('.')[op.path.split('.').length - 1],
              value: op.value,
            });
          } else if (op.key === 'newLanguage') {
            acc.newLanguage.push(op);
          }
          return acc;
        },
        { meta: [], newLanguage: [] },
      );
      if (formattedTranslationsUpdate.meta) {
        await this.$services.hierarchies.updatePublicDataMetaTranslations(
          focusCompanyPublicConfig.knowledgeId,
          formattedTranslationsUpdate.meta,
        );
      }
      if (formattedTranslationsUpdate.newLanguage) {
        await this.$services.companyPublicConfig.publishOperations(
          focusCompanyPublicConfig.id,
          [],
        );
      }
    }

    const res = await dispatch(
      'getPendingDeploymentStatus',
      focusCompanyPublicConfig.id,
    );

    if (res) {
      dispatch('sendLogs', getAllOperations);
      commit(types.DESTROY_OPERATIONS);
      dispatch('knowledgeModule/getKnowledges', null, { root: true });
    }
  },
  // LANGUAGES

  async addLanguage({ commit, dispatch }, { id, lang }) {
    try {
      await this.$services.hierarchies.addLanguage(id, lang);
      commit(types.ADD_LANGUAGE, { lang });
      dispatch('knowledgeModule/addNewLanguage', lang, {
        root: true,
      });
      dispatch('newOperation', {
        direction: DIRECTIONS.TRANSLATIONS,
        operationType: 'add',
        key: 'newLanguage',
        path: lang,
      });
    } catch (error) {
      return error;
    }
  },

  async setMultilingual({ commit, dispatch }, { id, lang }) {
    try {
      commit(types.SET_MULTILINGUAL_LOADER, true);
      await this.$services.hierarchies.setMultilingual(id, lang);
      commit(types.SET_MULTILINGUAL, { lang });
      commit(types.SET_MULTILINGUAL_LOADER, false);
      dispatch('knowledgeModule/setFocusKnowledgeAsMultilingual', lang, {
        root: true,
      });
      dispatch('knowledgeModule/switchEditingLanguage', lang, {
        root: true,
      });
    } catch (error) {
      return error;
    }
  },

  // NEW PUBLIC KB

  async createCompanyPublicConfig({ commit, dispatch }, { models }) {
    try {
      const { subdomain, secondary, primary, lang, groups } = models;
      const operations = [
        {
          key: 'primary',
          value: primary,
          path: 'uiConfig.primary',
          operationType: 'update',
          inputType: 'color',
        },
        {
          key: 'secondary',
          value: secondary,
          path: 'uiConfig.secondary',
          operationType: 'update',
          inputType: 'color',
        },
      ];
      const companyPublicConfig =
        await this.$services.companyPublicConfig.createCompanyPublicConfig(
          subdomain,
          lang,
          operations,
          groups,
        );
      await dispatch('knowledgeModule/getKnowledges', null, { root: true });
      commit(types.GET_FOCUS_PUBLIC_CONFIG, companyPublicConfig);
      return companyPublicConfig;
    } catch (error) {
      return error;
    }
  },

  async uploadPublicFile(_, { fileName, fileData }) {
    try {
      const res = await this.$services.companyPublicConfig.uploadPublicFile(
        fileName,
        fileData,
      );

      try {
        new URL(res);
      } catch (err) {
        return null;
      }

      return res;
    } catch (error) {
      return null;
    }
  },
  async checkHostname(_, { hostname }) {
    return this.$services.companyPublicConfig.checkHostname(hostname);
  },
};
