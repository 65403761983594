import data from './company.data.raw.js';

export class CompanyServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ METHODS
   */

  async getCompanyDetails(
    page,
    pageSize,
    filters,
    sort,
    search,
    {
      skipEmployees = true,
      skipPendingEmployees = true,
      skipGroups = true,
      skipRoles = true,
      skipLabels = true,
    },
  ) {
    return this.client
      .query({
        query: data.getCompanyDetails,
        variables: {
          page,
          pageSize,
          filters,
          sort,
          search,
          skipEmployees,
          skipPendingEmployees,
          skipGroups,
          skipRoles,
          skipLabels,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.currentCompany)
      .catch((error) => error);
  }

  getHostnames() {
    return this.client
      .query({
        query: data.getHostnames,
      })
      .then((response) => response.data.getCompanyPublicConfigHostnames)
      .catch((error) => error);
  }

  async getCompanyApiToken() {
    return this.client
      .query({
        query: data.getCompanyApiToken,
      })
      .then((response) => response.data.company.apitoken)
      .catch((error) => error);
  }

  async getReviewers() {
    return this.client
      .query({
        query: data.getReviewers,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.currentCompany.reviewers)
      .catch((error) => error);
  }

  /**
   * CREATE METHODS
   */

  async addCompanyPreference(preferenceType, preferenceValue) {
    return this.client
      .mutate({
        mutation: data.addCompanyPreference,
        variables: {
          preferenceType,
          preferenceValue,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.addCompanyPreference)
      .catch((error) => error);
  }

  async addCompanyIntegration(integration) {
    return this.client
      .mutate({
        mutation: data.addCompanyIntegration,
        variables: {
          integration,
        },
      })
      .then((response) =>
        response.data.addIntegration.map((o) => o.integration),
      )
      .catch((error) => error);
  }

  /**
   * UPDATE METHODS
   */

  async updateCompanyPreference(preferenceId, preferenceValue) {
    return this.client
      .mutate({
        mutation: data.updateCompanyPreference,
        variables: {
          preferenceId,
          preferenceValue,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.updateCompanyPreference)
      .catch((error) => error);
  }

  async updateCompanyLogo(url) {
    return this.client
      .mutate({
        mutation: data.updateCompanyLogo,
        variables: {
          url,
        },
      })
      .then((response) => response.data.updateCompanyLogo)
      .catch((error) => error);
  }

  /**
   * DELETE METHODS
   */

  async deleteCompanyPreference(preferenceIds) {
    return this.client
      .mutate({
        mutation: data.deleteCompanyPreference,
        variables: {
          preferenceIds,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.removeCompanyPreference)
      .catch((error) => error);
  }

  async deleteCompanyIntegration(integration) {
    return this.client
      .mutate({
        mutation: data.deleteCompanyIntegration,
        variables: {
          integration,
        },
      })
      .then((response) =>
        response.data.deleteIntegration.map((o) => o.integration),
      )
      .catch((error) => error);
  }

  async deleteCompanyLogo() {
    return this.client
      .mutate({
        mutation: data.deleteCompanyLogo,
      })
      .then((response) => response.data.deleteCompanyLogo)
      .catch((error) => error);
  }
}
