export class SettingServiceData {
  constructor(client) {
    this.client = client;
  }

  async getCompanyIpRanges() {
    const res = await this.client.get('/settings/company/ip-ranges');
    return res.data;
  }

  async setCompanyIpRanges(ipRanges) {
    const res = await this.client.post('/settings/company/ip-ranges', {
      ipRanges,
    });
    return res.data;
  }

  async deleteCompanyIpRanges() {
    const res = await this.client.delete('/settings/company/ip-ranges');
    return res.data;
  }

  async getApiOAuthAppRegistrations() {
    const res = await this.client.get('/settings/api-oauth-app-registrations');
    return res.data;
  }

  async createApiOAuthAppRegistration() {
    const res = await this.client.post('/settings/api-oauth-app-registrations');
    return res.data;
  }

  async updateApiOAuthAppRegistration(id, key, value) {
    const res = await this.client.patch(
      `/settings/api-oauth-app-registrations/${id}`,
      {
        key,
        value,
      },
    );
    return res.data;
  }

  async deleteApiOAuthAppRegistration(id) {
    const res = await this.client.delete(
      `/settings/api-oauth-app-registrations/${id}`,
    );
    return res.data;
  }
}
