export const prepareStore = (store, router) => {
  const broadcastChannel = new BroadcastChannel('mayday_channel');

  broadcastChannel.onmessage = (event) => {
    const { action, payload } = event.data;
    store.dispatch(action, payload);
  };

  store.$router = router;
  store.$broadcastChannel = broadcastChannel;
};
