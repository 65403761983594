import initState from './state';
import * as types from './mutation-types';
import { cleanLookupObject } from './utils';

export default {
  // TMP only for web-parametric bug feedbacks on steps
  [types.SET_FOCUS_STEP](state, payload) {
    state.focusStep = payload;
  },

  /**
   * GENERAL
   */

  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  [types.UPDATE_LANGUAGE_TRANSLATION](state, translationLanguage) {
    state.editingLanguage = translationLanguage;
  },
  [types.SET_DEFAULT_LANGUAGE_TRANSLATION](state, defaultLanguage) {
    state.editingLanguage = defaultLanguage;
  },
  [types.UPDATE_NAVIGATION_LANGUAGE](state, navigationLanguage) {
    state.navigationLanguage = navigationLanguage;
  },
  [types.SET_FOCUS_KNOWLEDGE_AS_MULTILINGUAL](state, language) {
    state.focusKnowledge.supportedLanguages = [language];
    state.focusKnowledge.defaultLanguage = language;
  },
  [types.ADD_NEW_LANGUAGE_TO_FOCUS_KNOWLEDGE](state, language) {
    state.focusKnowledge.supportedLanguages.push(language);
  },
  [types.ADD_NEW_LANGUAGE_TO_KNOWLEDGE](
    state,
    { id, lang, labelTranslations },
  ) {
    const knowledge = state.knowledges.find((k) => k.id === id);

    if (!knowledge) return;

    knowledge.supportedLanguages.push(lang);
    knowledge.labelTranslations = labelTranslations;

    if (state.focusKnowledge && id === state.focusKnowledge.id) {
      state.focusKnowledge.supportedLanguages.push(lang);
      state.focusKnowledge.labelTranslations = labelTranslations;
    }
  },

  [types.EDIT_KNOWLEDGE_LABEL](state, { id, newLabel, labelTranslations }) {
    const knowledge = state.knowledges.find((k) => k.id === id);

    if (!knowledge) return;

    knowledge.label = newLabel;
    knowledge.labelTranslations = labelTranslations;

    if (state.focusKnowledge && id === state.focusKnowledge.id) {
      state.focusKnowledge.label = newLabel;
      state.focusKnowledge.labelTranslations = labelTranslations;
    }
  },

  /**
   * KNOWLEDGE
   */

  [types.SET_KNOWLEDGES](state, payload) {
    state.knowledges = payload;
  },
  [types.SET_FOCUS_KNOWLEDGE](state, payload) {
    state.focusKnowledge = payload;
  },
  [types.SET_FOCUS_KNOWLEDGE_ROOTS](state, payload) {
    state.focusKnowledgeRoots = payload;
  },
  [types.UPDATE_SINGLE_KNOWLEDGE_ROOT](state, payload) {
    const idx = state.focusKnowledgeRoots.findIndex(
      (root) => root.id === payload.id,
    );

    if (idx === -1) return;

    const copy = [...state.focusKnowledgeRoots];
    copy[idx] = payload;
    state.focusKnowledgeRoots = copy;
  },
  [types.SET_FOCUS_KNOWLEDGE_ROOTS_IS_LOADING](state, payload) {
    state.focusKnowledgeRootsIsLoading = payload;
  },
  [types.KNOWLEDGE_EDITOR_IS_LOADING](state, payload) {
    state.knowledgeEditorIsLoading = payload;
  },

  /**
   * COLLECTIONS NAVIGATION
   */

  [types.SET_COLLECTION_VIEWER_IS_LOADING](state, payload) {
    state.collectionsViewerIsLoading = payload;
  },
  [types.SET_FOCUS_COLLECTION_ID](state, payload) {
    state.focusCollectionId = payload;
  },
  [types.SET_FOCUS_COLLECTION](state, payload) {
    state.focusCollection = payload;
  },
  [types.SET_FOCUS_CONTENT_ID](state, id) {
    state.focusContentId = id;
  },
  [types.SET_FOCUS_CONTENT](state, payload) {
    state.focusContent = payload;
    state.focusContentConcepts = null;
  },
  [types.SET_FOCUS_CONTENT_CONTRIBUTORS](state, payload) {
    state.focusContentContributors = payload;
  },
  [types.SET_FOCUS_CONTENT_BACKLINKS](state, payload) {
    state.focusContentBacklinks = payload;
  },
  [types.SET_FOCUS_COLLECTION_RELATED_CONTENTS](state, payload) {
    state.focusCollectionRelatedContents = payload;
  },
  [types.SET_FOCUS_ITEM_ID](state, id) {
    state.focusItemId = id;
  },
  [types.ADD_TO_NAVIGATOR_LOOKUP_MAP](state, payload) {
    let entityType = '';

    if (payload.key === 'knowledge') {
      entityType = 'Knowledge';
    } else if (payload.type) {
      entityType = 'Content';
    } else {
      entityType = 'Case';
    }

    payload = cleanLookupObject(payload);

    state.navigatorLookupMap = {
      ...state.navigatorLookupMap,
      [`${entityType}-${payload.id}`]: payload,
    };
  },
  [types.ADD_MANY_TO_NAVIGATOR_LOOKUP_MAP](state, items) {
    Object.assign(
      state.navigatorLookupMap,
      items.reduce((acc, item) => {
        let entityType = 'Case';
        if (item.key === 'knowledge') {
          entityType = 'Knowledge';
        } else if (item.type) {
          entityType = 'Content';
        }

        item = cleanLookupObject(item);

        acc[`${entityType}-${item.id}`] = item;
        return acc;
      }, {}),
    );
  },
  [types.RESET_NAVIGATOR_LOOKUP_MAP](state) {
    state.navigatorLookupMap = {};
  },
  [types.SET_TOGGLE_NAVIGATION_BAR](state, payload) {
    state.toggleNavigationBar = payload;
  },
  /**
   * The FLUSH_NAVIGATOR_LOOKUP_MAP mutation deletes all the keys
   * from navigatorLookupMap not present in keysToKeep
   * TODO: we should delete unnecessary keys from the retrieved values
   * such as children or childrenOrder for Cases
   * @param {store} state
   * @param {[String]} keysToKeep
   */
  [types.FLUSH_NAVIGATOR_LOOKUP_MAP](state, keysToKeep) {
    state.navigatorLookupMap = keysToKeep.reduce((acc, val) => {
      acc[val] = state.navigatorLookupMap[val];
      return acc;
    }, {});
  },
  [types.REMOVE_CONTENT_FROM_NAVIGATOR_LOOKUP_MAP](state, id) {
    const copy = { ...state.navigatorLookupMap };

    delete copy[`Content-${id}`];

    state.navigatorLookupMap = { ...copy };
  },
  [types.REMOVE_CASE_FROM_NAVIGATOR_LOOKUP_MAP](state, id) {
    const copy = { ...state.navigatorLookupMap };

    delete copy[`Case-${id}`];

    state.navigatorLookupMap = { ...copy };
  },
  [types.SET_FOCUS_COLLECTION_CONTENTS](
    state,
    { strictResolutions = { results: [], total: 0, page: 1 } },
  ) {
    const { results, total, pages } = strictResolutions;
    state.focusCollectionContentsResults = results;
    state.focusCollectionContentsTotal = total;
    state.focusCollectionContentsPages = pages;
  },
  [types.SET_COLLECTION_SEARCH_FILTER](state, payload) {
    state.collectionSearch.filter = payload;
  },
  [types.SET_COLLECTION_SEARCH_PAGE](state, payload) {
    state.collectionSearch.page = payload;
  },
  [types.SET_CONTENT_PARAMETER_LABELS](state, payload) {
    state.contentParameterLabels = payload;
  },

  /**
   * ARCHIVE FOCUS
   */
  [types.SET_FOCUS_ARCHIVE](state, payload) {
    state.archiveId = payload.id;
    state.archiveContent = payload.content;
    state.archiveDescendents = payload.descendants;
    state.archiveFormerMainAncestor = payload.formerMainAncestor;
    state.archiveDeletionDate = payload.createdAt;
    state.archiveFormerCaseParents = payload.formerCaseParents;
  },
  [types.SET_FOCUS_ARCHIVE_ERROR](state) {
    state.archiveError = true;
  },
  [types.RESET_FOCUS_ARCHIVE](state) {
    state.archiveId = null;
    state.archiveContent = null;
    state.archiveDescendents = [];
    state.archiveFormerMainAncestor = null;
    state.archiveDeletionDate = null;
    state.archiveError = false;
    state.archiveFormerCaseParents = [];
  },

  /**
   * CASE ARCHIVE FOCUS
   */
  [types.SET_CASE_ARCHIVE_VIEWER_IS_LOADING](state, payload) {
    state.caseArchiveViewerIsLoading = payload;
  },
  [types.SET_MAIN_FOCUS_CASE_ARCHIVE](state, payload) {
    state.mainCaseArchive = payload;
  },
  [types.SET_FOCUS_CASE_ARCHIVE](state, payload) {
    state.focusCaseArchive = payload;
  },
  [types.SET_FOCUS_CASE_ARCHIVE_ANCESTORS](state, payload) {
    state.focusCaseArchiveAncestors = payload;
  },
  [types.SET_CASE_ARCHIVE_ERROR](state, payload) {
    state.caseArchiveError = payload;
  },
  [types.RESET_CASE_ARCHIVE](state) {
    state.mainCaseArchive = {};
    state.focusCaseArchive = {};
    state.focusCaseArchiveAncestors = [];
    state.caseArchiveError = false;
  },

  /**
   * DRAG N DROP
   */

  [types.ADD_TO_DRAG_AND_DROP](state, payload) {
    const { id, type, dragFromToData } = payload;
    const entityType = type ? 'Content' : 'Case';
    state.dragAndDrop = {
      ...state.dragAndDrop,
      [`${entityType}-${id}`]: dragFromToData,
    };
  },

  [types.REMOVE_FROM_DRAG_AND_DROP](state, key) {
    const copy = { ...state.dragAndDrop };

    delete copy[key];

    state.dragAndDrop = { ...copy };
  },
  [types.SET_NAVIGATOR_NODE_IS_LOADING](state, payload) {
    state.navigatorNodeIsLoading = payload;
  },

  /**
   * IMPORT ARTICLE
   */

  [types.SET_IMPORT_ARTICLE_IS_LOADING](state, payload) {
    state.importArticleIsLoading = payload;
  },

  /**
   * TEMPLATE
   */

  [types.SET_TEMPLATES](state, payload) {
    state.templates = payload;
  },

  [types.SET_TEMPLATE](state, payload) {
    state.focusTemplate = payload;
  },

  [types.SET_TEMPLATES_PATH](state, payload) {
    state.templatesPath = payload;
  },

  /**
   * PRODUCT
   */

  [types.SET_COLLECTION_PARENTS](state, payload) {
    state.collectionParents = payload;
  },

  /**
   * MAYDAY EDITOR
   */

  [types.UPDATE_FILE_UPLOAD_ERROR](state, payload) {
    state.fileUploadError = payload;
  },
  /**
   * CONCEPTS
   */

  [types.SET_CONTENT_CONCEPTS](state, payload) {
    state.focusContentConcepts = payload;
  },
};
