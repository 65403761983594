import data from './search.data.raw.js';

const lightSearchVariables = {
  entity: ['attached-contents'],
  fields: ['entity', 'type'],
  searchFrom: 0,
  correlationId: null,
};

const maydaywebSearchVariables = {
  entity: ['attached-contents', 'cases'],
  fields: ['entity', 'type'],
  searchFrom: 0,
  correlationId: null,
};

const createQueryOrderController = () => {
  let count = 0;
  let latestResponse = null;

  const increment = () => {
    count += 1;
  };

  const handleResponse = () => {
    const expectedCount = count;
    return (response) => {
      if (count === expectedCount) {
        latestResponse = response;
        return response;
      } else {
        if (latestResponse) {
          return latestResponse;
        }
        throw new Error('BAD_RESPONSE_ORDER_ERROR');
      }
    };
  };

  return {
    increment,
    handleResponse,
  };
};

export class SearchServiceData {
  constructor(client) {
    this.client = client;
    this.superSearchController = createQueryOrderController();
    this.searchAutocompleteController = createQueryOrderController();
    this.lightSearchController = createQueryOrderController();
  }

  /**
   * READ QUERIES
   */

  async superSearch(
    entity,
    fields,
    searchFrom,
    size,
    correlationId,
    payload,
    lang = '',
    isNlp = false,
  ) {
    this.superSearchController.increment();
    return this.client
      .query({
        query: data.superSearch,
        variables: {
          entity,
          fields,
          searchFrom,
          size,
          correlationId,
          payload,
          lang,
          isNlp,
        },
        fetchPolicy: 'no-cache',
      })
      .then(this.superSearchController.handleResponse())
      .then((response) => response.data.superSearch)
      .catch((error) => error);
  }

  searchAutocomplete(payload) {
    this.searchAutocompleteController.increment();
    return this.client
      .query({
        query: data.searchAutocomplete,
        variables: {
          payload,
        },
        fetchPolicy: 'no-cache',
      })
      .then(this.searchAutocompleteController.handleResponse())
      .then((response) => response.data.searchAutocomplete)
      .catch((error) => error);
  }

  async lightSearchContentFromKnowledge(
    knowledge,
    queryText,
    size,
    lang = '',
    type,
    published,
  ) {
    this.lightSearchController.increment();
    return this.client
      .query({
        query: data.lightSearchContentFromKnowledge,
        variables: {
          ...lightSearchVariables,
          size,
          payload: {
            queryText,
            pre: {
              type,
              knowledge: [knowledge],
              published,
            },
            post: {},
            showHidden: true,
          },
          lang,
        },
        fetchPolicy: 'no-cache',
      })
      .then(this.lightSearchController.handleResponse())
      .then((response) => response.data.superSearch)
      .catch((error) => error);
  }

  async getSimilarContents(body) {
    return this.client
      .query({
        query: data.getSimilarContents,
        variables: {
          body,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getSimilarContents)
      .catch((error) => error);
  }

  async maydayWebSearch(
    knowledge,
    queryText,
    entityType,
    categories,
    size,
    hasEngineRestrictions,
    lang,
    caseId,
  ) {
    const types = entityType
      ? { type: entityType }
      : hasEngineRestrictions
      ? { type: ['Article', 'Diagnostic', 'keyStep', null] }
      : {};
    return this.client
      .query({
        query: data.superSearch,
        variables: {
          ...maydaywebSearchVariables,
          size,
          payload: {
            queryText,
            pre: {
              ...types,
              categories,
              knowledge: [knowledge],
              published: ['true', null],
              ...(caseId ? { caseId } : {}),
            },
          },
          lang,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.superSearch)
      .catch((error) => error);
  }
}
