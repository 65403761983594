import gql from 'graphql-tag';

import { CONTENT_TEMPLATE_FRAGMENT } from './contentTemplate.fragments';

export default {
  getTemplateById: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    query getTemplateByIdQuery($id: ID!, $lang: String) {
      getContentTemplateById(id: $id, lang: $lang) {
        ...contentTemplateFragment
      }
    }
  `,
  getTemplates: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    query getTemplatesQuery($lang: String) {
      getCompanyContentTemplates(lang: $lang) {
        ...contentTemplateFragment
      }
    }
  `,
  createTemplateByContentId: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation createTemplateByContentIdMutation(
      $name: String!
      $description: String!
      $contentId: ID!
      $lang: String!
    ) {
      createContentTemplateByContentId(
        name: $name
        description: $description
        contentId: $contentId
        lang: $lang
      ) {
        ...contentTemplateFragment
      }
    }
  `,
  createTemplate: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation createTemplateMutation(
      $name: String!
      $description: String!
      $type: String!
      $lang: String!
      $knowledgeId: ID!
      $knowledge: String!
    ) {
      createContentTemplate(
        name: $name
        description: $description
        type: $type
        lang: $lang
        knowledgeId: $knowledgeId
        knowledge: $knowledge
      ) {
        ...contentTemplateFragment
      }
    }
  `,
  createContentFromTemplate: gql`
    mutation createContentFromTemplateMutation(
      $templateId: ID!
      $targetCaseId: ID!
      $targetKnowledge: String!
      $targetKnowledgeId: ID!
    ) {
      createContentFromTemplate(
        templateId: $templateId
        targetCaseId: $targetCaseId
        targetKnowledge: $targetKnowledge
        targetKnowledgeId: $targetKnowledgeId
      ) {
        id
        label
        type
        caseParameters
        defaultLanguage
        returnLanguage
        setLanguages
        userPermissions
      }
    }
  `,

  deleteTemplate: gql`
    mutation deleteTemplateMutation($id: ID!) {
      deleteContentTemplate(id: $id)
    }
  `,

  updateTemplate: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation updateTemplateMutation($id: ID!, $payload: JSON) {
      updateContentTemplate(id: $id, payload: $payload) {
        ...contentTemplateFragment
      }
    }
  `,

  updateTemplateTranslation: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation updateTemplateTranslationMutation(
      $id: ID!
      $descendentId: ID
      $key: String!
      $value: String!
      $lang: String!
    ) {
      updateContentTemplateTranslation(
        id: $id
        descendentId: $descendentId
        key: $key
        value: $value
        lang: $lang
      ) {
        ...contentTemplateFragment
      }
    }
  `,

  unsetTemplateTranslation: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation unsetTemplateTranslation(
      $id: ID!
      $descendentId: ID
      $langToUnset: String!
      $lang: String
    ) {
      unsetContentTemplateTranslation(
        id: $id
        descendentId: $descendentId
        langToUnset: $langToUnset
        lang: $lang
      ) {
        ...contentTemplateFragment
      }
    }
  `,

  deleteTemplateDescendent: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation deleteTemplateDescendentMutation(
      $id: ID!
      $descendentId: ID!
      $parentId: ID
    ) {
      deleteContentTemplateDescendent(
        id: $id
        descendentId: $descendentId
        parentId: $parentId
      ) {
        ...contentTemplateFragment
      }
    }
  `,

  createTemplateDescendent: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation createTemplateDescendentMutation(
      $id: ID!
      $parentId: ID
      $ancestors: [String]
      $label: String!
      $lang: String
    ) {
      createContentTemplateDescendent(
        id: $id
        parentId: $parentId
        ancestors: $ancestors
        label: $label
        lang: $lang
      ) {
        ...contentTemplateFragment
      }
    }
  `,

  orderTemplateDescendents: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation orderTemplateDescendentsMutation(
      $id: ID!
      $descendentId: ID!
      $path: [String!]
    ) {
      orderContentTemplateDescendents(
        id: $id
        descendentId: $descendentId
        path: $path
      ) {
        ...contentTemplateFragment
      }
    }
  `,

  createTemplateRedirection: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation createTemplateRedirectionMutation(
      $id: ID!
      $descendentId: ID!
      $entityId: ID!
      $entityType: String!
    ) {
      createContentTemplateRedirection(
        id: $id
        descendentId: $descendentId
        entityId: $entityId
        entityType: $entityType
      ) {
        ...contentTemplateFragment
      }
    }
  `,

  upsertTemplateAction: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation upsertTemplateActionMutation(
      $templateId: ID!
      $descendentId: ID
      $actionId: ID
      $actionPayload: JSON!
      $langs: [String]
      $type: String
      $title: String
    ) {
      upsertContentTemplateAction(
        templateId: $templateId
        descendentId: $descendentId
        actionId: $actionId
        actionPayload: $actionPayload
        langs: $langs
        type: $type
        title: $title
      ) {
        ...contentTemplateFragment
      }
    }
  `,

  deleteTemplateAction: gql`
    ${CONTENT_TEMPLATE_FRAGMENT}
    mutation deleteTemplateActionMutation(
      $templateId: ID!
      $descendentId: ID
      $actionId: ID!
    ) {
      deleteContentTemplateAction(
        templateId: $templateId
        descendentId: $descendentId
        actionId: $actionId
      ) {
        ...contentTemplateFragment
      }
    }
  `,
};
