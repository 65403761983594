<template>
  <base-alert ref="alert" :type="alert.type" class="text-left mb-4 mx-2">
    <template v-slot:text>
      {{ $t('components.modals.verification-policy.submit.resume.part0') }}
      <strong>{{ alert.message.title }}</strong>
      {{ $t('components.modals.verification-policy.submit.resume.part1') }}
      <strong>{{ alert.message.verify }}</strong>
      <strong>{{ ` ${alert.message.periodicity}` }} </strong>.
    </template>
  </base-alert>
</template>

<script>
import BaseAlert from '@/components/BaseAlert.vue';

export default {
  name: 'content-verification-policy-review',
  components: { BaseAlert },
  props: {
    title: String,
    model: Object,
    periods: Array,
    username: String,
  },
  computed: {
    alert() {
      const { reviewer, period } = this.model;
      const alert = {
        type: 'success',
        message: {
          title: this.title,
          verify: '',
          periodicity: '',
        },
      };
      if (!reviewer.type || period === '') {
        return alert;
      }
      alert.message.verify = this.verificationMessageGeneration(reviewer);

      alert.message.periodicity = this.periods
        .filter((eachPeriod) => eachPeriod.value === this.model.period)[0]
        .name.toLowerCase();

      return alert;
    },
  },
  methods: {
    verificationMessageGeneration(reviewer) {
      const { label, type } = reviewer;
      // SELF-ASSIGN VERIFICATION
      if (label === this.username) {
        return this.$t(
          'components.modals.verification-policy.submit.resume.part2',
        );
      }
      // VERIFICATION ASSIGNED TO USER
      if (type === 'users')
        return `${this.$t(
          'components.modals.verification-policy.submit.resume.part3',
        )} ${label}`;
      // VERIFICATION ASSIGNED TO ROLE
      return `${this.$t(
        'components.modals.verification-policy.submit.resume.part4',
      )} ${label} ${this.$t(
        'components.modals.verification-policy.submit.resume.part4-bis',
      )}`;
    },
  },
};
</script>

<style></style>
