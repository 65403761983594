import data from './userDirectory.data.raw';

export class UserDirectoryServiceData {
  constructor(client) {
    this.client = client;
  }

  async addCompanyFavorite(entityId) {
    return this.client
      .mutate({
        mutation: data.addCompanyFavorite,
        variables: {
          entityId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.favorites)
      .catch((error) => error);
  }
  async removeCompanyFavorite(entityId) {
    return this.client
      .mutate({
        mutation: data.removeCompanyFavorite,
        variables: {
          entityId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.favorites)
      .catch((error) => error);
  }
  async isCompanyFavorite(id) {
    return this.client
      .query({
        query: data.isCompanyFavorite,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.isCompanyFavorite)
      .catch((error) => error);
  }

  async getSettingsPluginEntities(type) {
    return this.client
      .query({
        query: data.getSettingsPluginEntities,
        variables: { type },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.pluginEntities;
      })
      .catch((error) => error);
  }

  async updatePluginEntities(pluginEntities) {
    return this.client
      .mutate({
        mutation: data.updatePluginEntities,
        variables: {
          pluginEntities,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.pluginEntities)
      .catch((error) => error);
  }

  async updateAccessRestrictions(payload) {
    return this.client
      .mutate({
        mutation: data.updateAccessRestrictions,
        variables: {
          id: payload.id,
          roleIds: payload.roleIds,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.pluginEntities)
      .catch((error) => error);
  }
}
