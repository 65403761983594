<template>
  <div ref="container" :hidden="!filteredProcesses.length" class="atg-manager">
    <TransitionGroup
      name="atg-pan"
      tag="ul"
      class="atg-manager__transition-group"
      @before-leave="handleBeforeLeave"
      @after-leave="handleAfterLeave"
    >
      <ArticleToGuideToast
        v-for="process in filteredProcesses"
        :key="process.articleId"
        :process-id="process.id"
        :article-id="process.articleId"
        :guide-id="process.guideId"
        :hidden="process.hidden"
        :status="process.status"
        @close="handleClose"
        @cancel="handleCancel"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import ArticleToGuideToast from 'components/AI/ArticleToGuide/ArticleToGuideToast.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ArticleToGuideManager',
  components: { ArticleToGuideToast },
  data: () => ({
    statusInterval: null,
  }),
  computed: {
    filteredProcesses() {
      return this.articleToGuideProcesses.filter((p) => !p.hidden);
    },
    ...mapGetters('brainModule', ['articleToGuideProcesses']),
  },
  methods: {
    handleBeforeLeave() {
      this.$refs.container.classList.add('transitioning');
    },
    handleAfterLeave() {
      this.$refs.container.classList.remove('transitioning');
    },
    handleClose(processId, deleteFromStore) {
      if (deleteFromStore)
        this.updateArticleToGuideProcess({
          processes: this.articleToGuideProcesses.filter(
            (p) => p.id !== processId,
          ),
        });
      else
        this.updateArticleToGuideProcess({
          processes: this.articleToGuideProcesses.map((p) => {
            if (p.id === processId) {
              return {
                ...p,
                hidden: true,
              };
            }
            return p;
          }),
        });
    },
    handleCancel(processId) {
      this.cancelArticleToGuide({ processId });
      this.updateArticleToGuideProcess({
        processes: this.articleToGuideProcesses.filter(
          (p) => p.id !== processId,
        ),
      });
    },
    checkStatuses() {
      this.articleToGuideProcesses.forEach(async (process) => {
        if (process.guideId || ['FAILED'].includes(process.status)) return;
        const response = await this.getArticleToGuideStatus({
          processId: process.id,
        });
        const status = response.data.status;
        const guideId = response.data.id;
        if (status === process.status) {
          return;
        }
        this.updateArticleToGuideProcess({
          processes: this.articleToGuideProcesses.map((p) => {
            if (p.id === process.id) {
              return {
                ...p,
                status,
                guideId,
                hidden: status === 'DONE' ? false : p.hidden,
              };
            }
            return p;
          }),
        });
      });
    },
    ...mapActions('brainModule', [
      'updateArticleToGuideProcess',
      'cancelArticleToGuide',
      'getArticleToGuideStatus',
    ]),
  },
  watch: {
    articleToGuideProcesses: {
      handler(newVal) {
        if (newVal.length > 0 && !this.statusInterval) {
          this.statusInterval = setInterval(this.checkStatuses, 2000);
        } else if (
          this.statusInterval &&
          (newVal.length === 0 ||
            newVal.filter(
              (p) => !!p.guideId || ['FAILED', 'DONE'].includes(p.status),
            ).length === newVal.length)
        ) {
          clearInterval(this.statusInterval);
          this.statusInterval = null;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.atg-manager {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 11;
  max-height: 100vh;
  transition: all 0.3s ease-in-out;
  padding-top: 8px;

  &.transitioning {
    padding-bottom: 200px;
  }
}
.atg-manager__transition-group {
  transition: all 0.3s ease-in-out;
}
.atg-manager__transition-group > * {
  margin-bottom: 8px;
}

.atg-pan-move,
.atg-pan-enter-active,
.atg-pan-leave-active {
  transition: all 0.3s ease-in-out;
}

.atg-pan-enter,
.atg-pan-leave-to {
  transform: translateX(320px) !important;
  opacity: 0 !important;
}

.atg-pan-leave-active {
  position: absolute;
}
</style>
