import { render, staticRenderFns } from "./NewConceptModal.vue?vue&type=template&id=28ed5880&scoped=true&"
import script from "./NewConceptModal.vue?vue&type=script&lang=js&"
export * from "./NewConceptModal.vue?vue&type=script&lang=js&"
import style0 from "./NewConceptModal.vue?vue&type=style&index=0&id=28ed5880&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ed5880",
  null
  
)

export default component.exports