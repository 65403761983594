<template>
  <div class="delete-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#fd00440D' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon red-mayday"
                :icon="['fad', 'trash']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">{{ content.title }}</p>
              </div>
              <el-divider class="my-3 w-divider"></el-divider>
              <p>
                {{ content.description }}
                <span :class="{ 'font-weight-bold': label }">
                  {{ labelDisplay }}
                </span>
                {{ content.warning }}
              </p>
            </div>
            <div slot="footer" v-if="isCase && caseCount">
              <small class="header-sublabel">
                {{ content.count }}
              </small>
            </div>
            <div slot="footer" v-if="!isCase && linkedContentsCount">
              <small class="header-sublabel">
                {{ content.relatedCount }}
              </small>
            </div>
            <div v-if="automationsCount" slot="footer">
              <small class="header-sublabel">
                {{ content.automationsCount }}
              </small>
            </div>
          </modal-header>
        </div>
      </div>
      <div slot="footer" class="col-12 d-flex justify-content-around">
        <el-button
          class="ml-2"
          type="secondary"
          size="sm"
          @click="userChoice(false)"
          >{{ content.ctas.cancel }}
        </el-button>
        <el-button
          class="mr-2"
          type="danger"
          size="sm"
          @click="userChoice(true)"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>{{ content.ctas.validate }}</span>
        </el-button>
      </div>
    </modal>
  </div>
</template>
<script>
import ModalHeader from '@/components/Modals/ModalHeader';
import Modal from '@/components/Modal';

export default {
  name: 'delete-content-modal',
  props: {
    display: Boolean,
    bulkCount: {
      type: Number,
      default: 1,
    },
    isCase: Boolean,
    caseCount: Number,
    deleteType: {
      type: String,
      default: 'content',
    },
    id: String,
    relatedCount: {
      type: Number,
      default: 0,
    },
    automationsCount: {
      type: Number,
      default: 0,
    },
    backlinks: {
      type: Array,
      default: () => [],
    },
    lang: String,
    label: String,
  },
  components: {
    Modal,
    ModalHeader,
  },
  data() {
    return {
      localDisplay: this.display,
      isLoading: false,
    };
  },
  computed: {
    linkedContentsCount() {
      return this.relatedCount + this.backlinks.length;
    },
    labelDisplay() {
      if (this.label) {
        return `${this.label}.`;
      } else if (this.bulkCount === 1) {
        return this.$t('components.modals.delete-modal.content.default-label');
      } else return '';
    },
    content() {
      switch (this.deleteType) {
        case 'content':
          return {
            title: this.$t('components.modals.delete-modal.content.title'),
            description: this.$tc(
              'components.modals.delete-modal.content.description',
              this.bulkCount,
              { count: this.bulkCount },
            ),
            warning: this.$t('components.modals.delete-modal.content.warning'),
            count: this.$tc(
              'components.modals.delete-modal.content.count',
              this.caseCount,
              { count: this.caseCount },
            ),
            relatedCount: this.$tc(
              'components.modals.delete-modal.content.related-count',
              this.relatedCount,
              { count: this.linkedContentsCount },
            ),
            automationsCount: this.$tc(
              'components.modals.delete-modal.content.automations-count',
              this.automationsCount,
              { count: this.automationsCount },
            ),
            ctas: {
              validate: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        case 'user':
          return {
            title: this.$t('components.modals.delete-modal.user.title'),
            description: this.$tc(
              'components.modals.delete-modal.user.description',
              this.bulkCount,
              { count: this.bulkCount },
            ),
            warning: this.$t('components.modals.delete-modal.user.warning'),
            ctas: {
              validate: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        case 'template':
          return {
            title: this.$t('components.modals.delete-modal.template.title'),
            description: this.$tc(
              'components.modals.delete-modal.template.description',
              this.bulkCount,
              { count: this.bulkCount },
            ),
            warning: this.$t('components.modals.delete-modal.template.warning'),
            ctas: {
              validate: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        case 'customRole':
          return {
            title: this.$t('components.modals.delete-modal.custom-role.title'),
            description: this.$t(
              'components.modals.delete-modal.custom-role.description',
            ),
            warning: this.$t(
              'components.modals.delete-modal.custom-role.warning',
            ),
            ctas: {
              validate: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        case 'language':
          return {
            title: this.$t('components.modals.delete-modal.language.title'),
            description: this.$t(
              'components.modals.delete-modal.language.description',
              { lang: this.$t(`knowledge.languages.${this.lang}`) },
            ),
            warning: this.$t('components.modals.delete-modal.language.warning'),
            ctas: {
              validate: this.$t('components.modals.delete-modal.ctas.validate'),
              cancel: this.$t('components.modals.delete-modal.ctas.cancel'),
            },
          };
        default:
          return {};
      }
    },
  },
  methods: {
    userChoice(choice) {
      this.isLoading = true;
      this.$emit('events', {
        eventName: 'choice',
        eventData: choice,
      });
      this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-modal {
  :deep() .modal-body {
    padding-bottom: 0px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.red-mayday {
  color: $red-mayday;
}

.header-sublabel {
  color: $red-mayday;
}

.icon {
  width: 26px;
  height: 26px;
}

.w-divider {
  background-color: white;
}
</style>
