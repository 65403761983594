import axios from 'axios';

export {
  client,
  createOptions,
  generateCancelTokenSource,
  handleCancelService,
};

const CancelToken = axios.CancelToken;

const client = () =>
  axios.create({
    baseURL: `${process.env.VUE_APP_BACK_URL}/integration`,
  });

const createOptions = (accessToken, params = null, cache = true) => {
  const headers = generateHeaders(accessToken, cache);
  let options = Object.assign({}, { headers });
  if (params) {
    options = Object.assign(options, { params });
  }
  return options;
};

function generateHeaders(accessToken, cache = true) {
  const auth = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    'x-client-origin': 'MAYDAY_WEB',
  };
  const noCacheHeaders = !cache
    ? {
        'cache-control': 'no-cache',
        pragma: 'no-cache',
      }
    : {};

  const headers = Object.assign({}, auth, noCacheHeaders);
  return headers;
}

const generateCancelTokenSource = () => CancelToken.source();

const handleCancelService = (cancelToken) => {
  if (cancelToken) {
    cancelToken.cancel();
  }
  return generateCancelTokenSource();
};
