import { updateArrayObject } from 'utils/utils';
import * as types from './mutation-types';

export default {
  actions: {
    /**
     *************************** TREE NODES ***************************
     */
    SOCKET_CREATED_TREE_NODE(
      { commit, rootState },
      { parent, newChildNode, newChildContent },
    ) {
      const { treeNodes: rawNodes, nodesContent: rawContent } =
        rootState.kbStore;
      newChildContent.id = String(newChildContent.id);
      let nodesContent = [...rawContent];
      nodesContent.push(newChildContent);
      const treeNodes = updateArrayObject([...rawNodes], parent.id, parent);
      treeNodes.push(newChildNode);
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent,
      });
      commit(`kbStore/${types.UPDATE_TREE_NODES}`, {
        treeNodes,
      });
    },

    SOCKET_UPDATED_TREE_NODE({ commit, rootState }, args) {
      args.id = String(args.id);
      const { treeNodes: rawNodes } = rootState.kbStore;
      const treeNodes = updateArrayObject([...rawNodes], args.id, args);
      commit(`kbStore/${types.UPDATE_TREE_NODES}`, {
        treeNodes,
      });
    },

    SOCKET_REMOVED_TREE_NODE(
      { commit, dispatch, rootState },
      { parent, formerChild: { id: formerId } },
    ) {
      const { treeNodes: rawNodes } = rootState.kbStore;
      let treeNodes = updateArrayObject([...rawNodes], String(parent.id), {
        id: String(parent.id),
        path: parent.path.map(String),
        contentId: String(parent.contentId),
        type: parent.type,
        actions: parent.actions,
      });
      const formerChildNode = treeNodes.find(({ id }) => id === formerId);
      commit(`kbStore/${types.UPDATE_TREE_NODES}`, {
        treeNodes: treeNodes.filter(({ id }) => id !== formerId),
      });
      if (formerChildNode) {
        const formerChildCopies = treeNodes.filter(
          ({ contentId }) => contentId === formerChildNode.contentId,
        );
        const { nodesContent: rawContent } = rootState.kbStore;
        if (formerChildCopies.length === 1) {
          const nodesContent = [...rawContent].filter(
            ({ id }) => id !== formerChildNode.contentId,
          );
          commit(types.UPDATE_NODES_CONTENT, {
            nodesContent,
          });
        } else {
          const formerContent = rawContent.find(
            (c) => c.id === formerChildNode.contentId,
          );
          formerContent.parents = formerContent.parents.filter(
            (p) => p.id !== formerChildNode.id,
          );
          try {
            dispatch('updateOrCreateNodesContent', [formerContent]);
          } catch (e) {
            return e;
          }
        }
      }
    },

    SOCKET_BULK_REMOVED_TREE_NODES({ dispatch }, { parentChildCouples }) {
      parentChildCouples.forEach(({ parent, childrenId }) =>
        dispatch('SOCKET_REMOVED_TREE_NODE', {
          parent,
          formerChild: {
            id: childrenId,
          },
        }),
      );
    },

    SOCKET_DUPLICATED_TREE_NODE(
      { commit, dispatch, rootState },
      { parent, newChild },
    ) {
      const { treeNodes: rawNodes, nodesContent } = rootState.kbStore;

      // 1. ADDING NEW TREE NODE
      const treeNodes = updateArrayObject([...rawNodes], parent.id, parent);
      treeNodes.push(newChild);
      commit(`kbStore/${types.UPDATE_TREE_NODES}`, {
        treeNodes,
      });

      // 2. UPDATING NEW NODE CONTENT PARENTS
      const content = nodesContent.find((c) => c.id === newChild.contentId);
      content.parents.push({ id: newChild.id });
      try {
        dispatch('kbStore/updateOrCreateNodesContent', [content], {
          root: true,
        });
      } catch (e) {
        return e;
      }
    },

    SOCKET_UNLINKED_TREE_NODE({ commit, rootState }, { newNodeContent, node }) {
      const { treeNodes: rawNodes, nodesContent: rawContent } =
        rootState.kbStore;

      // Contents Update
      newNodeContent.id = String(newNodeContent.id);
      let nodesContent = [...rawContent];
      nodesContent.push(newNodeContent);
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent,
      });

      // Tree Nodes Update
      node.id = String(node.id);
      const treeNodes = updateArrayObject([...rawNodes], node.id, node);
      commit(`kbStore/${types.UPDATE_TREE_NODES}`, {
        treeNodes,
      });
    },

    /**
     *************************** CONTENT ***************************
     */

    SOCKET_UPDATED_NODE_CONTENT({ commit, rootState }, args) {
      const { nodesContent } = rootState.kbStore;
      args.id = String(args.id);
      const newNodesContent = updateArrayObject(
        [...nodesContent],
        args.id,
        args,
      );
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent: newNodesContent,
      });
    },

    SOCKET_UPDATED_NODE_CONTENT_PUBLISH_STATUS({ commit, rootState }, args) {
      const { nodesContent } = rootState.kbStore;
      const newNodesContent = updateArrayObject(
        [...nodesContent],
        args.id,
        args,
      );
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent: newNodesContent,
      });
    },

    SOCKET_BULK_UPDATED_NODES_CONTENT_PUBLISH_STATUS(
      { commit, rootState },
      args,
    ) {
      const { contentIds, published } = args;
      const { nodesContent: rawNodesContent } = rootState.kbStore;
      let nodesContent = [...rawNodesContent];
      contentIds.forEach((contentId) => {
        nodesContent = updateArrayObject(nodesContent, contentId, {
          id: contentId,
          published,
        });
      });
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent,
      });
    },

    SOCKET_BULK_UPDATED_NODES_CONTENT_OUTDATED_STATUS(
      { commit, rootState },
      args,
    ) {
      const { contentIds, isOutdated } = args;
      const { nodesContent: rawNodesContent } = rootState.kbStore;
      let nodesContent = [...rawNodesContent];
      contentIds.forEach((contentId) => {
        nodesContent = updateArrayObject(nodesContent, contentId, {
          id: contentId,
          isOutdated,
        });
      });
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent,
      });
    },

    SOCKET_UPDATED_VERIFICATION_POLICY({ commit, rootState }, args) {
      const { nodesContent } = rootState.kbStore;

      args.contentId = String(args.contentId);
      args.userId = String(args.userId);
      args.customRoleId = String(args.customRoleId);
      args.verificationDueDate = String(Date.parse(args.verificationDueDate)); // TODO: check this
      const {
        contentId,
        userId,
        customRoleId,
        verificationRange,
        verificationDueDate,
      } = args;

      const node = nodesContent.filter((node) => node.id === contentId)[0];
      const newVerificationPolicy = {
        userId,
        customRoleId,
        verificationRange,
        verificationDueDate,
      };
      const updatedNode = Object.assign(node, {
        verificationPolicy: newVerificationPolicy,
      });

      const newNodesContent = updateArrayObject(
        [...nodesContent],
        contentId,
        updatedNode,
      );
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent: newNodesContent,
      });
    },

    SOCKET_DELETED_VERIFICATION_POLICY({ commit, rootState }, args) {
      const { nodesContent } = rootState.kbStore;

      args.contentId = String(args.contentId);

      const node = nodesContent.filter((node) => node.id === args.contentId)[0];
      const updatedNode = Object.assign(node, {
        verificationPolicy: null,
      });

      const newNodesContent = updateArrayObject(
        [...nodesContent],
        args.contentId,
        updatedNode,
      );
      commit(`kbStore/${types.UPDATE_NODES_CONTENT}`, {
        nodesContent: newNodesContent,
      });
    },

    /**
     *************************** AUTOMATIONS ***************************
     */

    SOCKET_CREATED_AUTOMATION({ rootState, commit }, args) {
      const { groups: formerGroups } = rootState.kbStore;
      const {
        newGroup: { alias, id, RuleAttribute, contentId, knowledgeId, type },
      } = args;
      formerGroups.push({
        id: String(id),
        contentId: String(contentId),
        knowledgeId: String(knowledgeId),
        alias,
        type,
        ruleAttributes: RuleAttribute.map((elem) => ({
          id: String(elem.id),
          companyAttribute: elem.CompanyAttribute,
          companyAttributeValue: elem.CompanyAttributeValue,
          operator: elem.operator,
          value: elem.value,
        })),
      });
      commit(`kbStore/${types.UPDATE_KNOWLEDGE_GROUPS}`, {
        groups: formerGroups,
      });
    },

    SOCKET_UPDATED_AUTOMATION({ rootState, commit }, args) {
      const {
        group: { alias, id, RuleAttribute, contentId, knowledgeId, type },
      } = args;
      const { groups: formerGroups } = rootState.kbStore;
      const groups = updateArrayObject([...formerGroups], String(id), {
        id: String(id),
        contentId: String(contentId),
        knowledgeId: String(knowledgeId),
        alias,
        type,
        ruleAttributes: RuleAttribute.map((elem) => ({
          id: String(elem.id),
          companyAttribute: elem.CompanyAttribute,
          companyAttributeValue: elem.CompanyAttributeValue,
          operator: elem.operator,
          value: elem.value,
        })),
      });
      commit(`kbStore/${types.UPDATE_KNOWLEDGE_GROUPS}`, {
        groups,
      });
    },

    SOCKET_DELETED_AUTOMATION({ rootState, commit }, args) {
      const { groups: formerGroups } = rootState.kbStore;
      const groups = [...formerGroups].filter(
        (group) => group.id !== String(args.groupId),
      );
      commit(`kbStore/${types.UPDATE_KNOWLEDGE_GROUPS}`, {
        groups,
      });
    },

    /**
     *************************** MAGIC ANSWERS ***************************
     */

    SOCKET_CREATED_MAGIC_ANSWERS({ rootState, commit }, args) {
      const { magicAnswers: formerMagicAnswers } = rootState.kbStore;
      const {
        newMagicAnswer: { id, treeNodeId },
      } = args;
      formerMagicAnswers.push({
        id: String(id),
        treeNodeId: String(treeNodeId),
      });
      commit(`kbStore/${types.UPDATE_MAGIC_ANSWERS}`, {
        magicAnswers: formerMagicAnswers,
      });
    },

    SOCKET_DELETED_MAGIC_ANSWERS({ rootState, commit }, args) {
      const { magicAnswers: formerMagicAnswers } = rootState.kbStore;
      const magicAnswers = [...formerMagicAnswers].filter(
        (group) => group.id !== String(args.magicAnswerId),
      );
      commit(`kbStore/${types.UPDATE_MAGIC_ANSWERS}`, {
        magicAnswers,
      });
    },

    /**
     ***************** COLLABORATORS BEHAVIOR *****************
     */

    SOCKET_COLLABORATOR_FOCUSING({ state, commit }, args) {
      const { collaboratorsFocus, userId } = state;
      const { collaboratorId } = args;
      if (userId !== collaboratorId) {
        let newCollaboratorsFocus = [...collaboratorsFocus];
        if (newCollaboratorsFocus.length) {
          const idx = collaboratorsFocus
            .map((o) => o.collaboratorId)
            .indexOf(collaboratorId);
          if (idx === -1) {
            newCollaboratorsFocus.push(args);
          } else {
            newCollaboratorsFocus[idx] = args;
          }
        } else {
          newCollaboratorsFocus = [args];
        }
        commit(`kbStore/${types.UPDATE_COLLABORATORS_FOCUS}`, {
          collaboratorsFocus: newCollaboratorsFocus,
        });
      }
    },

    SOCKET_COLLABORATOR_STOP_FOCUSING({ state, commit }, args) {
      const { collaboratorsFocus, userId } = state;
      const { collaboratorId } = args;
      if (userId !== collaboratorId) {
        let newCollaboratorsFocus = [...collaboratorsFocus];
        if (newCollaboratorsFocus.length) {
          commit(`kbStore/${types.UPDATE_COLLABORATORS_FOCUS}`, {
            collaboratorsFocus: newCollaboratorsFocus.filter(
              (o) => o.collaboratorId !== collaboratorId,
            ),
          });
        }
      }
    },
  },
};
