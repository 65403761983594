/**
 * CREATE METHODS
 */

export const createAutomation = function(_context, automation) {
  return new Promise((resolve, reject) => {
    const { knowledgeId, treeNodeId } = automation;
    if (!knowledgeId || !treeNodeId) return reject();
    return this.$services.automations
      .create(automation)
      .then(() => {
        return resolve();
      })
      .catch(() => reject());
  });
};

/**
 * UPDATE METHODS
 */

export const updateAutomation = function(_context, newAutomation) {
  return new Promise((resolve, reject) => {
    if (!newAutomation) return reject();
    return this.$services.automations
      .update(newAutomation)
      .then(() => {
        return resolve();
      })
      .catch(() => reject());
  });
};

/**
 * DELETE METHODS
 */

export const deleteAutomation = function(_context, id) {
  return new Promise((resolve, reject) => {
    if (!id) return reject();
    return this.$services.automations
      .delete(id)
      .then(() => {
        return resolve();
      })
      .catch(() => reject());
  });
};
