import gql from 'graphql-tag';

export default {
  bulkUpdateContentAttributes: gql`
    mutation bulkUpdateContentAttributesMutation(
      $contentIds: [ID!]
      $toAdd: [JSON!]
      $toRemove: [JSON!]
      $isGranularAccessRestrictions: Boolean
    ) {
      bulkUpdateContentAttributes(
        contentIds: $contentIds
        toAdd: $toAdd
        toRemove: $toRemove
        isGranularAccessRestrictions: $isGranularAccessRestrictions
      ) {
        id
      }
    }
  `,
  bulkUpdateContentStatus: gql`
    mutation bulkUpdateContentStatusMutation(
      $contentIds: [ID!]
      $key: String!
      $value: Boolean
      $lang: String
    ) {
      bulkUpdateContentStatus(
        contentIds: $contentIds
        key: $key
        value: $value
        lang: $lang
      ) {
        id
        published
        isOutdated
        verificationPolicy {
          verificationDueDate
          verificationRange
          customRoleId
          userId
        }
      }
    }
  `,
  bulkUpdateContentParameters: gql`
    mutation bulkUpdateContentParametersMutation(
      $contentIds: [ID!]
      $knowledge: String!
      $parameters: [JSON!]
    ) {
      bulkUpdateContentParameters(
        contentIds: $contentIds
        knowledge: $knowledge
        parameters: $parameters
      ) {
        id
        published
        isOutdated
      }
    }
  `,
  bulkArchiveContents: gql`
    mutation bulkArchiveContentsMutation($contentIds: [ID!], $softDeleted:Boolean) {
      bulkArchiveContents(contentIds: $contentIds, softDeleted: $softDeleted)
    }
  `,
  bulkRestoreContents: gql`
    mutation bulkRestoreContentsMutation($archiveIds: [ID!]) {
      bulkRestoreContents(archiveIds: $archiveIds)
    }
  `,
  bulkDeleteContents: gql`
    mutation bulkDeleteContentsMutation($archiveIds: [ID!]) {
      bulkDeleteContents(archiveIds: $archiveIds)
    }
  `,
};
