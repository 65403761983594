const knowledgeFiltersProcessing = (knowledgeFilters) => {
  const procFilter = {};
  knowledgeFilters.forEach((filter) => {
    const mainType = filter.type === 'NODE_TYPE' ? 'NODE_TYPE' : 'NODE_STATUS';
    if (Object.keys(procFilter).includes(mainType)) {
      if (mainType === 'NODE_TYPE') {
        procFilter[mainType].push(filter.value);
      } else {
        if (Object.keys(procFilter[mainType]).includes(filter.type)) {
          procFilter[mainType][filter.type].values.push(filter.value);
        } else {
          procFilter[mainType][filter.type] = {
            attr: filter.attr,
            values: [filter.value],
          };
        }
      }
    } else {
      if (mainType === 'NODE_TYPE') {
        procFilter[mainType] = [filter.value];
      } else {
        procFilter[mainType] = {
          [filter.type]: {
            attr: filter.attr,
            values: [filter.value],
          },
        };
      }
    }
  });
  return procFilter;
};

export const filterKnowledge = (knowledgeFilters, treeNodes, nodesContent) => {
  // We first process the knowledgeFilters
  const procFilter = knowledgeFiltersProcessing(knowledgeFilters);

  // Then we apply the consecutive filters, starting by nodes type to reduce the
  // number of search within nodesContent
  return treeNodes
    .filter((tn) => {
      if (procFilter['NODE_TYPE']) {
        return procFilter['NODE_TYPE'].includes(tn.type);
      }
      return true;
    })
    .filter((tn) => {
      if (procFilter['NODE_STATUS']) {
        let content = nodesContent.find((c) => c.id === tn.contentId);
        if (content) {
          return Object.keys(procFilter['NODE_STATUS'])
            .map((c) => {
              let filter = procFilter['NODE_STATUS'][c];
              return filter.values.includes(content[filter.attr]);
            })
            .every((o) => o);
        }
        return false;
      }
      return true;
    });
};
