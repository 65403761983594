import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';
import {
  initGeneralState,
  initContentState,
  initUsageState,
  initAskState,
  initWorkflowState
} from './state';

export default {
  namespaced: true,
  state: {
    ...initGeneralState(),
    ...initContentState(),
    ...initUsageState(),
    ...initAskState(),
    ...initWorkflowState(),
  },
  mutations,
  actions,
  getters,
};
