export default {
  contentThreads: (state) => state.contentThreads,
  displayContentThreads: (state) => state.displayContentThreads,
  filteredContentThreads: (state) => (status) =>
    state.contentThreads.filter((thread) => thread.status === status),
  openContentThread: (_state, getters) => {
    const openThread = getters.filteredContentThreads('OPEN');

    return openThread ? openThread[0] : null;
  },
};
