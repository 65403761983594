import * as getters from './getters';
import mutations from './mutations';
import actions from './actions';
import initState from './state';

export default {
  state: () => initState(),
  getters,
  mutations,
  actions,
};
