export class CustomRoleService {
  constructor(data) {
    this.data = data;
  }

  readCustomRoles() {
    return this.data.readCustomRoles();
  }

  updateCustomRole(id, customRole) {
    return this.data.updateCustomRole(id, customRole);
  }

  createCustomRole(customRole) {
    return this.data.createCustomRole(customRole);
  }

  deleteCustomRole(id) {
    return this.data.deleteCustomRole(id);
  }
}
