/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import * as types from '../mutation-types';
import { updateArrayObject } from 'utils/utils';

export const updateNodeContent = async function({ state }, { id, payload }) {
  return new Promise((resolve, reject) => {
    if (!id) return reject();
    if (Object.keys(payload).includes('outdated')) {
      return this.$services.contents
        .bulkUpdateOutdatedStatus([id], payload.outdated)
        .then(() => {
          return resolve();
        });
    } else if (Object.keys(payload).includes('published')) {
      return this.$services.contents
        .bulkUpdatePublishStatus([id], payload.published)
        .then(() => {
          return resolve();
        });
    }
    const nodesContent = [...state.nodesContent];
    const content = nodesContent.find((c) => c.id === id);
    if (!content) return reject();
    return this.$services.contents
      .update(
        Object.assign({}, content, payload),
        Object.keys(payload).includes('body'),
      )
      .then(() => {
        resolve();
      });
  });
};

export const createVerificationPolicy = async function(
  { state },
  { contentId, userId, customRoleId, verificationRange },
) {
  return new Promise((resolve, reject) => {
    if (!contentId) return reject();
    return this.$services.contents
      .createVerificationPolicy(
        contentId,
        userId,
        customRoleId,
        verificationRange,
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const setContentAsTrusted = async function({ state }, { contentId }) {
  return new Promise((resolve, reject) => {
    if (!contentId) return reject();
    return this.$services.contents
      .setContentAsTrusted(contentId)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const setContentAsUntrusted = async function({ state }, { contentId }) {
  return new Promise((resolve, reject) => {
    if (!contentId) return reject();
    return this.$services.contents
      .setContentAsUntrusted(contentId)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const updateVerificationPolicy = async function(
  { state },
  { id, userId, customRoleId, verificationRange },
) {
  return new Promise((resolve, reject) => {
    if (!id) return reject();

    return this.$services.contents
      .updateVerificationPolicy(id, userId, customRoleId, verificationRange)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const deleteVerificationPolicy = async function(
  { state },
  { contentId },
) {
  return new Promise((resolve, reject) => {
    if (!contentId) return reject();

    return this.$services.contents
      .deleteVerificationPolicy(contentId)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const bulkUpdateNodesContent = async function(
  _context,
  { ids, payload },
) {
  return new Promise((resolve, reject) => {
    if (!ids || !ids.length) return reject();
    if (Object.keys(payload).includes('outdated')) {
      return this.$services.contents
        .bulkUpdateOutdatedStatus(ids, payload.outdated)
        .then((res) => {
          return resolve(res);
        });
    } else if (Object.keys(payload).includes('published')) {
      return this.$services.contents
        .bulkUpdatePublishStatus(ids, payload.published)
        .then((res) => {
          return resolve(res);
        });
    }
  });
};

export const updateOrCreateNodesContent = (
  { state, commit },
  newNodesContent,
) => {
  let nodesContent = [...state.nodesContent];
  newNodesContent.forEach((content) => {
    const localCopy = nodesContent.find((c) => c.id === content.id);
    if (localCopy) {
      nodesContent = updateArrayObject(nodesContent, content.id, content);
    } else {
      nodesContent.push(content);
    }
  });
  commit(types.UPDATE_NODES_CONTENT, {
    nodesContent,
  });
};

export const updateNodesContent = ({ commit }, nodesContent) =>
  commit(types.UPDATE_NODES_CONTENT, {
    nodesContent,
  });
