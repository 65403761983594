export class SettingService {
  constructor(data) {
    this.data = data;
  }

  async getCompanyIpRanges() {
    return this.data.getCompanyIpRanges();
  }

  async setCompanyIpRanges(ipRanges) {
    return this.data.setCompanyIpRanges(ipRanges);
  }

  async deleteCompanyIpRanges() {
    return this.data.deleteCompanyIpRanges();
  }

  async getApiOAuthAppRegistrations() {
    return this.data.getApiOAuthAppRegistrations();
  }

  async createApiOAuthAppRegistration() {
    return this.data.createApiOAuthAppRegistration();
  }

  async updateApiOAuthAppRegistration(id, key, value) {
    return this.data.updateApiOAuthAppRegistration(id, key, value);
  }

  async deleteApiOAuthAppRegistration(id) {
    return this.data.deleteApiOAuthAppRegistration(id);
  }
}
