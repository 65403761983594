import {
  SET_FOCUS_COLLECTION_ID,
  SET_FOCUS_CONTENT_ID,
  ADD_TO_DRAG_AND_DROP,
} from './mutation-types';

export const knowledgeFetchPlugin = (store) => {
  const moduleName = 'knowledgeModule';

  // COLLECTIONS NAVIGATION MUTATIONS
  const collectionsNavigationMutations = [
    `${moduleName}/${SET_FOCUS_COLLECTION_ID}`,
  ];

  // CONTENTS NAVIGATION MUTATIONS
  const contentsNavigationMutations = [`${moduleName}/${SET_FOCUS_CONTENT_ID}`];

  // DRAG AND DROP MUTATIONS
  const dragAndDropMutations = [`${moduleName}/${ADD_TO_DRAG_AND_DROP}`];

  store.subscribe((mutation) => {
    // COLLECTIONS NAVIGATION
    if (collectionsNavigationMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/getFocusCollectionDetails`);
    }

    // CONTENTS NAVIGATION
    if (contentsNavigationMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/getFocusContentDetails`);
      store.dispatch(`${moduleName}/getFocusContentBacklinks`);
    }

    if (dragAndDropMutations.includes(mutation.type)) {
      store.dispatch(`${moduleName}/executeDragAndDrop`);
    }
  });
};
