export const groupContentsByLabels = (contents) => {
  const { labels, others } = contents.reduce(
    (acc, val) => {
      const { labels } = val;
      if (labels.length) {
        labels.forEach((label) => {
          const labelIdx = acc.labels.findIndex((el) => el.id === label.id);
          if (labelIdx != -1) {
            acc.labels[labelIdx].contents.push(val);
          } else {
            acc.labels.push({
              ...label,
              label: label.labelName,
              value: label.id,
              contents: [val],
            });
          }
        });
      } else {
        acc.others.push(val);
      }
      return acc;
    },
    { labels: [], others: [] },
  );
  return [
    ...labels,
    others.length
      ? {
          id: '0',
          labelName: 'Autres',
          label: 'Autres',
          value: '0',
          icon: 'cabinet-filing',
          color: '#0081F9',
          contents: others,
        }
      : null,
  ].filter((el) => !!el);
};

export const processCollections = (collections, skipOrdering = false) => {
  if (collections && collections.length) {
    const filteredCollections = collections.filter(
      (rc) => rc.resolutionsCount > 0,
    );
    if (skipOrdering) return filteredCollections;
    return filteredCollections.sort(
      (a, b) => b.resolutionsCount - a.resolutionsCount,
    );
  }
  return [];
};

const isSameProduct = (route, product) => {
  const routeName = route.name;
  const { family, brand } = route.query;
  const { productId, externalId } = route.params;
  const { id: pId, external_id: pExternalId } = product;
  const { family: pFamily, brand: pBrand } = product.parameters;

  try {
    switch (routeName) {
      case 'web-product-partial':
        return family === pFamily && brand === pBrand;
      case 'web-product-external':
        return pExternalId === externalId;
      case 'web-product':
        return pId === productId;
      default:
        return false;
    }
  } catch (err) {
    return false;
  }
};

export const shouldLoadProduct = (
  route,
  product,
  lastProductLoadingTimestamp,
) => {
  const RELOAD_DELAY = 60 * 60 * 1000; // 1 hour
  try {
    // Has any product been loaded yet ?
    if (!lastProductLoadingTimestamp) return true;

    // Was last updated product non-empty ?
    if (!product) return true;

    // Is the product we try to load different product from the previous loading ?
    if (!isSameProduct(route, product)) return true;

    // Was the last product loading more than hour ago ?
    if (lastProductLoadingTimestamp + RELOAD_DELAY < Date.now()) return true;

    return false;
  } catch (e) {
    return true;
  }
};
