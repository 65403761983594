export class UserService {
  constructor(data) {
    this.data = data;
  }

  /**
   * CREATE METHODS
   */

  register({ givenName, familyName, email, password, company, onlyNotify }) {
    const formattedGivenName = UserService.formatGivenName(givenName);
    const formattedFamilyName = UserService.formatFamilyName(familyName);

    return this.data.register({
      username: `${formattedGivenName} ${formattedFamilyName}`.trim(),
      givenName: formattedGivenName,
      familyName: formattedFamilyName,
      email,
      password,
      company,
      onlyNotify,
    });
  }

  invite(users, { currentPage, totalPageCount, filters, sort, search }) {
    return this.data.createUsersInvitation(
      users,
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
    );
  }

  renewInvitations(
    invitedUsers,
    { currentPage, totalPageCount, filters, sort, search },
    skip,
  ) {
    return this.data.renewUserInvitations(
      invitedUsers,
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
      skip,
    );
  }

  createUserFromInvitation(args) {
    return this.data.createUserFromInvitation(args);
  }

  verifyToken({ token, tokenType }) {
    return this.data.verifyToken({ token, tokenType });
  }

  sendFeedback(feedback) {
    return this.data.sendFeedback(feedback);
  }

  addFavorite(entity) {
    return this.data.addFavorite(entity);
  }

  removeFavorite(entity) {
    return this.data.removeFavorite(entity);
  }

  /**
   * READ METHODS
   */

  checkValidity(email) {
    return this.data.checkUserValidity(email);
  }

  syncWithHelpdesk(
    userId,
    helpdesk,
    { currentPage, totalPageCount, filters, sort, search },
  ) {
    return this.data.retrieveUserHelpdeskId(
      userId,
      helpdesk,
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
    );
  }

  getUserInfos() {
    return this.data.getUserInfos();
  }

  async getUsersWithPermissions(permissions, knowledgeId = null) {
    return this.data.getUsersWithPermissions(permissions, knowledgeId);
  }
  async searchEditorUsers(page, pageSize, filters, sort, search, permissions) {
    return this.data.searchEditorUsers(
      page,
      pageSize,
      filters,
      sort,
      search,
      permissions,
    );
  }

  async get(id) {
    return this.data.getUserById(id);
  }

  async getByIds(ids) {
    return this.data.getUsersByIds(ids);
  }

  async getUserOrDeletedUser(id) {
    return this.data.getUserOrDeletedUserById(id);
  }

  async me() {
    return this.data.getMe();
  }
  async getUserFromAccessToken() {
    return this.data.getUserFromAccessToken();
  }

  /**
   * UPDATE METHODS
   */

  updateManyGroups(
    userIds,
    groupIds,
    { currentPage, totalPageCount, filters, sort, search },
    skip,
  ) {
    return this.data.updateManyUsersGroup(
      userIds,
      groupIds,
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
      skip,
    );
  }

  updateManyRoles(
    userIds,
    roleIds,
    { currentPage, totalPageCount, filters, sort, search },
    skip,
  ) {
    return this.data.updateManyUsersRole(
      userIds,
      roleIds,
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
      skip,
    );
  }

  updateManyLabels(
    userIds,
    companyUserLabelIds,
    { currentPage, totalPageCount, filters, sort, search },
    skip,
  ) {
    return this.data.updateManyUsersLabels(
      userIds,
      companyUserLabelIds,
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
      skip,
    );
  }

  updateUser(user) {
    const formattedUser = {
      id: user.id,
      givenName: UserService.formatGivenName(user.givenName),
      familyName: UserService.formatFamilyName(user.familyName),
      labelIds: user.labels,
      groupIds: [user.group],
      roleIds: user.role,
    };
    return this.data.updateUser(formattedUser);
  }

  resetPasswordFromAdmin(userId) {
    return this.data.resetPasswordFromAdmin(userId);
  }

  resetPassword(email) {
    return this.data.resetPassword(email);
  }

  updateSettings({ familyName, givenName }) {
    return this.data.updateUserSettings({
      familyName,
      givenName,
    });
  }

  /**
   * DELETE METHODS
   */
  deleteMany(
    userIds,
    { currentPage, totalPageCount, filters, sort, search },
    skip,
  ) {
    return this.data.deleteManyUsers(
      userIds,
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
      skip,
    );
  }

  /**
   * HELPERS
   */
  static resolveUsername(firstName, lastName) {
    const pascalCaseLikeFirstName = UserService.formatGivenName(firstName);

    const upperCaseLastName = UserService.formatFamilyName(lastName);

    return `${pascalCaseLikeFirstName} ${upperCaseLastName}`.trim();
  }

  static formatGivenName(givenName) {
    const pascalCaseLikeFirstName = (givenName || '')
      .toLowerCase()
      .replace(/(^\w|(?<=\s|-)\w)/g, (match) => match.toUpperCase())
      .replace(/(?<=\w)(\w+)/g, (match) => match.toLowerCase())
      .trim();

    return pascalCaseLikeFirstName;
  }

  static formatFamilyName(familyName) {
    const upperCaseLastName = (familyName || '').toUpperCase().trim();

    return upperCaseLastName;
  }
}
