export default () => ({
  rootId: null,
  roots: null,
  languages: null,
  defaultLanguage: null,
  focusKnowledge: null,
  focusPath: [],
  focusTree: {},
  groups: [],
  magicAnswers: [],
  embeddableExtensionClickEvent: null,
  checkedTreeNodes: [],
  treeNodes: [],
  nodesContent: [],
  knowledgeFilters: [],
  showKnowledgeFilters: false,
  collaboratorsFocus: [],
  products: {},
  parameters: [],
  filters: [],
  diagPath: [],
  casePath: [],
});
