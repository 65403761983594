import { ApolloClient } from 'apollo-boost';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import store from '../store';
import router from '../router';

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_BACK_URL + '/graphql',
});

const authMidlleware = new ApolloLink((operation, forward) => {
  const accessToken = store.state.accessToken;
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });
  return forward(operation);
});
const link = ApolloLink.from([
  onError(({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.map((err) => {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            alert('Session expired');
            store.dispatch('logout');
            router.push('/login');
        }
      });
  }),
  authMidlleware,
  httpLink,
]);
export default new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: {
      TaskAssignee: ['SafeUser', 'CustomRole'],
      DashboardFilter: ['GenericFilter', 'DateRangeFilter'],
    },
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});
