export default () => ({
  /**
   * ARTICLE TO GUIDE
   */
  //Array of { articleId: String, id: String, status: String, guideId: String }
  articleToGuideProcesses: [],
  displayAskInSearch: true,

  /**
   * ASK IN SEARCH
   */
  lastAskQuery: {},
});
