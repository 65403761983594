<template>
  <div class="delete-modal">
    <modal
      :show-close="!localDisplay"
      class="delete-case-modal"
      :show.sync="localDisplay"
    >
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11 text-center">
          <modal-header :style-name="modalHeaderComputedStyle" class="mb-3">
            {{ $t('concepts.edit-concept-modal.title') }}
          </modal-header>
        </div>
      </div>
      <div slot="footer" class="col-12 d-flex justify-content-end">
        <base-button
          class="ml-2"
          type="secondary"
          size="sm"
          @click="userChoice(false)"
          >{{ $t('concepts.edit-concept-modal.yes') }}</base-button
        >
        <base-button
          class="mr-2"
          type="danger"
          size="sm"
          @click="userChoice(true)"
        >
          <span
            v-if="false"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>{{ $t('concepts.edit-concept-modal.no') }}</span>
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import ModalHeader from '@/components/Modals/ModalHeader';
import Modal from '@/components/Modal';

export default {
  name: 'EditConceptModal',
  props: {
    conceptId: String,
    display: Boolean,
  },
  data() {
    return {
      localDisplay: this.display,
    };
  },
  components: {
    Modal,
    ModalHeader,
  },
  computed: {
    modalHeaderComputedStyle() {
      return { backgroundColor: '#fd00440D' };
    },
  },
  methods: {
    userChoice(choice) {
      this.$emit('choice', choice);
      this.localDisplay = false;
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped></style>
