import * as types from '../mutation-types';

export const downloadTable = async function ({ commit, getters }, page) {
  let params;
  let downloadAction;
  switch (page) {
    case 'content': {
      params = getters.queryParamsForContentTable;
      downloadAction = 'getContentTableFile';
      break;
    }
    case 'usage': {
      params = getters.queryParamsForUsageTable;
      downloadAction = 'getUsageTableFile';
      break;
    }
    default:
      return;
  }

  try {
    commit(types.SET_TABLE_FILE_IS_LOADING, true);
    commit(
      types.SET_TABLE_FILE,
      await this.$services.contentAnalytics[downloadAction](params),
    );
    commit(types.SET_TABLE_FILE_IS_LOADING, false);
    return;
  } catch (error) {
    commit(types.SET_TABLE_FILE_IS_LOADING, false);
    commit(types.SET_TABLE_FILE, null);
    return error;
  }
};

export const resetTableFile = function ({ commit }) {
  commit(types.SET_TABLE_FILE, null);
  commit(types.SET_TABLE_FILE_IS_LOADING, false);
};

export const getExports = async function ({ commit, getters }) {
  try {
    const exports = await this.$services.downloadAnalytics.getExports(
      getters.queryParamsFromFilters,
    );
    commit(types.SET_EXPORTS, exports);
  } catch (error) {
    return error;
  }
};
