import data from './hierarchy.data.raw.js';

export class HierarchyServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * FILTER QUERIES
   */

  async getCompanyKnowledges() {
    return this.client
      .query({
        query: data.getCompanyKnowledges,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.knowledges)
      .catch((error) => error);
  }
  async getSettingsKnowledges() {
    return this.client
      .query({
        query: data.getSettingsKnowledges,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.knowledges)
      .catch((error) => error);
  }

  async getHierarchies() {
    return this.client
      .query({
        query: data.getHierarchies,
      })
      .then((response) => response.data.hierarchies)
      .catch((error) => error);
  }

  async getHierarchiesByValue(values) {
    return this.client
      .query({
        query: data.getHierarchiesByValue,
        variables: { values },
      })
      .then((response) => response.data.getHierarchiesByValue)
      .catch((error) => error);
  }

  async getHierarchiesAggregatedOptions(values) {
    return this.client
      .query({
        query: data.getHierarchiesAggregatedOptions,
        variables: { values },
      })
      .then((response) => response.data.getHierarchiesAggregatedOptions)
      .catch((error) => error);
  }

  async getHierarchiesAccessRestrictions(groupId) {
    return this.client
      .query({
        query: data.getHierarchiesAccessRestrictions,
        variables: { groupId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getHierarchiesAccessRestrictions)
      .catch((error) => error);
  }

  async getKnowledgeDetails() {
    return this.client
      .query({
        query: data.getKnowledgeDetails,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.knowledgesDetails)
      .catch((error) => error);
  }

  async getPinnedContents(hierarchyId) {
    return this.client
      .query({
        query: data.getPinnedContents,
        variables: { hierarchyId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getPinnedContents)
      .catch((error) => error);
  }

  // UPDATE METHODS

  async updateLabel(id, newLabel, lang) {
    return this.client
      .mutate({
        mutation: data.updateKnowledgeLabelMutation,
        variables: { id, newLabel, lang },
      })
      .then((response) => response.data.updateHierarchyLabel)
      .catch((error) => error);
  }

  async updateIsDefault(id) {
    return this.client
      .mutate({
        mutation: data.updateKnowledgeIsDefaultMutation,
        variables: { id },
      })
      .then((response) => response.data.updateHierarchyIsDefault)
      .catch((error) => error);
  }

  async updateIsDeleted(id) {
    return this.client
      .mutate({
        mutation: data.updateKnowledgeIsDeletedMutation,
        variables: { id },
      })
      .then((response) => response.data.updateHierarchyIsDeleted)
      .catch((error) => error);
  }

  async updateIcon(id, iconType, value, color) {
    return this.client
      .mutate({
        mutation: data.updateKnowledgeIconMutation,
        variables: { id, iconType, value, color },
      })
      .then((response) => response.data.updateHierarchyIcon)
      .catch((error) => error);
  }

  async updateIsPublic(id, isPublic) {
    return this.client
      .mutate({
        mutation: data.updateKnowledgeIsPublicMutation,
        variables: { id, isPublic },
      })
      .then((response) => response.data.updateHierarchyIcon)
      .catch((error) => error);
  }

  async addHierarchy(label, lang, groups) {
    return this.client
      .mutate({
        mutation: data.addHierarchyMutation,
        variables: { label, lang, groups },
      })
      .then((response) => response.data.addHierarchy)
      .catch((error) => error);
  }

  async updateHierarchyChildrenOrder(id, childrenOrder) {
    return this.client
      .mutate({
        mutation: data.updateHierarchyChildrenOrder,
        variables: { id, childrenOrder },
      })
      .then((response) => response.data.updateHierarchyChildrenOrder)
      .catch((error) => error);
  }

  async updateHierarchyPreferences(id, preferences) {
    return this.client
      .mutate({
        mutation: data.updateHierarchyPreferences,
        variables: { id, preferences },
      })
      .then((response) => response.data.updateHierarchyPreferences)
      .catch((error) => error);
  }

  async updatePinnedContents(id, pinnedContents) {
    return this.client
      .mutate({
        mutation: data.updatePinnedContents,
        variables: { id, pinnedContents },
      })
      .then((response) => response.data.updatePinnedContents)
      .catch((error) => error);
  }

  async addLanguage(id, lang) {
    return this.client
      .mutate({
        mutation: data.addLanguage,
        variables: { id, lang },
      })
      .then((response) => response.data.addLanguage)
      .catch((error) => error);
  }

  async setMultilingual(id, lang) {
    return this.client
      .mutate({
        mutation: data.setMultilingual,
        variables: { id, lang },
      })
      .then((response) => response.data.setMultilingual)
      .catch((error) => error);
  }

  async updatePublicDataMetaTranslations(id, newMeta) {
    return this.client
      .mutate({
        mutation: data.updatePublicDataMetaTranslations,
        variables: { id, newMeta },
      })
      .then((response) => response.data.updatePublicDataMetaTranslations)
      .catch((error) => error);
  }
}
