export class TaskService {
  constructor(data) {
    this.data = data;
  }

  async getCompanyTaskStatus(filters) {
    return this.data.getCompanyTaskStatus(filters);
  }

  async getCompanyTasks({ statusId, filters, page }) {
    return this.data.getCompanyTasks({ statusId, filters, page });
  }

  async getCompanyTaskById(id, skipModalFields = true) {
    return this.data.getCompanyTaskById(id, skipModalFields);
  }

  async getBacklogTasksCount() {
    return this.data.getBacklogTasksCount();
  }

  async setCompanyTaskStatus(id, newCompanyTaskStatusId) {
    return this.data.setCompanyTaskStatus(id, newCompanyTaskStatusId);
  }

  async setAssignee(taskId, assigneeId) {
    return this.data.setAssignee(taskId, assigneeId);
  }

  async upvoteTask(taskId) {
    return this.data.upvoteTask(taskId);
  }

  async removeUpvoteTask(taskId) {
    return this.data.removeUpvoteTask(taskId);
  }

  async bulkSetIsArchive(ids, isArchive) {
    return this.data.bulkSetIsArchive(ids, isArchive);
  }

  async newTaskComment(
    taskId,
    comment,
    attachments,
    mentions,
    isInternal = false,
  ) {
    return this.data.newTaskComment(
      taskId,
      comment,
      attachments,
      mentions,
      isInternal,
    );
  }
  async deleteTaskComment(id) {
    return this.data.deleteTaskComment(id);
  }
  async updateTaskComment({ id, comment, attachments, mentions }) {
    return this.data.updateTaskComment({ id, comment, attachments, mentions });
  }

  async setTaskRating(taskId, rating) {
    return this.data.setTaskRating(taskId, rating);
  }

  async deleteTaskRating(taskId) {
    return this.data.deleteTaskRating(taskId);
  }

  async getSettingsTaskViews(page = 1) {
    return this.data.getSettingsTaskViews(page);
  }

  async getTaskViews() {
    return this.data.getTaskViews();
  }

  async getTaskView(id) {
    return this.data.getTaskView(id);
  }

  async createTaskView({ name, filters, description }) {
    return this.data.createTaskView({ name, filters, description });
  }

  async updateTaskView({ id, name, filters, description }) {
    return this.data.updateTaskView({ id, name, filters, description });
  }

  async deleteTaskView(id) {
    return this.data.deleteTaskView(id);
  }
}
