export class CompanySynonymService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async getAll() {
    return this.data.getCompanySynonyms();
  }

  /**
   * CREATE METHODS
   */
  async create(synonyms) {
    return this.data.createCompanySynonyms(synonyms);
  }

  /**
   * UPDATE METHODS
   */

  async updateMany(ids, aSynonym) {
    return this.data.updateCompanySynonyms(ids, aSynonym);
  }

  /**
   * DELETE METHODS
   */
  async delete(ids) {
    return this.data.deleteCompanySynonyms(ids);
  }
}
