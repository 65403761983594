export default {
  companyFavoriteLoading: (state) => state.companyFavoriteLoading,
  pluginEntities: (state) => (type) => state.pluginEntities[type],
  integrationStatus:
    (state) =>
    (type, integrationName, index = 0) => {
      if (!type || !integrationName || !state.pluginEntities[type][index]) {
        return false;
      }

      const integration = state.pluginEntities[type][index].integrations.find(
        (integration) => integration.name === integrationName,
      );
      return integration ? integration.isActive : false;
    },
};
