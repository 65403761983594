import gql from 'graphql-tag';

export default {
  getNotificationById: gql`
    query getNotificationById($id: ID!) {
      getNotificationById(id: $id) {
        id
        user {
          id
          username
        }
        isRead
        type
        notificationFamily
        feedback {
          id
          report
          thumbsup
          source
          upvoters {
            id
            username
          }
          author {
            id
            username
            groups {
              id
              name
            }
          }
          entityId
          entityType
          path
        }
        createdAt
      }
    }
  `,

  getNotifications: gql`
    query getNotifications {
      getNotifications {
        id
        user {
          id
          username
        }
        isRead
        type
        notificationFamily
        feedback {
          id
          report
          thumbsup
          source
          upvoters {
            id
            username
          }
          author {
            id
            username
            groups {
              id
              name
            }
          }
          entityId
          entityType
          path
        }
        createdAt
      }
    }
  `,
  readNotification: gql`
    mutation readNotificationMutation($id: ID!) {
      readNotification(id: $id) {
        id
        user {
          id
          username
        }
        isRead
        type
        notificationFamily
        feedback {
          id
          report
          thumbsup
          source
          author {
            id
            username
            groups {
              id
              name
            }
          }
          upvoters {
            id
            username
          }
          entityId
          entityType
          path
        }
        createdAt
      }
    }
  `,

  removeNotification: gql`
    mutation removeNotificationMutation($id: ID!) {
      removeNotification(id: $id)
    }
  `,

  addThumbsUp: gql`
    mutation addThumbsUpMutation($id: ID!) {
      addThumbsUp(id: $id) {
        id
        report
        thumbsup
        source
        author {
          id
          username
          groups {
            id
            name
          }
        }
        upvoters {
          id
          username
        }
        entityId
        entityType
        path
      }
    }
  `,

  removeThumbsUp: gql`
    mutation removeThumbsUpMutation($id: ID!) {
      removeThumbsUp(id: $id) {
        id
        report
        thumbsup
        source
        author {
          id
          username
          groups {
            id
            name
          }
        }
        upvoters {
          id
          username
        }
        entityId
        entityType
        path
      }
    }
  `,
};
