import * as types from './mutation-types';
import cloneDeep from 'lodash.clonedeep';

const updateThreads = (newThread, threads) => {
  if (!threads.length) return [newThread];

  const index = threads.findIndex((thread) => thread.id === newThread.id);
  if (index === -1) return [...threads, newThread];

  const newThreads = cloneDeep(threads);
  newThreads[index] = newThread;

  return newThreads;
};

export default {
  async getAllContentThread({ commit, rootGetters }) {
    try {
      const lang =
        rootGetters['knowledgeModule/editingLanguage'] ||
        rootGetters['knowledgeModule/focusKnowledgeDefaultLanguage'];

      const { id, type } = rootGetters['knowledgeModule/focusContent'];

      const contentThreads = await this.$services.contentThreads.getAll({
        entityId: id,
        entityType: type,
        lang,
      });

      if (!contentThreads) return false;

      commit(types.SET_CONTENT_THREADS, contentThreads);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getContentThreadById(
    { commit, state },
    { entityId, entityType, threadId, lang },
  ) {
    try {
      const contentThread = await this.$services.contentThreads.get({
        threadId,
        entityId,
        entityType,
        lang,
      });

      if (!contentThread) return false;

      const { contentThreads } = state;
      const newContentThreads = updateThreads(contentThread, contentThreads);
      commit(types.SET_CONTENT_THREADS, newContentThreads);

      return true;
    } catch (error) {
      return false;
    }
  },
  async createContentThread(
    { commit, rootGetters, state },
    { body, mentions, attachments },
  ) {
    try {
      const lang =
        rootGetters['knowledgeModule/editingLanguage'] ||
        rootGetters['knowledgeModule/focusKnowledgeDefaultLanguage'];

      const { id, type } = rootGetters['knowledgeModule/focusContent'];

      const contributors =
        rootGetters['knowledgeModule/focusContentContributors'];

      const contentThread = await this.$services.contentThreads.create({
        entityId: id,
        entityType: type,
        lang,
        body,
        mentions,
        attachments,
        contributors,
      });

      if (!contentThread) return false;

      const { id: messageId } = contentThread.messages.at(-1);

      this.$services.events.thread.createMessage(
        {
          message: {
            id: messageId,
            threadId: contentThread.id,
          },
        },
        this.$router.currentRoute.fullPath,
      );

      const { contentThreads } = state;

      commit(types.SET_CONTENT_THREADS, [...contentThreads, contentThread]);

      return true;
    } catch (error) {
      return false;
    }
  },
  async createContentThreadMessage(
    { commit, state, rootGetters },
    { threadId, body, mentions, attachments },
  ) {
    try {
      const contributors =
        rootGetters['knowledgeModule/focusContentContributors'];

      const contentThread = await this.$services.contentThreads.createMessage({
        threadId,
        body,
        mentions,
        attachments,
        contributors,
      });

      if (!contentThread) return false;

      const { id: messageId } = contentThread.messages.at(-1);

      this.$services.events.thread.createMessage(
        {
          message: {
            id: messageId,
            threadId,
          },
        },
        this.$router.currentRoute.fullPath,
      );

      const { contentThreads } = state;
      const newContentThreads = updateThreads(contentThread, contentThreads);
      commit(types.SET_CONTENT_THREADS, newContentThreads);

      return true;
    } catch (error) {
      return false;
    }
  },
  async editContentThreadMessage(
    { commit, state, rootGetters },
    { threadId, messageId, body, mentions, attachments },
  ) {
    try {
      const contributors =
        rootGetters['knowledgeModule/focusContentContributors'];

      const contentThread = await this.$services.contentThreads.editMessage({
        threadId,
        messageId,
        body,
        mentions,
        attachments,
        contributors,
      });

      if (!contentThread) return false;

      this.$services.events.thread.editMessage(
        {
          message: {
            id: messageId,
            threadId,
          },
        },
        this.$router.currentRoute.fullPath,
      );

      const { contentThreads } = state;
      const newContentThreads = updateThreads(contentThread, contentThreads);
      commit(types.SET_CONTENT_THREADS, newContentThreads);

      return true;
    } catch (error) {
      return false;
    }
  },
  async deleteContentThreadMessage(
    { commit, state, rootGetters },
    { threadId, messageId },
  ) {
    try {
      const contributors =
        rootGetters['knowledgeModule/focusContentContributors'];

      const contentThread = await this.$services.contentThreads.deleteMessage({
        threadId,
        messageId,
        contributors,
      });

      if (!contentThread) return false;

      this.$services.events.thread.deleteMessage(
        {
          message: {
            id: messageId,
            threadId,
          },
        },
        this.$router.currentRoute.fullPath,
      );

      const { contentThreads } = state;
      const newContentThreads = updateThreads(contentThread, contentThreads);
      commit(types.SET_CONTENT_THREADS, newContentThreads);

      return true;
    } catch (error) {
      return false;
    }
  },
  async voteContentThreadMessage(
    { commit, state, rootState },
    { threadId, messageId },
  ) {
    try {
      const contentThread = await this.$services.contentThreads.voteMessage({
        threadId,
        messageId,
      });

      if (!contentThread) return false;

      const { userId } = rootState;
      const { votes } = contentThread.messages.find((m) => m.id === messageId);

      this.$services.events.thread.voteMessage(
        {
          message: {
            id: messageId,
            threadId,
            vote: votes.includes(String(userId)) ? 'ADDED' : 'REMOVED',
          },
        },
        this.$router.currentRoute.fullPath,
      );

      const { contentThreads } = state;
      const newContentThreads = updateThreads(contentThread, contentThreads);
      commit(types.SET_CONTENT_THREADS, newContentThreads);

      return true;
    } catch (error) {
      return false;
    }
  },
  async updateContentThreadStatus(
    { commit, state, rootGetters },
    { threadId, status },
  ) {
    try {
      const contributors =
        rootGetters['knowledgeModule/focusContentContributors'];

      const contentThread = await this.$services.contentThreads.updateStatus({
        threadId,
        status,
        contributors,
      });

      if (!contentThread) return false;

      this.$services.events.thread.updateStatus(
        {
          thread: {
            id: threadId,
            status,
          },
        },
        this.$router.currentRoute.fullPath,
      );

      const { contentThreads } = state;
      const newContentThreads = updateThreads(contentThread, contentThreads);
      commit(types.SET_CONTENT_THREADS, newContentThreads);

      return true;
    } catch (error) {
      return false;
    }
  },
  async updateDisplayContentThread({ commit, state }, entityId) {
    try {
      const { displayContentThreads } = state;

      if (displayContentThreads.includes(entityId)) {
        displayContentThreads.splice(
          displayContentThreads.indexOf(entityId),
          1,
        );
      } else {
        displayContentThreads.push(entityId);
      }

      commit(types.SET_DISPLAY_CONTENT_THREADS, displayContentThreads);

      return true;
    } catch (error) {
      return false;
    }
  },
};
