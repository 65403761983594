<template>
  <span class="time-ago" :style="customStyle">
    {{ displayDate }}
  </span>
</template>

<script>
export default {
  name: 'time-ago',
  props: {
    date: {
      type: String,
      required: true,
    },
    reset: {
      type: Boolean,
    },
    customStyle: {
      type: Object,
    },
  },
  computed: {
    displayDate() {
      this.reset;
      return this.timeSince(this.date);
    },
  },
  methods: {
    dateParser(date) {
      return new Date(Date.parse(date)).toLocaleString().slice(0, 10);
    },
    timeSince(date) {
      if (!this.isIsoDate(date)) {
        date = new Date(parseInt(date, 10)).toISOString();
      }
      const seconds = Math.floor((Date.now() - Date.parse(date)) / 1000);
      const intervals = [
        { label: 'h', seconds: 3600 },
        { label: 'min', seconds: 60 },
        { label: 's', seconds: 1 },
      ];
      if (seconds < 2) {
        return this.$t('time-ago.now');
      }
      if (seconds < 86400) {
        const interval = intervals.find((i) => i.seconds < seconds);
        const count = Math.floor(seconds / interval.seconds);
        const time = `${count}${interval.label}`;
        return this.$t('time-ago.time-ago', { time });
      }
      if (seconds < 86400 * 2) {
        return this.$t('time-ago.yesterday');
      }
      return this.dateParser(date);
    },
    isIsoDate(str) {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
      var d = new Date(str);
      return d.toISOString() === str;
    },
  },
};
</script>

<style lang="scss" scoped>
.time-ago {
  color: $grey-6-mayday;
  font-size: 10px;
  font-weight: 500;
}
</style>
