export class ExportServiceData {
  constructor(axiosClient) {
    this.axiosClient = axiosClient;
  }

  async putKBOverviewExport({ knowledgeId }) {
    return await this.axiosClient.put(`/knowledge/export/${knowledgeId}`);
  }

  async getKBOverviewExport({ knowledgeId }) {
    return await this.axiosClient.get(`/knowledge/export/${knowledgeId}`);
  }

  async putKBContentsExport({ knowledgeId }) {
    return await this.axiosClient.put(`/contents/export/${knowledgeId}`);
  }

  async getKBContentsExport({ knowledgeId }) {
    return await this.axiosClient.get(`/contents/export/${knowledgeId}`);
  }
}
