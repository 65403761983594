import gql from 'graphql-tag';

export default {
  createCompanyUserLabelCategory: gql`
    mutation createCompanyUserLabelCategory($name: String!, $icon: String!, $color: String!) {
      createCompanyUserLabelCategory: createCompanyUserLabelCategory(
        name: $name
        icon: $icon
        color: $color
      ) {
        id
        name
        icon
        color
        companyUserLabels {
          id
          name
          companyUserLabelCategoryId
        }
      }
    }
  `,

  findAllCategories: gql`
    query findAllCategories {
      companyUserLabelCategories {
        id
        name
        icon
        color
        companyUserLabels {
          id
          name
          companyUserLabelCategoryId
        }
      }
    }
  `,

  deleteCompanyUserLabelCategory: gql`
    mutation deleteCompanyUserLabelCategory($id: ID!) {
      deleteCompanyUserLabelCategory: deleteCompanyUserLabelCategory(id: $id)
    }
  `,

  updateCompanyUserLabelCategory: gql`
    mutation updateCompanyUserLabelCategory(
      $id: ID!
      $name: String!
      $icon: String!
      $color: String!
    ) {
      updateCompanyUserLabelCategory: updateCompanyUserLabelCategory(
        id: $id
        name: $name
        icon: $icon
        color: $color
      ) {
        id
        name
        icon
        color
        companyUserLabels {
          id
          name
          companyUserLabelCategoryId
        }
      }
    }
  `,

  createCompanyUserLabel: gql`
    mutation createCompanyUserLabel(
      $companyUserLabelCategoryId: ID!
      $name: String!
    ) {
      createCompanyUserLabel: createCompanyUserLabel(
        companyUserLabelCategoryId: $companyUserLabelCategoryId
        name: $name
      ) {
        id
        name
        companyUserLabelCategoryId
      }
    }
  `,

  updateCompanyUserLabel: gql`
    mutation updateCompanyUserLabel(
      $id: ID!
      $companyUserLabelCategoryId: ID!
      $name: String!
    ) {
      updateCompanyUserLabel: updateCompanyUserLabel(
        id: $id
        companyUserLabelCategoryId: $companyUserLabelCategoryId
        name: $name
      ) {
        id
        name
        companyUserLabelCategoryId
      }
    }
  `,

  deleteCompanyUserLabel: gql`
    mutation deleteCompanyUserLabel($id: ID!) {
      deleteCompanyUserLabel: deleteCompanyUserLabel(id: $id)
    }
  `,
};
