export class KnowledgeService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  async getOneById(id) {
    return this.data.getOneById(id);
  }
  async getDefault() {
    return this.data.getDefault();
  }
  async getCollections(id) {
    return this.data.getCollections(id);
  }

  /**
   * UPDATE METHODS
   */

  async updateDefault(knowledgeId) {
    return this.data.updateDefaultKnowledge(knowledgeId);
  }

  /**
   * DELETE METHODS
   */

  async deleteById(knowledgeId) {
    return this.data.deleteKnowledge(knowledgeId);
  }
}
