import * as types from './mutation-types';
import initState from './state';

export default {
  [types.LOGOUT](state, resetBacklog = true) {
    Object.assign(state, {
      ...initState(),
      backlogTasksCount: resetBacklog ? 0 : state.backlogTasksCount,
    });
  },

  [types.SET_COMPANY_TASK_STATUS_AND_FIRST_TASKS](state, payload) {
    if (!payload) return;
    state.companyTasksLoading = false;
    state.companyTaskStatus = payload ? payload.statuses : [];
    state.companyTasks = payload ? payload.tasks : [];
  },

  [types.SET_COMPANY_TASKS](state, payload) {
    const { page, results, companyTasksByStatusIndex } = payload;
    if (companyTasksByStatusIndex !== -1) {
      state.companyTasks[companyTasksByStatusIndex].value.results.push(
        ...results,
      );
      state.companyTasks[companyTasksByStatusIndex].value.page = page;
    }
  },

  [types.SET_FILTERS](state, filters) {
    state.filters = filters;
  },

  [types.EMPTY_FILTERS](state) {
    state.filters = [];
  },

  [types.SET_COMPANY_TASK_BY_ID](state, payload) {
    const isArchive = new URLSearchParams(payload).get('archived');
    const focusTaskCopy = state.focusTask ? { ...state.focusTask } : null;
    if (focusTaskCopy && focusTaskCopy.id === payload.id) {
      state.focusTask = payload;
    }
    const companyTasksCopy = [...state.companyTasks];

    // Get old task data
    const oldTask = {
      statusIndex: null,
      taskIndex: null,
      task: null,
    };
    companyTasksCopy.forEach((status, index) => {
      const taskIndex = status.value.results.findIndex(
        (task) => task.id === payload.id,
      );
      if (taskIndex >= 0) {
        oldTask.statusIndex = index;
        oldTask.taskIndex = taskIndex;
        oldTask.task = status.value.results[taskIndex];
      }
    });

    // Update task data
    const statusIndex = companyTasksCopy.findIndex(
      (status) => status.key === payload.companyTaskStatusId,
    );
    if (statusIndex < 0) return;
    const taskIndex = companyTasksCopy[statusIndex].value.results.findIndex(
      (task) => task.id === payload.id,
    );
    if (taskIndex < 0) {
      companyTasksCopy[statusIndex].value.results.push(payload);
      companyTasksCopy[statusIndex].value.total += 1;
    } else {
      companyTasksCopy[statusIndex].value.results[taskIndex] = payload;
    }

    // Remove old task if status changed
    if (
      (oldTask.statusIndex !== null && oldTask.statusIndex !== statusIndex) ||
      !!isArchive !== !!payload.isArchive
    ) {
      companyTasksCopy[oldTask.statusIndex].value.results.splice(
        oldTask.taskIndex,
        1,
      );
      companyTasksCopy[oldTask.statusIndex].value.total -= 1;
    }

    state.companyTasks = companyTasksCopy;
  },

  [types.SET_USERS_WITH_PERMISSIONS](state, payload) {
    state.assignableUsers = payload;
  },

  [types.SET_COMPANY_TASK_STATUS](state, payload) {
    const { taskId, companyTaskStatusId, oldCompanyTaskStatusId } = payload;
    const { companyTaskStatus } = state;
    const companyTasks = [...state.companyTasks];
    const oldStatus = companyTaskStatus.find(
      (el) => el.id === oldCompanyTaskStatusId,
    );
    const newStatus = companyTaskStatus.find(
      (el) => el.id === companyTaskStatusId,
    );
    const oldTasks = companyTasks.find(
      (el) => el.key === oldCompanyTaskStatusId,
    ).value;
    const newTasks = companyTasks.find(
      (el) => el.key === companyTaskStatusId,
    ).value;

    const taskIndex = oldTasks.results.findIndex((el) => el.id === taskId);
    const task = oldTasks.results[taskIndex];

    if (task) {
      task.companyTaskStatusId = companyTaskStatusId;
      newTasks.results.push(task);
      newTasks.total = newTasks.total + 1;
      oldTasks.results.splice(taskIndex, 1);
      oldTasks.total = oldTasks.total - 1;

      state.companyTasks.find((s) => s.key === newStatus.id).value = [
        ...newTasks,
      ];
      state.companyTasks.find((s) => s.key === oldStatus.id).value = [
        ...oldTasks,
      ];
      if (state.focusTask) {
        state.focusTask.companyTaskStatusId = companyTaskStatusId;
      }

      // Update backlog tasks count
      if (oldStatus.name === 'BACKLOG') {
        state.backlogTasksCount = state.backlogTasksCount - 1;
      } else if (newStatus.name === 'BACKLOG') {
        state.backlogTasksCount = state.backlogTasksCount + 1;
      }
    }
  },

  [types.GET_BACKLOG_TASKS_COUNT](state, payload) {
    const { backlogTasksCount } = payload;
    state.backlogTasksCount = backlogTasksCount;
  },

  [types.SET_TASK_RATING](state, payload) {
    const { taskId, statusId, rating } = payload;
    state.companyTasks
      .find((t) => t.key === statusId)
      .value.results.find((task) => task.id === taskId).rating = rating;
    if (state.focusTask && state.focusTask.id === taskId) {
      state.focusTask.rating = rating;
    }
  },

  [types.SET_ASSIGNEE](state, payload) {
    const { taskId, statusId, assignee } = payload;
    const tasksCopy = [...state.companyTasks];
    tasksCopy
      .find((t) => t.key === statusId)
      .value.results.find((task) => task.id === taskId).assignee = assignee;
    state.companyTasks = [...tasksCopy];
  },

  [types.BULK_SET_IS_ARCHIVE](state, payload) {
    const { ids } = payload;
    state.companyTasks = state.companyTasks.map((status) => {
      const newTasks = [...status.value.results].filter(
        (task) => !ids.includes(task.id),
      );
      return {
        ...status,
        value: {
          ...status.value,
          results: newTasks,
          total:
            status.value.total -
            (status.value.results.length - newTasks.length),
        },
      };
    });
  },

  [types.UPVOTE_TASK](state, payload) {
    const { taskId, statusId, user } = payload;
    const tasksCopy = [...state.companyTasks];
    const focusTaskCopy = { ...state.focusTask };
    tasksCopy
      .find((t) => t.key === statusId)
      .value.results.find((task) => task.id === taskId).upvoteCount++;
    tasksCopy
      .find((t) => t.key === statusId)
      .value.results.find((task) => task.id === taskId)
      .upvotes.push(user);

    state.companyTasks = tasksCopy;
    if (focusTaskCopy && focusTaskCopy.id === taskId) {
      focusTaskCopy.upvoteCount++;
      focusTaskCopy.upvotes.push(user);
      state.focusTask = focusTaskCopy;
    }
  },

  [types.REMOVE_UPVOTE_TASK](state, payload) {
    const { taskId, statusId, user } = payload;
    const focusTaskCopy = { ...state.focusTask };

    const statusIndex = state.companyTasks.findIndex(
      (status) => status.key === statusId,
    );
    const taskIndex = state.companyTasks[statusIndex].value.results.findIndex(
      (task) => task.id === taskId,
    );
    const taskCopy = state.companyTasks[statusIndex].value.results[taskIndex];

    taskCopy.upvoteCount--;
    taskCopy.upvotes = taskCopy.upvotes.filter(
      (upvote) => upvote.id !== user.id,
    );

    state.companyTasks[statusIndex].value.results[taskIndex] = taskCopy;
    if (focusTaskCopy && focusTaskCopy.id === taskId) {
      focusTaskCopy.upvoteCount = focusTaskCopy.upvoteCount - 1;
      const userToRemoveIdx = focusTaskCopy.upvotes.findIndex(
        (upvoter) => upvoter.id === user.id,
      );
      focusTaskCopy.upvotes.splice(userToRemoveIdx, 1);
      state.focusTask = focusTaskCopy;
    }
  },

  [types.SET_FOCUS_TASK](state, payload) {
    const { taskDetails } = payload;
    state.focusTask = taskDetails;
  },

  [types.SET_FOCUS_TASK_LOADING](state, payload) {
    const { loading } = payload;
    state.loadingFocusTask = loading;
  },

  [types.NEW_TASK_COMMENT](state, payload) {
    const focusTaskCopy = { ...state.focusTask };
    const { newComment } = payload;

    const commentIdx = focusTaskCopy.comment.findIndex(
      (comment) => comment.id === newComment.id,
    );

    // Comment is already present from websocket event
    if (commentIdx !== -1) return;

    focusTaskCopy.comment.push(newComment);
    focusTaskCopy.commentCount = focusTaskCopy.commentCount + 1;
    state.focusTask = focusTaskCopy;
    state.companyTasks
      .find((status) => status.key === focusTaskCopy.companyTaskStatusId)
      .value.results.find((task) => task.id === focusTaskCopy.id).commentCount =
      focusTaskCopy.commentCount;
  },

  [types.DELETE_TASK_COMMENT](state, payload) {
    const focusTaskCopy = { ...state.focusTask };
    const { commentId } = payload;

    if (commentId >= 0) {
      focusTaskCopy.comment.splice(commentId, 1);
      focusTaskCopy.commentCount = focusTaskCopy.commentCount - 1;
      state.focusTask = focusTaskCopy;
      state.companyTasks
        .find((status) => status.key === focusTaskCopy.companyTaskStatusId)
        .value.results.find(
          (task) => task.id === focusTaskCopy.id,
        ).commentCount = focusTaskCopy.commentCount;
    }
  },
};
