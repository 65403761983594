import * as types from './mutation-types';
import initState from './state';

export default {
  /**
   * GENERAL
   */
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  /**
   * ARTICLE TO GUIDE
   */
  [types.UPDATE_ARTICLE_TO_GUIDE_PROCESSES](state, payload) {
    state.articleToGuideProcesses = payload;
  },
  [types.SET_DISPLAY_ASK_IN_SEARCH](state, payload) {
    state.displayAskInSearch = payload;
  },
  /**
   * ASK IN SEARCH
   */
  [types.SET_LAST_ASK_QUERY](state, payload) {
    state.lastAskQuery = payload;
  },
};
