export default {
  async uploadFiles(_, files) {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    const uploadFiles = await this.$services.parametricContents.attachFiles(
      formData,
    );
    return uploadFiles.data;
  },
};
