<template>
  <div>
    <img v-if="isArticle" src="~assets/article-icon-v2.svg" class="icon mr-2" />
    <img v-else-if="isDiag" src="~assets/diag_icon.svg" class="icon mr-2" />
    <font-awesome-icon
      v-else-if="isStep"
      :icon="['fas', 'level-up']"
      class="icon text-blue fa-rotate-90 mr-2"
    />
    <font-awesome-icon
      v-else-if="isKeyStep"
      :icon="['fad', 'key']"
      class="icon text-blue mr-2"
    />
    <font-awesome-icon
      v-else-if="isProduct"
      :icon="['fad', 'barcode-scan']"
      class="icon text-black mr-2"
    />
    <font-awesome-icon
      v-else-if="isCase"
      :icon="['fad', 'folder']"
      class="icon mr-2"
    />
  </div>
</template>

<script>
export default {
  name: 'article-type-icon',
  components: {},
  props: {
    item: Object,
  },
  computed: {
    isProduct() {
      return this.item.__typename === 'Product';
    },
    isArticle() {
      return this.item.type === 'Article';
    },
    isDiag() {
      return this.item.type === 'Diagnostic';
    },
    isStep() {
      return this.item.type === 'Step';
    },
    isKeyStep() {
      return this.item.type === 'keyStep';
    },
    isCase() {
      return this.item.__typename === 'Case';
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
}
.text-blue {
  color: $blue-mayday;
}
.text-black {
  color: $grey-8-mayday;
}
</style>
