import gql from 'graphql-tag';

export const readContentParents = gql`
  query readContentQuery($id: ID!) {
    readContentParents: readContent(id: $id) {
      id
      label
      title
      attachments
      parents {
        id
        content {
          id
          label
        }
        parents {
          id
          content {
            id
            label
          }
          parents {
            id
            content {
              id
              label
            }
            parents {
              id
              content {
                id
                label
              }
              parents {
                id
                content {
                  id
                  label
                }
                parents {
                  id
                  content {
                    id
                    label
                  }
                  parents {
                    id
                    content {
                      id
                      label
                    }
                    parents {
                      id
                      content {
                        id
                        label
                      }
                      parents {
                        id
                        parents {
                          id
                          content {
                            id
                            label
                          }
                          parents {
                            id
                            parents {
                              id
                              content {
                                id
                                label
                              }
                              parents {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const readContentVersions = gql`
  query readContentVersionsQuery($id: ID!) {
    readContent(id: $id) {
      versions {
        createdAt
        title
        label
        body
        author {
          username
        }
        icon
      }
    }
  }
`;

export const bulkUpdateContentPublishStatus = gql`
  mutation bulkUpdateContentPublishStatusMutation(
    $ids: [ID!]
    $published: Boolean
  ) {
    bulkUpdateContentPublishStatus(ids: $ids, published: $published)
  }
`;

export const bulkUpdateContentOutdatedStatus = gql`
  mutation bulkUpdateContentOutdatedStatusMutation(
    $ids: [ID!]
    $isOutdated: Boolean
  ) {
    bulkUpdateContentOutdatedStatus(ids: $ids, isOutdated: $isOutdated)
  }
`;
