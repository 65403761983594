const attachedObjectsParsing = (doc) => {
  const remainingLinkedObjects = Array.from(
    doc.getElementsByClassName('linked-node'),
  );
  const remainingLinkedObjectsIds = remainingLinkedObjects.map(
    (lo) => lo.attributes['node-id'].nodeValue,
  );
  return [...new Set(remainingLinkedObjectsIds)];
};

const attachmentsParsing = (doc) => {
  // raw attachmennts
  const remainingAttachments = Array.from(
    doc.getElementsByClassName('inline-attachment'),
  );
  let remainingAttachmentsSrc = remainingAttachments.map(
    (lo) => lo.attributes['src'].nodeValue,
  );

  // iframed attachments
  const iframedAttachments = Array.from(
    doc.querySelectorAll('iframe[is-attachment="true"]'),
  );
  if (iframedAttachments.length) {
    remainingAttachmentsSrc = remainingAttachmentsSrc.concat(
      iframedAttachments.map((lo) => lo.attributes['src'].nodeValue),
    );
  }

  return [...new Set(remainingAttachmentsSrc)];
};

export default {
  parsingContentHTML(content) {
    const localContent = { ...content };
    const { body } = localContent;
    const doc = new DOMParser().parseFromString(body, 'text/html');
    localContent.attachedObjects = attachedObjectsParsing(doc);
    localContent.attachments = attachmentsParsing(doc);
    return localContent;
  },
};
