export class TreeNodeService {
  constructor(data) {
    this.data = data;
  }

  /**
   * CREATE METHODS
   */

  async addChild(id, type, label) {
    return this.data.addTreeNodeChildren(id, type, label);
  }
  async duplicateChild(id, parentId, position) {
    return this.data.duplicateTreeNodeChildren(id, parentId, position);
  }
  async bulkTranslateTreeNodes(ids, parentId) {
    return this.data.bulkTranslateTreeNodes(ids, parentId);
  }

  /**
   * READ METHODS
   */

  async getOneById(id) {
    return this.data.getTreeNodeById(id);
  }
  async getDescendents(id) {
    return this.data.getTreeNodeDescendents(id);
  }
  async getTreeNodesAncestors(ids) {
    return this.data.getTreeNodesAncestors(ids);
  }

  /**
   * UPDATE METHODS
   */

  async update(newTreeNode) {
    return this.data.updateTreeNode(newTreeNode);
  }
  async dragndrop(newTreeNode) {
    return this.data.dragndrop(newTreeNode);
  }
  async link(newTreeNode) {
    return this.data.linkTreeNode(newTreeNode);
  }
  async unlink(id, label) {
    return this.data.unlinkTreeNode(id, label);
  }

  /**
   * DELETE METHODS
   */

  async removeChild(parentId, childId) {
    return this.data.removeTreeNodeChildren(parentId, childId);
  }
  async bulkRemove(ids) {
    return this.data.bulkRemoveTreeNodes(ids);
  }
}
