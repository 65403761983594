/* eslint-disable no-extra-boolean-cast */
import * as types from './mutation-types';
import { shouldLoadProduct } from './utils';

export default {
  /**
   * GENERAL
   */
  logout({ commit }) {
    commit(types.LOGOUT);
  },

  /**
   * PRODUCT
   */
  async loadProduct({ commit, dispatch, state }, route) {
    if (
      !shouldLoadProduct(
        route,
        state.product,
        state.lastProductLoadingTimestamp,
      )
    ) {
      dispatch('knowledgeModule/resetFocusContent', null, { root: true });
      return state.product;
    }

    // Reset State before new loading
    commit(types.LOGOUT);

    const routeName = route.name;
    const { family, brand } = route.query;
    const { productId, externalId } = route.params;
    let product = null;
    let isPartial = false;

    commit(types.SET_PRODUCT_LOADING, true);

    try {
      switch (routeName) {
        case 'web-product-partial':
          product = await dispatch('loadPartialProduct', { family, brand });
          isPartial = true;
          break;
        case 'web-product-external':
          product = await dispatch('loadProductByExternalId', externalId);
          break;
        case 'web-product':
          product = await dispatch('loadProductById', productId);
          break;
        default:
          product = null;
          break;
      }
    } catch (err) {
      product = null;
    }

    commit(types.SET_PRODUCT, product);
    commit(types.SET_IS_PARTIAL, isPartial);
    commit(types.SET_PRODUCT_LOADING, false);

    if (product) {
      dispatch('loadProductAttachedResolutions');
      dispatch('loadRootCollections');
      dispatch('setCollectionParents', []);
      dispatch('knowledgeModule/resetFocusContent', null, { root: true });
    }

    return product;
  },
  async loadPartialProduct(_, { family, brand }) {
    if (!family || !brand) return null;
    const tmpProduct = await this.$services.products.getPartial(
      family,
      brand,
      10,
      '',
      true,
    );
    return { ...tmpProduct, parameters: { family, brand } };
  },
  async loadProductById(_, productId) {
    if (!productId) return null;
    return this.$services.products.get(productId, this.contentSearch, 10, true);
  },
  async loadProductByExternalId(_, externalId) {
    if (!externalId) return null;
    return this.$services.products.getByExternalId(externalId, 10, true);
  },

  /**
   * PRODUCT RESOLUTIONS
   */
  async loadSolutions(
    { commit, dispatch },
    { caseId, count = 10, search = '' },
  ) {
    commit(types.SET_RESOLUTIONS_LOADING, true);
    let resolutions = { results: [], total: 0 };
    try {
      resolutions = caseId
        ? await dispatch('loadSolutionsWithPath', {
            caseId,
            count,
            search,
          })
        : await dispatch('loadSolutionsWithoutPath', {
            count,
            search,
          });
    } catch (err) {
      return err;
    } finally {
      commit(types.SET_RESOLUTIONS, resolutions);
      commit(types.SET_RESOLUTIONS_LOADING, false);
    }
  },
  async loadSolutionsWithPath({ getters }, { caseId, count, search }) {
    const { isPartial, productId, parameters } = getters;
    const filter = isPartial
      ? await this.$services.cases.getPartialProductResolutionsById(
          caseId,
          parameters,
          count,
          search,
        )
      : await this.$services.cases.getResolutionsById(
          caseId,
          productId,
          count,
          search,
        );
    return filter.resolutions;
  },
  async loadSolutionsWithoutPath({ getters }, { count, search }) {
    const { isPartial, productId, parameters } = getters;
    return isPartial
      ? await this.$services.parametricContents.getContentsByPartialProduct(
          parameters,
          count,
          search,
        )
      : await this.$services.parametricContents.getContentsByProductId(
          productId,
          count,
          search,
        );
  },

  /**
   * PRODUCT ATTACHED RESOLUTIONS
   */
  async loadProductAttachedResolutions({ commit, getters }) {
    const { isPartial, productId, parameters } = getters;
    commit(types.SET_ATTACHED_RESOLUTIONS_LOADING, true);
    try {
      const contents = isPartial
        ? await this.$services.parametricContents.getAttachedContentsByPartialProduct(
            parameters,
          )
        : await this.$services.parametricContents.getAttachedContentsByProductId(
            productId,
          );
      commit(types.SET_ATTACHED_RESOLUTIONS, contents);
    } catch (err) {
      return err;
    } finally {
      commit(types.SET_ATTACHED_RESOLUTIONS_LOADING, false);
    }
  },

  /**
   * PRODUCT COLLECTIONS
   */
  async setCollectionParents({ commit }, collectionParents) {
    commit(types.SET_COLLECTION_PARENTS, collectionParents);
  },
  async loadRootCollections({ commit, getters }) {
    const { isPartial, productId, parameters, productKnowledge } = getters;
    commit(types.SET_COLLECTIONS_LOADING, true);
    let collections = [];
    try {
      collections = isPartial
        ? await this.$services.cases.getCasesRootByPartialProduct(
            parameters,
            productKnowledge,
          )
        : await this.$services.cases.getRootsByProductId(
            productId,
            productKnowledge,
          );
    } catch (err) {
      return err;
    } finally {
      commit(types.SET_COLLECTIONS, collections);
      commit(types.SET_COLLECTIONS_LOADING, false);
    }
  },
  async loadCollectionChildren({ commit, getters }, collectionId) {
    const { isPartial, productId, parameters, productKnowledge } = getters;
    commit(types.SET_COLLECTIONS_LOADING, true);
    let collections = [];
    try {
      collections = isPartial
        ? await this.$services.cases.getChildrenByPartialProduct(
            collectionId,
            parameters,
            productKnowledge,
          )
        : await this.$services.cases.getChildrenByProductId(
            collectionId,
            productId,
            productKnowledge,
          );
    } catch (err) {
      return err;
    } finally {
      commit(types.SET_COLLECTIONS, collections);
      commit(types.SET_COLLECTIONS_LOADING, false);
    }
  },

  // PRODUCT DOCUMENTATION
  async getProductDocumentation(_, externalId) {
    return this.$services.products.getDocumentation(externalId);
  },
};
