// TMP only for web-parametric bug feedbacks on steps
export const SET_FOCUS_STEP = 'SET_FOCUS_STEP';

/**
 * GENERAL
 */
export const LOGOUT = 'LOGOUT';
export const UPDATE_LANGUAGE_TRANSLATION = 'UPDATE_LANGUAGE_TRANSLATION';
export const SET_DEFAULT_LANGUAGE_TRANSLATION =
  'SET_DEFAULT_LANGUAGE_TRANSLATION';
export const UPDATE_NAVIGATION_LANGUAGE = 'UPDATE_NAVIGATION_LANGUAGE';
export const SET_FOCUS_KNOWLEDGE_AS_MULTILINGUAL =
  'SET_FOCUS_KNOWLEDGE_AS_MULTILINGUAL';
export const ADD_NEW_LANGUAGE_TO_FOCUS_KNOWLEDGE =
  'ADD_NEW_LANGUAGE_TO_FOCUS_KNOWLEDGE';
export const ADD_NEW_LANGUAGE_TO_KNOWLEDGE = 'ADD_NEW_LANGUAGE_TO_KNOWLEDGE';
export const EDIT_KNOWLEDGE_LABEL = 'EDIT_KNOWLEDGE_LABEL';

/**
 * KNOWLEDGE
 */

export const SET_KNOWLEDGES = 'SET_KNOWLEDGES';
export const SET_FOCUS_KNOWLEDGE = 'SET_FOCUS_KNOWLEDGE';
export const SET_FOCUS_KNOWLEDGE_ROOTS = 'SET_FOCUS_KNOWLEDGE_ROOTS';
export const UPDATE_SINGLE_KNOWLEDGE_ROOT = 'UPDATE_SINGLE_KNOWLEDGE_ROOT';
export const SET_FOCUS_KNOWLEDGE_ROOTS_IS_LOADING =
  'SET_FOCUS_KNOWLEDGE_ROOTS_IS_LOADING';
export const KNOWLEDGE_EDITOR_IS_LOADING = 'KNOWLEDGE_EDITOR_IS_LOADING';
/**
 * COLLECTIONS NAVIGATION
 */

export const SET_COLLECTION_VIEWER_IS_LOADING =
  'SET_COLLECTION_VIEWER_IS_LOADING';
export const SET_FOCUS_COLLECTION_ID = 'SET_FOCUS_COLLECTION_ID';
export const SET_FOCUS_CONTENT_ID = 'SET_FOCUS_CONTENT_ID';
export const SET_FOCUS_COLLECTION = 'SET_FOCUS_COLLECTION';
export const SET_FOCUS_CONTENT = 'SET_FOCUS_CONTENT';
export const SET_FOCUS_CONTENT_CONTRIBUTORS = 'SET_FOCUS_CONTENT_CONTRIBUTORS';
export const SET_FOCUS_CONTENT_BACKLINKS = 'SET_FOCUS_CONTENT_BACKLINKS';
export const SET_FOCUS_COLLECTION_RELATED_CONTENTS =
  'SET_FOCUS_COLLECTION_RELATED_CONTENTS';
export const SET_FOCUS_ITEM_ID = 'SET_FOCUS_ITEM_ID';

export const ADD_TO_NAVIGATOR_LOOKUP_MAP = 'ADD_TO_NAVIGATOR_LOOKUP_MAP';
export const ADD_MANY_TO_NAVIGATOR_LOOKUP_MAP =
  'ADD_MANY_TO_NAVIGATOR_LOOKUP_MAP';
export const RESET_NAVIGATOR_LOOKUP_MAP = 'RESET_NAVIGATOR_LOOKUP_MAP';
export const FLUSH_NAVIGATOR_LOOKUP_MAP = 'FLUSH_NAVIGATOR_LOOKUP_MAP';
export const REMOVE_CONTENT_FROM_NAVIGATOR_LOOKUP_MAP =
  'REMOVE_CONTENT_FROM_NAVIGATOR_LOOKUP_MAP';
export const REMOVE_CASE_FROM_NAVIGATOR_LOOKUP_MAP =
  'REMOVE_CASE_FROM_NAVIGATOR_LOOKUP_MAP';
export const SET_FOCUS_COLLECTION_CONTENTS = 'SET_FOCUS_COLLECTION_CONTENTS';
export const SET_COLLECTION_SEARCH_FILTER = 'SET_COLLECTION_SEARCH_FILTER';
export const SET_COLLECTION_SEARCH_PAGE = 'SET_COLLECTION_SEARCH_PAGE';
export const SET_CONTENT_PARAMETER_LABELS = 'SET_CONTENT_PARAMETER_LABELS';
export const SET_TOGGLE_NAVIGATION_BAR = 'SET_DISPLAY_NAVIGATION_BAR';

/**
 * ARCHIVE FOCUS
 */

export const SET_FOCUS_ARCHIVE = 'SET_FOCUS_ARCHIVE';
export const SET_FOCUS_ARCHIVE_ERROR = 'SET_FOCUS_ARCHIVE_ERROR';
export const RESET_FOCUS_ARCHIVE = 'RESET_FOCUS_ARCHIVE';

/**
 * CASE ARCHIVE FOCUS
 */

export const SET_CASE_ARCHIVE_VIEWER_IS_LOADING =
  'SET_CASE_ARCHIVE_VIEWER_IS_LOADING';
export const SET_MAIN_FOCUS_CASE_ARCHIVE = 'SET_MAIN_FOCUS_CASE_ARCHIVE';
export const SET_FOCUS_CASE_ARCHIVE = 'SET_FOCUS_CASE_ARCHIVE';
export const SET_FOCUS_CASE_ARCHIVE_ANCESTORS =
  'SET_FOCUS_CASE_ARCHIVE_ANCESTORS';
export const SET_CASE_ARCHIVE_ERROR = 'SET_CASE_ARCHIVE_ERROR';
export const RESET_CASE_ARCHIVE = 'RESET_CASE_ARCHIVE';

/**
 * DRAG N DROP
 */

export const ADD_TO_DRAG_AND_DROP = 'ADD_TO_DRAG_AND_DROP';
export const REMOVE_FROM_DRAG_AND_DROP = 'REMOVE_FROM_DRAG_AND_DROP';
export const SET_NAVIGATOR_NODE_IS_LOADING = 'SET_NAVIGATOR_NODE_IS_LOADING';

/**
 * IMPORT ARTICLE
 */

export const SET_IMPORT_ARTICLE_IS_LOADING = 'SET_IMPORT_ARTICLE_IS_LOADING';

/**
 * TEMPLATE
 */

export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_TEMPLATES_PATH = 'SET_TEMPLATES_PATH';

/**
 * PRODUCT
 */

export const SET_COLLECTION_PARENTS = 'SET_COLLECTION_PARENTS';

/**
 * MAYDAY EDITOR
 */

export const UPDATE_FILE_UPLOAD_ERROR = 'UPDATE_FILE_UPLOAD_ERROR';

/**
 * CONCEPTS
 */

export const SET_CONTENT_CONCEPTS = 'SET_CONTENT_CONCEPTS';

/**
 * TRANSLATIONS
 */

export const SET_TRANSLATION_STATUS = 'SET_TRANSLATION_STATUS';
