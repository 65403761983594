import * as types from '../mutation-types';

export const getAskMetrics = async function ({ commit, getters }) {
  commit(types.SET_ASK_METRICS_IS_LOADING, true);
  commit(
    types.SET_ASK_METRICS,
    await this.$services.askAnalytics.getAskMetrics(
      getters.queryParamsFromFilters,
    ),
  );
  commit(types.SET_ASK_METRICS_IS_LOADING, false);
};

export const initAskMetrics = async function ({ commit }) {
  commit(types.SET_ASK_METRICS_IS_LOADING, true);
  commit(types.SET_ASK_METRICS, {
    questions: {
      value: 0,
    },

    withAnswers: {
      value: 0,
    },

    withoutAnswers: {
      value: 0,
    },

    feedbacks: {
      value: 0,
    },

    satisfaction: {
      value: 0,
    },
  });
  commit(types.SET_ASK_METRICS_IS_LOADING, false);
};

export const exportAskAnalytics = async function (
  { commit, getters },
  { isPending },
) {
  commit(types.EXPORT_ASK_ANALYTICS_LOADING, { isPending, loading: true });
  await this.$services.askAnalytics.exportAskAnalytics(
    isPending,
    getters.queryParamsFromFilters,
  );
  commit(types.EXPORT_ASK_ANALYTICS_LOADING, { isPending, loading: false });
};
