export class CaseService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async getRoots(knowledge, lang) {
    return this.data.getCasesRoot(knowledge, lang);
  }

  async getRootsWithChildren(knowledge, lang) {
    return this.data.getCasesRootWithChildren(knowledge, lang);
  }

  async getSettingsRoots(knowledge) {
    return this.data.getSettingsCasesRoot(knowledge);
  }

  async getById(
    id,
    skipOrdered = true,
    skipPath = true,
    lang,
    publishedContentsOnly = false,
  ) {
    return this.data.getDetailedCaseById(
      id,
      skipOrdered,
      skipPath,
      lang,
      publishedContentsOnly,
    );
  }

  async getLight(id, lang) {
    return this.data.getLightCaseById(id, lang);
  }

  async getByIds(ids) {
    return this.data.getCasesById(ids);
  }

  async getCasesTranslationState(ids, lang) {
    return this.data.getCasesTranslationState(ids, lang);
  }

  async getContentsByCaseId(id, pageSize = 20, page = 1, search = '') {
    return this.data.getContentsByCaseId(id, pageSize, page, search);
  }

  async getResolutionsById(id, productId, count, search) {
    return this.data.getCaseResolutionsById(id, productId, count, search);
  }

  async getPartialProductResolutionsById(id, parameters, count, search) {
    return this.data.getPartialProductCaseResolutionsById(
      id,
      parameters,
      count,
      search,
    );
  }

  async getRootsByProductId(productId, knowledge) {
    return this.data.getCasesRootByProductId(productId, knowledge);
  }

  async getCasesRootByPartialProduct(parameters, knowledge) {
    return this.data.getCasesRootByPartialProduct(parameters, knowledge);
  }

  async getChildren(id, knowledge, lang) {
    return this.data.getCaseChildren(id, knowledge, lang);
  }

  // THIS SHOULD NEVER BE USED IN KNOWLEDGE NAVIGATION, ONLY FOR SETTINGS/ADMINS VIEWS
  async getSettingsChildren(id, knowledge, lang) {
    return this.data.getSettingsCaseChildren(id, knowledge, lang);
  }

  async getChildrenByProductId(id, productId, knowledge) {
    return this.data.getCaseChildrenByProductId(id, productId, knowledge);
  }

  async getChildrenByPartialProduct(id, parameters, knowledge) {
    return this.data.getChildrenByPartialProduct(id, parameters, knowledge);
  }

  async getByKnowledge(value) {
    return this.data.getByKnowledge(value);
  }

  async getRebuiltCasePathFromCaseId(id, lang) {
    return this.data.getRebuiltCasePathFromCaseId(id, lang);
  }

  async getOrderedChildrenAndContents(
    caseId,
    knowledge,
    childrenOrder = [],
    lang,
  ) {
    return this.data.getOrderedChildrenAndContents(
      caseId,
      knowledge,
      childrenOrder,
      lang,
    );
  }

  async getCaseArchiveById(archiveId, skipAncestors, lang) {
    return this.data.getCaseArchiveById(archiveId, skipAncestors, lang);
  }

  /**
   * CREATE METHODS
   */

  async add(parenId, label, lang) {
    return this.data.addCase(parenId, label, lang);
  }

  async createRoot(label, knowledge, knowledgeId, lang) {
    return this.data.createRootCase(label, knowledge, knowledgeId, lang);
  }

  /**
   * UPDATE METHODS
   */

  async update(
    caseId,
    label,
    body,
    childrenOrder = [],
    lang,
    skipOrdered = true,
  ) {
    return this.data.updateCase(
      caseId,
      label,
      body,
      childrenOrder,
      lang,
      skipOrdered,
    );
  }

  async updateIcon(caseId, iconType, value, color, field) {
    return this.data.updateCaseIcon(caseId, iconType, value, color, field);
  }

  async restoreCaseArchive(archiveId, parent) {
    return this.data.restoreCaseArchive(archiveId, parent);
  }

  async moveCase(caseId, newFullPath, moveToRoot, newIndex) {
    return this.data.moveCase(caseId, newFullPath, moveToRoot, newIndex);
  }

  async unsetLanguage(caseId, lang) {
    return this.data.unsetLanguage(caseId, lang);
  }

  /**
   * DELETE METHODS
   */

  async delete(caseId, softDeleted) {
    return this.data.deleteCase(caseId, softDeleted);
  }

  async deleteIcon(caseId, field) {
    return this.data.deleteCaseIcon(caseId, field);
  }

  async deleteCaseArchive(archiveId) {
    return this.data.deleteCaseArchive(archiveId);
  }

  async bulkDeleteCaseArchive(caseArchiveIds) {
    return this.data.bulkDeleteCaseArchive(caseArchiveIds);
  }
}
