import gql from 'graphql-tag';

export const getImportedArticle = gql`
  query getImportedArticleQuery($sourceArticleId: ID!, $source: String!) {
    getImportedArticle
  }
`;

export const selectedImportedArticle = gql`
  mutation selectedImportedArticleMutation(
    $sourceArticleId: ID!
    $contentId: ID!
    $source: String!
  ) {
    selectedImportedArticle(
      sourceArticleId: $sourceArticleId
      contentId: $contentId
      source: $source
    ) {
      id
      content {
        id
        body
        label
        isReadOnly
      }
    }
  }
`;

export const updateImportedArticleContent = gql`
  mutation updateImportedArticleContentMutation($contentId: ID!) {
    updateImportedArticleContent(contentId: $contentId) {
      id
      content {
        id
        body
        label
        isReadOnly
      }
    }
  }
`;

export const delinkFromSource = gql`
  mutation delinkFromSourceMutation($contentId: ID!) {
    delinkFromSource(contentId: $contentId) {
      id
      body
      label
      isReadOnly
    }
  }
`;
