import data from './companyGroup.data.raw.js';

export class CompanyGroupServiceData {
  constructor(client) {
    this.client = client;
  }

  async getByIds(ids) {
    return this.client
      .query({
        query: data.getCompanyGroups,
        variables: {
          ids,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCompanyGroups)
      .catch((error) => error);
  }

  async getLightGroups() {
    return this.client
      .query({
        query: data.getAllCompanyGroupsLight,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCompanyGroups)
      .catch((error) => error);
  }

  async getGroups() {
    return this.client
      .query({
        query: data.getAllCompanyGroups,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCompanyGroups)
      .catch((error) => error);
  }

  async createGroup({ name, knowledgeIds, reachable }) {
    return this.client
      .mutate({
        mutation: data.createCompanyGroupMutation,
        variables: { name, knowledgeIds, reachable },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createCompanyGroup)
      .catch((error) => error);
  }

  async updateGroup({ id, name, knowledgeIds, reachable }) {
    return this.client
      .mutate({
        mutation: data.updateCompanyGroupMutation,
        variables: {
          id,
          name,
          knowledgeIds,
          reachable,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.updateCompanyGroup)
      .catch((error) => error);
  }

  async deleteGroup({ id }) {
    return this.client
      .mutate({
        mutation: data.deleteCompanyGroupMutation,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.deleteCompanyGroup)
      .catch((error) => error);
  }
}
