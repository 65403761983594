import {
  generateAnalyticsDateRange,
  isNotEmptyParam,
  createFilterValueAccessor,
  shouldIgnoreKey,
} from '../utils';
import moment from 'moment';

export const filtersAreReady = (state, getters) =>
  state.filters != null &&
  !state.filtersLoading &&
  getters.filterValuesArePopulated;

export const filterValuesArePopulated = (state) =>
  state.filterValues != null &&
  Array.isArray(state.filterValues) &&
  !!state.filterValues.length;

export const filters = ({ activeTab, filters, filtersLoading }, getters) => {
  if (filtersLoading) {
    return [];
  }

  if (activeTab !== 'workflow') return filters;
  return getters.workflowFilters;
};

export const workflowFilters = ({ filters, filterValues }) => {
  let newWorkflowFilters = [...filters[1].filters];

  // 1. check environmentIds for resource (1)
  // and function (2) filterings
  const environmentIds = filterValues[1][0];
  if (environmentIds.length > 0) {
    [1, 2].forEach((k) => {
      newWorkflowFilters[k] = {
        ...newWorkflowFilters[k],
        values: newWorkflowFilters[k].values.filter((res) =>
          environmentIds.includes(res.environmentId),
        ),
      };
    });
  }

  // 2. check resourceIds for functions filtering
  const resourceIds = filterValues[1][1];
  if (resourceIds.length > 0) {
    newWorkflowFilters[2] = {
      ...newWorkflowFilters[2],
      values: newWorkflowFilters[2].values.filter((res) =>
        resourceIds.includes(res.resourceId),
      ),
    };
  }

  return [filters[0], { ...filters[1], filters: newWorkflowFilters }];
};

export const filterValues = ({ filterValues, filtersLoading }) => {
  if (filtersLoading) {
    return [];
  }

  return filterValues;
};

export const filterAdditionalValues = ({
  filterAdditionalValues,
  filtersLoading,
}) => {
  if (filtersLoading) {
    return {};
  }

  return filterAdditionalValues;
};

export const filterDateRangeShortcutValues = ({
  filtersLoading,
  filterDateRangeShortcutValues,
}) => {
  if (filtersLoading) {
    return {};
  }
  return filterDateRangeShortcutValues;
};

export const queryParamsFromAdditionalFilters = (state, getters) => {
  if (!getters.filtersAreReady) return [];

  return Object.keys(state.filterAdditionalValues).reduce((acc, key) => {
    if (state.filterAdditionalValues[key] && !shouldIgnoreKey(key)) {
      acc.push({ key, value: state.filterAdditionalValues[key] });
    }

    return acc;
  }, []);
};

export const queryParamsFromFilters = (state, getters) => {
  if (!getters.filtersAreReady) return [];

  return state.filters
    .reduce((acc, group, i) => {
      return acc.concat(
        group.filters.flatMap((filter, j) => {
          if (filter.field === 'range') {
            return filter.filters.map((rangeFilter, k) => ({
              key: rangeFilter.key,
              value: state.filterValues[i][j][k],
            }));
          } else {
            if (
              getters.isPublicSelected &&
              (filter.key === 'labelIds' || filter.key === 'groupIds')
            )
              return {};
            return {
              key: filter.key,
              value: state.filterValues[i][j],
            };
          }
        }),
      );
    }, [])
    .concat([
      { key: 'isPublic', value: getters.isPublicSelected },
      {
        key: 'isKnowledgeFirstDay',
        value: getters.isKnowledgeFirstDaySelected,
      },
      ...getters.queryParamsFromAdditionalFilters,
    ])
    .filter(({ value }) => isNotEmptyParam(value));
};

export const analyticsDateRange = (_, getters) => {
  const { queryParamsFromFilters, rawContentLineChart } = getters;
  return generateAnalyticsDateRange(
    rawContentLineChart,
    queryParamsFromFilters,
  );
};

export const mapSourceToLabel = ({ sourceToLabel }) => sourceToLabel;

export const filterDateRangeValue = (_, { queryParamsFromFilters }) => {
  const begin = createFilterValueAccessor(queryParamsFromFilters, 'startDate');
  const end = createFilterValueAccessor(queryParamsFromFilters, 'endDate');

  return {
    begin: moment(begin).format('DD/MM/YYYY'),
    end: moment(end).format('DD/MM/YYYY'),
  };
};

const getFilterValueAt = (filters, filterValues, path) => {
  const [filterGroupLabel, filterKey] = path.split('.');
  const filterGroupIndex = filters.findIndex(
    (filterGroup) => filterGroup.label === filterGroupLabel,
  );
  const filterKeyIndex = filters[filterGroupIndex].filters.findIndex(
    (filter) => filter.key === filterKey,
  );
  return filterValues[filterGroupIndex][filterKeyIndex];
};

const isKeySelected = (filters, filterValues, key) => {
  if (!filters || !filters.length) return;

  const generalFilters = filters.find((filter) => filter.label === 'general');
  if (!generalFilters) return false;

  const knowledgeFilters = generalFilters.filters.find(
    (filter) => filter.key === 'knowledgeId',
  );
  if (
    !knowledgeFilters ||
    !knowledgeFilters.values ||
    !knowledgeFilters.values.length
  ) {
    return false;
  }
  const selectedKnowledgeValue = getFilterValueAt(
    filters,
    filterValues,
    'general.knowledgeId',
  );

  const selectedKnowledge = knowledgeFilters.values.find(
    (knowledge) => knowledge.value === selectedKnowledgeValue,
  );
  return selectedKnowledge ? selectedKnowledge[key] : false;
};

export const isPublicSelected = ({ filters, filterValues }) =>
  isKeySelected(filters, filterValues, 'isPublic');

export const isKnowledgeFirstDaySelected = ({ filters, filterValues }) =>
  isKeySelected(filters, filterValues, 'isKnowledgeFirstDay');

export const filterDateRange = ({ filters, filterValues }) => {
  const filterGroupIndex = filters.findIndex(
    (filterGroup) => filterGroup.label === 'general',
  );
  const filterDateIndex = filters[filterGroupIndex].filters.findIndex(
    (filter) => filter.label === 'date',
  );
  return {
    startDate: filterValues[filterGroupIndex][filterDateIndex][0],
    endDate: filterValues[filterGroupIndex][filterDateIndex][1],
  };
};
