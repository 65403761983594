/**
 * GENERAL
 */
export const LOGOUT = 'LOGOUT';

/**
 * PRODUCT
 */

export const SET_PRODUCT = 'PRODUCT';
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING';
export const SET_IS_PARTIAL = 'SET_IS_PARTIAL';
export const SET_PRODUCT_ERROR = 'SET_PRODUCT_ERROR';
export const SET_LAST_PRODUCT_LOADING_TIMESTAMP =
  'SET_LAST_PRODUCT_LOADING_TIMESTAMP';

/**
 * PRODUCT RESOLUTIONS
 */
export const SET_RESOLUTIONS = 'SET_RESOLUTIONS';
export const SET_RESOLUTIONS_LOADING = 'SET_RESOLUTIONS_LOADING';

/**
 * PRODUCT ATTACEHD RESOLUTIONS
 */
export const SET_ATTACHED_RESOLUTIONS = 'SET_ATTACHED_RESOLUTIONS';
export const SET_ATTACHED_RESOLUTIONS_LOADING =
  'SET_ATTACHED_RESOLUTIONS_LOADING';

/**
 * PRODUCT COLLECTIONS
 */
export const SET_COLLECTIONS = 'SET_COLLECTIONS';
export const SET_COLLECTION_PARENTS = 'SET_COLLECTION_PARENTS';
export const SET_COLLECTIONS_LOADING = 'SET_COLLECTIONS_LOADING';
