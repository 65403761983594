import gql from 'graphql-tag';
import { EMPLOYEES_FRAGMENT, PENDING_EMPLOYEES_FRAGMENT } from '../fragments';

export default {
  getCompanyDetails: gql`
    ${EMPLOYEES_FRAGMENT}
    ${PENDING_EMPLOYEES_FRAGMENT}
    query GetCompany(
      $page: Int
      $pageSize: Int
      $filters: [Filter]
      $sort: Sort
      $search: String
      $skipEmployees: Boolean!
      $skipPendingEmployees: Boolean!
      $skipGroups: Boolean!
      $skipRoles: Boolean!
      $skipLabels: Boolean!
    ) {
      currentCompany {
        employees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipEmployees) {
          ...EmployeesFragment
        }
        pendingEmployees(
          page: $page
          pageSize: $pageSize
          filters: $filters
          sort: $sort
          search: $search
        ) @skip(if: $skipPendingEmployees) {
          ...PendingEmployeesFragment
        }
        userGroups @skip(if: $skipGroups) {
          id
          name
          reachable
          users {
            id
            username
            email
          }
          usersCount
          hasPendingUsers
        }
        roles @skip(if: $skipRoles) {
          id
          name
          isCustom
          usersCount
          hasPendingUsers
          customRolePermissions {
            rolePermission
            scope
          }
        }
        companyUserLabelCategories @skip(if: $skipLabels) {
          id
          name
          icon
          color
          companyUserLabels {
            id
            name
            companyUserLabelCategoryId
          }
        }
      }
    }
  `,
  getReviewers: gql`
    query getReviewers {
      currentCompany {
        reviewers {
          roles {
            id
            name
          }
          users {
            id
            username
          }
        }
      }
    }
  `,
  getCompanyApiToken: gql`
    query getApiTokenQuery {
      company: currentCompany {
        id
        name
        apitoken
      }
    }
  `,
  getHostnames: gql`
    query getCompanyPublicConfigHostnames {
      getCompanyPublicConfigHostnames {
        knowledgeId
        hostname
      }
    }
  `,
  addCompanyPreference: gql`
    mutation addCompanyPreferenceMutation(
      $preferenceType: String!
      $preferenceValue: String
    ) {
      addCompanyPreference(
        preferenceType: $preferenceType
        preferenceValue: $preferenceValue
      ) {
        preferences {
          id
          type
          value
        }
      }
    }
  `,
  updateCompanyPreference: gql`
    mutation updateCompanyPreferenceMutation(
      $preferenceId: ID!
      $preferenceValue: String
    ) {
      updateCompanyPreference(
        preferenceId: $preferenceId
        preferenceValue: $preferenceValue
      ) {
        preferences {
          id
          type
          value
        }
      }
    }
  `,
  deleteCompanyPreference: gql`
    mutation deleteCompanyPreferenceMutation($preferenceIds: [ID!]) {
      removeCompanyPreference(preferenceIds: $preferenceIds) {
        preferences {
          id
          type
          value
        }
      }
    }
  `,

  addCompanyIntegration: gql`
    mutation addCompanyIntegrationMutation($integration: String!) {
      addIntegration(integration: $integration) {
        integration
      }
    }
  `,

  deleteCompanyIntegration: gql`
    mutation deleteCompanyIntegrationMutation($integration: String!) {
      deleteIntegration(integration: $integration) {
        integration
      }
    }
  `,

  updateCompanyLogo: gql`
    mutation updateCompanyLogoMutation($url: String!) {
      updateCompanyLogo(url: $url) {
        logo
      }
    }
  `,

  deleteCompanyLogo: gql`
    mutation deleteCompanyLogoMutation {
      deleteCompanyLogo {
        logo
      }
    }
  `,
};
