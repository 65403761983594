import { adminToWebRouteMapping, isValidRoute } from './adminToWebRouteMapping';

const webRedirections = ['web-filter', 'mayday-web'];

export const computeRedirectionWithParams = (
  to,
  initialRedirectRouteName,
  isParametric,
  isAdmin,
) => {
  if (
    !isValidRoute(to.name, isParametric) ||
    (!!isAdmin && !webRedirections.includes(initialRedirectRouteName))
  ) {
    return {
      name: initialRedirectRouteName,
      params: {},
      query: {},
    };
  }

  const routes = adminToWebRouteMapping(isParametric);
  const { name: adminInitialRouteName } = to;

  const nextRouteName = routes.find((r) => r.from === adminInitialRouteName).to;

  return {
    name: nextRouteName,
    params: to.params,
    query: to.query,
    hash: to.hash,
  };
};
