import * as types from './mutation-types';

/**
 * LOGIN
 */
export const loginWithCredentials = async function (
  { commit, dispatch },
  args,
) {
  try {
    const res = await this.$services.auth.login('credentials', args);
    const auth = res.data;

    return dispatch('loginFromAuth', auth);
  } catch (err) {
    commit(types.NEW_AUTH_ATTEMPT, args.email);
    commit(types.AUTH_ERROR, {
      message: 'authentication error',
    });
  }
};

export const loginFromOboToken = async function ({ commit, dispatch }, token) {
  try {
    const res = await this.$services.auth.login('oboToken', { token });
    const auth = res.data;

    return dispatch('loginFromAuth', auth);
  } catch (err) {
    commit(types.AUTH_ERROR, {
      message: 'authentication error',
    });
    return false;
  }
};

export const loginFromSSO = async function ({ dispatch }, auth) {
  return dispatch('loginFromAuth', auth);
};

export const loginFromAuth = async function ({ commit, dispatch }, auth) {
  try {
    if (!auth) return;

    commit(types.RESET_AUTH_ATTEMPT);
    commit(types.SET_AUTH, auth);

    return dispatch('getUserFromAccessToken');
  } catch (err) {
    commit(types.AUTH_ERROR, {
      message: 'authentication error',
    });
  }
};

export const getUserFromAccessToken = async function ({ commit, dispatch }) {
  try {
    const user = await this.$services.users.getUserFromAccessToken();
    if (!user) {
      return dispatch('logout');
    }
    const groups = await this.$services.companyGroups.getLight();
    if (groups) {
      user.company.userGroups = groups;
    }

    return dispatch('loginFromToken', user);
  } catch (err) {
    console.log(err);
    commit(types.AUTH_ERROR, {
      message: 'authentication error',
    });
  }
};

export const loginFromToken = function ({ commit, state, dispatch }, user) {
  if (user) {
    const auth = {
      accessToken: state.accessToken,
      accessExpirationDate: state.accessExpirationDate,
      refreshToken: state.refreshToken,
      user,
    };
    const { isVerified, auth: authData } = user;

    if (authData) {
      auth.websocketData = authData.websocketData;
    }

    if (isVerified) {
      dispatch('clearStore');
      commit(types.RESET_AUTH_ATTEMPT);
      commit(types.LOGIN, auth);
      commit(`kbStore/${types.LOGIN}`, auth);
      this.$broadcastChannel.postMessage({
        action: 'loginFromTab',
        payload: { auth, userId: user.id },
      });
      dispatch('notificationModule/init', user.id);
      dispatch('adminModule/getAllContributors', {}, { root: true });
      dispatch(
        'associateUserToRecentlyConsultedContent',
        { user },
        { root: true },
      );
      dispatch('getCompanyAttributes', {}, { root: true });
      dispatch(
        'setUserPreferences',
        { preferences: user.preferences },
        { root: true },
      );
    } else {
      commit(types.LOGIN, {
        userRole: 'PENDING',
      });
    }
  } else {
    commit(types.AUTH_ERROR, {
      message: 'authentication error',
    });
  }
};

export const loginFromTab = async function (
  { commit, dispatch },
  { auth, userId },
) {
  await dispatch('clearStore');
  dispatch('notificationModule/init', userId);
  commit(types.RESET_AUTH_ATTEMPT);
  commit(types.LOGIN, auth);
  commit(`kbStore/${types.LOGIN}`, auth);
  return this.$router.push(this.$route.query.redirect || '/knowledge?from=tab');
};

/**
 * REFRESH
 */

export const refreshToken = async function ({ commit, dispatch, state }) {
  try {
    const auth = await this.$services.auth.refreshToken();

    if (!auth) {
      return commit(types.AUTH_ERROR, 'SESSION_EXPIRED');
    }

    const { accessToken, refreshToken, accessExpirationDate, status } = auth;

    if (status === 'LOCKED') {
      return state.accessToken;
    }

    if (accessToken && refreshToken && accessExpirationDate) {
      const payload = {
        accessToken,
        refreshToken,
        accessExpirationDate,
      };
      this.$broadcastChannel.postMessage({
        action: 'refreshFromTab',
        payload,
      });

      commit(types.SET_AUTH, payload);
      return accessToken;
    }
  } catch (err) {
    dispatch('logout');
    commit(types.AUTH_ERROR, 'SESSION_EXPIRED');
    this.$router.push({ path: '/login' });
  }
};

export const refreshFromTab = async function ({ commit }, auth) {
  commit(types.SET_AUTH, auth);
};

/**
 * REGISTER
 */

const codeToError = {
  1: 'landing.form.error-email',
  2: 'landing.form.error-company',
  3: 'landing.form.error-email-company',
  4: 'landing.form.error-company-config',
  5: 'landing.form.error-knowledge',
  6: 'landing.form.error-hub',
  7: 'landing.form.error-public-config',
};

export const register = async function ({ commit }, data) {
  let message, type;
  try {
    const response = await this.$services.users.register(data);
    const { code } = response.data;
    switch (code) {
      case 1:
        message = 'landing.form.success';
        type = 'success';
        break;
      default:
        message = '';
        type = '';
        break;
    }
  } catch (err) {
    const { code } = err.response.data;
    message = codeToError[code] || 'landing.form.unknown';
    type = 'danger';
  } finally {
    commit(types.REGISTRATION_ERROR, {
      message,
      type,
    });
  }
};

/**
 * LOGOUT
 */

export const logout = async function ({ dispatch, getters }, force = false) {
  if (!getters.loggedIn && !force) return;

  await this.$services.auth.logout();
  await this.$services.onLogout();

  this.$broadcastChannel.postMessage({ action: 'logout' });

  dispatch('clearStore');

  const { redirect } = this.$router.currentRoute.query;

  return this.$router.push({ name: 'login', query: { redirect } });
};

export const clearStore = async function ({ commit, dispatch }) {
  commit(types.SET_AUTH, {});
  commit(types.LOGOUT);
  commit('adminModule/LOGOUT');
  commit('adminUserDirectoryModule/LOGOUT');
  commit('dashboard/LOGOUT');
  commit('kbStore/LOGOUT');
  commit('knowledgeModule/LOGOUT');
  commit('webModule/LOGOUT');
  commit('modalsModule/LOGOUT');
  commit('taskModule/LOGOUT');
  dispatch('websocketModule/logout'); // Close websocket through logout action
  commit('notificationModule/LOGOUT');
  commit('threadModule/LOGOUT');
  commit('brainModule/LOGOUT');
  commit('academyModule/LOGOUT');

  // agent client storage
  localStorage.removeItem('md-admin');
  localStorage.removeItem('md-knowledge');
  localStorage.removeItem('md-notifications');
  localStorage.removeItem('md-search');
  localStorage.removeItem('md-toasts');
  localStorage.removeItem('md-feedback');
  localStorage.removeItem('md-favorites');
  localStorage.removeItem('md-config');

  return commit('publicKnowledgeModule/LOGOUT');
};
