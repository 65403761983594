const timestampToDate = (tmstp) => {
  return new Date(tmstp).toISOString().split('T')[0];
};

export const generateDateRange = (startDate, endDate) => {
  const day = 24 * 60 * 60 * 1000;
  const dates = [];
  let flag = Date.parse(startDate);
  while (flag < Date.parse(endDate)) {
    dates.push(timestampToDate(flag));
    flag += day;
  }
  dates.push(timestampToDate(Date.parse(endDate)));
  return dates;
};

const mapSourceToHumanReadableLabel = (mapSourceToLabel, source) => {
  return mapSourceToLabel.hasOwnProperty(source)
    ? mapSourceToLabel[source]
    : source;
};

export const rawDataToLineChart = (
  labels,
  rawData,
  labelKey,
  xKey,
  yKey,
  mapSourceToLabel,
  isPercent = false,
) => {
  if (labels && labels.length) {
    const color = colorDistribution(rawData.length);
    const datasets = rawData.map((el, idx) => {
      const data = labels.map((label) => {
        if (el.points.length) {
          const match = el.points.find((p) => {
            return p[xKey].split('T')[0] === label;
          });
          const value = match ? match[yKey] : 0;
          return isPercent ? parseFloat(value * 100).toFixed(1) : value;
        }
        return 0;
      });
      return {
        label: mapSourceToHumanReadableLabel(mapSourceToLabel, el[labelKey]),
        data,
        borderColor: color[idx],
        pointBackgroundColor: color[idx],
      };
    });
    return {
      labels,
      datasets,
    };
  }
  return {
    labels: [],
    datasets: [],
  };
};

export const rawDataToStackedLineChart = (
  labels,
  rawData,
  labelKey,
  xKey,
  yKey,
  mapSourceToLabel,
) => {
  const data = rawDataToLineChart(
    labels,
    rawData,
    labelKey,
    xKey,
    yKey,
    mapSourceToLabel,
  );
  const color = colorDistribution(rawData.length);
  data.datasets = data.datasets.map((set, idx) => ({
    backgroundColor: color[idx],
    ...set,
  }));
  return data;
};

export const rawDataToPieChart = (
  rawData,
  labelKey,
  countKey,
  mapSourceToLabel,
) => {
  if (rawData && rawData.length) {
    const labels = rawData.map((el) =>
      mapSourceToHumanReadableLabel(mapSourceToLabel, el[labelKey]),
    );
    const colors = colorDistribution(rawData.length);
    const datasets = [
      {
        data: rawData.map((el) =>
          el.points.reduce((acc, val) => {
            if (val) {
              acc += val[countKey];
            }
            return acc;
          }, 0),
        ),
        backgroundColor: colors,
      },
    ];
    return {
      labels,
      datasets,
    };
  }
  return {
    labels: [],
    datasets: [],
  };
};

export const generateAnalyticsDateRange = (rawContent, queryParams) => {
  if (rawContent && rawContent.length) {
    const startDate = queryParams.find((f) => f.key === 'startDate');
    const endDate = queryParams.find((f) => f.key === 'endDate');
    if (startDate && endDate && startDate.value && endDate.value) {
      return generateDateRange(startDate.value, endDate.value);
    }
    return [];
  }
  return [];
};

export /**
 * Query parameter must not be an empty string
 *
 * @param {String|String[]|Number|Boolean} param
 * @return {Boolean}
 */
const isNotEmptyParam = (param) => {
  return (
    param != null &&
    (typeof param === 'boolean' || typeof param === 'number' || !!param.length)
  );
};

const colorPalette = [
  'rgba(25,95,148,0.7)',
  'rgba(29,194,225,1)',
  'rgba(115,45,182,0.7)',
  'rgba(214,61,166,0.7)',
  'rgba(232,70,127,0.7)',
  'rgba(230,182,62,0.7)',
  'rgba(29,207,206,0.7)',
  'rgba(29,160,215,0.7)',
  'rgba(237,154,133,0.7)',
  'rgba(21,31,77,0.7)',
  'rgba(251,231,226,1)',
  'rgba(192,249,236,1)',
  'rgba(166,60,196,0.7)',
  'rgba(37,0,94,0.7)',
  'rgba(245,243,186,1)',
  'rgba(255,144,117,0.7)',
  'rgba(0,129,249,0.8)',
  'rgba(103,194,58,0.6)',
  'rgba(19,80,108,0.7)',
  'rgba(184,38,28,0.7)',
  'rgba(82,158,137,0.7)',
  'rgba(198,15,123,0.7)',
  'rgba(155,72,155,0.7)',
  'rgba(163,126,186,0.7)',
  'rgba(154,179,245,0.7)',
  'rgba(75,64,54,0.7)',
  'rgba(0,129,249,0.7)',
  'rgba(252,191,73,0.7)',
  'rgba(253,0,68,0.7)',
  'rgba(140,179,105,0.7)',
];

/**
 * Extracts a subset of colors where the "distance" between each
 * color is "optimal".
 * If the sample size (sampleSize) is bigger than
 * the colors set, we double the colors and slice them to fit the
 * size of the sample.
 * @param {Number} sampleSize - sample size
 */
export const colorDistribution = (sampleSize) => {
  if (colorPalette.length < sampleSize) {
    return colorPalette.concat(colorPalette).slice(0, sampleSize.length);
  }
  const step = Math.floor(colorPalette.length / sampleSize);
  if (step > 1) {
    return colorPalette.filter((_o, idx) => idx % step === 0);
  }
  const firstShot = colorPalette.filter((_o, idx) => idx % 2 === 0);
  const secondShot = colorPalette
    .filter((_o, idx) => idx % 2 !== 0)
    .slice(0, sampleSize - firstShot.length);
  return firstShot.concat(secondShot);
};

export const createFilterValueAccessor = (additionalFilters, keyToMatch) => {
  if (additionalFilters == null || !Array.isArray(additionalFilters)) {
    return null;
  }
  const el = additionalFilters.find(({ key }) => key === keyToMatch);
  return el ? el.value : null;
};

export const mapTrendToBadgeType = {
  up: 'success',
  flat: 'flat',
  down: 'error',
};

export const mapTrendToBadgeTypeInverted = {
  up: 'error',
  flat: 'flat',
  down: 'success',
};

export const rawDataToMiniLineChart = (
  labels,
  rawData,
  labelKey,
  xKey,
  yKey,
  mapSourceToLabel,
  isPercent = false,
) => {
  if (labels && labels.length) {
    const datasets = rawData.map((el) => {
      const data = labels.map((label) => {
        if (el.points.length) {
          const match = el.points.find((p) => {
            return p[xKey].split('T')[0] === label;
          });
          const value = match ? match[yKey] : 0;
          return isPercent ? value * 100 : value;
        }
        return 0;
      });
      return {
        label: mapSourceToHumanReadableLabel(mapSourceToLabel, el[labelKey]),
        data,
        borderColor: 'rgba(0, 129, 249, 0.1)',
        backgroundColor: 'rgba(0, 129, 249, 0.3)',
      };
    });
    return {
      labels,
      datasets,
    };
  }
  return {
    labels: [],
    datasets: [],
  };
};

export const deepCopy = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepCopy(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = deepCopy(obj[key]);
      return newObj;
    }, {});
  }
};

export const isPrimitive = (val) => Object(val) !== val;

export const formatDate = (date) => {
  const formattedDate = new Date(date);
  return (
    String(formattedDate.getDate()).padStart(2, '0') +
    '/' +
    String(formattedDate.getMonth() + 1).padStart(2, '0') +
    '/' +
    String(formattedDate.getFullYear())
  );
};

export const extractBodyFromFeedback = (body) => {
  return body
    .replaceAll('><', '> <')
    .replaceAll(/<\/?[^>]+(>|$)/g, '')
    .replaceAll('  ', ' ');
};

const additionalKeySuffixesToIgnore = ['Remote', 'Merge'];

export const shouldIgnoreKey = (key) => {
  const regexesToIgnore = additionalKeySuffixesToIgnore.map(
    (key) => new RegExp(`${key}.*`, 'i'),
  );

  return regexesToIgnore.some((reg) => reg.test(key));
};
