import gql from 'graphql-tag';

export default {
  cloneCaseToTarget: gql`
    mutation cloneCaseToTargetMutation(
      $caseId: ID!
      $caseName: String!
      $targetCaseId: ID
      $targetKnowledge: String!
      $targetKnowledgeId: ID!
      $languagesMatch: [LanguageMatchInput]
      $skipOrdered: Boolean!
    ) {
      cloneCaseToTarget(
        caseId: $caseId
        caseName: $caseName
        targetCaseId: $targetCaseId
        targetKnowledge: $targetKnowledge
        targetKnowledgeId: $targetKnowledgeId
        languagesMatch: $languagesMatch
      ) {
        id
        label
        root
        returnLanguage
        userPermissions
        knowledgeId
        icon {
          iconType
          value
          color
        }
        miniIcon {
          iconType
          value
          color
        }
        orderedChildrenAndContents @skip(if: $skipOrdered) {
          __typename
          ... on Case {
            id
            label
            childrenOrder {
              id
              entityType
            }
            returnLanguage
          }
          ... on Content {
            id
            label
            type
            published
            isOutdated
            caseParameters
            labels {
              id
              labelName
              icon
              color
            }
            returnLanguage
          }
        }
      }
    }
  `,
  cloneContentsToTarget: gql`
    mutation cloneContentsToTargetMutation(
      $contentIds: [ID!]
      $targetCaseId: ID!
      $targetKnowledge: String!
      $targetKnowledgeId: ID!
      $languagesMatch: [LanguageMatchInput]
    ) {
      cloneContentsToTarget(
        contentIds: $contentIds
        targetCaseId: $targetCaseId
        targetKnowledge: $targetKnowledge
        targetKnowledgeId: $targetKnowledgeId
        languagesMatch: $languagesMatch
      ) {
        id
        label
        type
        published
        isOutdated
        caseParameters
        userPermissions
        labels {
          id
          labelName
          icon
          color
        }
        returnLanguage
      }
    }
  `,
  duplicateContents: gql`
    mutation duplicateContentsMutation($contentIds: [ID!]) {
      duplicateContents(contentIds: $contentIds) {
        id
        label
        type
        published
        isOutdated
        caseParameters
        userPermissions
        labels {
          id
          labelName
          icon
          color
        }
        returnLanguage
      }
    }
  `,
};
