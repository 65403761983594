export class ProductNotificationService {
  constructor(data) {
    this.data = data;
  }

  /**
   *
   * CREATE METHODS
   */
  async createProductNotification(productNotification) {
    return this.data.createProductNotification(productNotification);
  }

  /**
   * READ METHODS
   */
  async getProductNotifications() {
    return this.data.getProductNotifications();
  }

  /**
   * DELETE METHODS
   */
  async deleteProductNotification(productNotificationId) {
    return this.data.deleteProductNotification(productNotificationId);
  }
}
