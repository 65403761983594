export const urlCurator = (rawUrl) => {
  const [route, query] = rawUrl.split('?');
  if (query) {
    const curatedQuery = new URLSearchParams(query);
    curatedQuery.delete('sso_token');
    curatedQuery.delete('token');
    return `${route}?${curatedQuery.toString()}`;
  }
  return rawUrl;
};
