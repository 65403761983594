import gql from 'graphql-tag';

import { MODULE_LABEL } from './moduleLabel.fragments';

export default {
  moduleLabelsCategories: gql`
    query moduleLabelCategoriesQuery {
      moduleLabelsCategories {
        ...ModuleLabelFragment
        children {
          ...ModuleLabelFragment
        }
      }
    }
    ${MODULE_LABEL}
  `,
  createModuleLabel: gql`
    mutation createModuleLabelMutation($input: CreateModuleLabelInput!) {
      createModuleLabel(input: $input) {
        ...ModuleLabelFragment
        children {
          ...ModuleLabelFragment
        }
      }
    }
    ${MODULE_LABEL}
  `,
  updateModuleLabelTitle: gql`
    mutation updateModuleLabelTitleMutation(
      $input: UpdateModuleLabelTitleInput!
    ) {
      updateModuleLabelTitle(input: $input) {
        ...ModuleLabelFragment
      }
    }
    ${MODULE_LABEL}
  `,
  updateModuleLabelIcon: gql`
    mutation updateModuleLabelIconMutation(
      $input: UpdateModuleLabelIconInput!
    ) {
      updateModuleLabelIcon(input: $input) {
        ...ModuleLabelFragment
      }
    }
    ${MODULE_LABEL}
  `,
  deleteModuleLabel: gql`
    mutation deleteModuleLabel($id: ID!) {
      deleteModuleLabel(id: $id)
    }
  `,
};
