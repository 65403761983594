import { mapTrendToBadgeType, mapTrendToBadgeTypeInverted } from '../utils';

export const askCardMetrics = ({ askMetrics }, { askMetricsIsLoading }) => {
  if (askMetricsIsLoading) {
    return [];
  }
  return [
    {
      name: 'new-dashboard.stat-card.name.analytics-ask-metrics.questions',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-ask-metrics.questions',
      stat: askMetrics.questions.value,
      badgeType: mapTrendToBadgeType[askMetrics.questions.trend],
      additionalStat: askMetrics.questions.trend,
      footerSlot: 'questions',
      footerStat: {
        trend: askMetrics.withAnswers.trend,
      },
      icon: 'comments',
      isEmpty: askMetrics.questions.value === 0,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-ask-metrics.with-answers',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-ask-metrics.with-answers',
      stat: askMetrics.withAnswers.value,
      badgeType: mapTrendToBadgeType[askMetrics.withAnswers.trend],
      additionalStat: askMetrics.withAnswers.trend,
      footerSlot: 'questionsWithAnswers',
      footerStat: {
        trend: askMetrics.withAnswers.trend,
        percent: (
          (askMetrics.withAnswers.value / askMetrics.questions.value) *
          100
        ).toFixed(0),
      },
      badgeSlot: 'trendBadge',
      icon: 'comment-check',
      isEmpty: askMetrics.questions.value === 0,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-ask-metrics.without-answers',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-ask-metrics.without-answers',
      stat: askMetrics.withoutAnswers.value,
      badgeType: mapTrendToBadgeTypeInverted[askMetrics.withoutAnswers.trend],
      additionalStat: askMetrics.withoutAnswers.trend,
      icon: 'comment-times',
      footerSlot: 'questionsWithoutAnswers',
      footerStat: {
        trend: askMetrics.withAnswers.trend,
        percent: (
          (askMetrics.withoutAnswers.value / askMetrics.questions.value) *
          100
        ).toFixed(0),
      },
      badgeSlot: 'trendBadge',
      isEmpty: askMetrics.questions.value === 0,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-ask-metrics.feedbacks',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-ask-metrics.feedbacks',
      stat: askMetrics.feedbacks.value,
      badgeType: mapTrendToBadgeType[askMetrics.feedbacks.trend],
      additionalStat: askMetrics.feedbacks.trend,
      footerSlot: 'feedbacks',
      footerStat: {
        trend: askMetrics.feedbacks.trend,
        percentChange: askMetrics.feedbacks.percentChange,
      },
      badgeSlot: 'trendBadge',
      icon: 'megaphone',
      isEmpty: askMetrics.questions.value === 0,
    },
    {
      name: 'new-dashboard.stat-card.name.analytics-ask-metrics.satisfaction',
      badgeType: mapTrendToBadgeType[askMetrics.satisfaction.trend],
      additionalStat: askMetrics.satisfaction.trend,
      footerSlot: 'satisfaction',
      footerStat: {
        trend: askMetrics.satisfaction.trend,
        percentChange: askMetrics.satisfaction.percentChange,
      },
      badgeSlot: 'trendBadge',
      tooltipContent:
        'new-dashboard.header.tooltip.analytics-ask-metrics.satisfaction',
      stat: askMetrics.satisfaction.value.toFixed(0) + '%',
      icon: 'smile',
      isEmpty: askMetrics.questions.value === 0,
    },
  ];
};

export const askMetricsIsLoading = ({ askMetricsIsLoading }) => {
  return askMetricsIsLoading;
};
