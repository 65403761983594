import gql from 'graphql-tag';

export default {
  readCustomRoles: gql`
    query readCustomRoles {
      readCustomRoles {
        id
        name
        isCustom
        usersCount
        customRolePermissions {
          id
          customRoleId
          rolePermission
          scope
        }
      }
    }
  `,

  updateCustomRole: gql`
    mutation updateCustomRole($id: ID!, $customRole: JSON) {
      updateCustomRole: updateCustomRole(id: $id, customRole: $customRole) {
        id
        name
        isCustom
        usersCount
        customRolePermissions {
          id
          customRoleId
          rolePermission
          scope
        }
      }
    }
  `,

  createCustomRole: gql`
    mutation createCustomRole($customRole: JSON) {
      createCustomRole: createCustomRole(customRole: $customRole) {
        id
        name
        isCustom
        usersCount
        customRolePermissions {
          id
          customRoleId
          rolePermission
          scope
        }
      }
    }
  `,

  deleteCustomRole: gql`
    mutation deleteCustomRole($id: ID!) {
      deleteCustomRole: deleteCustomRole(id: $id)
    }
  `,
};
