export function getSteps(status) {
  if (status === 'FAILED') {
    steps[0].state = 'passed';
    steps[1].state = 'passed';
    steps[2].state = 'failed';
    steps[3].state = 'impossible';
  }
  if (status === 'RELEASED') {
    steps[0].state = 'passed';
    steps[1].state = 'passed';
    steps[2].state = 'passed';
    steps[3].state = 'passed';
  }
  const statusIdx = steps.findIndex((step) => step.label === status);
  if (statusIdx !== -1)
    steps.map((step, idx) => {
      if (idx < statusIdx) {
        step.state = 'passed';
      }
      if (idx === statusIdx) step.state = 'loading';
      if (idx > statusIdx) step.state = 'todo';
    });

  return steps;
}

const steps = [
  {
    label: 'TRIGGERED',
    state: 'todo',
    title: 'public-knowledge.deployment.init-title',
    description: 'public-knowledge.deployment.init-description',
  },
  {
    label: 'STARTED',
    state: 'todo',
    title: 'public-knowledge.deployment.build-title',
    description: 'public-knowledge.deployment.build-description',
  },
  {
    label: 'BUILT',
    state: 'todo',
    title: 'public-knowledge.deployment.deploy-title',
    description: 'public-knowledge.deployment.deploy-description',
  },
  {
    label: 'DEPLOYED',
    state: 'todo',
    title: 'public-knowledge.deployment.finished-title',
    description: 'public-knowledge.deployment.finished-description',
  },
];
