import * as types from './mutation-types';
import initState from './state';

export default {
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  [types.OPEN](state, payload) {
    state.component = payload.component;
    state.uid = payload.uid;
    state.props = payload.props;
  },
  [types.SET_EVENT](state, payload) {
    state.eventName = payload.eventName;
    state.eventData = payload.eventData || null;
    state.eventWait = payload.eventWait || 0;
  },
};
