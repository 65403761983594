export class ContentTemplateService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async get(id, lang) {
    return this.data.getTemplateById(id, lang);
  }

  async getAll(lang) {
    return this.data.getTemplates(lang);
  }

  /**
   * CREATE METHODS
   */

  async createByContentId({ name, description, contentId, lang }) {
    return this.data.createTemplateByContentId({
      name,
      description,
      contentId,
      lang,
    });
  }

  async create({ name, description, type, lang, knowledgeId, knowledge }) {
    return this.data.createTemplate({
      name,
      description,
      type,
      lang,
      knowledgeId,
      knowledge,
    });
  }

  async createContent(
    templateId,
    targetCaseId,
    targetKnowledge,
    targetKnowledgeId,
  ) {
    return this.data.createContentFromTemplate({
      templateId,
      targetCaseId,
      targetKnowledge,
      targetKnowledgeId,
    });
  }

  /**
   * DELETE METHODS
   */

  async delete(id) {
    return this.data.deleteTemplateById(id);
  }

  /**
   * UPDATE METHODS
   */

  async update({ id, payload }) {
    return this.data.updateTemplateById({ id, payload });
  }

  async updateTranslation({ id, descendentId, key, value, lang }) {
    return this.data.updateTemplateTranslation({
      id,
      descendentId,
      key,
      value,
      lang,
    });
  }

  async unsetTranslation({ id, descendentId, langToUnset, lang }) {
    return this.data.unsetTemplateTranslation({
      id,
      descendentId,
      langToUnset,
      lang,
    });
  }

  async deleteDescendent({ id, descendentId, parentId }) {
    return this.data.deleteTemplateDescendent({ id, descendentId, parentId });
  }

  async createDescendent({ id, parentId, ancestors, label, lang }) {
    return this.data.createTemplateDescendent({
      id,
      parentId,
      ancestors,
      label,
      lang,
    });
  }

  async orderDescendents({ id, descendentId, path }) {
    return this.data.orderTemplateDescendents({ id, descendentId, path });
  }

  async createRedirection({ id, descendentId, entityId, entityType }) {
    return this.data.createTemplateRedirection({
      id,
      descendentId,
      entityId,
      entityType,
    });
  }

  async upsertAction(
    templateId,
    descendentId,
    actionId,
    actionPayload,
    langs,
    type,
    title,
  ) {
    return this.data.upsertTemplateAction(
      templateId,
      descendentId,
      actionId,
      actionPayload,
      langs,
      type,
      title,
    );
  }

  async deleteAction(id, descendentId, actionId) {
    return this.data.deleteTemplateAction(id, descendentId, actionId);
  }
}
