import * as types from './mutation-types';
import { DIRECTIONS } from '../../views/Settings/PublicKnowledge/PublicKnowledgeMenuConfiguration';
import initState from './state';

export default {
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  [types.GET_COMPANY_PUBLIC_CONFIGS](state, payload) {
    state.companyPublicConfigs = payload;
  },
  [types.GET_FOCUS_PUBLIC_CONFIG](state, payload) {
    state.focusCompanyPublicConfig = payload;
  },

  [types.SET_FOCUS_KNOWLEDGE](state, payload) {
    state.focusKnowledge = payload;
  },

  [types.NEW_OPERATION](state, payload) {
    const operationsCopy = { ...state.operations };
    const { focusCompanyPublicConfig } = state;
    if (operationsCopy[focusCompanyPublicConfig.id]) {
      let keyIdx;
      if (payload.direction === DIRECTIONS.TRANSLATIONS) {
        keyIdx = operationsCopy[focusCompanyPublicConfig.id].findIndex(
          (op) =>
            op.key === payload.key &&
            op.path.split('.')[0] === payload.path.split('.')[0],
        );
      } else {
        keyIdx = operationsCopy[focusCompanyPublicConfig.id].findIndex(
          (op) => op.key === payload.key,
        );
      }
      if (keyIdx === -1)
        operationsCopy[focusCompanyPublicConfig.id].push({
          ...payload,
        });
      else
        operationsCopy[focusCompanyPublicConfig.id][keyIdx] = {
          ...payload,
        };
    } else operationsCopy[focusCompanyPublicConfig.id] = [{ ...payload }];

    state.operations = operationsCopy;
  },

  [types.DELETE_OPERATION](state, payload) {
    const operationsCopy = { ...state.operations };
    const { focusCompanyPublicConfig } = state;
    if (operationsCopy[focusCompanyPublicConfig.id]) {
      const keyIdx = operationsCopy[focusCompanyPublicConfig.id].findIndex(
        (op) => op.key === payload,
      );
      if (keyIdx !== -1) {
        operationsCopy[focusCompanyPublicConfig.id].splice(keyIdx, 1);
      }
    }
    state.operations = operationsCopy;
  },

  [types.DESTROY_OPERATIONS](state) {
    state.operations = [];
  },

  [types.SET_DEPLOYMENT_STATUS](state, deploymentStatus) {
    state.deploymentStatus = deploymentStatus;
  },

  [types.SET_POLLING_TIMER](state, payload) {
    const { poolingTimer } = state;

    if (poolingTimer) clearTimeout(poolingTimer);

    state.poolingTimer = payload;
  },

  [types.SET_DEPLOYING](state, deploying) {
    state.deploying = deploying;
  },
  [types.SET_HTTPS_STATUS](state, httpsStatus) {
    state.httpsStatus = httpsStatus;
  },

  [types.SET_HTTPS_POLLING_TIMER](state, payload) {
    const { httpsPoolingTimer } = state;
    if (httpsPoolingTimer) clearTimeout(httpsPoolingTimer);

    state.httpsPoolingTimer = payload;
  },

  [types.SET_HTTPS_POOLING](state, deploying) {
    state.httpsPooling = deploying;
  },

  /**
   * LANGUAGES
   */

  [types.ADD_LANGUAGE](state, payload) {
    const { lang } = payload;
    state.focusKnowledge.publicData.translations.push({
      key: lang,
      contentCount: 0,
      caseCount: 0,
      meta: {
        title: '',
        description: '',
      },
    });
    state.focusKnowledge.supportedLanguages.push(lang);
  },

  [types.SET_MULTILINGUAL_LOADER](state, payload) {
    state.multilingualLoader = payload;
  },

  [types.SET_MULTILINGUAL](state, payload) {
    const { lang } = payload;

    state.focusKnowledge.publicData.translations = [
      {
        key: lang,
        meta: {
          title: '',
          description: '',
        },
      },
    ];
    state.focusKnowledge.supportedLanguages = [lang];
    state.focusKnowledge.defaultLanguage = lang;
  },

  // Errors

  [types.SET_CUSTOM_HOSTNAME_ERROR](state, payload) {
    state.customHostnameError = payload;
  },
};
