export class ZendeskService {
  constructor(data) {
    this.data = data;
  }

  searchMacros(input) {
    return this.data.searchMacros(input);
  }

  getMacroById(id) {
    return this.data.getMacroById(id);
  }
}
