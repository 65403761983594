export class ProductService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async get(id, search, count = 10, skipContents = false) {
    return this.data.getProductById(id, count, search, skipContents);
  }

  async getByExternalId(id, count = 10, skipContents = false) {
    return this.data.getProductByExternalId(id, count, skipContents);
  }

  async getPartial(
    family,
    brand,
    count = 10,
    search = '',
    skipContents = false,
  ) {
    return this.data.getPartialProduct(
      family,
      brand,
      count,
      search,
      skipContents,
    );
  }

  async filterProductsByHierarchy(
    filters,
    pageSize = 20,
    page = 1,
    search = '',
  ) {
    return this.data.filterProductsByHierarchy(filters, pageSize, page, search);
  }
  async filterProductsByHierarchyCount(filters, search = '') {
    return this.data.filterProductsByHierarchyCount(filters, search);
  }

  async getContentsByProductId(productId, count = 20, search = '') {
    return this.data.getContentsByProductId(productId, count, search);
  }

  async getAttachedFilesByProductId(productId, count = 20) {
    return this.data.getAttachedFilesByProductId(productId, count);
  }

  async regexProductParameters(key, input, parameters) {
    return this.data.regexProductParameters(key, input, parameters);
  }

  async regexProductFacets(input) {
    return this.data.regexProductFacets(input);
  }

  // DOCUMENTATION (CUSTOM)
  async getDocumentation(externalId) {
    return this.data.getDocumentation(externalId);
  }
}
