import gql from 'graphql-tag';

export default {
  getContentHistory: gql`
    query getContentHistoryQuery(
      $contentId: ID!
      $pageSize: Int
      $page: Int
      $lang: String
    ) {
      getContentHistory(
        contentId: $contentId
        pageSize: $pageSize
        page: $page
        lang: $lang
      ) {
        total
        pages
        page
        results {
          authorId
          updatedAt
          username
        }
      }
    }
  `,
};
