import * as types from './mutation-types';
import { initContentState, initGeneralState, initUsageState } from './state';
import { formatDate, extractBodyFromFeedback } from './utils';

export default {
  [types.SET_FILTERS](state, filters) {
    state.filters = filters;
  },

  [types.SET_FILTERS_LOADING](state, loading) {
    state.filtersLoading = loading;
  },

  [types.SET_FILTERS_VALUES](state, { values, defaultValues }) {
    state.filterValues = values;
    state.defaultFilterValues = defaultValues;
  },

  [types.SET_FILTERS_ADDITIONAL_VALUES](
    state,
    { additionalValues, defaultAdditionalValues },
  ) {
    state.filterAdditionalValues = additionalValues;
    state.defaultFilterAdditionalValues = defaultAdditionalValues;
  },

  [types.SET_FILTERS_DATE_RANGE_SHORTCUT_VALUES](state, shortcutValues) {
    state.filterDateRangeShortcutValues = shortcutValues;
  },

  [types.SET_FILTERS_VALUE](state, { i, j, value }) {
    state.filterValues[i].splice(j, 1, value);
  },

  [types.SET_FILTERS_ADDITIONAL_VALUE](state, additionalValue) {
    state.filterAdditionalValues = Object.assign(
      {},
      state.filterAdditionalValues,
      { [`${additionalValue.key}`]: additionalValue.value },
    );
  },

  [types.RESET_FILTERS_VALUE](state, { i, j, defaultValue }) {
    state.filterValues[i].splice(j, 1, defaultValue);
  },

  [types.SET_TABLE_FILE](state, tableFile) {
    state.tableFile = tableFile;
  },

  [types.SET_TABLE_FILE_IS_LOADING](state, isLoading) {
    state.tableFileIsLoading = isLoading;
  },

  [types.SET_ACTIVE_TAB](state, tabName) {
    state.activeTab = tabName;
  },

  [types.SET_VIEWS_GRAPH_ADDITIONAL_FILTERS](state, filters) {
    state.additionalViewsGraphFilters = filters;
  },

  [types.SET_VIEWS_GRAPH_ADDITIONAL_FILTER_VALUE](state, { idx, value }) {
    state.additionalViewsGraphFilters.splice(idx, 1, value);
  },

  [types.SET_CONTENT_TABLE_ADDITIONAL_FILTERS](state, filters) {
    state.additionalContentTableFilters = filters;
  },

  [types.SET_CONTENT_TABLE_ADDITIONAL_FILTER_VALUE](state, { idx, value }) {
    state.additionalContentTableFilters.splice(idx, 1, value);
  },

  [types.SET_CONTENT_TABLE_ADDITIONAL_SEARCH_FILTER_CHANGED](state, changed) {
    state.contentTableSearchFilterChanged = changed;
  },

  [types.SET_CONTENT_LINE_CHART_BY_COLLECTION](
    state,
    rawContentLineChartByCollection,
  ) {
    state.rawContentLineChartByCollection = rawContentLineChartByCollection;
  },

  [types.SET_CONTENT_LINE_CHART_BY_SOURCE](state, rawContentLineChartBySource) {
    state.rawContentLineChartBySource = rawContentLineChartBySource;
  },

  [types.SET_CONTENT_STAT_CARDS](state, rawContentStatCards) {
    state.rawContentStatCards = rawContentStatCards;
  },

  [types.SET_CONTENT_STAT_CARDS_LOADING](state, loading) {
    state.rawContentStatCardsLoading = loading;
  },

  [types.SET_CONTENT_PUBLIC_STAT_CARDS](state, rawContentPublicStatCards) {
    state.rawContentPublicStatCards = rawContentPublicStatCards;
  },

  [types.SET_CONTENT_PUBLIC_STAT_CARDS_LOADING](state, loading) {
    state.rawContentPublicStatCardsLoading = loading;
  },

  [types.SET_CONTENT_TABLE](state, rawContentTable) {
    state.contentTableData = rawContentTable;
  },

  [types.SET_CONTENT_CHART_DATA_LOADING](state, loading) {
    state.contentChartDataIsLoading = loading;
  },

  [types.SET_CONTENT_TABLE_LOADING](state, loading) {
    state.contentTableDataIsLoading = loading;
  },

  [types.SET_CONTENT_TABLE_PAGE_CHANGED](state, changed) {
    state.contentTableCurrentPageChanged = changed;
  },

  [types.SET_CONTENT_TABLE_SORT_OPTIONS](state, sort) {
    state.contentTableSortOptions = sort;
  },

  [types.SET_CONTENT_TABLE_ADDITIONAL_COLLECTION_FILTER_CHANGED](
    state,
    changed,
  ) {
    state.contentTableCollectionsFilterChanged = changed;
  },

  [types.SET_CONTENT_SEARCH_STATISTICS](state, contentSearchStatistics) {
    state.contentSearchStatistics = contentSearchStatistics;
  },

  [types.SET_CONTENT_SEARCH_STATISTICS_LOADING](state, contentSearchLoading) {
    state.contentSearchLoading = contentSearchLoading;
  },

  // DOWNLOAD ANALYTICS

  [types.SET_EXPORTS](state, exports) {
    state.exports = exports;
  },

  [types.EXPORT_USER_LOADING](state, { loading, isMissed }) {
    state.exportSearchLoading[isMissed ? 'fail' : 'success'] = loading;
  },

  // USAGE
  [types.SET_USAGE_STAT_CARDS](state, rawUsageStatCards) {
    state.rawUsageStatCards = rawUsageStatCards;
  },
  [types.SET_USAGE_STAT_CARDS_LOADING](state, loading) {
    state.rawUsageStatCardsLoading = loading;
  },
  [types.SET_USAGE_ADOPTION_RATE_CHART_DATA](
    state,
    rawUsageAdoptionRateChartData,
  ) {
    state.rawUsageAdoptionRateChartData = rawUsageAdoptionRateChartData;
  },
  [types.SET_USAGE_ADOPTION_RATE_CHART_DATA_LOADING](state, loading) {
    state.rawUsageAdoptionRateChartDataLoading = loading;
  },

  // CONTENT MODAL
  [types.SET_CONTENT_MODAL_SHOW](state, show) {
    state.showContentModal = show;
  },
  [types.RESET_CONTENT_MODAL](state) {
    state.contentModalId = null;
    state.contentModalIsDiagnostic = false;
    state.contentModalDetails = null;
    state.contentModalTableData = [];
    state.contentModalLineChart = {};
    state.contentModalNodes = [];
  },
  [types.SET_CONTENT_MODAL_ID](state, id) {
    state.contentModalId = id;
  },
  [types.SET_CONTENT_MODAL_IS_DIAGNOSTIC](state, isDiagnostic) {
    state.contentModalIsDiagnostic = isDiagnostic;
  },
  [types.SET_CONTENT_MODAL_CONTENT_DETAILS](state, overviewStats) {
    state.contentModalDetails = overviewStats;
  },
  [types.SET_CONTENT_MODAL_CONTENT_DETAILS_IS_LOADING](state, loading) {
    state.contentModalDetailsIsLoading = loading;
  },
  [types.SET_CONTENT_MODAL_LINE_CHART](state, lineChart) {
    state.contentModalLineChart = lineChart;
  },
  [types.SET_CONTENT_MODAL_CHART_TYPE](state, chartType) {
    state.contentModalChartType = chartType;
  },
  [types.SET_CONTENT_MODAL_LINE_CHART_IS_LOADING](state, loading) {
    state.contentModalLineChartIsLoading = loading;
  },
  [types.SET_CONTENT_MODAL_DIAGNOSTIC_CHART](state, diagnosticChart) {
    state.contentModalDiagnosticChart = diagnosticChart;
  },
  [types.SET_CONTENT_MODAL_DIAGNOSTIC_CHART_IS_LOADING](state, loading) {
    state.contentModalDiagnosticChartIsLoading = loading;
  },

  // USAGE TABLE

  [types.SET_USAGE_TABLE](state, rawUsageTable) {
    state.usageTableData = rawUsageTable;
  },
  [types.SET_USAGE_TABLE_LOADING](state, loading) {
    state.usageTableDataIsLoading = loading;
  },
  [types.SET_USAGE_TABLE_ADDITIONAL_FILTERS](state, filters) {
    state.additionalUsageTableFilters = filters;
  },
  [types.SET_USAGE_TABLE_ADDITIONAL_FILTERS_VALUE](state, { idx, value }) {
    state.additionalUsageTableFilters.splice(idx, 1, value);
  },
  [types.SET_USAGE_TABLE_PAGE_CHANGED](state, changed) {
    state.usageTableCurrentPageChanged = changed;
  },
  [types.SET_USAGE_TABLE_SEARCH_FILTER_CHANGED](state, changed) {
    state.usageTableSearchFilterChanged = changed;
  },
  [types.SET_USAGE_TABLE_SORT_OPTIONS](state, sort) {
    state.usageTableSortOptions = sort;
  },

  // USAGE MODAL
  [types.SET_USAGE_MODAL_SHOW](state, show) {
    state.showUsageModal = show;
  },
  [types.RESET_USAGE_MODAL](state) {
    state.usageModalId = null;
    state.usageModalDetails = null;
    state.usageModalTableData = { results: [], total: 0, page: 1 };
    state.usageModalLineChart = {};
    state.additionalUsageModalTableFilters = [];
  },
  [types.SET_USAGE_MODAL_ID](state, id) {
    state.usageModalId = id;
  },
  [types.SET_USAGE_MODAL_DETAILS](state, modalDetails) {
    state.usageModalDetails = modalDetails;
  },
  [types.SET_USAGE_MODAL_DETAILS_IS_LOADING](state, loading) {
    state.usageModalDetailsIsLoading = loading;
  },
  [types.SET_USAGE_MODAL_TABLE](state, rawUsageModalTable) {
    state.usageModalTableData = rawUsageModalTable;
  },
  [types.SET_USAGE_MODAL_TABLE_IS_LOADING](state, loading) {
    state.usageModalTableIsLoading = loading;
  },
  [types.SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS](state, filters) {
    state.additionalUsageModalTableFilters = filters;
  },
  [types.SET_USAGE_MODAL_TABLE_ADDITIONAL_FILTERS_VALUE](
    state,
    { idx, value },
  ) {
    state.additionalUsageModalTableFilters.splice(idx, 1, value);
  },
  [types.SET_USAGE_MODAL_TABLE_PAGE_CHANGED](state, changed) {
    state.usageModalTableCurrentPageChanged = changed;
  },
  [types.SET_USAGE_MODAL_TABLE_SORT_OPTIONS](state, sort) {
    state.usageModalTableSortOptions = sort;
  },
  [types.SET_USAGE_MODAL_CHART_DATA](state, data) {
    state.usageModalLineChart = data;
  },
  [types.SET_USAGE_MODAL_CHART_IS_LOADING](state, loading) {
    state.usageModalLineChartIsLoading = loading;
  },
  [types.SET_USAGE_MODAL_FEEDBACKS](state, data) {
    if (!data) {
      state.usageModalFeedbacks = [];
      return;
    }
    state.usageModalFeedbacks = data.map((feedback) => {
      return {
        ...feedback,
        createdAt: formatDate(feedback.createdAt),
        feedback: extractBodyFromFeedback(feedback.feedback),
      };
    });
  },
  [types.SET_USAGE_MODAL_FEEDBACKS_IS_LOADING](state, loading) {
    state.usageModalFeedbacksIsLoading = loading;
  },
  [types.SET_ASK_METRICS_IS_LOADING](state, loading) {
    state.askMetricsIsLoading = loading;
  },
  [types.SET_ASK_METRICS](state, metrics) {
    state.askMetrics = metrics;
  },
  [types.EXPORT_ASK_ANALYTICS_LOADING](state, { isPending, loading }) {
    state.askExportLoading[isPending ? 'pending' : 'notPending'] = loading;
  },


  [types.SET_WORKFLOW_METRICS_IS_LOADING](state, loading) {
    state.workflowMetricsIsLoading = loading;
  },
  [types.SET_WORKFLOW_METRICS](state, metrics) {
    state.workflowMetrics = metrics;
  },
  [types.SET_RESOURCES](state, resources) {
    state.workflowResources = resources;
  },
  [types.SET_MAP_SOURCE_TO_LABEL](state, mapSourceToLabel) {
    state.sourceToLabel = mapSourceToLabel;
  },
  [types.LOGOUT](state) {
    Object.assign(state, {
      ...initGeneralState(),
      ...initContentState(),
      ...initUsageState(),
    });
  },
  [types.RESET_CONTENTS](state) {
    Object.assign(state, {
      ...initContentState(),
    });
  },
  [types.RESET_USAGES](state) {
    Object.assign(state, {
      ...initUsageState(),
    });
  },
};
