export class SearchService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async superSearch({
    entity,
    fields,
    searchFrom,
    size,
    correlationId,
    payload,
    lang,
    isNlp,
  }) {
    return this.data.superSearch(
      entity,
      fields,
      searchFrom,
      size,
      correlationId,
      payload,
      lang,
      isNlp,
    );
  }

  async lightSearchContentFromKnowledge({
    knowledge,
    query,
    size,
    lang,
    type = ['Article', 'Diagnostic'],
    published = ['true', null],
  }) {
    return this.data.lightSearchContentFromKnowledge(
      knowledge,
      query,
      size,
      lang,
      type,
      published,
    );
  }

  async autocomplete(payload) {
    return this.data.searchAutocomplete(payload);
  }

  async maydayWebSearch({
    knowledge,
    query,
    entityType,
    categories,
    size,
    hasEngineRestrictions,
    lang,
    caseId,
  }) {
    return this.data.maydayWebSearch(
      knowledge,
      query,
      entityType,
      categories,
      size,
      hasEngineRestrictions,
      lang,
      caseId,
    );
  }

  async getSimilarContents({ body }) {
    return this.data.getSimilarContents(body);
  }
}
