export class CompanyService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async getDetails(
    { currentPage, totalPageCount, filters, sort, search },
    skip,
  ) {
    return this.data.getCompanyDetails(
      currentPage,
      totalPageCount,
      filters,
      sort,
      search,
      skip,
    );
  }

  async getHostnames() {
    return this.data.getHostnames();
  }

  async getApiToken() {
    return this.data.getCompanyApiToken();
  }

  async getReviewers() {
    return this.data.getReviewers();
  }

  /**
   * CREATE METHODS
   */

  async addPreference(preferenceType, preferenceValue) {
    return this.data.addCompanyPreference(preferenceType, preferenceValue);
  }

  async addIntegration(integration) {
    return this.data.addCompanyIntegration(integration);
  }

  /**
   * UPDATE METHODS
   */

  async updatePreference(preferenceId, preferenceValue) {
    return this.data.updateCompanyPreference(preferenceId, preferenceValue);
  }

  async updateLogo(url) {
    return this.data.updateCompanyLogo(url);
  }

  /**
   * DELETE METHODS
   */

  async deletePreference(preferenceIds) {
    return this.data.deleteCompanyPreference(preferenceIds);
  }

  async deleteIntegration(integration) {
    return this.data.deleteCompanyIntegration(integration);
  }

  async deleteLogo() {
    return this.data.deleteCompanyLogo();
  }
}
