<template>
  <div
    @mouseenter="startScroll"
    @mouseleave="stopScroll"
    class="wrapper"
    :class="{ truncate: !scroll }"
    :id="textId"
    :style="`${fontStyle};${dynamicWidth}`"
  >
    <div :id="textId + '-temp'" class="temp">
      {{ text }}
    </div>
    <div :style="scrollStyle">
      {{ text }}
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
export default {
  name: 'text-ellipsis',
  props: {
    text: {
      type: String,
      required: false,
    },
    stop: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      textId: uuid(),
      tempWidth: 0,
      loading: true,
      distance: 0,
      scroll: false,
    };
  },
  computed: {
    scrollStyle() {
      if (this.stop) return '';
      const duration = Math.floor((this.distance / 60) * 1000);
      return `transition: transform ${duration}ms linear ${
        this.scroll ? '500ms' : '0ms'
      }; transform: translateX(-${this.distance}px)`;
    },
    fontStyle() {
      return `font-size: ${this.fontSize}`;
    },
    dynamicWidth() {
      return this.width ? `max-width: ${this.width}px` : 'max-width: 100%';
    },
  },
  methods: {
    startScroll() {
      this.calcTempWidth();
      const el = document.getElementById(this.textId);
      const width = el.getBoundingClientRect().width;
      this.distance = this.tempWidth - width + 5;
      this.scroll = true;
    },
    stopScroll() {
      this.distance = 0;
      this.scroll = false;
    },
    calcTempWidth() {
      const el = document.getElementById(this.textId + '-temp');
      this.tempWidth = el.getBoundingClientRect().width;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-width: 0;
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}
.temp {
  position: fixed;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}
.truncate {
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
