import { BrainTaskSelector } from '@/services/brainTasks/brainTasks.selector';
import * as types from './mutation-types';

export default {
  async triggerBrainTask(_, { taskType, payload }) {
    if (!taskType) return null;
    const brainTask = BrainTaskSelector(taskType, this.$services);
    return brainTask.trigger(payload);
  },

  async getBrainTaskStatus({ getters, commit }, { lockId, targetLanguage }) {
    if (!lockId) return { status: 'failed' };

    const brainTask = BrainTaskSelector(lockId.split(':')[0], this.$services);
    const status = await brainTask.getStatus(lockId);
    if (!status) return { status: 'failed' };

    if (status === 'done') {
      const { focusContent } = getters;
      commit(
        types.SET_FOCUS_CONTENT,
        Object.assign(focusContent, {
          setLanguages: focusContent.setLanguages + [targetLanguage],
        }),
      );
    }

    return status;
  },

  async pollBrainTaskStatus(
    _,
    { lockId, onStatusChangeCallback, status, frequency },
  ) {
    if (!lockId) {
      onStatusChangeCallback({ status: 'failed' }, { status });
      return;
    }

    const brainTask = BrainTaskSelector(lockId.split(':')[0], this.$services);
    await brainTask.pollStatus({
      lockId,
      onStatusChangeCallback,
      status,
      frequency,
    });
  },

  async triggerBrainImportFile(
    _,
    { contentId, contentType, lang, importType, rawFiles, settings },
  ) {
    if (!contentId || !contentType || !lang || !importType || !rawFiles) return;

    const data = await this.$services.parametricContents.brainImportFiles({
      contentId,
      contentType,
      lang,
      importType,
      rawFiles,
      settings,
    });

    if (!data) return;

    return data.statusId;
  },
};
