import { groupContentsByLabels, processCollections } from './utils';

export default {
  /**
   * PRODUCT
   */

  productId: (state) => (state.product ? state.product.id : null),
  product: (state) => state.product,
  productLoading: (state) => state.productLoading,
  parameters: (state) => {
    const { product } = state;
    if (!product || !product.parameters) return [];
    return Object.keys(product.parameters).map((key) => ({
      key,
      values: [product.parameters[key]],
    }));
  },
  productKnowledge: (state) => state.product.knowledge,
  isPartial: (state) => state.isPartial,
  hasPicture: (state) =>
    state.product && state.product.pictures
      ? state.product.pictures.length > 0
      : false,
  productError: (state) => state.productError,

  /**
   * PRODUCT RESOLUTIONS
   */
  resolutions: (state) => state.resolutions,
  resolutionsCount: (state) => state.resolutionsCount,
  resolutionsLoading: (state) => state.resolutionsLoading,

  /**
   * PRODUCT ATTACHED RESOLUTIONS
   */
  attachedResolutions: (state) => state.attachedResolutions,
  attachedResolutionsCount: (state) => state.attachedResolutionsCount,
  attachedResolutionsLoading: (state) => state.attachedResolutionsLoading,
  attachedResolutionsByLabels: (state) =>
    groupContentsByLabels(state.attachedResolutions),

  /**
   * PRODUCT COLLECTIONS
   */
  collections: (state, getters) => processCollections(state.collections, getters.parametricDeterministicCasesOrder),
  collectionParents: (state) => state.collectionParents,
  collectionsLoading: (state) => state.collectionsLoading,
  rootCollectionFocused: (state) => !state.collectionParents.length,
  focusedCollectionId: (state, getters) =>
    getters.rootCollectionFocused
      ? null
      : state.collectionParents.slice(-1)[0].id,

  /**
   * GENERAL
   */
  tableOfContents: (state, getters) =>
    [
      state.product.notifications.results.length
        ? {
            label: 'Alertes et Notifications',
            value: 'notifications',
          }
        : undefined,
      {
        label: 'Fichiers Attachés',
        value: 'attached-files',
        options: getters.attachedResolutionsByLabels,
      },
      {
        label: 'Solutions',
        value: 'solutions',
      },
    ].filter((el) => !!el),

  /**
   * COMPANY PREFERENCE
   */
  parametricDeterministicCasesOrder: (_state, _getters, { companyPreferences }) =>
    !!companyPreferences.find(
      ({ type }) => type === 'PARAMETRIC_DETERMINISTIC_CASES_ORDER',
    ),
};
