<template>
  <div class="tooltip-container">
    <el-tooltip
      :content="text"
      placement="top"
      :disabled="tooltip"
      :popper-class="area ? 'textarea-tooltip' : ''"
    >
      <div class="overflow" ref="tooltip-overflow" :style="lineClampStyle">
        {{ text }}
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'text-overflow-tooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    area: {
      type: Boolean,
      default: false,
    },
    lineClamp: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tooltip: true,
    };
  },
  mounted() {
    this.isTooltipDisabled();
  },
  computed: {
    lineClampStyle() {
      if (!this.lineClamp) return {};
      return {
        display: '-webkit-box !important',
        '-webkit-line-clamp': this.lineClamp,
        '-webkit-box-orient': 'vertical',
        'word-break': 'break-word',
        'white-space': 'unset',
      };
    },
  },
  methods: {
    isTooltipDisabled() {
      this.$nextTick(() => {
        const textEl = this.$refs['tooltip-overflow'];
        if (textEl) {
          if (this.lineClamp !== null) {
            this.tooltip = textEl.scrollHeight <= textEl.clientHeight;
          } else {
            this.tooltip = textEl.scrollWidth <= textEl.clientWidth;
          }
        }
      });
    },
  },
  watch: {
    text() {
      this.isTooltipDisabled();
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  min-width: 0;
  max-width: 100%;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
