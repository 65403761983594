import gql from 'graphql-tag';

export default {
  getContentParameterLabels: gql`
    query getContentParameterLabels($count: Int) {
      labels: getContentParameterLabels(count: $count) {
        id
        labelName
        categoryName
        icon
        color
      }
    }
  `,
  createContentParameterLabel: gql`
    mutation createContentParameterLabel(
      $labelName: String!
      $categoryName: String!
      $icon: String!
      $color: String!
    ) {
      createdContentParameterLabel: createContentParameterLabel(
        labelName: $labelName
        categoryName: $categoryName
        icon: $icon
        color: $color
      ) {
        id
        labelName
        categoryName
        icon
        color
        createdAt
      }
    }
  `,
  updateContentParameterLabel: gql`
    mutation updateContentParameterLabel(
      $id: ID!
      $labelName: String!
      $categoryName: String!
      $icon: String!
      $color: String!
    ) {
      updatedContentParameterLabel: updateContentParameterLabel(
        id: $id
        labelName: $labelName
        categoryName: $categoryName
        icon: $icon
        color: $color
      ) {
        id
        labelName
        categoryName
        icon
        color
        createdAt
      }
    }
  `,
  deleteContentParameterLabel: gql`
    mutation deleteContentParameterLabel($id: ID!) {
      isContentParameterLabelDeleted: deleteContentParameterLabel(id: $id)
    }
  `,
};
