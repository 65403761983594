import * as types from './mutation-types';

export default {
  async initModal({ commit }) {
    commit(types.LOGOUT);
  },
  async openModal({ commit }, payload) {
    commit(types.OPEN, payload);
  },
  async sendEvent({ commit }, payload) {
    commit(types.SET_EVENT, payload);
  },
};
