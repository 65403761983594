export class CloneService {
  constructor(data) {
    this.data = data;
  }

  async cloneCaseToTarget(
    caseId,
    caseName,
    targetCaseId,
    targetKnowledge,
    targetKnowledgeId,
    languagesMatch,
    skipOrdered = true,
  ) {
    return this.data.cloneCaseToTarget(
      caseId,
      caseName,
      targetCaseId,
      targetKnowledge,
      targetKnowledgeId,
      languagesMatch,
      skipOrdered,
    );
  }

  async cloneContentsToTarget(
    contentIds,
    targetCaseId,
    targetKnowledge,
    targetKnowledgeId,
    languagesMatch,
  ) {
    return this.data.cloneContentsToTarget(
      contentIds,
      targetCaseId,
      targetKnowledge,
      targetKnowledgeId,
      languagesMatch,
    );
  }

  async duplicateContents(contentIds) {
    return this.data.duplicateContents(contentIds);
  }
}
