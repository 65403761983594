import * as types from '../mutation-types';
import debounce from 'lodash.debounce';

export const resetContentModalDetails = async function ({ commit }) {
  commit(types.SET_CONTENT_MODAL_LINE_CHART, null);
  commit(types.SET_CONTENT_MODAL_CONTENT_DETAILS, null);
  commit(types.SET_CONTENT_MODAL_DIAGNOSTIC_CHART, null);
};

export const initContentModalGraphType = async function ({ state, commit }) {
  if (state.contentModalChartType == null)
    commit(types.SET_CONTENT_MODAL_CHART_TYPE, 'line');
};

export const prepareContentModalDetails = async function (
  { commit, state, getters, dispatch },
  { id, isDiagnostic } = {},
) {
  if (id != null && isDiagnostic != null && state.contentModalId !== id) {
    await dispatch('resetContentModalDetails');
    commit(types.SET_CONTENT_MODAL_ID, id);
    commit(types.SET_CONTENT_MODAL_IS_DIAGNOSTIC, isDiagnostic);
  }

  commit(types.SET_CONTENT_MODAL_CONTENT_DETAILS_IS_LOADING, true);
  commit(types.SET_CONTENT_MODAL_LINE_CHART_IS_LOADING, true);
  commit(types.SET_CONTENT_MODAL_DIAGNOSTIC_CHART_IS_LOADING, true);
  commit(types.SET_CONTENT_MODAL_SHOW, true);
  commit(
    types.SET_CONTENT_MODAL_LINE_CHART,
    await this.$services.contentAnalytics.getContentLineChart(
      state.contentModalId,
      getters.queryParamsFromFilters,
    ),
  );
  commit(types.SET_CONTENT_MODAL_LINE_CHART_IS_LOADING, false);
  commit(
    types.SET_CONTENT_MODAL_CONTENT_DETAILS,
    await this.$services.contentAnalytics.getContent(
      state.contentModalId,
      getters.queryParamsFromFilters,
    ),
  );
  commit(types.SET_CONTENT_MODAL_CONTENT_DETAILS_IS_LOADING, false);
  if (state.contentModalIsDiagnostic) {
    commit(
      types.SET_CONTENT_MODAL_DIAGNOSTIC_CHART,
      await this.$services.contentAnalytics.getContentDiagnosticChart(
        state.contentModalId,
        getters.queryParamsFromFilters,
      ),
    );
    commit(types.SET_CONTENT_MODAL_DIAGNOSTIC_CHART_IS_LOADING, false);
  }
};

export const updateContentModalChartType = async function ({ commit }, value) {
  commit(types.SET_CONTENT_MODAL_CHART_TYPE, value);
};

export const hideContentModal = async function ({ commit }) {
  commit(types.SET_CONTENT_MODAL_SHOW, false);
  commit(types.RESET_CONTENT_MODAL);
};

export const getContentLineChartData = async function (
  { getters, commit },
  context,
) {
  const filterType = getters.viewsGraphFilterType;
  try {
    const params = context && context.length ? [...context] : [];
    params.push(...getters.queryParamsForViewsGraph);
    commit(types.SET_CONTENT_CHART_DATA_LOADING, true);
    const rawContentLineChart =
      await this.$services.contentAnalytics.getContentsLineChart(params);
    switch (filterType) {
      case 'collection':
        commit(types.SET_CONTENT_LINE_CHART_BY_COLLECTION, rawContentLineChart);
        break;
      case 'source':
        commit(types.SET_CONTENT_LINE_CHART_BY_SOURCE, rawContentLineChart);
        break;
    }
    commit(types.SET_CONTENT_CHART_DATA_LOADING, false);
  } catch (e) {
    return e;
  }
  return;
};

export const getContentStatCardsData = async function ({ getters, commit }) {
  const { queryParamsFromFilters: params } = getters;
  const knowledgeId = params.find(({ key }) => key === 'knowledgeId');
  if (knowledgeId) {
    commit(types.SET_CONTENT_STAT_CARDS_LOADING, true);
    const rawContentStatCards =
      await this.$services.contentAnalytics.getStatCards(knowledgeId.value);
    commit(types.SET_CONTENT_STAT_CARDS, rawContentStatCards);
    commit(types.SET_CONTENT_STAT_CARDS_LOADING, false);
  }
};

export const getContentPublicStatCardsData = async function ({
  getters,
  commit,
}) {
  if (!getters.isPublicSelected) return;

  const { queryParamsFromFilters: params } = getters;
  const knowledgeId = params.find(({ key }) => key === 'knowledgeId');
  if (knowledgeId) {
    commit(types.SET_CONTENT_PUBLIC_STAT_CARDS_LOADING, true);
    const rawContentPublicStatCards =
      await this.$services.contentAnalytics.getPublicStatCards(
        knowledgeId.value,
        params,
      );
    commit(types.SET_CONTENT_PUBLIC_STAT_CARDS, rawContentPublicStatCards);
    commit(types.SET_CONTENT_PUBLIC_STAT_CARDS_LOADING, false);
  }
};

export const initContentTableSortOptions = async function ({
  commit,
  getters,
}) {
  if (!getters.contentTableSortOptionsArePopulated)
    commit(types.SET_CONTENT_TABLE_SORT_OPTIONS, {});
};

export const initAdditionalContentTableFilters = async function ({
  commit,
  getters,
}) {
  if (!getters.additionalContentTableFiltersArePopulated)
    commit(types.SET_CONTENT_TABLE_ADDITIONAL_FILTERS, [
      { key: 'isWithViews', value: true },
      { key: 'search', value: '' },
      { key: 'page', value: 1 },
      { key: 'parentIds', value: [] },
    ]);
};

export const getContentTableData = async function (
  { getters, commit },
  context = [],
) {
  const { queryParamsForContentTable: params } = getters;
  commit(types.SET_CONTENT_TABLE_LOADING, true);
  const rawContentTable = await this.$services.contentAnalytics.getTableData([
    ...context,
    ...params,
  ]);
  commit(types.SET_CONTENT_TABLE, rawContentTable);
  commit(types.SET_CONTENT_TABLE_LOADING, false);
  return;
};

export const debouncedGetContentTableData = debounce(getContentTableData, 700);

export const getContentSearchStatistics = async function ({ getters, commit }) {
  const { queryParamsForContentSearchStatistics } = getters;

  const queryParams = [...queryParamsForContentSearchStatistics];

  commit(types.SET_CONTENT_SEARCH_STATISTICS_LOADING, true);
  const rawContentSearchStatistics =
    await this.$services.contentAnalytics.getSearchStatistics(queryParams);
  commit(types.SET_CONTENT_SEARCH_STATISTICS, rawContentSearchStatistics);
  commit(types.SET_CONTENT_SEARCH_STATISTICS_LOADING, false);
};

export const exportContentSearchStatistics = async function (
  { getters, commit },
  { isMissed },
) {
  const { queryParamsForContentSearchStatistics } = getters;
  const queryParams = [
    ...queryParamsForContentSearchStatistics,
    { key: 'isMissed', value: isMissed },
  ];
  commit(types.EXPORT_USER_LOADING, { loading: true, isMissed });
  await this.$services.contentAnalytics.exportSearchStatistics(
    queryParams,
    isMissed,
  );
  commit(types.EXPORT_USER_LOADING, { loading: false, isMissed });
};

export const initAdditionalViewsGraphFilters = async function ({
  commit,
  getters,
}) {
  if (!getters.additionalViewsGraphFiltersArePopulated)
    commit(types.SET_VIEWS_GRAPH_ADDITIONAL_FILTERS, [
      { key: 'filterType', value: 'collection' },
      { key: 'graphType', value: 'line' },
    ]);
};

export const updateViewsFilterType = async function ({ commit, state }, value) {
  const idx = state.additionalViewsGraphFilters.findIndex(
    ({ key }) => key === 'filterType',
  );
  if (idx !== -1) {
    const el = state.additionalViewsGraphFilters[idx];
    commit(types.SET_VIEWS_GRAPH_ADDITIONAL_FILTER_VALUE, {
      value: { ...el, value },
      idx,
    });
  }
};

export const updateViewsGraphType = async function ({ commit, state }, value) {
  const idx = state.additionalViewsGraphFilters.findIndex(
    ({ key }) => key === 'graphType',
  );
  if (idx !== -1) {
    const el = state.additionalViewsGraphFilters[idx];
    commit(types.SET_VIEWS_GRAPH_ADDITIONAL_FILTER_VALUE, {
      value: { ...el, value },
      idx,
    });
  }
};

export const updateContentTableIsWithViews = async function (
  { commit, state },
  value,
) {
  const idx = state.additionalContentTableFilters.findIndex(
    ({ key }) => key === 'isWithViews',
  );
  if (idx !== -1) {
    const el = state.additionalContentTableFilters[idx];
    commit(types.SET_CONTENT_TABLE_ADDITIONAL_FILTER_VALUE, {
      value: { ...el, value },
      idx,
    });
  }
};

export const updateContentTableSearch = async function (
  { commit, state },
  value,
) {
  const idx = state.additionalContentTableFilters.findIndex(
    ({ key }) => key === 'search',
  );
  if (idx !== -1) {
    const currentFilter = state.additionalContentTableFilters[idx];
    commit(
      types.SET_CONTENT_TABLE_ADDITIONAL_SEARCH_FILTER_CHANGED,
      currentFilter.value !== value,
    );
    // { key: String, value: any }
    commit(types.SET_CONTENT_TABLE_ADDITIONAL_FILTER_VALUE, {
      value: { ...currentFilter, value },
      idx,
    });
  }
};

export const updateContentTablePage = async function (
  { commit, state },
  value,
) {
  const idx = state.additionalContentTableFilters.findIndex(
    ({ key }) => key === 'page',
  );
  if (idx !== -1) {
    const currentPage = state.additionalContentTableFilters[idx];
    commit(types.SET_CONTENT_TABLE_PAGE_CHANGED, currentPage.value !== value);
    // { key: String, value: any }
    commit(types.SET_CONTENT_TABLE_ADDITIONAL_FILTER_VALUE, {
      value: { key: 'page', value },
      idx,
    });
  }
};

export const updateContentTableCollections = async function (
  { commit, state },
  value,
) {
  const idx = state.additionalContentTableFilters.findIndex(
    ({ key }) => key === 'parentIds',
  );
  if (idx !== -1) {
    const currentFilter = state.additionalContentTableFilters[idx];
    commit(
      types.SET_CONTENT_TABLE_ADDITIONAL_COLLECTION_FILTER_CHANGED,
      currentFilter.value !== value,
    );
    // { key: String, value: any }
    commit(types.SET_CONTENT_TABLE_ADDITIONAL_FILTER_VALUE, {
      value: { key: 'parentIds', value },
      idx,
    });
  }
};

export const updateContentTableDataOptions = function (
  { commit, dispatch, getters },
  options,
) {
  const { currentPage, sort, filters } = options;
  const collectionsFilter = Array.isArray(filters)
    ? filters.find(({ key }) => key === 'collection')
    : null;
  const collectionFilterValue = collectionsFilter
    ? collectionsFilter.values
    : [];

  if (getters.contentTableSearchFilterChanged)
    commit(types.SET_CONTENT_TABLE_ADDITIONAL_SEARCH_FILTER_CHANGED, false);
  return Promise.all([
    dispatch('updateContentTableCollections', collectionFilterValue),
    dispatch('updateContentTableSortOptions', sort),
    dispatch('updateContentTablePage', currentPage),
  ]);
};

export const updateContentTableSortOptions = async function ({ commit }, sort) {
  if (sort != null) {
    commit(types.SET_CONTENT_TABLE_SORT_OPTIONS, sort);
  }
};

export const updateMergeTerms = function ({ commit }, value) {
  commit(types.SET_FILTERS_ADDITIONAL_VALUE, {
    key: 'mergeTerms',
    value,
  });
};
