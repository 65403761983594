export class AutomationRecollectionServiceData {
  constructor(axiosClient) {
    this.axiosClient = axiosClient;
  }
  getGroupsByKnowledge({ knowledgeId }) {
    try {
      return this.axiosClient.get('api/automations/groups-by-knowledge', {
        params: { knowledgeId },
      });
    } catch (error) {
      return [];
    }
  }
}
