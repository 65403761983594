import data from './companyPublicConfig.data.raw';

export class CompanyPublicConfigServiceData {
  constructor(client) {
    this.client = client;
  }

  getAllCompanyPublicConfigs() {
    return this.client
      .query({
        query: data.getAllCompanyPublicConfigs,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.CompanyPublicConfigs;
      })
      .catch((error) => error);
  }

  getCompanyPublicConfigById(id) {
    return this.client
      .query({
        query: data.getCompanyPublicConfigById,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.CompanyPublicConfig;
      })
      .catch((error) => error);
  }

  getCompanyPublicConfigByKnowledgeId(id) {
    return this.client
      .query({
        query: data.getCompanyPublicConfigByKnowledgeId,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.CompanyPublicConfig;
      })
      .catch((error) => error);
  }

  publishOperations(id, operations) {
    return this.client
      .mutate({
        mutation: data.publishOperations,
        variables: { id, operations },
      })
      .then((response) => {
        return response.data.changedRows;
      })
      .catch((error) => {
        throw error;
      });
  }

  getPendingDeploymentStatus(companyPublicConfigId) {
    return this.client
      .query({
        query: data.getPendingDeploymentStatus,
        variables: { companyPublicConfigId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.deploymentStatus;
      })
      .catch((error) => error);
  }

  createCompanyPublicConfig(subdomain, lang, operations, groups) {
    return this.client
      .mutate({
        mutation: data.createCompanyPublicConfig,
        variables: { subdomain, lang, operations, groups },
      })
      .then((response) => {
        return response.data.CompanyPublicConfig;
      })
      .catch((error) => error);
  }

  checkHostname(hostname) {
    return this.client
      .query({
        query: data.checkHostnameValidity,
        variables: { hostname },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.hostnameValidity;
      })
      .catch((error) => error);
  }

  checkCdnEndpointHttpsStatus(hostname) {
    return this.client
      .query({
        query: data.checkCdnEndpointHttpsStatus,
        variables: { hostname },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.cdnEndpointHttpsStatus;
      })
      .catch((error) => error);
  }
  checkCdnEndpointValidity(customHostname, companyPublicConfigId) {
    return this.client
      .query({
        query: data.checkCdnEndpointValidity,
        variables: { customHostname, companyPublicConfigId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.cdnEndpointValidity;
      })
      .catch((error) => {
        throw error;
      });
  }

  uploadPublicFile(fileName, fileData) {
    return this.client
      .mutate({
        mutation: data.uploadPublicFile,
        variables: { fileName, fileData },
      })
      .then((response) => {
        return response.data.uploadPublicFile;
      })
      .catch((error) => error);
  }
}
