import * as types from './mutation-types';

export default {
  clearTaskStore({ commit }, resetBacklog = true) {
    return commit(types.LOGOUT, resetBacklog);
  },

  async getCompanyTaskStatus({ commit, state }, filters) {
    state.companyTasksLoading = true;
    try {
      const companyTaskStatus = await this.$services.tasks.getCompanyTaskStatus(
        filters,
      );
      commit(types.SET_COMPANY_TASK_STATUS_AND_FIRST_TASKS, companyTaskStatus);
    } catch (e) {
      state.companyTasksLoading = false;
      return e;
    }
  },

  async getCompanyTasksPage({ commit, state }, payload) {
    const { statusId, filters } = payload;
    const { companyTasks } = state;

    try {
      const companyTasksByStatusIndex = companyTasks.findIndex(
        (el) => el.key === statusId,
      );
      if (companyTasksByStatusIndex < 0) return;
      const companyTasksByStatus = companyTasks[companyTasksByStatusIndex];

      const newCompanyTasks = await this.$services.tasks.getCompanyTasks({
        statusId,
        filters,
        page: companyTasksByStatus.value.page + 1,
      });
      if (!newCompanyTasks) return;
      newCompanyTasks.results = newCompanyTasks.results.filter((task) => {
        const idx = companyTasksByStatus.value.results.findIndex(
          (el) => el.id === task.id,
        );
        return idx === -1;
      });
      commit(types.SET_COMPANY_TASKS, {
        ...newCompanyTasks,
        companyTasksByStatusIndex,
      });
    } catch (e) {
      return e;
    }
  },

  addToFilter({ state, commit }, newFilter) {
    try {
      let filtersCopy = [...state.filters];
      filtersCopy = filtersCopy.filter(
        (f) => f.filter.key !== newFilter.filter.key,
      );
      if (newFilter.filter.value && newFilter.filter.value.length) {
        filtersCopy.push(newFilter);
      }
      commit(types.SET_FILTERS, filtersCopy);
    } catch (e) {
      return e;
    }
  },

  emptyFilters({ commit }) {
    try {
      commit(types.EMPTY_FILTERS);
    } catch (e) {
      return e;
    }
  },

  async getAssignableUsers({ commit }, permissions) {
    try {
      const usersWithPermissions =
        await this.$services.users.getUsersWithPermissions(permissions);
      commit(types.SET_USERS_WITH_PERMISSIONS, usersWithPermissions);
    } catch (e) {
      return e;
    }
  },

  async getTaskById({ commit, dispatch, state }, id) {
    const { focusTask } = state;
    try {
      const skipModalFields =
        !focusTask || focusTask.id.toString() !== id.toString();

      const task = await this.$services.tasks.getCompanyTaskById(
        id,
        skipModalFields,
      );
      commit(types.SET_COMPANY_TASK_BY_ID, task);
      dispatch('getBacklogTasksCount');
    } catch (e) {
      return e;
    }
  },

  async getBacklogTasksCount({ commit }) {
    try {
      const backlogTasksCount =
        await this.$services.tasks.getBacklogTasksCount();
      commit(types.GET_BACKLOG_TASKS_COUNT, { backlogTasksCount });
    } catch (e) {
      return e;
    }
  },

  /**
   * UPDATE
   */

  async setTaskRating({ commit }, payload) {
    try {
      const { taskId, rating, statusId } = payload;
      const newRating = await this.$services.tasks.setTaskRating(
        taskId,
        rating,
      );
      await this.$services.events.task.update(
        {
          task: {
            id: parseInt(taskId),
            update: { rating },
          },
        },
        this.$router.currentRoute.fullPath,
      );
      commit(types.SET_TASK_RATING, { taskId, statusId, rating: newRating });
    } catch (error) {
      console.log(error);
      return error;
    }
  },

  async deleteTaskRating({ commit }, payload) {
    try {
      const { taskId, statusId } = payload;
      const newRating = await this.$services.tasks.deleteTaskRating(taskId);
      await this.$services.events.task.update(
        {
          task: {
            id: parseInt(taskId),
            update: { rating: null },
          },
        },
        this.$router.currentRoute.fullPath,
      );
      commit(types.SET_TASK_RATING, { taskId, statusId, rating: newRating });
    } catch (error) {
      console.log(error);
      return error;
    }
  },

  async setAssignee({ commit }, payload) {
    try {
      const { taskId, statusId, assignee } = payload;
      await this.$services.tasks.setAssignee(taskId, assignee.id);
      this.$services.events.task.update(
        {
          task: {
            id: parseInt(taskId),
            update: {
              assignee: {
                id: parseInt(assignee.id),
                username: assignee.username,
              },
            },
          },
        },
        this.$router.currentRoute.fullPath,
      );
      commit(types.SET_ASSIGNEE, { taskId, statusId, assignee });
    } catch (error) {
      console.log(error);
      return error;
    }
  },

  async setFocusTask({ commit }, payload) {
    const { taskId } = payload;
    commit(types.SET_FOCUS_TASK_LOADING, { loading: true });
    try {
      const taskDetails = await this.$services.tasks.getCompanyTaskById(
        taskId,
        false,
      );
      this.$services.events.task.update(
        {
          task: {
            id: parseInt(taskId),
            update: { isFocused: true },
          },
        },
        this.$router.currentRoute.fullPath,
      );
      commit(types.SET_FOCUS_TASK, { taskDetails });
    } catch (error) {
      return error;
    } finally {
      commit(types.SET_FOCUS_TASK_LOADING, { loading: false });
    }
  },

  resetFocusTask({ commit }) {
    commit(types.SET_FOCUS_TASK, { taskDetails: null });
  },

  async setCompanyTaskStatus({ commit, dispatch }, payload) {
    const { taskId, newCompanyTaskStatusId, oldCompanyTaskStatusId } = payload;
    try {
      await this.$services.tasks.setCompanyTaskStatus(
        taskId,
        newCompanyTaskStatusId,
      );
      commit(types.SET_COMPANY_TASK_STATUS, {
        taskId,
        companyTaskStatusId: newCompanyTaskStatusId,
        oldCompanyTaskStatusId,
      });
      dispatch('logStatusUpdate', {
        taskId,
        newStatusId: newCompanyTaskStatusId,
        oldStatusId: oldCompanyTaskStatusId,
      });
    } catch (error) {
      return error;
    }
  },

  async bulkSetIsArchive({ commit, getters, dispatch }, payload) {
    const { ids, isArchive } = payload;
    try {
      // Keep a copy of tasks for logs before state changes
      const taskRefs = [...getters.getTasksByIds(ids)].map((task) => ({
        id: task.id,
        companyTaskStatusId: task.companyTaskStatusId,
        createdAt: task.createdAt,
        type: task.type,
      }));
      await this.$services.tasks.bulkSetIsArchive(ids, isArchive);
      commit(types.BULK_SET_IS_ARCHIVE, {
        ids,
      });
      [...taskRefs].forEach((task) => {
        if (isArchive) {
          taskRefs.oldStatusId = task.companyTaskStatusId;
        } else {
          taskRefs.newStatusId = task.companyTaskStatusId;
        }
        dispatch('logStatusUpdate', task);
      });
    } catch (error) {
      return error;
    }
  },

  async upvoteTask({ commit, rootState }, payload) {
    const { taskId, statusId } = payload;
    const { userId, username } = rootState;
    try {
      await this.$services.tasks.upvoteTask(taskId);
      await this.$services.events.task.reaction(
        {
          task: {
            id: parseInt(taskId),
            reaction: { upvote: { userId: parseInt(userId) } },
          },
        },
        this.$router.currentRoute.fullPath,
      );
      commit(types.UPVOTE_TASK, {
        taskId,
        statusId,
        user: { id: userId, username },
      });
    } catch (error) {
      return error;
    }
  },

  async removeUpvoteTask({ commit, rootState }, payload) {
    const { taskId, statusId } = payload;
    const { userId, username } = rootState;

    try {
      await this.$services.tasks.removeUpvoteTask(taskId);
      await this.$services.events.task.reaction(
        {
          task: {
            id: parseInt(taskId),
            reaction: { upvoteDelete: { userId: parseInt(userId) } },
          },
        },
        this.$router.currentRoute.fullPath,
      );
      commit(types.REMOVE_UPVOTE_TASK, {
        taskId,
        statusId,
        user: { id: userId, username },
      });
    } catch (error) {
      return error;
    }
  },

  async newTaskComment({ commit }, payload) {
    const {
      comment,
      attachments,
      taskId,
      isInternal = false,
      mentions,
    } = payload;
    try {
      const newComment = await this.$services.tasks.newTaskComment(
        taskId,
        comment,
        attachments,
        mentions,
        isInternal,
      );
      await this.$services.events.task.reaction(
        {
          task: {
            id: parseInt(taskId),
            reaction: {
              comment: {
                userId: parseInt(newComment.author.id),
                content: newComment.comment,
                isInternal,
                mentions,
              },
            },
          },
        },
        this.$router.currentRoute.fullPath,
      );
      commit(types.NEW_TASK_COMMENT, { newComment });
    } catch (error) {
      return error;
    }
  },

  async deleteTaskComment({ commit, state }, payload) {
    const { id } = payload;
    try {
      const isDeleted = await this.$services.tasks.deleteTaskComment(id);
      if (!isDeleted) return false;
      const commentToDeleteIdx = state.focusTask.comment.findIndex(
        (notif) => notif.id === id,
      );
      if (commentToDeleteIdx === -1) return false;
      commit(types.DELETE_TASK_COMMENT, { commentId: commentToDeleteIdx });
      return isDeleted;
    } catch (e) {
      return e;
    }
  },

  async updateTaskComment({ commit, state }, payload) {
    const { id, comment, attachments, mentions } = payload;
    try {
      await this.$services.tasks.updateTaskComment({
        id,
        comment,
        attachments,
        mentions,
      });
      let focusTaskCopy = { ...state.focusTask };
      const commentToUpdateIdx = focusTaskCopy.comment.findIndex(
        (notif) => notif.id === id,
      );
      if (commentToUpdateIdx === -1) return false;
      focusTaskCopy.comment[commentToUpdateIdx].comment = comment;
      focusTaskCopy.comment[commentToUpdateIdx].attachments = attachments;
      focusTaskCopy.comment[commentToUpdateIdx].updatedAt =
        Date.now().toString();
      commit(types.SET_FOCUS_TASK, { taskDetails: focusTaskCopy });
    } catch (e) {
      return e;
    }
  },

  /**
   * LOGS
   */
  async logStatusUpdate(
    { state, getters },
    { newStatusId, oldStatusId, taskId, createdAt, type },
  ) {
    const { companyTaskStatus } = state;

    const newStatus = companyTaskStatus.find(({ id }) => id === newStatusId);
    const oldStatus = companyTaskStatus.find(({ id }) => id === oldStatusId);
    const task = type
      ? { id: taskId, createdAt, type }
      : getters.getTaskById(taskId);

    await this.$services.events.task.updateStatus(
      {
        id: task.id,
        source: 'HUB',
        oldStatus: oldStatus ? orderToStatusLog[oldStatus.order] : 'Archived',
        newStatus: newStatus ? orderToStatusLog[newStatus.order] : 'Archived',
        creationDate: task.createdAt,
        type: task.type,
      },
      this.$router.currentRoute.fullPath,
    );
  },

  // HUB
  getSettingsTaskViews(_, { page = 1 }) {
    return this.$services.tasks.getSettingsTaskViews(page);
  },

  getTaskViews() {
    return this.$services.tasks.getTaskViews();
  },

  getTaskView(_, id) {
    return this.$services.tasks.getTaskView(id);
  },

  createTaskView(_, input) {
    return this.$services.tasks.createTaskView(input);
  },

  updateTaskView(_, input) {
    return this.$services.tasks.updateTaskView(input);
  },

  deleteTaskView(_, id) {
    return this.$services.tasks.deleteTaskView(id);
  },
};

const orderToStatusLog = {
  1: 'Backlog',
  2: 'To do',
  3: 'Doing',
  4: 'Over',
};
