import data from './contentVersion.data.raw';

export class ContentVersionServiceData {
  constructor(client) {
    this.client = client;
  }

  async getContentVersions(contentId, pageSize, page, lang) {
    return this.client
      .query({
        query: data.getContentVersions,
        variables: { contentId, pageSize, page, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentVersions)
      .catch((error) => error);
  }

  async getContentVersionsContributorIds(contentId, knowledgeId, lang) {
    return this.client
      .query({
        query: data.getContentVersionsContributorIds,
        variables: { contentId, knowledgeId, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentVersionsContributorIds)
      .catch((error) => error);
  }
}
