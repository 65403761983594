import gql from 'graphql-tag';

export default {
  getProductNotifications: gql`
    query {
      notifications: getProductNotifications {
        id
        title
        description
        urgent
        wasUrgent
        parameters
        tags
        accessRestrictions
        createdAt
      }
    }
  `,
  createProductNotification: gql`
    mutation createProductNotification(
      $title: String!
      $description: String!
      $urgent: Boolean!
      $parameters: [JSON]
      $tags: [String]
      $accessRestrictions: [ID!]!
    ) {
      createdNotification: createProductNotification(
        title: $title
        description: $description
        urgent: $urgent
        parameters: $parameters
        tags: $tags
        accessRestrictions: $accessRestrictions
      ) {
        id
        title
        description
        urgent
        wasUrgent
        parameters
        tags
        accessRestrictions
        createdAt
      }
    }
  `,
  deleteProductNotification: gql`
    mutation deleteProductNotification($id: ID!) {
      isProductNotificationDeleted: deleteProductNotification(id: $id)
    }
  `,
};
