import gql from 'graphql-tag';

export default {
  getCompanyKnowledges: gql`
    query getCompanyKnowledgesQuery {
      knowledges: getKnowledges {
        id
        label
        labelTranslations
        value
        key
        userPermissions
        icon {
          iconType
          value
          color
        }
        isDefault
        isDeleted
        publicData {
          isPublic
          description
          translations {
            key
            meta {
              title
              description
            }
          }
        }
        preferences {
          name
          value
        }
        plugins {
          id
          type
          elements
          position
          isVisible
          settings {
            title
            tooltip
            eligibleScope
          }
        }
        supportedLanguages
        defaultLanguage
      }
    }
  `,

  getSettingsKnowledges: gql`
    query getSettingsKnowledgesQuery {
      knowledges: getSettingsKnowledges {
        id
        label
        labelTranslations
        value
        key
        userPermissions
        icon {
          iconType
          value
          color
        }
        isDefault
        isDeleted
        publicData {
          isPublic
          description
          translations {
            key
            meta {
              title
              description
            }
          }
        }
        preferences {
          name
          value
        }
        plugins {
          id
          type
          elements
          position
          isVisible
          settings {
            title
            tooltip
            eligibleScope
          }
        }
        supportedLanguages
        defaultLanguage
      }
    }
  `,

  getHierarchies: gql`
    query getHierarchiesQuery {
      hierarchies: getHierarchies {
        id
        label
        value
        key
      }
    }
  `,
  getHierarchiesByValue: gql`
    query getHierarchiesByValueQuery($values: [String!]) {
      getHierarchiesByValue(values: $values) {
        id
        label
        value
      }
    }
  `,
  getHierarchiesAggregatedOptions: gql`
    query getHierarchiesAggregatedOptionsQuery($values: [String!]) {
      getHierarchiesAggregatedOptions(values: $values) {
        id
        label
        value
        optionsCount
      }
    }
  `,
  getHierarchiesAccessRestrictions: gql`
    query getHierarchiesAccessRestrictionsQuery($groupId: ID!) {
      getHierarchiesAccessRestrictions(groupId: $groupId) {
        id
        label
        value
        isRestricted
        focusIds
        nodeIdsToExpand
        restrictedNodes {
          id
          label
        }
      }
    }
  `,

  getKnowledgeDetails: gql`
    query getKnowledgeDetailsQuery {
      knowledgesDetails: getKnowledges {
        id
        label
        value
        key
        solutionsCount
        enrichedAccessRestrictions {
          id
          label
          isRestricted
        }
        icon {
          iconType
          value
          color
        }
        publicData {
          isPublic
        }
        isDefault
        isDeleted
        plugins {
          id
          type
          elements
          position
          isVisible
          settings {
            title
            tooltip
            eligibleScope
          }
        }
      }
    }
  `,

  getPinnedContents: gql`
    query getPinnedContentsQuery($hierarchyId: ID!) {
      getPinnedContents(id: $hierarchyId) {
        pinnedContents
        enrichedPinnedContents {
          id
          label
          type
        }
      }
    }
  `,

  updateKnowledgeLabelMutation: gql`
    mutation updateKnowledgeLabelMutation(
      $id: ID!
      $newLabel: String!
      $lang: String
    ) {
      updateHierarchyLabel(id: $id, newLabel: $newLabel, lang: $lang) {
        id
        label
        labelTranslations
        defaultLanguage
        value
        key
        solutionsCount
        enrichedAccessRestrictions {
          id
          label
          isRestricted
        }
        icon {
          iconType
          value
          color
        }
        isDefault
        isDeleted
      }
    }
  `,

  updateKnowledgeIsDefaultMutation: gql`
    mutation updateKnowledgeIsDefaultMutation($id: ID!) {
      updateHierarchyIsDefault(id: $id) {
        id
        label
        value
        key
        solutionsCount
        enrichedAccessRestrictions {
          id
          label
          isRestricted
        }
        icon {
          iconType
          value
          color
        }
        isDefault
        isDeleted
      }
    }
  `,

  updateKnowledgeIsDeletedMutation: gql`
    mutation updateKnowledgeIsDeletedMutation($id: ID!) {
      updateHierarchyIsDeleted(id: $id) {
        id
        label
        value
        key
        solutionsCount
        enrichedAccessRestrictions {
          id
          label
          isRestricted
        }
        icon {
          iconType
          value
          color
        }
        isDefault
        isDeleted
      }
    }
  `,

  updateKnowledgeIconMutation: gql`
    mutation updateKnowledgeIconMutation(
      $id: ID!
      $iconType: String!
      $value: String!
      $color: String
    ) {
      updateHierarchyIcon(
        id: $id
        iconType: $iconType
        value: $value
        color: $color
      ) {
        id
        label
        value
        key
        solutionsCount
        enrichedAccessRestrictions {
          id
          label
          isRestricted
        }
        icon {
          iconType
          value
          color
        }
        isDefault
      }
    }
  `,

  updateKnowledgeIsPublicMutation: gql`
    mutation updateKnowledgeIsPublicMutation($id: ID!, $isPublic: Boolean!) {
      updateHierarchyIsPublic(id: $id, isPublic: $isPublic) {
        id
        label
        publicData {
          isPublic
        }
      }
    }
  `,

  addHierarchyMutation: gql`
    mutation addHierarchyMutation(
      $label: String
      $lang: String
      $groups: [String]
    ) {
      addHierarchy(label: $label, lang: $lang, groups: $groups) {
        id
        label
        value
        key
        solutionsCount
        userPermissions
        enrichedAccessRestrictions {
          id
          label
          isRestricted
        }
        publicData {
          isPublic
        }
        icon {
          iconType
          value
          color
        }
        isDefault
        isDeleted
        publicData {
          isPublic
          description
          translations {
            key
            contentCount
            caseCount
            meta {
              title
              description
            }
          }
        }
        preferences {
          name
          value
        }
        plugins {
          id
          type
        }
        supportedLanguages
        defaultLanguage
      }
    }
  `,

  updateHierarchyChildrenOrder: gql`
    mutation updateHierarchyChildrenOrderMutation(
      $id: ID!
      $childrenOrder: [JSON!]
    ) {
      updateHierarchyChildrenOrder(id: $id, childrenOrder: $childrenOrder) {
        id
        value
      }
    }
  `,

  updateHierarchyPreferences: gql`
    mutation updateHierarchyPreferences(
      $id: ID!
      $preferences: [PreferenceInput]
    ) {
      updateHierarchyPreferences(id: $id, preferences: $preferences) {
        id
        label
        value
        key
        preferences {
          name
          value
        }
      }
    }
  `,

  updatePinnedContents: gql`
    mutation updatePinnedContentsMutation($id: ID!, $pinnedContents: [ID!]) {
      updatePinnedContents(id: $id, pinnedContents: $pinnedContents) {
        id
        pinnedContents
      }
    }
  `,

  addLanguage: gql`
    mutation addLanguage($id: ID!, $lang: String) {
      addLanguage(id: $id, lang: $lang) {
        id
        label
        supportedLanguages
        labelTranslations
        defaultLanguage
        value
        key
        publicData {
          isPublic
          description
          translations {
            key
            meta {
              title
              description
            }
          }
        }
      }
    }
  `,

  setMultilingual: gql`
    mutation setMultilingual($id: ID!, $lang: String) {
      setMultilingual(id: $id, lang: $lang) {
        id
        label
        value
        key
        publicData {
          isPublic
          description
          translations {
            key
            meta {
              title
              description
            }
          }
        }
      }
    }
  `,

  updatePublicDataMetaTranslations: gql`
    mutation updatePublicDataMetaTranslations(
      $id: ID!
      $newMeta: [NewMetaInput]
    ) {
      updatePublicDataMetaTranslations(id: $id, newMeta: $newMeta) {
        id
        label
        value
        key
        publicData {
          isPublic
          description
          translations {
            key
            meta {
              title
              description
            }
          }
        }
      }
    }
  `,
};
