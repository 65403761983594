export class HierarchyService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async getKnowledges() {
    return this.data.getCompanyKnowledges();
  }

  // THIS SHOULD NEVER BE USED IN KNOWLEDGE NAVIGATION, ONLY FOR SETTINGS/ADMINS VIEWS
  async getSettingsKnowledges() {
    return this.data.getSettingsKnowledges();
  }

  async getHierarchies() {
    return this.data.getHierarchies();
  }

  async getByValues(values) {
    return this.data.getHierarchiesByValue(values);
  }

  async getAggregatedOptions(values) {
    return this.data.getHierarchiesAggregatedOptions(values);
  }
  async getAccessRestrictions(values) {
    return this.data.getHierarchiesAccessRestrictions(values);
  }

  async getKnowledgeDetails() {
    return this.data.getKnowledgeDetails();
  }

  async getPinnedContents(HierarchyId) {
    return this.data.getPinnedContents(HierarchyId);
  }

  /**
   * UPDATE METHODS
   */
  async updateLabel(id, newLabel, lang) {
    return this.data.updateLabel(id, newLabel, lang);
  }

  async updateIsDefault(id) {
    return this.data.updateIsDefault(id);
  }

  async updateIsDeleted(id) {
    return this.data.updateIsDeleted(id);
  }

  async updateIcon(id, iconType, value, color) {
    return this.data.updateIcon(id, iconType, value, color);
  }

  async updateIsPublic(id, isPublic) {
    return this.data.updateIsPublic(id, isPublic);
  }

  async updatePinnedContents(id, pinnedContents) {
    return this.data.updatePinnedContents(id, pinnedContents);
  }

  async addLanguage(id, lang) {
    return this.data.addLanguage(id, lang);
  }

  async setMultilingual(id, lang) {
    return this.data.setMultilingual(id, lang);
  }

  async updatePublicDataMetaTranslations(id, newTranslations) {
    return this.data.updatePublicDataMetaTranslations(id, newTranslations);
  }

  /**
   * CREATE METHODS
   */

  async add(label, lang, groups) {
    return this.data.addHierarchy(label, lang, groups);
  }

  /**
   * UPDATE METHODS
   */
  async updateHierarchyChildrenOrder(id, childrenOrder) {
    return this.data.updateHierarchyChildrenOrder(id, childrenOrder);
  }
  async updateHierarchyPreferences(id, preferences) {
    return this.data.updateHierarchyPreferences(id, preferences);
  }
}
