import * as common from './common';
import * as knowledge from './knowledge';
import * as content from './content';
import * as treeNode from './treeNode';
import * as magicAnswer from './magicAnswer';
import * as automation from './automation';
import * as product from './product';

export default {
  ...common,
  ...knowledge,
  ...content,
  ...treeNode,
  ...magicAnswer,
  ...automation,
  ...product,
};
