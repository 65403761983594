<template>
  <modal class="create-notification-modal" :show="localOpen">
    <new-notification-ce
      :company-id="companyId"
      :user-id="userId"
      :access-token="accessToken"
      :locale="appLanguage"
      :env="JSON.stringify(envVars)"
      :entity-id="entityId"
      :entity-type="entityType"
      :entity-label="entityLabel"
      :entity-lang="entityLang"
      :lang="$route.params.lang"
      :contributors="JSON.stringify(allContributors)"
      :source="source"
      @cancel="closeModal"
      @close-success="closeModal"
    />
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'CreateNotificationModalCE',
  components: { Modal },
  props: {
    open: Boolean,
    entityId: {
      type: String,
      default: null,
    },
    entityType: {
      type: String,
      default: null,
    },
    entityLabel: {
      type: String,
      default: null,
    },
    entityLang: {
      type: String,
      default: null,
    },
    linkContent: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: 'NOTIFICATION_CENTER',
    },
  },
  data() {
    return {
      localOpen: this.open,
    };
  },
  computed: {
    envVars() {
      return {
        VITE_APP_BACK_URL: process.env.VUE_APP_BACK_URL,
        VITE_APP_MONGO_BACK_URL: process.env.VUE_APP_MONGO_BACK_URL,
        VITE_APP_NOTIFICATIONS_URL: process.env.VUE_APP_NOTIFICATIONS_URL,
        VITE_APP_CORS_LOCAL_HEADER: process.env.VUE_APP_CORS_LOCAL_HEADER,
        VITE_APP_CORS_LOCAL_SECRET: process.env.VUE_APP_CORS_LOCAL_SECRET,
        VITE_APP_LOGS_URL: process.env.VUE_APP_LOGS_URL,
      };
    },
    ...mapState(['userId', 'appLanguage', 'companyId', 'accessToken']),
    ...mapGetters('adminModule', ['allContributors']),
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.closeModal();
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('events', {
        eventName: 'close',
        eventData: null,
      });
    },
    goToContent({ detail }) {
      const payload = { ...detail, newTab: true };
      this.handleOnClickInlineContent(payload);
    },
    ...mapActions('knowledgeModule', ['handleOnClickInlineContent']),
  },
  created() {
    window.addEventListener('md-close-new-notification', this.closeModal);
    window.addEventListener('md-go-to-content', this.goToContent);
  },
  destroyed() {
    window.removeEventListener('md-close-new-notification', this.closeModal);
    window.removeEventListener('md-go-to-content', this.goToContent);
  },
};
</script>

<style lang="scss">
.justify-end {
  justify-content: flex-end;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.space-x-1 > * + * {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
}
.text-gray-8 {
  --tw-text-opacity: 1;
  color: rgb(79 79 79 / var(--tw-text-opacity));
}
.tracking-wide {
  letter-spacing: 0.02em;
}
.font-bold {
  font-weight: 700;
}
.text-xs {
  font-size: 12px;
  line-height: 14px;
}
.px-2\.5 {
  padding-left: 10px;
  padding-right: 10px;
}
.bg-transparent {
  background-color: transparent;
}
.rounded {
  border-radius: 4px;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.min-w-5\.5 {
  min-width: 22px;
  border-color: transparent !important;
  &:focus {
    outline: none;
  }
}
.h-5\.5 {
  height: 22px;
}
.disabled\:\!text-gray-5:disabled {
  color: #adb5bd !important;
}
.disabled\:\!bg-gray-2:disabled {
  background-color: #e9ecef !important;
}
.disabled\:\!cursor-not-allowed:disabled {
  cursor: not-allowed !important;
}
button.bg-blue:hover.disabled\:\!bg-gray-2:disabled {
  background-color: #e9ecef !important;
}
</style>
<style lang="scss" scoped>
.create-notification-modal {
  :deep() .modal-body {
    height: 90vh;
    max-height: 90vh;
    padding: 0;
    overflow: auto;
  }

  :deep() .modal-content {
    border-radius: 4px !important;
  }

  :deep() .modal-dialog {
    max-width: 900px !important;
  }
}
</style>
