export class UserDirectoryService {
  constructor(data) {
    this.data = data;
  }

  async addCompanyFavorite(entityId) {
    return this.data.addCompanyFavorite(entityId);
  }
  async removeCompanyFavorite(entityId) {
    return this.data.removeCompanyFavorite(entityId);
  }
  async isCompanyFavorite(entityId) {
    return this.data.isCompanyFavorite(entityId);
  }

  async getSettingsPluginEntities(type) {
    return this.data.getSettingsPluginEntities(type);
  }

  async updatePluginEntities(pluginEntities) {
    return this.data.updatePluginEntities(pluginEntities);
  }

  async updateAccessRestrictions(payload) {
    return this.data.updateAccessRestrictions(payload);
  }
}
