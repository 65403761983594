export default () => ({
  /**
   * PRODUCT PAGE
   */
  product: null,
  productLoading: false,
  isPartial: false,
  lastProductLoadingTimestamp: null,
  productError: null,

  /**
   * PRODUCT RESOLUTIONS
   */
  resolutions: [],
  resolutionsCount: 0,
  resolutionsLoading: false,

  /**
   * PRODUCT ATTACEHD RESOLUTIONS
   */
  attachedResolutions: [],
  attachedResolutionsCount: 0,
  attachedResolutionsLoading: false,

  /**
   * PRODUCT COLLECTIONS
   */
  collections: [],
  collectionParents: [],
  collectionsLoading: false,
});
