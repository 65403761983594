import data from './magicAnswer.data.raw';

export class MagicAnswerServiceData {
  constructor(client) {
    this.client = client;
  }

  async createMagicAnswer(magicAnswer) {
    return this.client
      .mutate({
        mutation: data.createKnowledgeMagicAnswer,
        variables: magicAnswer,
      })
      .then((response) => response.data.createKnowledgeMagicAnswer.magicAnswers)
      .catch((error) => error);
  }

  async deleteMagicAnswer(id) {
    return this.client
      .mutate({
        mutation: data.deleteKnowledgeMagicAnswer,
        variables: {
          id,
        },
      })
      .then((response) => response.data.deleteKnowledgeMagicAnswer.magicAnswers)
      .catch((error) => error);
  }
}
