export class WebsocketsServiceData {
  constructor(client, axiosClient) {
    this.client = client;
    this.axiosClient = axiosClient;
  }

  async grantPermissionToGroup({ targetGroup, connectionId, companyId }) {
    return this.axiosClient.post(`/auth/web-pubsub/grant`, {
      targetGroup,
      connectionId,
      companyId,
    });
  }
}