export default {
  event:
    (state) =>
    ({ uid, modalName }) =>
      state.uid === uid && state.component === modalName
        ? {
            name: state.eventName,
            data: state.eventData,
            wait: state.eventWait,
          }
        : null,
};
