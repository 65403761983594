import * as types from './mutation-types';

export default {
  async getModuleLabelsCategories({ commit }) {
    try {
      commit(types.SET_MODULE_LABELS_CATEGORIES_LOADING, true);

      const moduleLabelsCategories =
        await this.$services.academy.moduleLabels.getCategories();

      if (!moduleLabelsCategories || !Array.isArray(moduleLabelsCategories))
        throw new Error('INTERNAL_ERROR');

      commit(types.SET_MODULE_LABELS_CATEGORIES, moduleLabelsCategories);

      return true;
    } catch (e) {
      return false;
    } finally {
      commit(types.SET_MODULE_LABELS_CATEGORIES_LOADING, false);
    }
  },
  async createModuleLabelCategory(
    { commit, state },
    { title, icon, parentId },
  ) {
    try {
      const moduleLabel = await this.$services.academy.moduleLabels.create({
        title,
        icon,
        parentId,
      });

      if (!moduleLabel) throw new Error('INTERNAL_ERROR');

      const moduleLabelsCategories = [
        ...state.moduleLabelsCategories,
        moduleLabel,
      ];

      commit(types.SET_MODULE_LABELS_CATEGORIES, moduleLabelsCategories);

      return true;
    } catch (e) {
      return false;
    }
  },
  async updateModuleLabelCategoryTitle({ commit, state }, { id, title }) {
    try {
      const moduleLabelCategory =
        await this.$services.academy.moduleLabels.updateTitle({ id, title });

      if (!moduleLabelCategory) throw new Error('INTERNAL_ERROR');

      const stateCopy = [...state.moduleLabelsCategories];

      const moduleLabelCategoryIndex = stateCopy.findIndex(
        (category) => category.id === id,
      );

      if (moduleLabelCategoryIndex === -1) throw new Error('INTERNAL_ERROR');

      stateCopy[moduleLabelCategoryIndex].title = moduleLabelCategory.title;

      commit(types.SET_MODULE_LABELS_CATEGORIES, stateCopy);

      return true;
    } catch (e) {
      return false;
    }
  },
  async updateModuleLabelCategoryIcon({ commit, state }, { id, icon }) {
    try {
      const moduleLabelCategory =
        await this.$services.academy.moduleLabels.updateIcon({ id, icon });

      if (!moduleLabelCategory) throw new Error('INTERNAL_ERROR');

      const stateCopy = [...state.moduleLabelsCategories];

      const moduleLabelCategoryIndex = stateCopy.findIndex(
        (category) => category.id === id,
      );

      if (moduleLabelCategoryIndex === -1) throw new Error('INTERNAL_ERROR');

      stateCopy[moduleLabelCategoryIndex].icon = moduleLabelCategory.icon;

      commit(types.SET_MODULE_LABELS_CATEGORIES, stateCopy);

      return true;
    } catch (e) {
      return false;
    }
  },
  async deleteModuleLabelCategory({ commit, state }, id) {
    try {
      const moduleLabelCategory =
        await this.$services.academy.moduleLabels.delete(id);

      if (!moduleLabelCategory) throw new Error('INTERNAL_ERROR');

      const stateCopy = [...state.moduleLabelsCategories];

      const moduleLabelCategoryIndex = stateCopy.findIndex(
        (category) => category.id === id,
      );

      if (moduleLabelCategoryIndex === -1) throw new Error('INTERNAL_ERROR');

      stateCopy.splice(moduleLabelCategoryIndex, 1);

      commit(types.SET_MODULE_LABELS_CATEGORIES, stateCopy);

      return true;
    } catch (e) {
      return false;
    }
  },
  /* Module Labels */
  async createModuleLabel({ commit, state }, { title, icon, parentId }) {
    try {
      const moduleLabel = await this.$services.academy.moduleLabels.create({
        title,
        icon,
        parentId,
      });

      if (!moduleLabel) throw new Error('INTERNAL_ERROR');

      const stateCopy = [...state.moduleLabelsCategories];

      const category = stateCopy.find((category) => category.id === parentId);

      if (!category) throw new Error('INTERNAL_ERROR');

      category.children.push(moduleLabel);

      commit(types.SET_MODULE_LABELS_CATEGORIES, stateCopy);

      return true;
    } catch (e) {
      return false;
    }
  },
  async updateModuleLabelTitle({ commit, state }, { id, title }) {
    try {
      const moduleLabel = await this.$services.academy.moduleLabels.updateTitle(
        { id, title },
      );

      if (!moduleLabel) throw new Error('INTERNAL_ERROR');

      const { parentId } = moduleLabel;

      const stateCopy = [...state.moduleLabelsCategories];

      const category = stateCopy.find((category) => category.id === parentId);

      if (!category) throw new Error('INTERNAL_ERROR');

      const moduleLabelIndex = category.children.findIndex(
        (moduleLabel) => moduleLabel.id === id,
      );

      if (moduleLabelIndex === -1) throw new Error('INTERNAL_ERROR');

      category.children[moduleLabelIndex].title = moduleLabel.title;

      commit(types.SET_MODULE_LABELS_CATEGORIES, stateCopy);

      return true;
    } catch (e) {
      return false;
    }
  },
  async updateModuleLabelIcon({ commit, state }, { id, icon }) {
    try {
      const moduleLabel = await this.$services.academy.moduleLabels.updateIcon({
        id,
        icon,
      });

      if (!moduleLabel) throw new Error('INTERNAL_ERROR');

      const { parentId } = moduleLabel;

      const stateCopy = [...state.moduleLabelsCategories];

      const category = stateCopy.find((category) => category.id === parentId);

      if (!category) throw new Error('INTERNAL_ERROR');

      const moduleLabelIndex = category.children.findIndex(
        (moduleLabel) => moduleLabel.id === id,
      );

      if (moduleLabelIndex === -1) throw new Error('INTERNAL_ERROR');

      category.children[moduleLabelIndex].icon = moduleLabel.icon;

      commit(types.SET_MODULE_LABELS_CATEGORIES, stateCopy);

      return true;
    } catch (e) {
      return false;
    }
  },
  async deleteModuleLabel({ commit, state }, { id, parentId }) {
    try {
      const moduleLabel = await this.$services.academy.moduleLabels.delete(id);

      if (!moduleLabel) throw new Error('INTERNAL_ERROR');

      const stateCopy = [...state.moduleLabelsCategories];

      const category = stateCopy.find((category) => category.id === parentId);

      if (!category) throw new Error('INTERNAL_ERROR');

      const moduleLabelIndex = category.children.findIndex(
        (moduleLabel) => moduleLabel.id === id,
      );

      if (moduleLabelIndex === -1) throw new Error('INTERNAL_ERROR');

      category.children.splice(moduleLabelIndex, 1);

      commit(types.SET_MODULE_LABELS_CATEGORIES, stateCopy);

      return true;
    } catch (e) {
      return false;
    }
  },
  async goToTranslatedAcademyEntity(
    _,
    { slideId, publishedModuleId, entityType, lang },
  ) {
    if (!entityType) throw new Error('Invalid Type');
    if (!lang) lang = this.$router.history.current.params.lang;

    let url = `/academy/published-modules/${lang}/${publishedModuleId}`;

    if (entityType === 'Slide') {
      url += `?s=${slideId}#content`;
    }

    return window.open(url);
  },
};
