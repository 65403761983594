import gql from 'graphql-tag';

export default {
  getContentParameterCategories: gql`
    query getContentParameterCategories($count: Int) {
      contentParameterCategories: getContentParameterCategories(count: $count) {
        id
        name
        labels {
          id
          labelName
          icon
          color
          categoryName
        }
        userPermissions
      }
    }
  `,
  updateContentParameterCategory: gql`
    mutation updateContentParameterCategory(
      $oldName: String!
      $newName: String!
    ) {
      updatedContentParameterCategory: updateContentParameterCategory(
        oldName: $oldName
        newName: $newName
      ) {
        id
        name
        labels {
          id
          labelName
          icon
          color
          categoryName
        }
      }
    }
  `,
  deleteContentParameterCategory: gql`
    mutation deleteContentParameterCategory($categoryName: String!) {
      isContentParameterCategoryDeleted: deleteContentParameterCategory(
        categoryName: $categoryName
      )
    }
  `,
};
