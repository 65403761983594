import gql from 'graphql-tag';

export const EMPLOYEES_FRAGMENT = gql`
  fragment EmployeesFragment on FilterResults {
    results {
      id
      helpdeskId
      username
      givenName
      familyName
      email
      jobtitle
      externalId
      helpdeskId
      isVerified
      createdAt
      role {
        id
        name
      }
      groups {
        id
        name
      }
      labels {
        id
        name
        companyUserLabelCategoryId
      }
    }
    total
    pages
  }
`;

export const PENDING_EMPLOYEES_FRAGMENT = gql`
  fragment PendingEmployeesFragment on FilterResults {
    results {
      id
      email
      isVerified
      createdAt
      role {
        id
        name
      }
      groups {
        id
        name
      }
      labels {
        id
        name
        companyUserLabelCategoryId
      }
      invitationToken {
        isExpired
        invitationLink
        expirationDate
      }
    }
    total
    pages
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    helpdeskId
    username
    givenName
    familyName
    email
    jobtitle
    helpdeskId
    isVerified
    createdAt
    role {
      id
      name
    }
    groups {
      id
      name
    }
    labels {
      id
      name
      companyUserLabelCategoryId
    }
  }
`;
