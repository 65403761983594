<template>
  <modal :show.sync="localDisplay">
    <!-- HEADER -->
    <div class="header-card">
      <div class="title">
        {{ $t('knowledge.templates.create.title') }}
      </div>
      <div class="divider"></div>
      <div class="description">
        {{ $t('knowledge.templates.create.description') }}
      </div>
    </div>
    <!-- BODY -->
    <div class="template-inputs">
      <div v-for="input in inputs" :key="input.path" class="template-input">
        <label :for="input.path">{{ input.label }}</label>
        <input
          v-if="input.type === 'text'"
          v-model="input.value"
          :name="input.path"
          :placeholder="input.placeholder"
        />
        <textarea
          v-if="input.type === 'textarea'"
          v-model="input.value"
          :name="input.path"
          :placeholder="input.placeholder"
          rows="3"
        />
        <div v-if="input.type === 'radio'" class="radio-group">
          <div
            v-for="option in input.options"
            :key="option.label"
            class="radio-wrapper"
            @click="input.value = option.label"
          >
            <IconWrapper :icon="option.icon" :size="42" />
            <el-radio
              class="radio"
              v-model="input.value"
              :label="option.label"
            ></el-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <el-button @click="localDisplay = false" size="small">{{
        $t('knowledge.templates.create.cancel')
      }}</el-button>
      <el-button
        @click="handleChoice"
        :disabled="!this.values.name"
        type="primary"
        size="small"
        >{{ $t('knowledge.templates.create.validate') }}</el-button
      >
    </div>
  </modal>
</template>
<script>
import IconWrapper from '@/components/IconWrapper';
import articleIcon from '@/assets/article-icon-v2.1.svg';
import diagIcon from '@/assets/diag-icon-v2.1.svg';

export default {
  name: 'add-template-modal',
  components: {
    IconWrapper,
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    values() {
      return Object.fromEntries(
        this.inputs.map((input) => [input.path, input.value]),
      );
    },
  },
  data() {
    return {
      localDisplay: this.display,
      inputs: [],
    };
  },
  created() {
    this.inputs = [
      ...(this.type
        ? [
            {
              label: this.$t('knowledge.templates.create.inputs.type'),
              value: 'Article',
              path: 'type',
              type: 'radio',
              options: [
                {
                  label: 'Article',
                  icon: {
                    iconType: 'url',
                    value: articleIcon,
                    color: null,
                  },
                },
                {
                  label: 'Diagnostic',
                  icon: {
                    iconType: 'url',
                    value: diagIcon,
                    color: null,
                  },
                },
              ],
            },
          ]
        : []),
      {
        label: this.$t('knowledge.templates.create.inputs.name'),
        value: this.name,
        path: 'name',
        type: 'text',
        placeholder: this.$t('knowledge.templates.create.placeholder.name'),
      },
      {
        label: this.$t('knowledge.templates.create.inputs.description'),
        value: '',
        path: 'description',
        type: 'textarea',
        placeholder: this.$t(
          'knowledge.templates.create.placeholder.description',
        ),
      },
    ];
  },
  methods: {
    handleChoice() {
      if (!this.values.name) return;
      this.$emit('events', {
        eventName: 'choice',
        eventData: this.values,
      });
      this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-body {
  padding: 16px;
}

.header-card {
  padding: 12px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .divider {
    content: '';
    height: 1px;
    width: 80%;
    background-color: $grey-4-mayday;
    margin-bottom: 0px;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}

.template-inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0px;
}

.template-input {
  font-size: 14px;
  input,
  textarea {
    outline: none;
    padding: 8px 8px;
    width: 100%;
    background-color: $grey-1-mayday;
    border: solid 1px $grey-3-mayday;
    border-radius: 4px;
    resize: none;
  }
  label {
    margin-bottom: 4px;
  }
}

.modal-footer {
  display: flex;
  align-items: right;
  padding: 0;
}

.radio-group {
  display: flex;
  width: 100%;
  gap: 16px;
}

.radio-wrapper {
  width: 100%;
  background-color: $grey-1-mayday;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
  border: solid 1px $grey-3-mayday;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border: solid 1px $blue-mayday;
  }
}

.radio {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 16px;
}
</style>
