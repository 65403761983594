import gql from 'graphql-tag';

export default {
  addCompanyFavorite: gql`
    mutation addCompanyFavoriteMutation($entityId: String!) {
      favorites: addCompanyFavorite(entityId: $entityId) {
        enrichedChildren {
          id
          type
          entity {
            id
            label
            type
          }
        }
      }
    }
  `,
  removeCompanyFavorite: gql`
    mutation removeCompanyFavoriteMutation($entityId: String!) {
      favorites: removeCompanyFavorite(entityId: $entityId) {
        enrichedChildren {
          id
          type
          entity {
            id
            label
            type
          }
        }
      }
    }
  `,
  isCompanyFavorite: gql`
    query isCompanyFavorite($id: ID!) {
      isCompanyFavorite: isCompanyFavorite(id: $id)
    }
  `,

  getSettingsPluginEntities: gql`
    query getSettingsPluginEntities($type: String!) {
      pluginEntities: getSettingsPluginEntities(type: $type) {
        id
        label
        type
        description
        url
        color
        title
        subtype
        integrations {
          name
          isActive
        }
        icon {
          iconType
          value
        }
        accessRestrictions
      }
    }
  `,

  updatePluginEntities: gql`
    mutation updatePluginEntitiesMutation(
      $pluginEntities: [PluginEntityInput]
    ) {
      pluginEntities: updatePluginEntities(pluginEntities: $pluginEntities) {
        type
        id
        label
        description
        url
        integrations {
          name
          isActive
        }
        icon {
          iconType
          value
        }
        subtype
        color
        title
        accessRestrictions
      }
    }
  `,

  updateAccessRestrictions: gql`
    mutation updateAccessRestrictionsMutation(
      $id: String!
      $roleIds: [String]
    ) {
      pluginEntities: updateAccessRestrictions(id: $id, roleIds: $roleIds) {
        type
        id
        label
        description
        url
        integrations {
          name
          isActive
        }
        icon {
          iconType
          value
        }
        subtype
        color
        title
        accessRestrictions
      }
    }
  `,
};
