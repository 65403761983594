import data from './integration.data.raw.js';

export class IntegrationServiceData {
  constructor(client, axiosClient) {
    this.client = client;
    this.axiosClient = axiosClient;
  }

  /**
   * READ METHODS
   */

  async checkAuthExists(integration) {
    return this.axiosClient.head(`/auth/${integration.toLowerCase()}/check`);
  }

  async getOauthInitUrl(integration) {
    return this.axiosClient.get(`/auth/init/${integration.toLowerCase()}`);
  }

  async getAvailableFields(integration) {
    return this.axiosClient.get(`/admin/integration/attributes`, {
      params: { integration },
    });
  }

  async getSalesforceAuth(companyId) {
    return this.client
      .query({
        query: data.getSalesforceAuth,
        variables: {
          companyId,
        },
      })
      .then((response) => response.data.getSalesforceAuth);
  }

  async getNotionAuth(companyId) {
    return this.client
      .query({
        query: data.getNotionAuth,
        variables: {
          companyId,
        },
      })
      .then((response) => response.data.getNotionAuth);
  }

  async getImportedArticles(page, query, source) {
    return this.client
      .query({
        query: data.getImportedArticles,
        variables: {
          page,
          query,
          source,
        },
      })
      .then((response) => response.data.getImportedArticles);
  }

  /**
   * CREATE METHODS
   */

  async addZendeskAuth(subdomain, clientId, clientSecret) {
    return this.client
      .mutate({
        mutation: data.addZendeskAuth,
        variables: {
          subdomain,
          clientId,
          clientSecret,
        },
      })
      .then((response) => response.data.initializeZendeskAuth);
  }

  addSalesforceAuth(instanceUrl, clientId, clientSecret) {
    return this.axiosClient.post('/auth/init/salesforce', {
      instanceUrl,
      clientId,
      clientSecret,
    });
  }

  async addNotionAuth(token) {
    return this.client
      .mutate({
        mutation: data.addNotionAuth,
        variables: {
          token,
        },
      })
      .then((response) => response.data.initializeNotionAuth);
  }

  async addIadvizeAuth(token, projectId) {
    return this.client
      .mutate({
        mutation: data.addIadvizeAuth,
        variables: {
          token,
          projectId,
        },
      })
      .then((response) => response.data.addIadvizeAuth);
  }

  /**
   * UPDATE METHODS
   */

  /**
   * DELETE METHODS
   */

  async deleteAuth(integration) {
    return this.axiosClient.delete(`/auth/${integration.toLowerCase()}`);
  }
}
