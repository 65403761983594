/* eslint-disable max-len */
import axios from 'axios';
import * as types from './mutation-types';
import { updateArrayObject } from 'utils/utils';
export * from './auth.actions';

import CustomLRU from '../utils/customLRU';

// APP VERSION

export const fetchVersion = async ({ commit }) => {
  try {
    const res = await axios.get(process.env.VUE_APP_REMOTE_VERSION || '', {
      // query URL without using browser cache
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    commit(types.SET_BLOB_VERSION, res.data);
  } catch (error) {
    commit(types.SET_BLOB_VERSION, {});
  }
};

export const startVersionPolling = async ({ commit, dispatch }) => {
  if (!process.env.VUE_APP_REMOTE_VERSION || !process.env.VUE_APP_VERSION) {
    console.warn('Missing some version environment variables');
    return;
  }
  const timeoutId = setTimeout(async () => {
    await dispatch('fetchVersion');

    dispatch('startVersionPolling');
  }, 30000); // 30 seconds

  commit(types.SET_VERSION_POLLING_TIMEOUT_ID, timeoutId);
};

export const setBlobVersion = ({ commit }) => {
  commit(types.SET_BLOB_VERSION);
};

// CUSTOM CONFIG
export const fetchCustomConfig = async ({ commit }, host) => {
  if (host.includes('localhost')) {
    commit(types.SET_CUSTOM_CONFIG, {});
    return;
  }

  let subdomain;
  try {
    if (host.match(/(.*).mayday.fr/)[1]) {
      subdomain = host.match(/(.*).mayday.fr/)[1];
    }
  } catch (err) {
    console.error('Failed to fetch subdomain');
  }

  if (['dev', 'staging', 'app'].includes(subdomain) || !subdomain) return;

  try {
    const res = await axios.get(
      `${process.env.VUE_APP_REMOTE_CUSTOM_CONFIG}/${subdomain}.json`,
    );
    commit(types.SET_CUSTOM_CONFIG, res.data);
  } catch (err) {
    console.error(err);
  }
};

export const resetAuthAttempt = ({ commit }) =>
  commit(types.RESET_AUTH_ATTEMPT);

export const setBlockedLoginStartTime = ({ commit }) => {
  commit(types.SET_BLOCKED_LOGIN_START_TIME);
};

export const headerSearchChoice = ({ commit }, hit) =>
  commit(types.HEADER_SEARCH_CHOICE, hit);

export const resetHeaderSearch = ({ commit }) =>
  commit(types.RESET_HEADER_SEARCH);

export const initIntegrationAttributes = async function (_, integration) {
  return Promise.all([
    this.$services.companyAttributes.get(integration),
    this.$services.integrations.getAvailableFields(integration),
  ]);
};

export const getIntegrationAttributes = async function (_, integration) {
  return this.$services.integrations.getAvailableFields(integration);
};

export const getCompanyAttributes = async function ({ commit }) {
  const attributes = await this.$services.companyAttributes.get();
  commit(types.UPDATE_COMPANY_ATTRIBUTES, {
    companyAttributes: attributes.attributes,
  });
  return attributes;
};

export const CompanyAttributes = async function () {
  return this.$services.companyAttributes.get();
};

export const setCompanyAttributes = async function (
  { commit },
  { integration, companyAttributes },
) {
  const newAttributes = await this.$services.companyAttributes.set(
    integration,
    companyAttributes,
  );

  if (!newAttributes) return null;

  commit(types.UPDATE_COMPANY_ATTRIBUTES, {
    companyAttributes: newAttributes.attributes,
  });

  return newAttributes;
};

export const updateCompanyAttributes = ({ commit }, companyAttributes) =>
  commit(types.UPDATE_COMPANY_ATTRIBUTES, {
    companyAttributes,
  });

export const updateUsername = ({ commit }, username) =>
  commit(types.UPDATE_USERNAME, {
    username,
  });

export const updateFocusPath = ({ commit }, focusPath) =>
  commit(types.UPDATE_FOCUS_PATH, {
    focusPath: focusPath.map(String),
  });

export const updateFocusTree = ({ commit }, focusTree) => {
  commit(types.UPDATE_FOCUS_TREE, {
    focusTree,
  });
};

export const asyncUpdateFocusTree = ({ commit }, focusTree) =>
  new Promise((resolve) => {
    commit(types.UPDATE_FOCUS_TREE, {
      focusTree,
    });
    resolve();
  });

export const updateKnowledgeGroups = ({ commit }, groups) =>
  commit(types.UPDATE_KNOWLEDGE_GROUPS, {
    groups,
  });

export const updateMagicAnswers = ({ commit }, magicAnswers) =>
  commit(types.UPDATE_MAGIC_ANSWERS, {
    magicAnswers,
  });

export const updateEmbeddableExtensionClick = ({ commit }, src) =>
  commit(types.UPDATE_EMBEDDABLE_EXTENSION_CLICK, src);

export const addCheckeckTreeNode = ({ commit, state }, newCheckedTreeNode) => {
  const {
    checkedTreeNodes,
    focusKnowledge: { rootId },
  } = state;
  if (newCheckedTreeNode.id !== rootId) {
    commit(types.UPDATE_CHECKED_TREE_NODES, {
      checkedTreeNodes: checkedTreeNodes.concat([newCheckedTreeNode]),
    });
  }
};

export const removeCheckedTreeNode = ({ commit, state }, { id, parentIds }) => {
  const { checkedTreeNodes } = state;
  commit(types.UPDATE_CHECKED_TREE_NODES, {
    checkedTreeNodes: checkedTreeNodes
      .filter((o) => !o.parentIds.includes(id))
      .filter((o) => !parentIds.includes(o.id))
      .filter((o) => o.id !== id),
  });
};

export const resetCheckedTreeNodes = ({ commit }) =>
  commit(types.UPDATE_CHECKED_TREE_NODES, {
    checkedTreeNodes: [],
  });

export const updateTreeNodes = ({ commit }, { treeNodes, focusPath }) => {
  if (focusPath !== undefined) {
    commit(types.UPDATE_FOCUS_PATH, {
      focusPath: focusPath.map(String),
    });
  }
  commit(types.UPDATE_TREE_NODES, {
    treeNodes,
  });
};

export const updateOrCreateTreeNodes = ({ state, commit }, newTreeNodes) => {
  let treeNodes = [...state.treeNodes];
  newTreeNodes.forEach((node) => {
    const localCopy = treeNodes.find((n) => n.id === node.id);
    if (localCopy) {
      treeNodes = updateArrayObject(treeNodes, node.id, node);
    } else {
      treeNodes.push(node);
    }
  });
  commit(types.UPDATE_TREE_NODES, {
    treeNodes,
  });
};

export const updateOrCreateNodesContent = (
  { state, commit },
  newNodesContent,
) => {
  let nodesContent = [...state.nodesContent];
  newNodesContent.forEach((content) => {
    const localCopy = nodesContent.find((c) => c.id === content.id);
    if (localCopy) {
      nodesContent = updateArrayObject(nodesContent, content.id, content);
    } else {
      nodesContent.push(content);
    }
  });
  commit(types.UPDATE_NODES_CONTENT, {
    nodesContent,
  });
};

export const updateNodesContent = ({ commit }, nodesContent) =>
  commit(types.UPDATE_NODES_CONTENT, {
    nodesContent,
  });

export const updateKnowledgeFilters = ({ commit }, knowledgeFilters) =>
  commit(types.UPDATE_KNOWLEDGE_FILTERS, {
    knowledgeFilters,
  });

export const updateShowKnowledgeFilters = ({ commit }, showKnowledgeFilters) =>
  commit(types.UPDATE_SHOW_KNOWLEDGE_FILTERS, {
    showKnowledgeFilters,
  });

export const updateOrCreateCustomRole = ({ state, commit }, customRole) => {
  let roles = [...state.roles];
  const localCopy = roles.find((r) => r.id === customRole.id);
  if (localCopy) {
    roles = updateArrayObject(roles, customRole.id, customRole);
  } else {
    roles.push(customRole);
  }
  commit(types.UPDATE_USER_ROLES, {
    roles,
  });
};

export const updateAppLanguage = ({ commit }, appLanguage) => {
  commit(types.UPDATE_APP_LANGUAGE, {
    appLanguage,
  });
};

export const updateAskAccess = ({ commit }, askAccess) => {
  commit(types.UPDATE_ASK_ACCESS, {
    askAccess,
  });
};

export const setAnalyticsFilters = ({ commit }, analyticsFilters) => {
  commit(types.SET_ANALYTICS_FILTERS, {
    analyticsFilters,
  });
};

export const updateSession = ({ commit }, session) =>
  commit(types.UPDATE_SESSION, { session });

export const searchAction = async function (_, { searchType, searchArgs }) {
  const response = await this.$services.search[searchType]({
    ...searchArgs,
  });

  return response;
};

export const getSimilarContents = async function (_, body) {
  const response = await this.$services.search.getSimilarContents({
    body,
  });

  return response;
};

/**
 * COMPANY INTEGRATIONS
 */

export const updateCompanyIntegrations = async function (
  { commit },
  { integration, action },
) {
  const { fullPath: route } = this.$router.currentRoute;
  if (action === 'add') {
    const integrations = await this.$services.companies.addIntegration(
      integration,
    );
    this.$services.events.company.addIntegration(integration, route, 'ADMIN');
    commit(types.UPDATE_INTEGRATIONS, integrations);
  } else if (action === 'delete') {
    const integrations = await this.$services.companies.deleteIntegration(
      integration,
    );
    this.$services.events.company.removeIntegration(
      integration,
      route,
      'ADMIN',
    );
    commit(types.UPDATE_INTEGRATIONS, integrations);
  }
};

/**
 * COMPANY PREFERENCES
 */

export const addCompanyPreferences = async function (
  { commit },
  { preferenceType, preferenceValue },
) {
  const { preferences } = await this.$services.companies.addPreference(
    preferenceType,
    preferenceValue,
  );
  commit(types.UPDATE_COMPANY_PREFERENCES, {
    companyPreferences: preferences,
  });
  return preferences;
};

export const updateCompanyPreferencesValue = async function (
  { commit },
  { preferenceId, preferenceValue },
) {
  const { preferences } = await this.$services.companies.updatePreference(
    preferenceId,
    preferenceValue,
  );
  commit(types.UPDATE_COMPANY_PREFERENCES, {
    companyPreferences: preferences,
  });
};

export const deleteCompanyPreferences = async function (
  { commit },
  { preferenceIds },
) {
  const { preferences } = await this.$services.companies.deletePreference(
    preferenceIds,
  );
  commit(types.UPDATE_COMPANY_PREFERENCES, {
    companyPreferences: preferences,
  });
};

/**
 * AUTH INTEGRATION
 */

export const initZendeskAuth = async function (
  _,
  { subdomain, clientId, clientSecret },
) {
  return this.$services.integrations.initZendeskAuth(
    subdomain,
    clientId,
    clientSecret,
  );
};

export const checkAuthExists = async function (_, integration) {
  return this.$services.integrations.checkAuthExists(integration);
};

export const deleteAuth = async function (_, integration) {
  return this.$services.integrations.deleteAuth(integration);
};

export const getOauthInitUrl = async function (_, integration) {
  return this.$services.integrations.getOauthInitUrl(integration);
};

// SALESFORCE

export const initSalesforceAuth = async function (
  _,
  { instanceUrl, clientId, clientSecret },
) {
  return this.$services.integrations.initSalesforceAuth(
    instanceUrl,
    clientId,
    clientSecret,
  );
};

export const getSalesforceAuth = async function ({ state }) {
  return this.$services.integrations.getSalesforceAuth(state.companyId);
};

// NOTION

export const initNotionAuth = async function (_, { token }) {
  return this.$services.integrations.initNotionAuth(token);
};

export const getNotionAuth = async function ({ state }) {
  return this.$services.integrations.getNotionAuth(state.companyId);
};

export const getApiToken = async function () {
  return this.$services.companies.getApiToken();
};

// IADVIZE

export const initIadvizeAuth = async function (_, { token, projectId }) {
  return this.$services.integrations.initIadvizeAuth(token, projectId);
};

/**
 * REDIRECT
 */

export const createRedirectUrl = async function (
  { dispatch },
  { entityId, entityType, target, blockId },
) {
  if (!entityType) throw new Error('Invalid Type');
  const URL_TYPES = {
    Case: 'case',
    Article: 'content',
    Diagnostic: 'content',
    Step: 'step',
    keyStep: 'step',
  };

  const urlType = URL_TYPES[entityType];

  const hash = blockId ? `#${blockId}` : '';

  if (urlType === 'step') {
    const content = await this.$services.parametricContents.getWithAncestors(
      entityId,
    );

    if (!content)
      return dispatch(
        'knowledgeModule/flagRedirectionStepsAsDeleted',
        entityId,
      );
    const diagId = content.ancestorsContent[0].id;
    return `/${target}/content/${diagId}/step/${entityId}${hash}`;
  }

  return `/${target}/${urlType}/${entityId}${hash}`;
};

//USER

export const associateUserToRecentlyConsultedContent = function (
  { commit, state },
  { user },
) {
  const { userRecentlyConsultedContents } = state;
  const asLRU = new CustomLRU({
    state: userRecentlyConsultedContents,
  });
  if (!asLRU.shouldReset(user)) return;
  asLRU.reset(user);
  commit(types.SET_USER_RECENTLY_CONSULTED_CONTENTS, {
    ...asLRU.state(),
  });
};

export const setUserRecentlyConsultedContent = function (
  { commit, state },
  { content, lang },
) {
  if (!['Article', 'Diagnostic'].includes(content.type)) {
    return;
  }

  const { userRecentlyConsultedContents } = state;

  const asLRU = new CustomLRU({
    state: userRecentlyConsultedContents,
  });

  content.lang = lang;
  asLRU.set(content.id, content, content.knowledgeId);

  commit(types.SET_USER_RECENTLY_CONSULTED_CONTENTS, {
    ...asLRU.state(),
  });
};

export const setUserPreferences = function ({ commit }, { preferences }) {
  commit(types.SET_USER_PREFERENCES, preferences);
};
