import Vue from 'vue';
import App from './App.vue';
import VueApollo from 'vue-apollo';
import VTooltip from 'v-tooltip';
import ElementUI from 'element-ui';
import '@/assets/scss/element-ui/theme-mayday.css';
import locale from 'element-ui/lib/locale/lang/fr';

import VueClipboard from 'vue-clipboard2';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vBlur from 'v-blur';
import VueInputAutowidth from 'vue-input-autowidth';
import VueZoomer from 'vue-zoomer';

import VueRouter from 'vue-router';
import router from './router';
import store from './store';
import i18n from './i18n';

import vuelidate from 'vuelidate';

import './registerServiceWorker';

import ArgonDashboard from './plugins/argon-dashboard';

import { prepareStore } from './plugins/prepareStore';
import { prepareServices } from './plugins/services';
import { prepareApis } from './plugins/prepareApis';

import Hotjar from './plugins/hotjar';

import VueGtm from 'vue-gtm';

// Font Awesome
import initFontAwesome from './plugins/fontAwesome';

import { CollapsePlugin, CarouselPlugin, NavbarPlugin } from 'bootstrap-vue';

//Corresponding CSS
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import '@/assets/scss/main.scss';

import FlowyPlugin from '@getmayday/mayday-flow-chart';
import '@getmayday/mayday-flow-chart/dist/lib/flowy-vue.css';

import MaydayEditorPlugin from '@getmayday/mayday-editor';
import '@getmayday/mayday-editor/dist/lib/mayday-editor.css';

import {
  UserFavoritesModule,
  FeedbackModule,
  NotificationModule,
} from '@getmayday/mayday-modules';
import '@getmayday/mayday-modules/dist/lib/mayday-modules.css';

import { SET_MAP_SOURCE_TO_LABEL } from './store/dashboard/mutation-types';
import * as filters from './filters';
import draggable from 'vuedraggable';
import moment from 'moment';
import MaydayLogsClient from '@getmayday/mayday-logs-client';

import LottieAnimation from '@/components/LottieAnimation.vue';
import NewFeature from '@/components/Commons/NewFeature.vue';

moment.locale('fr-FR');

Vue.use(VueAxios, axios);

const {
  adminClient,
  adminApolloClient,
  analyticsClient,
  authClient,
  knowledgeClient,
  knowledgeApolloClient,
  notificationClient,
  knowledgeIntegrationClient,
  adminIntegrationClient,
  brainClient,
  exportClient,
  brainFetchClient,
  academyClient,
  academyApolloClient,
} = prepareApis(store);

const logClient = new MaydayLogsClient({
  baseURL: `${process.env.VUE_APP_LOGS_URL}/api/events`,
  lenient: false,
  axiosClient: adminClient,
});

Vue.use(vuelidate);
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.use(ArgonDashboard);
Vue.use(VueRouter);
Vue.use(VueApollo);
Vue.use(vBlur);
Vue.use(VueInputAutowidth);
Vue.use(VTooltip);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(NavbarPlugin);
Vue.use(ElementUI, {
  locale,
});
Vue.use(VueZoomer);

// GOOGLE TAG MANAGER FOR HOTJAR
if (process.env.VUE_APP_PRODUCTION_MODE === 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-WV7B54B', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    // queryParams: {
    //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    //   gtm_preview: "env-4",
    //   gtm_cookies_win: "x",
    // },
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
}

if (process.env.VUE_APP_HOTJAR_ID) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    snippetVersion: 6,
  });
}

const apolloProvider = new VueApollo({
  defaultClient: adminApolloClient,
});

if (process.env.VUE_APP_PRODUCTION_MODE === 'development') {
  Vue.config.devtools = true;
}

prepareServices(
  store,
  adminApolloClient,
  knowledgeApolloClient,
  knowledgeClient,
  analyticsClient,
  adminClient,
  logClient,
  authClient,
  brainClient,
  exportClient,
  brainFetchClient,
  academyApolloClient,
);

initFontAwesome(Vue);

prepareStore(store, router);

Vue.use(FlowyPlugin, { store });

Vue.use(MaydayEditorPlugin, {
  callbacks: {
    handleFileUpload: async (file) => {
      return store.dispatch('knowledgeModule/handleFileUpload', file);
    },
    handleFetchInlineContent: ({ contentId, lang, type }) =>
      store.dispatch('knowledgeModule/handleFetchInlineContent', {
        contentId,
        lang,
        type,
      }),
    handleFetchInlineMention: ({ entityId, entityType }) =>
      store.dispatch('knowledgeModule/handleFetchInlineMention', {
        entityId,
        entityType,
      }),
    handleSearchInlineEntity: (query) =>
      store.dispatch('knowledgeModule/handleSearchInlineEntity', query),
    notifyUploadError: async (error) => {
      store.dispatch('knowledgeModule/notifyUploadError', error);
    },
  },
  i18n,
  store,
});

Vue.use(UserFavoritesModule.plugin, {
  i18n,
  store,
  knowledgeClient: knowledgeIntegrationClient,
});

Vue.use(FeedbackModule.plugin, {
  i18n,
  store,
  adminClient: adminIntegrationClient,
  knowledgeClient: knowledgeIntegrationClient,
});

Vue.use(NotificationModule.plugin, {
  i18n,
  store,
  notificationClient,
  adminClient: adminIntegrationClient,
  knowledgeClient: knowledgeIntegrationClient,
});

window.___MAYDAY_CE___ = {
  locale: 'fr',
  services: {
    notificationClient,
    adminClient: adminIntegrationClient,
    knowledgeClient: knowledgeIntegrationClient,
    academyClient: academyClient,
    logClient,
  },
};

store.commit(
  `dashboard/${SET_MAP_SOURCE_TO_LABEL}`,
  i18n.t('new-dashboard.chart.legend'),
);

Vue.filter('percentage', filters.percentage);
Vue.filter('possiblyDeleted', filters.possiblyDeleted);

Vue.component('draggable', draggable);
Vue.component('lottie-animation', LottieAnimation);
Vue.component('new-feature', NewFeature);

Vue.config.ignoredElements = ['mayday-editor-ce', 'new-notification-ce'];

new Vue({
  i18n,
  store,
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
