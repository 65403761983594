export class ZendeskServiceData {
  constructor(axiosClient) {
    this.axiosClient = axiosClient;
  }

  searchMacros(input) {
    return this.axiosClient
      .get('/admin/search-macros', {
        params: {
          query: input,
        },
      })
      .then((response) => response.data.macros);
  }

  getMacroById(id) {
    return this.axiosClient
      .get('/admin/get-macro', {
        params: {
          id,
        },
      })
      .then((response) => response.data.macro);
  }
}
