import * as types from '../mutation-types';

const shouldResetFilters = (oldTab, newTab) => {
  if (oldTab === newTab) return false;
  if (
    (oldTab === 'usages' || oldTab === 'contents') &&
    (newTab === 'usages' || newTab === 'contents')
  )
    return false;
  return true;
};

export const updateActiveTab = ({ commit, dispatch, state }, tabName) => {
  if (tabName === 'contents') {
    commit(types.RESET_USAGES);
  } else if (tabName === 'usages') {
    commit(types.RESET_CONTENTS);
  } else {
    commit(types.LOGOUT);
  }
  if (shouldResetFilters(state.activeTab, tabName)) {
    commit(types.SET_ACTIVE_TAB, tabName);
    dispatch('prepareFilters');
    return;
  }
  commit(types.SET_ACTIVE_TAB, tabName);
};

export const getAllTabData = ({ dispatch, state }) => {
  const { activeTab } = state;
  if (activeTab == null) {
    return;
  }

  if (activeTab === 'contents') {
    dispatch('getContentLineChartData');
    dispatch('getContentTableData');
    dispatch('getContentStatCardsData');
    dispatch('getContentPublicStatCardsData');
    dispatch('getContentSearchStatistics');
  } else if (activeTab === 'usages') {
    dispatch('getUsageStatCardsData');
    dispatch('getAdoptionRateData');
    dispatch('getUsageTableData');
  } else if (activeTab === 'ask') {
    dispatch('getAskMetrics');
  } else if (activeTab === 'workflow') {
    dispatch('getWorkflowMetrics');
  } else if (activeTab === 'download-table') {
    dispatch('getExports');
  }
};
