import gqlService from 'utils/gqlService';

// Asychronous forEach method
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const updateDeepNestedNode = (tree, keyValPairs, path) => {
  let transition = tree;
  path.forEach((parentId) => {
    transition = transition.nodes.find(
      ({ id }) => String(id) === String(parentId),
    );
  });
  keyValPairs.forEach(({ key, value }) => {
    if (typeof key === 'string') {
      if (key === 'nodes') {
        if (transition.nodes[0]) {
          const newNodes = nodesUpdate(transition.nodes, value);
          transition.nodes = newNodes;
        } else {
          transition.nodes = value;
        }
      } else {
        transition[key] = value;
      }
    }
    if (typeof key === 'object') transition[key[0]][key[1]] = value;
  });
  return tree;
};

const recursiveDeepLoading = async (path, tree) => {
  var transition = tree;
  await asyncForEach(path.slice(1), async (parentId) => {
    transition = transition.nodes.find(
      ({ id }) => String(id) === String(parentId),
    );
    if (!transition.nodes) {
      const newNode = await gqlService.knowledge.readTreeNode(parentId);
      transition.nodes = newNode.nodes;
      transition.actions = newNode.actions;
      transition.content = newNode.content;
      transition.type = newNode.type;
    }
  });
  return tree;
};

const nodesUpdate = (currentNode, newNodes) => {
  const currentIds = currentNode.map(({ id }) => id);
  const newIds = newNodes.map(({ id }) => id);
  return newIds.map((id) => {
    if (currentIds.includes(id)) {
      const node = currentNode.find((o) => o.id === id);
      // As the newNodes informations is fixed by the GraphQL query
      // we assess here if the currentNode nodes has more informations
      // --> for instance a depth of more than 2 descendents
      try {
        const trans = node.nodes[0].nodes[0].type;
        return trans ? node : newNodes.find((o) => o.id === id);
      } catch {
        return newNodes.find((o) => o.id === id);
      }
    } else return newNodes.find((o) => o.id === id);
  });
};

const updateArrayObject = (arr, id, val) => {
  Object.entries(val).reduce(
    (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
    {},
  );
  const idx = arr.map((o) => o.id).indexOf(id);
  arr[idx] = {
    ...arr[idx],
    ...val,
  };
  return arr;
};

const userColor = (str, s, l) => {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h},${s}%,${l}%)`;
};

const flattenNodes = (nodes) => {
  return nodes.reduce(
    (toReturn, node) => {
      const { id, content, type, actions, nodes } = node;
      if (nodes) {
        const flatChildren = flattenNodes(nodes);
        toReturn.nodes = toReturn.nodes.concat(flatChildren.nodes);
        toReturn.contents = toReturn.contents.concat(flatChildren.contents);
      }
      if (content) {
        toReturn.nodes.push({
          id,
          type,
          actions,
          contentId: content.id,
          path: nodes ? nodes.map((n) => n.id) : [],
        });
        toReturn.contents.push(content);
      }
      return toReturn;
    },
    { nodes: [], contents: [] },
  );
};

const clamp = (num, min, max) => {
  return Math.min(Math.max(num, min), max);
};

export {
  userColor,
  asyncForEach,
  updateDeepNestedNode,
  recursiveDeepLoading,
  nodesUpdate,
  updateArrayObject,
  flattenNodes,
  clamp,
};
