import { publicKnowledgeMenuConfiguration } from '../../views/Settings/PublicKnowledge/PublicKnowledgeMenuConfiguration.js';
import { getSteps } from '../../views/Settings/PublicKnowledge/commons/DeploymentStepsConfiguration.js';

export default {
  companyPublicConfigs: (state) => state.companyPublicConfigs,
  focusCompanyPublicConfig: (state) => state.focusCompanyPublicConfig,
  focusKnowledge: (state) => state.focusKnowledge,
  deploymentStatus: (state) => state.deploymentStatus,
  deploying: (state) => state.deploying,
  httpsStatus: (state) => state.httpsStatus,
  httpsPooling: (state) => state.httpsPooling,
  multilingualLoader: (state) => state.multilingualLoader,
  getMenuConfig: (state, getters) => {
    const { focusCompanyPublicConfig, focusKnowledge } = state;
    const { getAllOperations } = getters;
    return publicKnowledgeMenuConfiguration(
      focusCompanyPublicConfig,
      focusKnowledge,
      getAllOperations,
    );
  },
  getAllOperations: (state) => {
    const { focusCompanyPublicConfig } = state;
    return state.operations[focusCompanyPublicConfig.id];
  },
  getSteps: (state) => {
    const { deploymentStatus } = state;
    return Object.values(getSteps(deploymentStatus.deploymentStatus));
  },
  getCustomHostnameError: (state) => {
    return state.customHostnameError;
  },
  // This plugin is used to fetch plugins that are made to emit an event in selfcare client
  eventEmitterPlugins: (state) => {
    const plugins = state.focusKnowledge.plugins;
    const eventEmitterPlugins = plugins.reduce((acc, val) => {
      const { elements } = val;

      const eventElement = elements.find(
        (el) => el.action && el.action.id === 3,
      );
      if (eventElement)
        acc.push({
          id: val.id,
          event: eventElement.action.event,
          data: eventElement.action.data,
        });
      return acc;
    }, []);
    return eventEmitterPlugins;
  },
};
