import { getWebTranslatedEntityUrl } from 'utils/adminToWebRouteMapping';

export default {
  resolveLanguage({ getters }, lang) {
    if (lang) return lang;
    const currentLanguage = this.$router.currentRoute.params.lang;
    return currentLanguage
      ? currentLanguage
      : getters.navigationLanguage || 'default';
  },

  /**
   * NAVIGATION
   */
  async getParentId({ dispatch }, stepId) {
    const content = await this.$services.parametricContents.getWithAncestors(
      stepId,
    );

    if (!content)
      return dispatch('knowledgeModule/flagRedirectionStepsAsDeleted', stepId, {
        root: true,
      });
    return content.ancestorsContent[0].id;
  },

  /**
   *
   * @param {*} param0
   * @param {*} args { entityId, entityType, parentId, lang, hash, query, open}
   * @returns
   */
  async goToWebTranslatedEntity({ dispatch, getters }, args) {
    const { open, lang, entityId, entityType } = args;
    const resolvedLang = await dispatch('resolveLanguage', lang);
    const { focusKnowledgeId } = getters;

    let parentId;

    if (['Step', 'keyStep'].includes(entityType)) {
      parentId = args.parentId || (await dispatch('getParentId', entityId));
    }

    const { name, params, query, hash } = await getWebTranslatedEntityUrl({
      ...args,
      parentId,
      lang: resolvedLang,
      knowledgeId: focusKnowledgeId,
    });
    const { href } = this.$router.resolve({
      name,
      params,
      query,
      hash,
    });
    const url = `${href}${hash}`;

    return open ? window.open(url) : this.$router.push(url);
  },
};
