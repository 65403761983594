export class MagicAnswerService {
  constructor(data) {
    this.data = data;
  }

  /**
   * CREATE METHODS
   */

  async create(magicAnswer) {
    return this.data.createMagicAnswer(magicAnswer);
  }

  /**
   * DELETE METHODS
   */

  async delete(id) {
    return this.data.deleteMagicAnswer(id);
  }
}
