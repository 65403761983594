export const EXTERNAL_DOC_URL = 'https://documentation.getmayday.co/tableaux-de-bords';

export const EXTERNAL_DOC_ANCHORS = {
  contentChart: '/la-page-contenu#graphique-devolution-des-consultations',
  contentTable: '/la-page-contenu#analyse-des-contenus',
  contentConfidenceScore: '/la-page-contenu#score-de-confiance',
  contentArticleCount: '/la-page-contenu#articles',
  contentDiagnosticCount: '/la-page-contenu#diagnostics',
  contentSearchStats: '/la-page-contenu#analyse-des-recherches',
  contentModalChart: '/la-page-contenu#modale-danalyse-precise',
  contentModalViewCount: '/la-page-contenu#apercu-de-lutilisation',
  contentModalFeedbackCount: '/la-page-contenu#modale-danalyse-precise',
  contentModalDiagnosticChart: '/la-page-contenu#modale-danalyse-precise',
  usageViewCount: '/la-page-utilisation#consultations-de-mayday',
  usageUserCount: '/la-page-utilisation#utilisateurs',
  usageNotificationCount: '/la-page-utilisation#notifications',
  usageFeedbackCount: '/la-page-utilisation#feedbacks',
  usageAdoptionRate: '/la-page-utilisation#taux-dadoption',
  usageChart: '/la-page-utilisation#modale-danalyse-precise',
  usageTable: '/la-page-utilisation#analyse-des-utilisateurs',
  usageModalTable: '/la-page-utilisation#contenus-mayday-consultes-par-lutilisateur',
  usageModalViewCount: '/la-page-utilisation#apercu-de-lutilisation-1',
  usageModalNotificationCount: '/la-page-utilisation#apercu-de-lutilisation-1',
  usageModalFeedbackCount: '/la-page-utilisation#apercu-de-lutilisation-1',
};
