export default class CustomLRU {
  constructor({ state = { identification: {}, items: {} }, maxSize = 15 }) {
    this.maxSize = maxSize;
    this.items = state.items || {};
    this.identification = state.identification || {};
  }

  state() {
    return {
      items: this.items,
      identification: this.identification,
    };
  }

  entries(
    { forbiddenKeys = [], upperKey } = { forbiddenKeys: [], upperKey: null },
  ) {
    if (upperKey) {
      return this.items[upperKey]
        ? Object.values(this.items[upperKey])
            .filter((i) => !forbiddenKeys.includes(i.id))
            .sort((a, b) => b.addedAt - a.addedAt)
        : [];
    } else {
      return Object.values(this.items)
        .filter((i) => !forbiddenKeys.includes(i.id))
        .sort((a, b) => b.addedAt - a.addedAt);
    }
  }

  setIdentifier({ userId, companyId }) {
    if (!userId || !companyId) throw new 'INVALID_IDENTIFIER'();
    this.identification = { userId, companyId };
  }

  set(key, item, upperKey) {
    if (!key || !item) return;
    let itemsCopy = { ...this.items };
    // Only set required fields to avoid overloading state with useless data
    if (upperKey)
      itemsCopy[upperKey]
        ? (itemsCopy[upperKey][key] = {
            ...this.format(item),
            addedAt: Date.now(),
          })
        : (itemsCopy[upperKey] = {
            [key]: { ...this.format(item), addedAt: Date.now() },
          });
    else itemsCopy[key] = { ...this.format(item), addedAt: Date.now() };
    let orderedValues = Object.values(
      upperKey ? itemsCopy[upperKey] : itemsCopy,
    ).sort((a, b) => b.addedAt - a.addedAt);
    if (orderedValues && orderedValues.length > this.maxSize) {
      orderedValues = orderedValues.slice(0, this.maxSize);
      itemsCopy = orderedValues.reduce((acc, val) => {
        acc[val.id] = val;
        return acc;
      }, {});
    }
    this.items = itemsCopy;
  }

  format(content) {
    return {
      id: content.id,
      type: content.type,
      label: content.label,
      lang: content.lang,
      __typename: content.__typename,
    };
  }

  get(key, upperKey) {
    return upperKey ? this.items[upperKey][key] : this.items[key];
  }

  reset(user) {
    if (!user.id || !user.company.id) throw new Error('INVALID_IDENTIFIER');
    this.items = {};
    this.setIdentifier({ userId: user.id, companyId: user.company.id });
    return;
  }

  shouldReset(user) {
    if (!user.id || !user.company.id) throw new Error('INVALID_IDENTIFIER');
    return !(
      this.identification &&
      user.id === this.identification.userId &&
      user.company.id === this.identification.companyId
    );
  }
}
