export class DownloadAnalyticsServiceData {
  constructor(analyticsClient) {
    this.analyticsClient = analyticsClient;
  }

  getExports(params) {
    return this.analyticsClient
      .get('/exports', {
        params,
      })
      .then((res) => res.data);
  }

  getExportFilters(params) {
    return this.analyticsClient
      .get('/exports/filters', {
        params,
      })
      .then((res) => res.data);
  }
}
