import data from './contentThread.data.raw.js';

export class ContentThreadServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ METHODS
   */

  async getAll({ entityId, entityType, lang }) {
    return this.client
      .query({
        query: data.getAllContentThread,
        variables: { entityId, entityType, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getAllContentThread);
  }

  async get({ threadId, entityId, entityType, lang }) {
    return this.client
      .query({
        query: data.getContentThread,
        variables: { threadId, entityId, entityType, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentThread);
  }

  /**
   * CREATE METHODS
   */

  async create({
    entityId,
    entityType,
    lang,
    body,
    mentions,
    attachments,
    contributors,
  }) {
    return this.client
      .mutate({
        mutation: data.createContentThread,
        variables: {
          entityId,
          entityType,
          lang,
          body,
          mentions,
          attachments,
          contributors,
        },
      })
      .then((response) => response.data.createContentThread);
  }

  async createMessage({ threadId, body, mentions, attachments, contributors }) {
    return this.client
      .mutate({
        mutation: data.createContentThreadMessage,
        variables: { threadId, body, mentions, attachments, contributors },
      })
      .then((response) => response.data.createContentThreadMessage);
  }

  /**
   * UPDATE METHODS
   */

  async editMessage({
    threadId,
    messageId,
    body,
    mentions,
    attachments,
    contributors,
  }) {
    return this.client
      .mutate({
        mutation: data.editContentThreadMessage,
        variables: {
          threadId,
          messageId,
          body,
          mentions,
          attachments,
          contributors,
        },
      })
      .then((response) => response.data.editContentThreadMessage);
  }

  async updateStatus({ threadId, status, contributors }) {
    return this.client
      .mutate({
        mutation: data.updateContentThreadStatus,
        variables: { threadId, status, contributors },
      })
      .then((response) => response.data.updateContentThreadStatus);
  }

  async voteMessage({ threadId, messageId }) {
    return this.client
      .mutate({
        mutation: data.voteContentThreadMessage,
        variables: { threadId, messageId },
      })
      .then((response) => response.data.voteContentThreadMessage);
  }

  /**
   * DELETE METHODS
   */

  async deleteMessage({ threadId, messageId, contributors }) {
    return this.client
      .mutate({
        mutation: data.deleteContentThreadMessage,
        variables: { threadId, messageId, contributors },
      })
      .then((response) => response.data.deleteContentThreadMessage);
  }
}
