export class AutomationService {
  constructor(data) {
    this.data = data;
  }
  /**
   * READ METHODS
   */

  async getContentAutomations(contentId) {
    return this.data.getContentAutomations(contentId);
  }

  /**
   * CREATE METHODS
   */

  async create(automation) {
    return this.data.createAutomation(automation);
  }

  /**
   * UPDATE METHODS
   */

  async update(newAutomation) {
    return this.data.updateAutomation(newAutomation);
  }

  /**
   * DELETE METHODS
   */

  async delete(id) {
    return this.data.deleteAutomation(id);
  }
}
