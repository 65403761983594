export class AuthServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * AUTH METHOS
   */

  loginWithCredentials(email, password) {
    return this.client.post(
      'auth/login',
      {
        email,
        password,
        source: 'app',
      },
      { headers: { 'x-no-retry': 'true' } },
    );
  }

  loginFromOboToken(token) {
    return this.client.post('auth/login/obo-token', {
      token,
      source: 'app',
    });
  }

  logout() {
    return this.client.get('auth/logout');
  }

  async refreshToken() {
    const { data } = await this.client.get('auth/refresh', {
      headers: {
        'x-no-retry': 'true',
      },
    });

    if (data && data.status === 'LOCKED') {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return { status: 'LOCKED' };
    }

    return data;
  }
}
