<template>
  <label class="custom-toggle" :class="switchClass">
    <input type="checkbox" :disabled="disabled" v-model="model" />
    <span
      class="custom-toggle-slider rounded-circle"
      :data-label-off="offText"
      :data-label-on="onText"
    >
    </span>
  </label>
</template>
<script>
export default {
  name: 'base-switch',
  props: {
    value: [Array, Boolean],
    type: String,
    onText: {
      type: String,
      default: function () {
        return this.$t('main.yes');
      },
    },
    offText: {
      type: String,
      default: function () {
        return this.$t('main.no');
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    switchClass() {
      let baseClass = 'custom-toggle-';
      if (this.type) {
        return baseClass + this.type;
      }
      return '';
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    triggerToggle() {
      this.model = !this.model;
    },
  },
};
</script>
<style></style>
