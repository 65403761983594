export default () => ({
  /**
   * General
   */
  companyTaskStatus: [],
  companyTasks: [],
  companyTasksLoading: false,

  /**
   * Focused Task
   */
  focusTask: null,
  focusTaskComments: [],
  focusTaskUpvotes: [],
  loadingFocusTask: null,
  backlogTasksCount: 0,

  /**
   * Drag and Drop
   */
  // drangAndDrop must be like :
  // {taskId : {dragTo : newCompanyTaskStatusId, dragFrom : oldCompanyTaskStatusId}}
  dragAndDrop: {},

  assignableUsers: [],
});
