/**
 * GENERAL
 */
export const LOGOUT = 'LOGOUT';

/**
 * COMPANY FAVORITE
 */
export const UPDATE_COMPANY_FAVORITE_LOADING =
  'UPDATE_COMPANY_FAVORITE_LOADING';

/**
 * PLUGIN
 */
export const UPDATE_PLUGIN = 'UPDATE_PLUGIN';
