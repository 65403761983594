export class AutomationRecollectionService {
  constructor(data) {
    this.data = data;
  }

  async getGroupsByKnowledge(knowledgeId) {
    const cases = await this.data.getGroupsByKnowledge(knowledgeId);
    return cases.data;
  }
}
