import data from './contentTemplate.data.raw.js';

export class ContentTemplateServiceData {
  constructor(client) {
    this.client = client;
  }

  async getTemplateById(id, lang) {
    return this.client
      .query({
        query: data.getTemplateById,
        variables: { id, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentTemplateById)
      .catch((error) => {
        throw error;
      });
  }

  async getTemplates(lang) {
    return this.client
      .query({
        query: data.getTemplates,
        variables: { lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCompanyContentTemplates)
      .catch((error) => {
        throw error;
      });
  }

  async createTemplateByContentId({ name, description, contentId, lang }) {
    return this.client
      .mutate({
        mutation: data.createTemplateByContentId,
        variables: { name, description, contentId, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createContentTemplateByContentId)
      .catch((error) => {
        throw error;
      });
  }

  async createTemplate({
    name,
    description,
    type,
    lang,
    knowledgeId,
    knowledge,
  }) {
    return this.client
      .mutate({
        mutation: data.createTemplate,
        variables: { name, description, type, lang, knowledgeId, knowledge },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createContentTemplate)
      .catch((error) => {
        throw error;
      });
  }

  async createContentFromTemplate({
    templateId,
    targetCaseId,
    targetKnowledge,
    targetKnowledgeId,
  }) {
    return this.client
      .mutate({
        mutation: data.createContentFromTemplate,
        variables: {
          templateId,
          targetCaseId,
          targetKnowledge,
          targetKnowledgeId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createContentFromTemplate)
      .catch((error) => {
        throw error;
      });
  }

  async deleteTemplateById(id) {
    return this.client
      .mutate({
        mutation: data.deleteTemplate,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.deleteContentTemplate)
      .catch((error) => {
        throw error;
      });
  }

  async updateTemplateById({ id, payload }) {
    return this.client
      .mutate({
        mutation: data.updateTemplate,
        variables: { id, payload },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.updateContentTemplate)
      .catch((error) => {
        throw error;
      });
  }

  async updateTemplateTranslation({ id, descendentId, key, value, lang }) {
    return this.client
      .mutate({
        mutation: data.updateTemplateTranslation,
        variables: { id, descendentId, key, value, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.updateContentTemplateTranslation)
      .catch((error) => {
        throw error;
      });
  }

  async unsetTemplateTranslation({ id, descendentId, langToUnset, lang }) {
    return this.client
      .mutate({
        mutation: data.unsetTemplateTranslation,
        variables: { id, descendentId, langToUnset, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.unsetContentTemplateTranslation)
      .catch((error) => {
        throw error;
      });
  }

  async deleteTemplateDescendent({ id, descendentId, parentId }) {
    return this.client
      .mutate({
        mutation: data.deleteTemplateDescendent,
        variables: { id, descendentId, parentId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.deleteContentTemplateDescendent)
      .catch((error) => {
        throw error;
      });
  }

  async createTemplateDescendent({ id, parentId, ancestors, label, lang }) {
    return this.client
      .mutate({
        mutation: data.createTemplateDescendent,
        variables: { id, parentId, ancestors, label, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createContentTemplateDescendent)
      .catch((error) => {
        throw error;
      });
  }

  async orderTemplateDescendents({ id, descendentId, path }) {
    return this.client
      .mutate({
        mutation: data.orderTemplateDescendents,
        variables: { id, descendentId, path },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.orderContentTemplateDescendents)
      .catch((error) => {
        throw error;
      });
  }

  async createTemplateRedirection({ id, descendentId, entityId, entityType }) {
    return this.client
      .mutate({
        mutation: data.createTemplateRedirection,
        variables: { id, descendentId, entityId, entityType },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createContentTemplateRedirection)
      .catch((error) => {
        throw error;
      });
  }

  async upsertTemplateAction(
    templateId,
    descendentId,
    actionId,
    actionPayload,
    langs,
    type,
    title,
  ) {
    return this.client
      .mutate({
        mutation: data.upsertTemplateAction,
        variables: {
          templateId,
          descendentId,
          actionId,
          actionPayload,
          langs,
          type,
          title,
        },
      })
      .then((response) => response.data.upsertContentTemplateAction)
      .catch((error) => error);
  }

  async deleteTemplateAction(templateId, descendentId, actionId) {
    return this.client
      .mutate({
        mutation: data.deleteTemplateAction,
        variables: { templateId, descendentId, actionId },
      })
      .then((response) => response.data.deleteContentTemplateAction)
      .catch((error) => error);
  }
}
