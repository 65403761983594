<template>
  <div class="new-feature">{{ $t('generic.new') }}</div>
</template>

<script>
export default {
  name: 'new-feature',
};
</script>

<style lang="scss" scoped>
.new-feature {
  border-radius: 10px;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
  font-size: 12px;
  line-height: 16px;
  padding: 0 8px;
}
</style>
