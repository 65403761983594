export class AdminNotificationService {
  constructor(data) {
    this.data = data;
  }

  async getNotificationById(id) {
    return this.data.getNotificationById(id);
  }

  async getNotifications() {
    return this.data.getNotifications();
  }

  async readNotification(id) {
    return this.data.readNotification(id);
  }

  async removeNotification(id) {
    return this.data.removeNotification(id);
  }

  async addThumbsUp(id) {
    return this.data.addThumbsUp(id);
  }

  async removeThumbsUp(id) {
    return this.data.removeThumbsUp(id);
  }
}
