export class ContentParameterCategoryService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */
  async getContentParameterCategories() {
    return this.data.getContentParameterCategories();
  }

  /**
   *
   * UPDATE METHODS
   */
  async updateContentParameterCategory(oldName, newName) {
    return this.data.updateContentParameterCategory(oldName, newName);
  }

  /**
   * DELETE METHODS
   */
  async deleteContentParameterCategory(categoryId) {
    return this.data.deleteContentParameterCategory(categoryId);
  }
}
