import data from './companySynonym.data.raw.js';

export class CompanySynonymServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ METHODS
   */

  async getCompanySynonyms() {
    return this.client
      .query({
        query: data.getCompanySynonyms,
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCompanySynonyms)
      .catch((error) => error);
  }

  /**
   * CREATE METHODS
   */

  async createCompanySynonyms(synonyms) {
    return this.client
      .mutate({
        mutation: data.createCompanySynonyms,
        variables: {
          synonyms,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createCompanySynonyms)
      .catch((error) => error);
  }

  /**
   * UPDATE METHODS
   */

  async updateCompanySynonyms(ids, aSynonym) {
    return this.client
      .mutate({
        mutation: data.updateCompanySynonyms,
        variables: {
          ids,
          aSynonym,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.updateCompanySynonyms)
      .catch((error) => error);
  }

  /**
   * DELETE METHODS
   */

  async deleteCompanySynonyms(ids) {
    return this.client
      .mutate({
        mutation: data.deleteCompanySynonyms,
        variables: {
          ids,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.deleteCompanySynonyms)
      .catch((error) => error);
  }
}
