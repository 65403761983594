const parametricRouteMapping = [
  {
    from: 'knowledge-home',
    to: 'web-filter',
  },
  {
    from: 'knowledge-filter',
    to: 'web-filter-results',
  },
  {
    from: 'knowledge-product',
    to: 'web-product',
  },
  {
    from: 'knowledge-content',
    to: 'web-content',
  },
];

const webRouteMapping = [
  {
    from: 'knowledge-home',
    to: 'mayday-web-by-knowledge',
  },
  {
    from: 'knowledge-case',
    to: 'mayday-web-by-case-shorted',
  },
  {
    from: 'knowledge-content',
    to: 'mayday-web-by-content-shorted',
  },
  {
    from: 'knowledge-content-step',
    to: 'mayday-web-by-step-shorted',
  },
];

// Routes where we edit contents and cases (except knowledge-home)
export const adminNavigationRoutes = [
  'knowledge-case',
  'knowledge-content',
  'knowledge-content-step',
];

export const ENTITY_TO_ROUTE_NAME = {
  Case: 'mayday-web-by-case',
  Article: 'mayday-web-by-content',
  Diagnostic: 'mayday-web-by-content',
  Content: 'mayday-web-by-content',
  Step: 'mayday-web-by-step',
  keyStep: 'mayday-web-by-step',
  Knowledge: 'mayday-web-by-knowledge',
};

export const isValidRoute = (routeName, isParametric) => {
  const routes = isParametric ? parametricRouteMapping : webRouteMapping;
  return routes.find((r) => r.from === routeName);
};

export const adminToWebRouteMapping = (isParametric) => {
  return isParametric ? parametricRouteMapping : webRouteMapping;
};

export const getWebTranslatedEntityUrl = async ({
  entityId,
  entityType,
  parentId,
  lang,
  hash = '',
  query,
  knowledgeId,
}) => {
  if (!entityType) throw new Error('Invalid Type');

  const routeName = ENTITY_TO_ROUTE_NAME[entityType];

  if (!routeName)
    throw new Error(`Unknown route name for entityType ${entityType}`);

  const params = { lang };

  switch (routeName) {
    case 'mayday-web-by-step':
      params.knowledgeId = knowledgeId;
      params.contentId = parentId;
      params.stepId = entityId;
      break;
    case 'mayday-web-by-content':
      params.knowledgeId = knowledgeId;
      params.contentId = entityId;
      break;
    case 'mayday-web-by-case':
      params.knowledgeId = knowledgeId;
      params.caseId = entityId;
      break;
    case 'mayday-web-by-knowledge':
      params.knowledgeId = entityId || knowledgeId;
      break;
  }

  return { name: routeName, params, query, hash };
};
