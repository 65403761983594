import { customFilter } from '@/utils/filter';

export default {
  getOrderedCompanyTaskStatus(state) {
    const { companyTaskStatus } = state;
    const tasks = [...companyTaskStatus];
    return tasks.sort((a, b) => a.order - b.order);
  },

  getFormattedTask: (state) => (rawTask) => {
    const { companyTaskStatus } = state;
    const task = { ...rawTask };
    task.createdAt = new Date(parseInt(task.createdAt)).toLocaleDateString(
      'fr-FR',
    );

    const isAuthorNameDefined = task.author && task.author.username;

    const authorFirstName = isAuthorNameDefined
      ? task.author.username
          .split(' ')
          .filter((part) => part !== part.toUpperCase())
          .join(' ') || task.author.username.split(' ')[0]
      : null;

    switch (task.type) {
      case 'CONTENT_FEEDBACK':
        if (task.source === 'SELFCARE') {
          task.author = {
            username: 'task-view.username-selfcare',
            groups: [],
            labels: [],
            trad: true,
          };
          task.title = {
            path: 'task-view.feedback-content-selfcare',
            name: '',
          };
        } else {
          task.title = isAuthorNameDefined
            ? {
                path: 'task-view.feedback-content',
                name: authorFirstName,
              }
            : { path: 'task-view.feedback-content-no-author', name: '' };
        }
        break;
      case 'CONTENT_FEEDBACK_ACADEMY':
        if (task.entityType === 'PublishedModule') {
          task.title = isAuthorNameDefined
            ? {
                path: 'task-view.feedback-module',
                name: authorFirstName,
              }
            : {
                path: 'task-view.feedback-module-no-author',
                name: '',
              };
        } else {
          task.title = isAuthorNameDefined
            ? {
                path: 'task-view.feedback-slide',
                name: authorFirstName,
              }
            : {
                path: 'task-view.feedback-slide-no-author',
                name: '',
              };
        }

        break;
      case 'FEEDBACK':
      case 'FEEDBACK_ACADEMY':
        task.title = isAuthorNameDefined
          ? {
              path: 'task-view.feedback-global',
              name: authorFirstName,
            }
          : { path: 'task-view.feedback-global-no-author', name: '' };
        break;
      case 'CONTENT_OBSOLETE':
        task.body = {
          path: 'task-view.feedback-obsolete-body',
          dueDate: new Date(task.body).toLocaleDateString('fr-FR'),
          title: task.title,
        };
        task.title = {
          path: 'task-view.feedback-obsolete',
          title: task.title,
        };
        break;
      case 'INFORMATION':
        task.title = { path: 'task-view.information' };
        break;
      default:
        task.title = 'task-view.feedback-default';
    }
    if (!task.companyTaskStatusId) {
      task.companyTaskStatusId = companyTaskStatus.find(
        (el) => el.order === 1,
      ).id;
    }
    return task;
  },
  getGroupedTasksByStatus: (state, getters) => {
    const { getOrderedCompanyTaskStatus, getFormattedTask } = getters;

    return getOrderedCompanyTaskStatus.map((status) => {
      const paginatedTasks = state.companyTasks.find(
        (paginatedTask) => paginatedTask.key === status.id,
      ).value;
      const tasks = paginatedTasks
        ? paginatedTasks.results.map((task) => {
            if (task) return getFormattedTask(task);
          })
        : [];
      return {
        ...status,
        tasks,
        total: paginatedTasks.total,
        page: paginatedTasks.page,
        pages: paginatedTasks.pages,
      };
    });
  },

  backlogTasksCount(state) {
    return state.backlogTasksCount;
  },

  getFilteredTasks(state) {
    const { filters, companyTasks, displayArchive, companyArchivedTasks } =
      state;

    if (!filters.length)
      return displayArchive ? companyArchivedTasks : companyTasks;

    return customFilter({
      arrayToFilter: displayArchive ? companyArchivedTasks : companyTasks,
      filters,
    });
  },

  getFocusTask(state, getters) {
    const { getFormattedTask } = getters;
    const { focusTask } = state;
    return getFormattedTask(focusTask);
  },

  dragAndDrop: (state) => {
    return state.dragAndDrop;
  },

  tasksCount: (state) => {
    return state.companyTasks.reduce((acc, paginatedTask) => {
      return acc + paginatedTask.value.total;
    }, 0);
  },

  assignableUsers: (state) => state.assignableUsers,
  displayArchive: (state) => state.displayArchive,
  filters: (state) => state.filters,
  loadingFocusTask: (state) => state.loadingFocusTask,
  archiveLoading: (state) => state.archiveLoading,

  getTaskById: (state) => (taskId) => {
    return state.companyTasks
      .reduce((acc, paginatedTask) => {
        return acc.concat(paginatedTask.value.results);
      }, [])
      .find((task) => (task.id = taskId));
  },

  getTasksByIds: (state) => (taskIds) => {
    return state.companyTasks
      .reduce((acc, paginatedTask) => {
        return acc.concat(paginatedTask.value.results);
      }, [])
      .filter((task) => taskIds.includes(task.id));
  },
};
