export class ContentThreadService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  async getAll({ entityId, entityType, lang }) {
    return this.data.getAll({ entityId, entityType, lang });
  }

  async get({ threadId, entityId, entityType, lang }) {
    return this.data.get({ threadId, entityId, entityType, lang });
  }

  /**
   * CREATE METHODS
   */

  async create({
    entityId,
    entityType,
    lang,
    body,
    mentions,
    attachments,
    contributors,
  }) {
    return this.data.create({
      entityId,
      entityType,
      lang,
      body,
      mentions,
      attachments,
      contributors,
    });
  }

  async createMessage({ threadId, body, mentions, attachments, contributors }) {
    return this.data.createMessage({
      threadId,
      body,
      mentions,
      attachments,
      contributors,
    });
  }

  /**
   * UPDATE METHODS
   */

  async editMessage({
    threadId,
    messageId,
    body,
    mentions,
    attachments,
    contributors,
  }) {
    return this.data.editMessage({
      threadId,
      messageId,
      body,
      mentions,
      attachments,
      contributors,
    });
  }

  async updateStatus({ threadId, status, contributors }) {
    return this.data.updateStatus({
      threadId,
      status,
      contributors,
    });
  }

  async voteMessage({ threadId, messageId }) {
    return this.data.voteMessage({
      threadId,
      messageId,
    });
  }

  /**
   * DELETE METHODS
   */

  async deleteMessage({ threadId, messageId, contributors }) {
    return this.data.deleteMessage({
      threadId,
      messageId,
      contributors,
    });
  }
}
