import * as types from './mutation-types';
import initState from './state';

export default {
  [types.LOGIN_FROM_TOKEN](state, payload) {
    state.rootId = payload.rootId;
    state.roots = payload.roots;
    state.languages = payload.languages;
    state.defaultLanguage = payload.defaultLanguage;
    state.focusKnowledge = payload.focusKnowledge;
  },
  [types.LOGIN](state, payload) {
    if (!Object.keys(payload).includes('user')) {
      state.userRole = payload.userRole;
    }
  },
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },
  [types.AUTH_ERROR](state, payload) {
    state.status = payload.message;
  },
  [types.UPDATE_INTEGRATIONS](state, newIntegrations) {
    state.integrations = newIntegrations;
  },
  [types.HEADER_SEARCH_CHOICE](state, payload) {
    state.headerSearch = payload;
  },
  [types.RESET_HEADER_SEARCH](state) {
    state.headerSearch = null;
  },
  [types.UPDATE_LANGUAGES](state, payload) {
    state.languages = payload.languages;
  },
  [types.UPDATE_DEFAULT_LANGUGAGE_ATTRIBUTES](state, payload) {
    state.searchAccess = payload.searchAccess;
    state.searchIndex = payload.searchIndex;
    state.rootId = payload.rootId;
    state.roots = payload.roots;
    state.defaultLanguage = payload.defaultLanguage;
  },
  [types.UPDATE_FOCUS_KNOWLEDGE](state, payload) {
    state.focusKnowledge = payload.knowledge;
    state.rootId = payload.knowledge.rootId;
  },
  [types.UPDATE_USER_GROUPS](state, payload) {
    state.userGroups = payload.userGroups;
  },
  [types.UPDATE_COMPANY_ATTRIBUTES](state, payload) {
    state.companyAttributes = payload.companyAttributes;
  },
  [types.UPDATE_ROOTS](state, payload) {
    state.roots = payload.roots;
  },
  [types.UPDATE_USERNAME](state, payload) {
    state.username = payload.username;
  },
  [types.UPDATE_FOCUS_PATH](state, payload) {
    state.focusPath = payload.focusPath;
  },
  [types.UPDATE_CASE_PATH](state, payload) {
    state.casePath = payload.casePath;
  },
  [types.UPDATE_FOCUS_TREE](state, payload) {
    state.focusTree = Object.assign({}, state.focusTree, payload.focusTree);
  },
  [types.UPDATE_KNOWLEDGE_GROUPS](state, payload) {
    state.groups = payload.groups;
  },
  [types.UPDATE_MAGIC_ANSWERS](state, payload) {
    state.magicAnswers = payload.magicAnswers;
  },
  [types.UPDATE_EMBEDDABLE_EXTENSION_CLICK](state, payload) {
    state.embeddableExtensionClickEvent = payload;
  },
  [types.UPDATE_COMPANY_PREFERENCES](state, payload) {
    state.companyPreferences = payload.companyPreferences;
  },
  [types.UPDATE_CHECKED_TREE_NODES](state, payload) {
    state.checkedTreeNodes = payload.checkedTreeNodes;
  },
  [types.UPDATE_TREE_NODES](state, payload) {
    state.treeNodes = payload.treeNodes;
  },
  [types.UPDATE_NODES_CONTENT](state, payload) {
    state.nodesContent = payload.nodesContent;
  },
  [types.UPDATE_KNOWLEDGE_FILTERS](state, payload) {
    state.knowledgeFilters = payload.knowledgeFilters;
  },
  [types.UPDATE_SHOW_KNOWLEDGE_FILTERS](state, payload) {
    state.showKnowledgeFilters = payload.showKnowledgeFilters;
  },
  [types.UPDATE_COLLABORATORS_FOCUS](state, payload) {
    state.collaboratorsFocus = payload.collaboratorsFocus;
  },
  [types.UPDATE_USER_ROLES](state, payload) {
    state.roles = payload.roles;
  },
  [types.UPDATE_APP_LANGUAGE](state, payload) {
    state.appLanguage = payload.appLanguage;
  },
  [types.SET_ANALYTICS_FILTERS](state, payload) {
    state.analyticsFilters = payload.analyticsFilters;
  },
  [types.UPDATE_PRODUCTS](state, payload) {
    state.products = payload.products;
  },
  [types.UPDATE_PARAMETERS](state, payload) {
    state.parameters = payload.parameters;
  },

  // NYFIMI KNOWLEDGE
  [types.UPDATE_DIAG_PATH](state, payload) {
    state.diagPath = payload.diagPath;
  },
};
