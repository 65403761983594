export class ContextVariableService {
  constructor(data) {
    this.data = data;
  }

  /**
   * READ METHODS
   */

  async getAll() {
    return this.data.getAll();
  }

  /**
   * CREATE METHODS
   */

  async create(contextVariable) {
    return this.data.create(contextVariable);
  }

  async update(variableId, contextVariable) {
    return this.data.update(variableId, contextVariable);
  }

  async delete(variableId) {
    return this.data.delete(variableId);
  }
}
