export const LOGOUT = 'LOGOUT';

export const SET_COMPANY_TASK_STATUS_AND_FIRST_TASKS =
  'SET_COMPANY_TASK_STATUS_AND_FIRST_TASKS';
export const SET_COMPANY_TASKS = 'SET_COMPANY_TASKS';
export const SET_COMPANY_TASK_BY_ID = 'SET_COMPANY_TASK_BY_ID';
export const SET_USERS_WITH_PERMISSIONS = 'SET_USERS_WITH_PERMISSIONS';

export const SET_FILTERS = 'SET_FILTERS';
export const EMPTY_FILTERS = 'EMPTY_FILTERS';

export const SET_COMPANY_TASK_STATUS = 'SET_COMPANY_TASK_STATUS';
export const SET_TASK_RATING = 'SET_TASK_RATING';
export const SET_ASSIGNEE = 'SET_ASSIGNEE';
export const SET_FOCUS_TASK = 'SET_FOCUS_TASK';
export const SET_FOCUS_TASK_LOADING = 'SET_FOCUS_TASK_LOADING';
export const BULK_SET_IS_ARCHIVE = 'BULK_SET_IS_ARCHIVE';
export const SET_ARCHIVE_LOADING = 'SET_ARCHIVE_LOADING';
export const UPVOTE_TASK = 'UPVOTE_TASK';
export const REMOVE_UPVOTE_TASK = 'REMOVE_UPVOTE_TASK';
export const NEW_TASK_COMMENT = 'NEW_TASK_COMMENT';
export const DELETE_TASK_COMMENT = 'DELETE_TASK_COMMENT';
export const GET_BACKLOG_TASKS_COUNT = 'GET_BACKLOG_TASKS_COUNT';
