<template>
  <fade-transition>
    <div
      class="alert"
      :class="[`alert-${type}`, { 'alert-dismissible': dismissible }]"
      role="alert"
      v-if="visible"
    >
      <slot v-if="!dismissible">
        <span v-if="icon" class="alert-inner--icon pr-1">
          <i :class="icon"></i>
        </span>
        <span v-else class="alert-inner--icon pr-1">
          <i class="fa" :class="[isSuccessType ? successIcon : dangerIcon]"></i>
        </span>
        <span v-if="$slots.text" class="alert-inner--text">
          <slot name="text"></slot>
        </span>
      </slot>
      <template v-else>
        <slot>
          <span v-if="icon" class="alert-inner--icon">
            <i :class="icon"></i>
          </span>
          <span v-if="$slots.text" class="alert-inner--text">
            <slot name="text"></slot>
          </span>
        </slot>
        <slot name="dismiss-icon">
          <button
            type="button"
            data-dismiss="alert"
            aria-label="Close"
            class="close"
            @click="dismissAlert"
          >
            <span aria-hidden="true">×</span>
          </button>
        </slot>
      </template>
    </div>
  </fade-transition>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';

export default {
  name: 'base-alert',
  components: {
    FadeTransition,
  },
  props: {
    type: {
      type: String,
      default: 'default',
      description: 'Alert type',
    },
    icon: {
      type: String,
      default: '',
      description: 'Alert icon. Will be overwritten by default slot',
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: 'Whether alert is closes when clicking',
    },
  },
  data() {
    return {
      visible: true,
      successIcon: 'fa-info-circle',
      dangerIcon: 'fa-exclamation-circle',
    };
  },
  computed: {
    isSuccessType() {
      return this.type === 'success';
    },
  },
  methods: {
    dismissAlert() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  background: white;
  color: #4f4f4f;
  font-size: 1em;
  border-radius: 4px;
}

.alert-success .alert-inner--icon .fa {
  color: $blue-mayday !important;
}

.alert-danger .alert-inner--icon .fa {
  color: $red-mayday !important;
}

.alert-warning {
  border-color: $yellow-mayday;
}

.alert-warning .alert-inner--icon .fa {
  color: $yellow-mayday;
}
</style>
