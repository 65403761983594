<template>
  <div class="d-flex flex-col gap-2">
    <div v-for="(content, index) in contents" :key="content.id">
      <ContentCheckerItem
        :item="content"
        :hovered="index === hovered"
        @mouseenter.native="onHover(index)"
        @mouseleave.native="onHover(-1)"
        @go-to-document="handleGoToDocument($event, index)"
        @set-content-check="handleSetContentCheck"
      />
    </div>
  </div>
</template>

<script>
import ContentCheckerItem from './ContentCheckerItem';

export default {
  name: 'content-checker',
  components: {
    ContentCheckerItem,
  },
  props: {
    contents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hovered: -1,
      contentCheckList: {},
    };
  },
  methods: {
    onHover(index) {
      this.hovered = index;
    },
    handleGoToDocument(doc, index) {
      this.$emit('go-to-document', { ...doc, index });
    },
    handleSetContentCheck(e) {
      this.contentCheckList = { ...this.contentCheckList, ...e };
    },
  },
  mounted() {
    this._keyListener = (e) => {
      if (
        e.key === 'Enter' &&
        this.hovered >= 0 &&
        this.hovered <= this.contents.length
      ) {
        e.preventDefault();
        this.$emit('go-to-document', this.contents[this.hovered]);
      } else if (e.key === 'ArrowUp' && !this.isMaydayWeb) {
        this.hovered =
          this.hovered > 0 ? this.hovered - 1 : this.contents.length - 1;
        this.scrollToSelectedItem('end');
      } else if (e.key === 'ArrowDown' && !this.isMaydayWeb) {
        this.hovered =
          this.hovered < this.contents.length - 1 ? this.hovered + 1 : 0;
        this.scrollToSelectedItem('start');
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
  watch: {
    contentCheckList(newVal) {
      this.$emit('update-content-check-list', newVal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
