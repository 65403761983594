import * as types from './mutation-types';

export default {
  logout({ commit }) {
    commit(types.LOGOUT);
  },
  async fetchSummary({ rootState }, { body, summaryType, contentId, feature }) {
    const service = this.$services.brainClient;
    const { path } = this.$router.currentRoute;
    this.$services.events.aiEditorAssist.trigger(
      {
        contentId,
        feature,
      },
      path,
    );
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchSummary(
          rootState.accessToken,
          body,
          summaryType,
        )) {
          yield chunk;
        }
      },
    };
  },

  async fetchActionItems(
    { rootState },
    { body, abortController, contentId, feature },
  ) {
    const service = this.$services.brainClient;
    const { path } = this.$router.currentRoute;
    this.$services.events.aiEditorAssist.trigger(
      {
        contentId,
        feature,
      },
      path,
    );
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchActionItems(
          rootState.accessToken,
          body,
          abortController,
        )) {
          yield chunk;
        }
      },
    };
  },

  async fetchImprove(
    { rootState },
    { text, abortController, contentId, feature, triggerOption },
  ) {
    const service = this.$services.brainClient;
    const { path } = this.$router.currentRoute;
    this.$services.events.aiEditorAssist.trigger(
      {
        contentId,
        feature,
        triggerOption,
      },
      path,
    );
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchImprove(
          rootState.accessToken,
          text,
          abortController,
        )) {
          yield chunk;
        }
      },
    };
  },
  async fetchOpenPrompt(
    { rootState },
    { text, abortController, query, triggerOption, contentId, feature },
  ) {
    const service = this.$services.brainClient;
    const { path } = this.$router.currentRoute;
    this.$services.events.aiEditorAssist.trigger(
      {
        contentId,
        feature,
        query,
        triggerOption,
      },
      path,
    );
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchOpenPrompt(
          rootState.accessToken,
          text,
          abortController,
          query,
        )) {
          yield chunk;
        }
      },
    };
  },

  async fetchKnowledgeManagerRecommendations({ rootState }, { body }) {
    return this.$services.brainClient.fetchKnowledgeManagerRecommendations(
      rootState.accessToken,
      body,
    );
  },

  async fetchAsk({ rootState }, { queryId, queryBody, dev, askInterface }) {
    const service = this.$services.brainClient;
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.askStream(
          rootState.accessToken,
          queryId,
          queryBody,
          dev,
          askInterface,
        )) {
          yield chunk;
        }
      },
    };
  },

  sendAskFeedback({ rootState }, { queryId, queryBody, feedback }) {
    return this.$services.brainClient.sendAskFeedback(
      rootState.accessToken,
      queryId,
      queryBody,
      feedback,
    );
  },
  sendAskBetaFeedback({ rootState }, { queryId, queryBody, feedback }) {
    return this.$services.brainClient.sendAskBetaFeedback(
      rootState.accessToken,
      queryId,
      queryBody,
      feedback,
    );
  },
  async fetchTranslation(
    { rootState },
    { text, language, abortController, contentId, feature, triggerOption },
  ) {
    const service = this.$services.brainClient;
    const { path } = this.$router.currentRoute;
    this.$services.events.aiEditorAssist.trigger(
      {
        contentId,
        feature,
        language,
        triggerOption,
      },
      path,
    );
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchTranslation(
          rootState.accessToken,
          text,
          language,
          abortController,
        )) {
          yield chunk;
        }
      },
    };
  },

  async fetchCorrection(
    { rootState },
    {
      text,
      abortController,
      correctionType,
      contentId,
      feature,
      triggerOption,
    },
  ) {
    const service = this.$services.brainClient;
    const { path } = this.$router.currentRoute;
    this.$services.events.aiEditorAssist.trigger(
      {
        contentId,
        feature,
        triggerOption,
      },
      path,
    );
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchCorrection(
          rootState.accessToken,
          text,
          correctionType,
          abortController,
        )) {
          yield chunk;
        }
      },
    };
  },

  async getArticleToGuideSuggestion(_, { label, body, language }) {
    return this.$services.brainClient.getArticleToGuideSuggestion(
      label,
      body,
      language,
    );
  },
  async postTryOutQuestions(_, { lang, n }) {
    const ans = await this.$services.brainClient.postTryOutQuestions({
      lang,
      n,
    });
    if (!ans && !ans.data && !ans.data.questions) return [];
    return ans.data.questions;
  },

  async postArticleToGuide(
    _,
    {
      objective,
      label,
      body,
      language,
      knowledge,
      knowledgeId,
      caseParameters,
    },
  ) {
    return this.$services.brainClient.postArticleToGuide(
      objective,
      label,
      body,
      language,
      knowledge,
      knowledgeId,
      caseParameters,
    );
  },

  analyzeCustomizationParameters({ rootState }, { queryId, textSpec }) {
    return this.$services.brainClient.analyzeCustomizationParameters(
      rootState.accessToken,
      queryId,
      textSpec,
    );
  },

  getCustomization({ rootState }, { queryId }) {
    return this.$services.brainClient.getCustomization(
      rootState.accessToken,
      queryId,
    );
  },

  setCustomization({ rootState }, { queryId, customization }) {
    return this.$services.brainClient.setCustomization(
      rootState.accessToken,
      queryId,
      customization,
    );
  },

  delCustomization({ rootState }, { queryId }) {
    return this.$services.brainClient.delCustomization(
      rootState.accessToken,
      queryId,
    );
  },

  async getArticleToGuideStatus(_, { processId }) {
    return this.$services.brainClient.getArticleToGuideStatus(processId);
  },

  async cancelArticleToGuide(_, { processId }) {
    return this.$services.brainClient.cancelArticleToGuide(processId);
  },

  updateArticleToGuideProcess({ commit }, { processes }) {
    commit(types.UPDATE_ARTICLE_TO_GUIDE_PROCESSES, processes);
  },

  async generateImageCover(_, { text, colors = [] }) {
    try {
      const res = await this.$services.brainClient.generateImageCover(
        text,
        colors,
      );
      return res.data.url;
    } catch (err) {
      return null;
    }
  },

  async streamSuggestionEvent(_, { eventName, event }) {
    const { path } = this.$router.currentRoute;
    await this.$services.events.aiEditorAssist[eventName](event, path);
  },

  // ASK IN SEARCH

  setDisplayAskInSearch({ commit }, payload) {
    commit(types.SET_DISPLAY_ASK_IN_SEARCH, payload);
  },
  setLastAskQuery({ commit }, { query, answer }) {
    commit(types.SET_LAST_ASK_QUERY, { query, answer });
  },
};
