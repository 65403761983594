/**
 * GENERAL
 */
export const LOGOUT = 'LOGOUT';

/**
 * COMPANY
 */

export const SET_ADMIN_KNOWLEDGES = 'SET_ADMIN_KNOWLEDGES';
export const SET_FOCUS_ADMIN_KNOWLEDGE_ID = 'SET_FOCUS_ADMIN_KNOWLEDGE_ID';
export const ADD_LANGUAGE = 'ADD_LANGUAGE';

export const SET_COMPANY_HOSTNAMES = 'SET_COMPANY_HOSTNAMES';

// EMPLOYEES
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_EMPLOYEES_TABLE_LOADING = 'SET_EMPLOYEES_TABLE_LOADING';
export const SET_EMPLOYEES_TABLE_FILTERS = 'SET_EMPLOYEES_TABLE_FILTERS';

export const SET_MODAL_EMPLOYEES = 'SET_MODAL_EMPLOYEES';
export const SET_MODAL_EMPLOYEES_TABLE_LOADING =
  'SET_MODAL_EMPLOYEES_TABLE_LOADING';
export const SET_MODAL_EMPLOYEES_TABLE_FILTERS =
  'SET_MODAL_EMPLOYEES_TABLE_FILTERS';
export const SET_SHOW_MODAL_EMPLOYEES = 'SET_SHOW_MODAL_EMPLOYEES';

// PENDING EMPLOYEES
export const SET_PENDING_EMPLOYEES = 'SET_PENDING_EMPLOYEES';
export const SET_PENDING_EMPLOYEES_TABLE_LOADING =
  'SET_PENDING_EMPLOYEES_TABLE_LOADING';
export const SET_PENDING_EMPLOYEES_TABLE_FILTERS =
  'SET_PENDING_EMPLOYEES_TABLE_FILTERS';

export const SET_MODAL_PENDING_EMPLOYEES = 'SET_MODAL_PENDING_EMPLOYEES';
export const SET_MODAL_PENDING_EMPLOYEES_TABLE_LOADING =
  'SET_MODAL_PENDING_EMPLOYEES_TABLE_LOADING';
export const SET_MODAL_PENDING_EMPLOYEES_TABLE_FILTERS =
  'SET_MODAL_PENDING_EMPLOYEES_TABLE_FILTERS';
export const SET_SHOW_MODAL_PENDING_EMPLOYEES =
  'SET_SHOW_MODAL_PENDING_EMPLOYEES';

export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUPS_LOADING = 'SET_GROUPS_LOADING';
export const SET_ROLES = 'SET_ROLES';
export const SET_ROLES_LOADING = 'SET_ROLES_LOADING';
export const SET_REVIEWERS = 'SET_REVIEWERS';

export const SET_COMPANY_USER_LABEL_CATEGORIES =
  'SET_COMPANY_USER_LABEL_CATEGORIES';

export const SET_COMPANY_USER_LABEL_CATEGORIES_LOADING =
  'SET_COMPANY_USER_LABEL_CATEGORIES_LOADING';

export const UPDATE_ADMIN_NOTIFICATIONS = 'UPDATE_ADMIN_NOTIFICATIONS';

//USERS ADMINISTRATION

export const SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING =
  'SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING';
export const SET_USERS_FOR_ADMINISTRATION_TABLE =
  'SET_USERS_FOR_ADMINISTRATION_TABLE';
export const ADD_TO_USERS_ADMINISTRATION_FILTER =
  'ADD_TO_USERS_ADMINISTRATION_FILTER';
export const EMPTY_FILTERS_SEARCH = 'EMPTY_FILTERS_SEARCH';
export const ADD_SEARCH_TO_FILTERS = 'ADD_SEARCH_TO_FILTERS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

export const EXPORT_USER_LOADING = 'EXPORT_USER_LOADING';

export const SET_USER_INVITATIONS = 'SET_USER_INVITATIONS';

export const SET_CONTRIBUTORS = 'SET_CONTRIBUTORS';
export const SET_ALL_CONTRIBUTORS = 'SET_ALL_CONTRIBUTORS';

// COMPANY SYNONYMS

export const SET_COMPANY_SYNONYMS = 'SET_COMPANY_SYNONYMS';

// WEBSOCKETS
export const SET_WS_CONNECTION_ID = 'SET_WS_CONNECTION_ID';
