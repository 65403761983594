import gql from 'graphql-tag';

const getDashboardFilters = gql`
  query getDashboardFiltersQuery {
    getFilters {
      label
      filters {
        ... on GenericFilter {
          label
          isRemote
          type
          condition
          key
          field
          filters {
            ... on GenericFilter {
              label
              isRemote
              type
              field
              value
              values {
                label
                key
                value
              }
            }
          }
          value
          values {
            label
            key
            value
            isPublic
            userPermissions
            isKnowledgeFirstDay
            tagAttributes {
              color
              icon
            }
          }
        }
        ... on DateRangeFilter {
          label
          isRemote
          type
          field
          filters {
            ... on GenericFilter {
              label
              key
              isRemote
              type
              field
              value
              values {
                label
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;

export default {
  getDashboardFilters,
};
