import * as types from './mutation-types';

const oneMb = 1024 * 1024;

export default {
  async handleFileUpload({ dispatch }, file) {
    try {
      const FILE_MAX_SIZE = 500 * oneMb;
      if (file.size > FILE_MAX_SIZE) {
        dispatch('notifyUploadError', 'FILE TOO LARGE');
        throw new Error('FILE TOO LARGE');
      }
      const formData = new FormData();
      formData.append('files', file);
      const uploadedFile = await this.$services.parametricContents.attachFiles(
        formData,
      );
      return uploadedFile.data[0];
    } catch (e) {
      if (e.message !== 'FILE TOO LARGE')
        dispatch('notifyUploadError', 'UNKNOWN');
      return null;
    }
  },

  async handleFetchInlineContent({ rootGetters }, { contentId, lang, type }) {
    const focusKnowledgeDefaultLanguage =
      rootGetters['knowledgeModule/focusKnowledgeDefaultLanguage'];
    const editingLanguage = rootGetters['knowledgeModule/editingLanguage'];
    lang = lang || editingLanguage || focusKnowledgeDefaultLanguage;

    const service =
      type === 'Case'
        ? this.$services.cases
        : this.$services.parametricContents;

    let content = await service.getLight(contentId, lang);

    if (type === 'Case') {
      content.type = content.__typename;
      content.entityType = content.__typename;
    }

    return content;
  },
  async handleFetchInlineMention(_, { entityId, entityType }) {
    try {
      const user = await this.$services.users.getUserOrDeletedUser(entityId);

      if (!user) return null;

      return {
        label: user.username,
        entityId,
        entityType,
        isDeleted: user.isDeleted,
      };
    } catch (error) {
      return null;
    }
  },
  async handleOnClickInlineContent({ dispatch }, content) {
    if (!content) return;
    const { id, lang, type, newTab } = content;
    let entityType = type;

    if (type === 'Content') {
      const lightContent = await this.$services.parametricContents.getLight(
        id,
        lang,
      );
      entityType =
        lightContent.type === 'Step' || lightContent.type === 'keyStep'
          ? lightContent.type
          : type;
    }
    return dispatch('goToTranslatedEntity', {
      entityId: id,
      entityType,
      lang,
      open: newTab === true,
    });
  },

  async handleSearchInlineEntity({ dispatch }, query) {
    // DO NOT DISPLAY USER MENTION ON TEMPLATES
    const { fullPath } = this.$router.currentRoute;
    if (fullPath.includes('templates')) {
      const results = await dispatch('handleSearchInlineContent', query);
      return [results];
    }

    if (query.startsWith('user:')) {
      const subQuery = query.split('user:').pop() || '';
      // use this to avoid content request to erase user request with /user
      if (!subQuery) await new Promise((resolve) => setTimeout(resolve, 500));

      const results = await dispatch('handleSearchInlineMention', subQuery);
      return [results];
    }

    if (query.startsWith('content:')) {
      const subQuery = query.split('content:').pop() || '';

      const results = await dispatch('handleSearchInlineContent', subQuery);
      return [results];
    }

    return Promise.all([
      dispatch('handleSearchInlineMention', query),
      dispatch('handleSearchInlineContent', query),
    ]);
  },
  async handleSearchInlineContent({ getters, dispatch }, query) {
    const { focusKnowledgeValue: knowledge } = getters;

    const contents = await dispatch(
      'searchAction',
      {
        searchType: 'lightSearchContentFromKnowledge',
        searchArgs: {
          knowledge,
          query,
          size: 10,
          lang: 'all', // Inline search should not be language specific
          published: ['true', 'false', null],
        },
      },
      { root: true },
    );
    const contentOptions = contents.documents.map((el) => ({
      ...el,
      entityId: el.id,
      entityType: 'Content',
      description: el.breadcrumb
        ? el.breadcrumb.filter((el) => el).join(' / ')
        : '',
    }));

    return { label: 'Content', options: contentOptions };
  },
  async handleSearchInlineMention({ rootGetters }, query) {
    const searchContributors = rootGetters['adminModule/searchContributors'];

    const filteredUsers = searchContributors(query);

    const userOptions = filteredUsers.map((u) => {
      const roleDescription = u.role
        ? u.role.map((r) => r.name).join(' | ')
        : '';

      return {
        label: u.username,
        entityId: u.id,
        entityType: 'User',
        type: 'User',
        description: roleDescription,
      };
    });

    return { label: 'User', options: userOptions };
  },
  notifyUploadError({ commit }, error) {
    commit(types.UPDATE_FILE_UPLOAD_ERROR, error);
  },
};
