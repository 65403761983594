/* eslint-disable camelcase */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable prefer-destructuring */
import { filterKnowledge } from 'utils/filterKnowledge';

export const navigationNodes = (state) => {
  const { focusPath, treeNodes } = state;
  const navNodes = focusPath.map((id) =>
    treeNodes.find((node) => String(node.id) === String(id)),
  );
  return navNodes.filter((node) =>
    node === undefined ? false : !['Step', 'keyStep'].includes(node.type),
  );
};

export const navigationPath = (_state, getters) => {
  const { navigationNodes } = getters;
  if (navigationNodes) return navigationNodes.map((node) => node.id);
  return [];
};

export const casePath = (state) => {
  const { casePath } = state;
  if (casePath) return casePath;
  return [];
};

export const navigationTags = (state, getters) => {
  const { nodesContent } = state;
  const { navigationNodes } = getters;
  return navigationNodes.map(
    (node) => nodesContent.find((c) => c.id === node.contentId).label,
  );
};

export const focusNavNode = (_state, getters) => {
  const { navigationNodes } = getters;
  return navigationNodes.slice(-1)[0];
};

export const focusNavNodeContent = (state, getters) =>
  state.nodesContent.find(
    (content) => content.id === getters.focusNavNode.contentId,
  );

export const buildNodes = (state) => {
  const { focusPath, treeNodes } = state;
  const navNodes = focusPath.map((id) =>
    treeNodes.find((node) => String(node.id) === String(id)),
  );
  return navNodes.filter((node) =>
    node === undefined
      ? false
      : ['Diagnostic', 'Step', 'keyStep'].includes(node.type),
  );
};

export const buildPath = (_state, getters) => {
  const { buildNodes } = getters;
  if (buildNodes) return buildNodes.map((node) => node.id);
  return [];
};

export const buildTree = (state, getters) => {
  const { navigationPath } = getters;
  const { focusTree } = state;
  let buildTree = {};
  if (focusTree !== {}) {
    buildTree = {
      ...focusTree,
    };
    navigationPath.slice(1).forEach((parentId) => {
      buildTree = buildTree.nodes.find(({ id }) => String(id) === parentId);
    });
  }
  return buildTree;
};

export const tags = (state) => {
  const { focusPath, focusTree } = state;
  const tags = [];
  if (focusTree !== {}) {
    tags.push(focusTree.content.label);
    let trans = {
      ...focusTree,
    };
    focusPath.slice(1).forEach((parentId) => {
      if (trans) {
        trans = trans.nodes.find(({ id }) => String(id) === parentId);
        if (trans) tags.push(trans.content.label);
      }
    });
  }
  return tags;
};

export const focusKnowledgeId = (state) => state.focusKnowledge.id;

export const focusNodeIsPublished = (getters) => {
  const { buildTree } = getters;
  let isPublished = false;
  if (buildTree && buildTree !== {}) {
    isPublished = buildTree.content.published;
  }
  return isPublished;
};

export const triggerEmbeddableExtensionModal = (state) =>
  !!state.embeddableExtensionClickEvent;

export const focusNode = (state) =>
  state.treeNodes.find((node) => node.id === state.focusPath.slice(-1)[0]);

export const focusNodeContent = (state, getters) =>
  state.nodesContent.find(
    (content) => content.id === getters.focusNode.contentId,
  );

export const linkedNodesMapping = (state) => {
  let linkedNodes = {};
  state.treeNodes.forEach((node) => {
    if (linkedNodes[node.contentId]) {
      linkedNodes[node.contentId].push(node.id);
    } else {
      linkedNodes[node.contentId] = [node.id];
    }
  });
  return linkedNodes;
};

export const filteredTreeNodes = (state) => {
  const { knowledgeFilters, treeNodes, nodesContent } = state;

  return knowledgeFilters.length
    ? filterKnowledge(knowledgeFilters, treeNodes, nodesContent)
    : [];
};

export const obsoleteArticlesDelay = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_OBSOLETE_ARTICLES_NOTIFICATIONS',
  );
  return preference ? preference.value : 15;
};

export const obsoleteDiagnosticsDelay = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find(
    (o) => o.type === 'ALLOW_OBSOLETE_DIAGNOSTICS_NOTIFICATIONS',
  );
  return preference ? preference.value : 15;
};

export const companyPrimaryColorPreference = (state) => {
  const { companyPreferences } = state;
  const preference = companyPreferences.find((o) => o.type === 'PRIMARY_COLOR');
  return preference;
};
