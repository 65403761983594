import gql from 'graphql-tag';

export default {
  getCompanyGroups: gql`
    query getCompanyGroupsQuery($ids: [ID!]) {
      getCompanyGroups(ids: $ids) {
        id
        users {
          id
          username
        }
      }
    }
  `,

  getAllCompanyGroupsLight: gql`
    query getCompanyGroupsQuery {
      getCompanyGroups {
        id
        name
        reachable
        usersCount
      }
    }
  `,

  getAllCompanyGroups: gql`
    query getCompanyGroupsQuery {
      getCompanyGroups {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  `,

  createCompanyGroupMutation: gql`
    mutation createCompanyGroupMutation(
      $name: String!
      $reachable: Boolean
      $knowledgeIds: [JSON]!
    ) {
      createCompanyGroup(
        name: $name
        reachable: $reachable
        knowledgeIds: $knowledgeIds
      ) {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  `,
  updateCompanyGroupMutation: gql`
    mutation updateCompanyGroupMutation(
      $id: ID!
      $name: String!
      $reachable: Boolean
      $knowledgeIds: [JSON]!
    ) {
      updateCompanyGroup(
        id: $id
        name: $name
        reachable: $reachable
        knowledgeIds: $knowledgeIds
      ) {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  `,

  deleteCompanyGroupMutation: gql`
    mutation deleteCompanyGroupMutation($id: ID!) {
      deleteCompanyGroup(id: $id) {
        id
        name
        reachable
        users {
          id
          username
          email
        }
        usersCount
      }
    }
  `,
};
