export const fetchEmployeesData = async (
  commit,
  loadingMutationType,
  setUserMutationType,
  userType,
  fetchQuery,
) => {
  try {
    commit(loadingMutationType, true);
    const res = await fetchQuery;
    commit(setUserMutationType, res[userType]);
    commit(loadingMutationType, false);
    return res;
  } catch (err) {
    commit(loadingMutationType, false);
    return err;
  }
};
