<template>
  <div class="row align-items-top">
    <div class="col-xl-12 order-xl-1">
      <router-link :to="href" class="link">
        <card class="integration-card">
          <div class="row align-items-center">
            <div class="col-1">
              <img :src="src" width="50px" />
            </div>
            <div class="col-10" style="margin-left: 20px">
              <div slot="header" class="border-0">
                <div class="row no-margin">
                  <h2 class="mb-0">{{ title }}</h2>
                  <div class="status ml-2 mt-1" v-if="isActive">
                    <img class="icon-active" src="/img/brand/active.png" />
                    <span class="status-text">Actif</span>
                  </div>
                </div>
                <p style="margin-bottom: 0px">
                  <small>{{ subTitle }}</small>
                </p>
              </div>
            </div>
          </div>
        </card>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-integration-card',
  props: {
    title: String,
    subTitle: String,
    src: String,
    href: String,
    isActive: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: inline-block;
}

.status-text {
  font-size: 12px;
  color: $green-mayday;
}

.icon-active {
  max-width: 10px;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

p {
  color: black;
}

.integration-card {
  :deep() .card-body {
    padding: 10px;
  }
}

.integration-card {
  margin-bottom: 25px;
  border: 0px;
}

.integration-card:hover {
  background-color: $grey-1-mayday;
  transition-duration: 0.5s;
}
</style>
