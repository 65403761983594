export class ContextSessionServiceData {
  constructor(axiosClient) {
    this.axiosClient = axiosClient;
  }

  async filter(searchQuery, pageSize, page) {
    try {
      const response = await this.axiosClient.get('context/sessions', {
        params: {
          searchQuery,
          pageSize,
          page,
        },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }
}
