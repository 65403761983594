import store from '../../../store';

export class PublicSettingsEventService {
  /**
   * @property {MaydayLogsClient} logClient
   */
  logClient;

  /**
   * @param {MaydayLogsClient} logClient
   */
  constructor(logClient) {
    this.logClient = logClient;
  }

  updateLoggerAttributes(origin) {
    this.logClient.setOrigin(origin);
    this.logClient.setAccessToken(store.state.accessToken);
  }

  async sendMany(events) {
    this.updateLoggerAttributes('ADMIN');
    return this.logClient.sendMany(events);
  }
}
