import gql from 'graphql-tag';

export default {
  superSearch: gql`
    query superSearch(
      $entity: [String]
      $fields: [String]
      $searchFrom: Int
      $size: Int
      $correlationId: String
      $payload: JSON!
      $lang: String
      $isNlp: Boolean
    ) {
      superSearch(
        entity: $entity
        fields: $fields
        searchFrom: $searchFrom
        size: $size
        correlationId: $correlationId
        payload: $payload
        lang: $lang
        isNlp: $isNlp
      ) {
        filters {
          keywords
        }
        correction {
          highlighted
          text
        }
        counts {
          totalCount
          casesCount
          contentsCount
          articlesCount
          diagnosticsCount
          productsCount
        }
        documents {
          __typename
          ... on Case {
            id
            label
            breadcrumb
            body
            userPermissions
            explain
          }
          ... on Content {
            id
            label
            userPermissions
            labels {
              id
              labelName
              icon
              color
            }
            children {
              id
              label
              body
              type
              redirection {
                entityId
                entityType
              }
            }
            ancestors
            breadcrumb
            denormalizedParameters
            type
            published
            isHidden
            isOutdated
            body
            explain
            summary {
              body
              isVisible
            }
            shortSummary {
              body
              isVisible
            }
            userBoost
          }
          ... on Product {
            id
            reference
            breadcrumb
            denormalizedParameters
          }
        }
      }
    }
  `,
  searchAutocomplete: gql`
    query searchAutocomplete($payload: JSON!) {
      searchAutocomplete(payload: $payload) {
        corrections {
          highlighted
          text
        }
        autocomplete {
          id
          label
          type
          remaining
        }
        filter {
          text
          type
          value {
            __typename
            ... on DenormalizedContentParameterLabel {
              documentId
              labelName
              categoryName
              icon
              color
            }
            ... on DenormalizedHierarchy {
              label
              documentId
            }
            ... on FilterValueString {
              id
            }
          }
        }
        isQuestion
      }
    }
  `,
  lightSearchContentFromKnowledge: gql`
    query lightSearchContentFromKnowledgeQuery(
      $entity: [String]
      $fields: [String]
      $searchFrom: Int
      $size: Int
      $correlationId: String
      $payload: JSON!
      $lang: String
    ) {
      superSearch(
        entity: $entity
        fields: $fields
        searchFrom: $searchFrom
        size: $size
        correlationId: $correlationId
        payload: $payload
        lang: $lang
      ) {
        documents {
          ... on Content {
            id
            label
            breadcrumb
            type
            updatedAt
            explain
            labelTranslations
            children {
              id
              label
              body
              type
            }
          }
        }
      }
    }
  `,
  getSimilarContents: gql`
    query getSimilarContents($body: String!) {
      getSimilarContents(body: $body) {
        documents {
          ... on Content {
            id
            label
            breadcrumb
            type
            updatedAt
          }
        }
      }
    }
  `,
};
