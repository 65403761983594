/* eslint-disable max-len */
import * as types from '../mutation-types';
import { updateArrayObject, flattenNodes } from 'utils/utils';

/**
 * CREATE MEHTODS
 */

export const addTreeNodeChild = function (_context, { id, type, label }) {
  return new Promise((resolve, reject) => {
    if (!id || !type || !label) return reject();
    return this.$services.treeNodes
      .addChild(id, type, label)
      .then((res) => {
        return resolve(res);
      })
      .catch(() => reject());
  });
};

/**
 * READ MEHTODS
 */

export const readTreeNode = function ({ dispatch }, id) {
  return new Promise((resolve, reject) => {
    if (!id) return reject();
    return this.$services.treeNodes
      .getOneById(id)
      .then((node) => {
        const { nodes, contents } = flattenNodes([node]);
        dispatch('updateOrCreateNodesContent', contents);
        dispatch('updateOrCreateTreeNodes', nodes);
        resolve();
      })
      .catch(() => reject());
  });
};

/**
 * UPDATE METHODS
 */

export const updateTreeNode = async function ({ state }, { id, payload }) {
  return new Promise((resolve, reject) => {
    const treeNodes = [...state.treeNodes];
    if (!id) return reject();
    const node = treeNodes.find((c) => c.id === id);
    if (!node) return reject();
    return this.$services.treeNodes
      .update(Object.assign({}, node, payload))
      .then(() => {
        resolve();
      });
  });
};

export const updateTreeNodeDragNDrop = async function (
  { state },
  { id, payload },
) {
  return new Promise((resolve, reject) => {
    const treeNodes = [...state.treeNodes];
    if (!id) return reject();
    const node = treeNodes.find((c) => c.id === id);
    if (!node) return reject();
    return this.$services.treeNodes
      .dragndrop(Object.assign({}, node, payload))
      .then(() => {
        return resolve();
      });
  });
};

export const updateTreeNodes = ({ commit }, { treeNodes, focusPath }) => {
  if (focusPath !== undefined) {
    commit(types.UPDATE_FOCUS_PATH, {
      focusPath: focusPath.map(String),
    });
  }
  commit(types.UPDATE_TREE_NODES, {
    treeNodes,
  });
};

export const updateOrCreateTreeNodes = ({ state, commit }, newTreeNodes) => {
  let treeNodes = [...state.treeNodes];
  newTreeNodes.forEach((node) => {
    const localCopy = treeNodes.find((n) => n.id === node.id);
    if (localCopy) {
      treeNodes = updateArrayObject(treeNodes, node.id, node);
    } else {
      treeNodes.push(node);
    }
  });
  commit(types.UPDATE_TREE_NODES, {
    treeNodes,
  });
};

export const duplicateTreeNodeChild = function (
  _context,
  { id, parentId, position },
) {
  return new Promise((resolve, reject) => {
    if (!id || !parentId || !position) return reject();
    return this.$services.treeNodes
      .duplicateChild(id, parentId, position)
      .then(() => {
        resolve();
      })
      .catch(() => reject());
  });
};

export const unlinkTreeNode = function (
  { dispatch, rootState },
  { id, label },
) {
  return new Promise((resolve, reject) => {
    if (!id || !label) return reject();
    const { nodesContent, treeNodes } = rootState.kbStore;
    const node = treeNodes.find((tn) => tn.id === id);
    if (!node) reject();
    return this.$services.treeNodes
      .unlink(id, label)
      .then(() => {
        const formerContent = nodesContent.find((c) => c.id === node.contentId);
        formerContent.parents = formerContent.parents.filter(
          (p) => p.id !== id,
        );
        try {
          dispatch('updateOrCreateNodesContent', [formerContent]);
        } catch (e) {
          return e;
        }
        resolve();
      })
      .catch(() => reject());
  });
};

/**
 * DELETE METHODS
 */

export const deleteTreeNodes = function ({ state, dispatch }, ids) {
  return new Promise((resolve, reject) => {
    if (!ids) return reject();
    return this.$services.treeNodes.bulkRemove(ids).then((res) => {
      const { groups, magicAnswers } = state;
      const locGroups = groups.filter((g) => !ids.includes(g.node.id));
      dispatch('updateKnowledgeGroups', locGroups);
      const locMagicAnswers = magicAnswers.filter(
        (ma) => !ids.includes(ma.node.id),
      );
      dispatch('updateMagicAnswers', locMagicAnswers);
      return resolve(res);
    });
  });
};
