import gql from 'graphql-tag';

export default {
  getAll: gql`
    query getAllResourcesQuery {
      resources {
        id
        title
        environment {
          id
          name
          development
        }
        functions {
          id
          title
          parameters {
            label
            key
            type
            required
          }
          config {
            url
            method
          }
        }
      }
    }
  `,
};
